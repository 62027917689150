.inputterBg {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 1000;
  /* -webkit-animation: slide 0.05s forwards;
  -webkit-animation-delay: 0.05s;
  animation: slide 0.05s forwards; */
  /* animation-delay: 2s; */
}

/* @-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
} */

.noneInputter {
  display: none;
}

.inputterContainer {
  /* background-color: yellow; */
  display: block;
  width: 100vw;
  height: 230px;
}

.backAndTitleContainer {
  /* background-color: orange; */
  max-height: 20px;
  display: flex;
  align-content: center;
  min-width: 130px;
  margin-top: 40px;
  margin-left: 7%;
}

.closeInputter {
  height: 17px;
}

.inputterTitle {
  line-height: 20px;
  font-size: 1.2em;
  margin-top: -1px;
  color: #143051;
  padding-bottom: 4px;
  min-height: 35px;
  font-weight: 500;
  margin-left: 20px;
}

#textInput {
  min-width: 90%;
  margin-top: 50px;
  font-size: 1.5em;
  height: 48px;
  color: #143051;
  font-weight: 400;
  text-indent: 10px;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid #496375;
}

#textInput::placeholder {
  color: #496375;
  font-weight: 300;
  text-indent: 10px;
}

.saveBtnContainer {
  min-width: 100vw;
  display: flex;
  justify-content: flex-end;
}

.inputSave {
  background-color: #0077ff;
  height: 35px;
  line-height: 37px;
  min-width: 80px;
  color: white;
  border-radius: 300px;
  margin-top: 23px;
  margin-right: 7%;
  font-weight: 500;
  font-size: 1.1em;
}

.fadedInputter {
  opacity: 0.35;
  pointer-events: none;
}

.uneditable {
  opacity: 0.5;
  /* font-style: italic; */
}
