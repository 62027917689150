

.accBtnSel {
  font-weight: 500;
  color: white;
  cursor: pointer;
}

.innerd {
  height: 100%;
  /* background-color: blue; */
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
}

.accountNavBtn {
  margin-top: 12px;
  font-size: 13px;
  cursor: pointer;
  font-weight:600;
}

.inactiveAccBtn{
  opacity: 0.7;
}

.selectPoint {
  /* margin-top: 2px; */
  /* margin-top: -6px; */
  /* display: none; */
  margin-bottom: 0px;
}

.invis {
  visibility: hidden;
}

.none {
  display: none;
}
