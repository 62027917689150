.addNewUserModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999; 
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.085s;
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.none {
  display: none;
}

.addNewUserModalBox {
  /* background-color: #e0ebf8; */
  background-color: white;
  max-width: 100vw;
  border-radius: 0 0 15px 15px;
  overflow: scroll;
  height: 220px;
  min-height: 220px;
  display: flex;
  align-items: center;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.fullHeight {
  min-height: 90vh;
  max-height: 90vh;
}

.addNewUserContentBox {
  /* padding: 15px 20px 20px; */
  color: #143051;
  overflow: scroll;
  /* padding-bottom: 75px; */
}

.addNewUserModalHeader {
  display: flex;
}

.addNewUserClose {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 15px;
  transform: rotate(90deg);
  margin-top: 2px;
}

.addNewUserTitle {
  /* margin: 0 auto; */
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  font-size: 1.4em;
  font-weight: 500;
  color: #143051;
  margin-top: 3px;
}

/* page frames */
.addUserInputFrame {
  /* background-color: lime; */
  min-width: 100vw;
  vertical-align: middle;
  margin: auto 0;
  /* min-height: 220px; */
}

.addPlusUser {
  /* background-color: #143051; */
  min-width: 5px;
  max-width: 55px;
  height: 55px;
  margin: 0 auto;
  color: white;
  /* line-height: 55px; */
  /* font-size: 2.5em; */
  /* border-radius: 100%; */
  margin-top: 12px;
}

.addUserInputContainer {
  /* background-color: lime; */
  padding-top: 5px;
  max-width: 84vw;
  margin: 0 auto;
  min-height: 200px;
  /* max-height: 170px; */
  display: grid;
}

.addUserInputTitle {
  text-align: center;
  padding-bottom: 5px;
  font-size: 1.2em;
  color: #496375;
  font-weight: 500;
  /* margin-top: 14px;
  margin-bottom: -10px; */
}

.addUserInput {
  min-height: 55px;
  font-size: 1.5em;
  border: none;
  text-align: center;
  border-bottom: 1px solid #143051;
  color: #496375;
}

/* .addUserInput:input] {
  min-height: 55px;
  font-size: 1.5em;
  border: none;
  text-align: center;
  border-bottom: 1px solid #143051;
  color: #496375;
} */

.pTeamDropdownContainer {
  min-height: 55px;
  max-height: 55px;
  display: flex;
  /* background-color: yellow; */
  align-items: flex-end;
  justify-content: center;
}

.addUserNextBtn {
  height: 35px;
  background-color: #b2c9e3;
  min-width: 70px;
  max-width: 70px;
  color: white;
  line-height: 37px;
  border-radius: 25px;
  pointer-events: none;
  font-weight: 500;
  /* margin-left: -7px; */
}

.nextBtnReady {
  background-color: #143051;
  pointer-events: auto;
}

.addUserPrimaryTeamDropdown {
  border-radius: 30px;
  background: url("../img/general/downArrowWhite.svg") no-repeat right #516b8b;
  -webkit-appearance: none;
  background-position-x: 90%;
  color: white;
  min-height: 40px;
  font-size: 1.05em;
  text-indent: 10%;
  min-width: 70%;
  min-width: 70%;
  font-weight: 500;
  padding-top: 2px;
  /* font-size: 1. */
}

.addUserCancelImg {
  min-width: 20px;
  position: fixed;
  top: 162px;
  margin-left: -38px;
}

.backNextContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  min-width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}

.addUserBack {
  width: 40px;
  /* margin-left: 15px; */
  min-width: 40px;
  max-width: 40px;
  font-size: 0.9em;
  font-weight: 500;
  color: #8997a8;
}

.addUserBackInvisible {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  /* margin-left: 15px; */
  font-size: 0.9em;
  color: #8997a8;
  pointer-events: none;
  /* visibility: hidden; */
  opacity: 0;
  /* visibility: hidden; */
}

.emptyNextThing {
  width: 40px;
}

.dateInputterForStartDate {
  /* background-color: orange; */
  min-height: 55px;
  max-height: 55px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: -18px;
  margin-bottom: -14px;
}

.hhy {
  max-height: 55px;
  /* background-color: yellow; */
  /* display: none; */
}

.opacity0 {
  opacity: 0;
}

.usrDatePickerStartDate {
  margin-top: 25px;
}

.addUserEmailExists {
  color: #ba0000;
  font-size: 0.8em;
  position: fixed;
  width: 100%;
  top: 127px;
  left: 50%;
  transform: translate(-50%, 0);
}

.reqClockOutKnobContainer {
  /* background-color: blue; */
  display: flex;
  min-height: 55px;
  justify-content: center;
  padding-top: 23px;
}

.clockOutKnob {
  /* background-color: yellow; */
  margin-top: -4px;
}

.reqClockOutY {
  color: #8ea7c5;
  font-size: 1em;
  margin-left: 15px;
  margin-top: 3px;
  font-weight: 500;
}

.reqClockOutN {
  color: #8ea7c5;
  font-size: 1em;
  margin-right: 15px;
  margin-top: 3px;
  font-weight: 500;
}

.reqClockOutNSelected {
  color: #143051;
}

.reqClockOutYSelected {
  color: #00ccff;
}

.workingPatternOptionsN {
  /* text-align: left; */
  /* font-size: 0.75em; */
  min-width: 110px;
  max-width: 110px;
}

.workingPatternOptionsY {
  /* text-align: center; */
  /* font-size: 0.75em; */
  min-width: 110px;
  max-width: 110px;
  /* padding-left: 15px; */
}

.contractual {
  color: #8ea7c5;
  font-size: 0.9em;
  margin-left: 15px;
  margin-top: 3px;
  text-align: left;
  min-width: 90px;
  max-width: 105px;
  margin-left: -15px;
  margin-right: 8px;
}

.contractualSel {
  color: #00ccff;
}

.casual {
  color: #8ea7c5;
  text-align: center;
  font-size: 0.9em;
  margin-left: 15px;
  margin-top: 3px;
  text-align: left;
  min-width: 70px;
  max-width: 110px;
  margin-left: 25px;
}

.casualSel {
  color: #00ccff;
}

.sameColour {
  background-color: #00ccff;
}

/* casFix */
.casFixContainer {
  /* background-color: yellow; */
  min-height: 55px;
  max-height: 55px;
  /* max-width: 250px;
  min-width: 250px; */

  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.casFixBase {
  /* background-color: orange; */
  border-radius: 30px;
  /* min-width: 220px; */
  min-height: 35px;
  max-height: 35px;
  /* min-width: 250px; */
  background-color: #ddebf4;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  /* padding-top: 3px; */
  /* line-height: 33px; */
  /* border: 1px solid #143051; */
}

.casFixBtn {
  min-width: 125px;
  background-color: white;
  border: 0px solid #143051;
  min-height: 35px;
  line-height: 35px;
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
}

.casFixUnsel {
  min-width: 100px;
  background-color: #ddebf4;
  border: 0px solid #143051;
  min-height: 35px;
  line-height: 35px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.casFixSel {
  min-width: 120px;
  background-color: #00ccff;
  position: relative;
  z-index: 99999;
  min-height: 35px;
  line-height: 35px;
  border-radius: 30px;
  color: white;
  /* font-weight: 400; */
  padding-left: 10px;
  padding-right: 10px;
}

.slightlySmaller {
  font-size: 0.9em;
}

.slightlySmallerAgain {
  font-size: 0.8em;
}

/* fixed varied */
.variedHrsContainer {
  background-color: blue;
  display: flex;
  min-height: 55px;
  justify-content: center;
  align-items: center;
}

.varDropdowns {
  /* background-color: yellow; */
  margin-top: 9px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.variedNumberDropdown {
  max-width: 60px;
  min-width: 60px;
  min-height: 35px;
  padding-left: 15px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 30px;
  color: white;
  background: url("../img/general/downArrowWhite.svg") no-repeat right #516b8b;
  -webkit-appearance: none;
  background-position-x: 80%;
}

.varHrsMins {
  /* background-color: pink; */
  max-height: 10px;
  /* min-height: 20px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 5px; */
  position: absolute;
  top: 124px;
  left: 51%;
  transform: translate(-50%, 0);
}

.varHrs {
  max-width: 60px;
  min-width: 60px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.8em;
}

.perWeek {
  margin-top: 5px;
  font-size: 0.9em;
}

.perWeekInvisible {
  margin-top: 5px;
  visibility: hidden;
  /* display: */
}

/* Daily hours inputter */
.schedHrsInputContainer {
  background-color: #f4f9ff;
  overflow: scroll;
  margin-top: 0px;
  max-height: 50vh;
  border-top: 1px solid #143051;

  border-bottom: 1px solid #143051;
}

.schedInputFaded {
  opacity: 0.2;
  pointer-events: none;
}

.schedHrsInputContainer::-webkit-scrollbar {
  display: none;
}

.fadeToggleButton {
  width: 35px;
}

.dayRow {
  padding-bottom: 25px;
  padding-top: 5px;
  border-bottom: 1px solid #8ea7c5;
  margin-top: 3px;
}

.dayRowLast {
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: 0px solid #ddebf4;
  margin-top: 3px;
}

.dayPopulated {
  background-color: rgb(219, 241, 219);
  padding-top: 5px;
  padding-bottom: 25px;
  margin-top: 3px;
  border-bottom: 1px solid #8ea7c5;
}

.dayPopulatedLast {
  background-color: rgb(219, 241, 219);
  padding-bottom: 25px;
}

.schedHrsRow {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
}

.schedWkDay {
  padding-right: 25px;
  font-weight: 500;
  font-size: 1.3em;
}

.schedInput {
  max-width: 45px;
  min-width: 45px;
  margin-left: 5px;
  background-color: #8ea7c5;
  border-radius: 30px;
  border: 1px solid #143051;
  min-height: 35px;
  color: white;
  text-align: center;
  font-size: 1em;
}

.startFinDivider {
  /* background-color: cyan; */
  min-width: 20px;
}

.brkRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  margin-left: 9%;
}

.breakTitleTxt {
  min-width: 30px;
  /* max-width: 30px; */
  font-size: 0.85em;
  /* background-color: yellow; */
}

.minsSuffix {
  font-size: 0.8em;
}
.brkInput {
  max-width: 45px;
  min-width: 45px;
  margin-left: 5px;
  background-color: #8ea7c5;
  border-radius: 30px;
  border: 1px solid #143051;
  min-height: 35px;
  color: white;
  text-align: center;
  font-size: 1em;
}

.brkInput::placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.schedInput::placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.at {
  font-size: 0.8em;
}

.brkInputMins {
  max-width: 45px;
  min-width: 45px;
  margin-left: 5px;
  background-color: #496375;
  border-radius: 30px;
  border: 1px solid #143051;
  min-height: 35px;
  color: white;
  text-align: center;
  font-size: 1em;
}

.brkInputMins::placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.timeInputDiv {
  margin-top: 15px;
}

.timeInputTxt {
  min-width: 90px;
  /* background-color: orange; */
  max-width: 90px;
  color: #8ea7c5;
  text-align: center;
  /* margin-left: 42px; */
  font-size: 0.8em;
  font-weight: 500;
  padding-bottom: 4px;
}

.endInput {
  min-width: 90px;
  max-width: 90px;
  color: #8ea7c5;
  text-align: center;
  margin-left: 7px;
  font-size: 0.8em;
  font-weight: 500;
}

.totalHrsAddUser {
  /* background-color: yellow; */
  min-height: 50px;
  line-height: 50px;
}

.clearHrs {
  font-size: 0.8em;
  text-align: right;
  margin-left: 20px;
  margin-right: -25px;
  min-width: 80px;
  color: #8997a8;
}

.upperSchedContainer {
  /* background-color: orange; */
  min-width: 100%;
  display: flex;
  justify-content: space-between;

  /* min-heigh5px; */
}

.checkboxSide {
  /* background-color: lime; */
  min-width: 60%;
  font-size: 0.7em;
  display: flex;
  align-items: center;
}

.hoursCalcSide {
  /* background-color: cyan; */
  min-width: 40%;
  font-size: 1.2em;
}

.fadeToggleButton {
  width: 28px;
  margin-left: 4px;
}

.fixedVariedSelect {
  text-align: left;
  font-size: 1.2em;
  margin-left: 10px;
  /* background-color: yellow; */
}

.variedNumberInput {
  max-width: 60px;
  min-width: 60px;
  min-height: 35px;
  /* padding-left: 15px; */
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 30px;
  text-align: center;
  font-size: 1.2em;
  color: white;
  background-color: #8ea7c5;
  border: solid 1px #143051;
}

.variedNumberInput::placeholder {
  color: white;
  text-align: center;
}
.userStartDateAddUserInput {
  font-weight: 500;
  padding-top: 3px;
}

.importantStartDateStrap {
  font-size: 0.9em;
  color: #8ea7c5;
  margin-top: 4px;
}

.pullUpABitAddUserTxt {
  margin-top: -4px;
}

.importantStartTxt {
  color: #143051;
}

.dateBolded {
  color: #143051;
  font-weight: 500;
}

.proratedhpwinstructions {
  font-size: 0.9em;
  line-height: 24px;
  margin-top: -10px;
}

.proHPWContainer {
  display: flex;
  justify-content: center;
  margin-top: 22px;
}

.proHPWUnit {
  display: flex;
  align-items: center;
}

.proHPWTxt {
  font-size: 0.8em;
  margin-left: -4px;
}

.noGoNextAddUsrBtn {
  opacity: 0.4;
}

.over59input {
  background-color: red;
}
