.react-datepicker {
  font-family: Work Sans, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  /* background-color: #777; */
  color: #143051;
  border: 1px solid #fff;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #143051;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.944rem;
}

.react-datepicker__day {
  color: #143051;
}

.react-datepicker__day--selected {
  background-color: #c6d3e4;
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: #143051;
  color: #fff;
}

.react-datepicker__day-name {
  color: #8ea7c5;
}

.react-datepicker__header {
  /* background-color: #fff; */
  padding-bottom: 5px;
  border-bottom: 2px solid #143051;
}

.react-datepicker__day--disabled {
  color: #dadada;
}

.react-datepicker__week {
  max-height: 38px;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #143051;
  color: white;
}

.react-datepicker__header {
  background-color: rgb(0, 0, 0, 0);
  max-height: 88px;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #143051;
}

.react-datepicker__day--outside-month {
  color: #8ea7c5;
}

.react-datepicker__month-container {
  margin-left: -6px;
}
