@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap");

/* html {margin: 0; height: 100%; overflow: hidden} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  display: block;
  justify-content: center;
  font-size: 16px;
  /* align-items: flex-end; */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  /* position: fixed; */
  /* top: 0; */
  overflow: hidden;
  max-height: 100vh;
  /* position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  min-width: 100vw; */
}

.__web-inspector-hide-shortcut__ {
  display: none;
}

.reqsPageHolder {
  /* wraps the reqs page so no scroll */
  max-height: 100%;
  overflow: hidden;
  /* position: fixed;
  top: 0;
  left: 0; */
  min-width: 100vw;
  max-width: 100vw;
}

.reqsPage {
  /* background-color: grey; */
  /* max-height: 100%; */
  height: 100vh;
  max-height: calc(100% - 188px);
  /* max-height: 400px; */
}

.reqsPageMgr {
  height: 100vh;
  max-height: calc(100% - 188px);
}

.reqsPageUpperContainer {
  min-height: 117px;
  max-height: 117px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-self: start;

  background-color: #f0f5ff;
  border-bottom:2px solid #e6eaee
}

.requestPageParent {
  background-color: #ebf0f7;
  min-width: 100%;
  min-height: 100vh;
}

.reqsPageUpperContainerTeam {
  min-height: 117px;
  max-height: 117px;
  /* padding-top: 10px; */
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-self: start;
  background-color: #403a8e;
  border-bottom: 1px solid #d1dfee;

  /* background-color: #9374d6; */
  /* background: rgb(75, 25, 172);
  background: linear-gradient(
    0deg,
    rgba(75, 25, 172, 1) 29%,
    rgba(98, 63, 173, 1) 98%
  ); */

  /* border-radius: 20px 20px 0 0; */
}

.reqsPageUpperInnerContainer {
  /* background-color: yellow; */
  min-width: 930px;
  max-width: 930px;
  margin-left: 0px;
  padding-left: 40px;
  /* margin: 0 auto; */
  /* max-height: 10px; */
  /* display: flex;
  align-items: flex-end; */
}




.requestsMenuBar {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 8px; */
  /* font-size: 1.025em; */
  color: #143051;
}

.requestsMenuBarDesktop {
  margin-bottom: 7px;
  margin-top: -2px;
  margin-left: -3px;
}




.backFromTeamReqsImg {
  max-width: 13px;
  margin-top: 10px;
  cursor: pointer;
  cursor: pointer;
  z-index: 99999;
  margin-left: 9px;
  margin-bottom: 1px;
}

.staffReqsTitleSpan {
  margin-top: 3px;
  font-size: 23px;
  font-weight: 500;
  margin-left: 16px;
  /* color: #143051 */
}

.staffRequestsTitle {
  color: #143051;
  font-weight: 500;
  font-size: 23px;
  /* margin-top: -4px; */
  text-align: left;
  margin-bottom: 2px;
}

.reqMenuLeft {
  width: 218px;
  display: flex;
  justify-content: space-between;
  color: #516b8b;
  font-weight: 300;
  margin-top: 9px;
}

.reqMenuTxt {
  cursor: pointer;
  color: #143051;
  font-size: 16px;
  /* margin-top: 1px */
}
.reqMenuTxt:active {
  transform: scale(0.95);
}

.reqTeamBtn {
  font-weight: 500;
  font-size: 16px;
  padding-left: 0px;
  cursor: pointer;
  /* min-width: fit-content; */
}



.reqMenuRight {
  display: flex;
  background-color: #a387df;
  border: 1px #8e76c1 solid;
  color: white;  min-height: 36px;
  line-height: 34px;
  padding-left: 9px;
  padding-right: -4px;
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
  margin-right: 8px;
  min-width: 68px;
  max-height: 35px;
  min-height: 35px;
  margin-top: 4px;
  font-weight: 500;
  cursor: pointer;
}

.cursorPointer {
  cursor: pointer;
}

.fadeReqActionDiv {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

.reqMenuRight:active {
  transform: scale(0.95);
}

.reqTeamDot {
  background-color: #4b1caf;
  /* background-color: #9a74eb; */
  /* background-color: #00CCff; */
  min-width: 5px;
  min-height: 5px;
  max-width: 5px;
  max-height: 5px;
  margin-top: 10px;
  position: fixed;
  margin-left: -7px;
  margin-top: 2px;
  border-radius: 15px;
  /* left: 18px; */
  /* margin-right: 10px; */
}

.upcomingTxt {
  min-width: 90px;
  max-width: 90px;
  /* font-size: 1.05em; */
  font-weight: 400;
  color: #143051;
  opacity: 0.8;

}

.pastTxt {
  /* background-color: blue; */
  min-width: 38px;
  max-width: 38px;
  /* font-size: 1.05em; */
  font-weight:400;
  color: #143051;
  opacity: 0.8;
}

.declinedTxt {
  /* background-color: lime; */
  min-width: 80px;
  max-width: 80px;
  /* font-size: 1.05em; */
  font-weight: 400;
  color: #143051;
  opacity: 0.8;

}

.reqMenuTxtSelected {
  font-weight: 500;

  color: #143051;
  max-height: 25px;
  border-bottom: 1px #e3e8ee solid;
  padding-bottom:3px;
  margin-top: 0px;
  opacity: 1;

  

}

.teamsSelectBar {
  display: flex;
  color: white;
  /* background-color: yellow; */
  align-items: flex-end;
  padding-bottom: 10px;
  min-height: 38px;
  max-width: 100%;
  overflow: scroll;
  white-space: nowrap;
  min-width: 100%;
  /* padding-: -2px; */
  /* padding-right: 200px; */
  -ms-overflow-style: none; /*IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* margin-left: 30px; */
  margin-top: -2px;
  margin-bottom: 2px;
}

.teamsSelectBarMob{
  margin-top: 8px;
  margin-bottom: -3px;
}
.emptyTeamForSpaceFromRight {
  /* background-color: yellow; */
  min-height: 5px;
  min-width: 70px;
}

.teamsSelectBar::-webkit-scrollbar {
  /* display: none; */
}

.teamReqsTxt {
  /* min-width: 500px; */
  min-width: fit-content;
  display: flex;
  color: #6a98d1;
  font-size: 16px;  
  min-height: 26px;
  margin-left: 20px;
  font-weight: 400;
  padding-bottom: 2px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.5s !important;
  cursor: pointer;
}

.teamReqsTxt:first-of-type {
  margin-left: 0px;
}

.teamReqsTxt:active {
  transform: scale(0.96);
}

.teamReqsTxt:hover {
  color: #4f79ab
}

.teamReqSelected {
  color: #214978;
  border-bottom: 1px solid #e3e8ee;
    font-weight: 500;
  font-size: 16px;
  padding-bottom: 2px;
  /* margin-bottom: -1px; */
}

.navyFadeImg {
  position: fixed;
  top: 94px;
  right: calc(5% - 1px);
  pointer-events: none;
}

.requestsContainer {
  background-color: #ebf0f7;
  min-height: calc(100%);
  max-height: calc(100%);
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.mobReqsContainerFullWidth {
  min-width:100dvw;
  /* margin-left:-2px; */
  border-radius:20px 20px 0 0;
  border-top:2px solid #e6eaee;
  /* border-left:2px solid #e6eaee; */
  /* border-right:2px solid #e6eaee; */

}

.mobReqsContainerFullWidthUser {
  border-radius:0;
  min-width: 100dvw;
  margin-left:0px;
  border-left:none;
  border-right:none;
  background-color: white;
  overflow:visible
}

.mobReqsContainerFullWidthUserCal {
  background-color: white;
  border-radius: 0;
  border-top:2px solid #f5f5f5 !important;
  border-left:none;
  border-right:none;
  margin-left:0px;
  min-width: 100dvw;
}


.mgrHoursShoulderBar {
  /* min-width:calc(100vw + 4px) !important; */
  /* margin-left:-2px !important; */
  border-radius:20px 20px 0 0  !important;
  /* border-top:2px solid #e6eaee !important; */
  /* border-left:2px solid #e6eaee !important; */
  /* border-right:2px solid #e6eaee !important; */
  min-height:108px !important;
  /* max-height:120px !important */
}



.purpleTopBorderTeam {
  /* border-top: 2px solid #7049c4; */
}

.bgColUpcoming {
  background-color: #0077ff;
  background-color: #143051;
  background-color: #fff;
}

.bgColTeam {
  background-color: #4b1caf;
  /* background-color: #9374d6; */
}

.fullHeightReqsBackdrop {
  min-height: 100vh;
  background-color: white;
  /* padding-bottom: 100px; */
}

.requestUnitPending {
  min-height: 72px;
  max-height: 72px;
  min-width: 90%;
  max-width: 90%;
  background-color: white;
  margin: 0 auto;
  /* -moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  box-shadow: 0 0 5px #ccc; */
  border-radius: 5px;
  color: #143051;
  color: #484848;
  margin-top: 10px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  border: 1px solid #e7ebef;
  /* border-bottom: 2px solid #e7ebef; */
}

.requestUnitPending:active {
  /* min-height: 72px;
  max-height: 72px;
  min-width: 90%;
  max-width: 90%;
  background-color: white;
  margin: 0 auto;
  -moz-box-shadow: 0 0 12px #ccc;
  -webkit-box-shadow: 0 0 12px #ccc;
  box-shadow: 0 0 12px #ccc;
  border-radius: 12px;
  color: #143051;
  color: #484848;
  margin-top: 12px;
  opacity: 0.4; */
  transform: scale(0.98);
}

.requestUnitPending:first-of-type {
  margin-top: 0px;
}

.reqContentContainerPending {
  display: flex;
  /* background-color: yellow; */
  min-height: 72px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.reqContentContainerPending:hover {
    background-color: #f1f6fb;
  
}

.requestUnitMgr {
  /* min-height: 100px; */
  /* padding-top: 13px; */
  margin-top: 4px;
  padding-bottom: 3px;
  /* max-width: 440px; */
  /* background-color: grey; */
}

.requestUnit {
  margin-top: 7px;
}

.reqLeftUnitIcon {
  min-width: 50px;
  max-width: 50px;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: black */
}
.pendingLeft {
  /* background-color: lime; */
  min-width: 50%;
  text-align: left;
  /* text-indent: 10%; */
  padding-top: 8px;
  /* padding-left: 11%; */
}

.leaveTypeNameTxt {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: -5px;
  margin-top: -7px;
  color: #496375;
  color: #143051;
  border-bottom: 1px solid #bfccdc;
  padding-bottom:3px;
  
  /* color: #00ccff; */
}

.reqUnitDateRangeTxt {
  font-size: 14px;
  margin-top: 8px;
  color: #95aaba;
  font-weight: 500;
  /* color: #143051; */
}

.pendingRight {
  text-align: right;
  min-width: 37%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* min-height: 40px; */
}

.estimatedTotalDuration {
  font-size: 12px;
  margin-top: -1px;
  background-color: #8ea7c5;
  /* background-color: #00ccff; */
  color: white;
  /* color: #496375; */

  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  /* padding-top: 1px; */
  max-height: 24px;
  max-width: fit-content;
  min-width: fit-content;
  /* margin-right: -6px; */
  font-weight: 400;

  max-width: fit-content;
  min-width: fit-content;
  /*  */
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 20px;
  line-height: 20px;
  align-items: center;
  border-radius: 5px;
  /* border: 1px solid #859dbb; */
  /* color: #496375; */
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 0px;
  padding-bottom: 0px;
  /* background: rgb(247, 250, 255); */
}

.approvedExcess {
  /* background-color: #99c587;
  color: #fff;
  border-color: #6fa05c; */
  /* color: white; */
}

.declinedExcess {
  /* background-color: #9e7a7a; */
}

.contractedTotal {
  margin-right: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #496375;
  padding-bottom: 3px;
  /* color: black; */
  /* color: #647583; */
  color: #143051;
}


.ContainerMobMarginTopMinus10 {
  margin-top:-10px !important
}
/* .moveRightABit {
  margin-left: 12px;
} */

.ofyourAllow {
  margin-top: 7px;
}

.pendingSplitter {
  background-color: #143051;
  min-width: 80%;
  min-height: 3px;
  max-width: 80%;
  margin-left: 10%;
  margin-top: 20px;
  margin-bottom: 25px;
  border-radius: 5px;
}

.requestUnitApproved {
  min-height: 72px;
  max-height: 72px;
  min-width: 90%;
  max-width: 90%;
  background-color: rgb(255, 255, 255);
  margin: 0 auto;
  /* border: 1px solid #d9d9d9; */
  border: 1px solid #e7ebef;
  /* border-bottom: 2px solid #e7ebef; */
  /* border: 1px solid rgba(89, 89, 89, 0.267); */

  border-radius: 5px;
  color: #143051;
  color: #484848;
  margin-top: 8px;
  /* min-height: 80px; */
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.requestUnitApproved:first-of-type {
  /* margin-top: -10px; */
}

.showReqs {
  /* padding-top: 110px; */
  padding-bottom: 30px;
  /* background-color: #1a406b; */
  /* margin-top: 2px; */
  /* padding-top: 62px; */
  min-height: calc(100vh - 213px);
  max-height: calc(100vh  - 213px);

      /* min-height: calc(100vh - 221px);
    min-height: calc(calc(var(--vh, 1vh) * 100) - 221px);
    max-height: calc(100vh - 221px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 221px); */
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid #e0ebf8;
padding-top: 15px;
min-width: 100%;
/* sctoll-to */
padding-left:10px;
padding-right:10px;



  /* margin-top: -19px; */
}

.formsShowReqs {
  min-height: calc(100vh - 208px) !important;
  max-height: calc(100vh  - 208px) !important;
}

.formsShowReqsMob {
  min-height: calc(100vh - 265px) !important;
  max-height: calc(100vh  - 265px) !important;
}
.myUpcomingReqsHeight {
  min-height: calc(100vh - 202px);
  max-height: calc(100vh - 202px);
}

.myUpcomingReqsHeightFull {
  min-height: calc(100vh - 202px);
  max-height: calc(100vh - 202px);
  padding-top:4px;
  margin-top:5px;
}



.paddingTop20 {
  padding-top: 10px;
}

.showReqsWithoutFilterBar {
  /* padding-top: 110px; */
  padding-bottom: 20px;
  /* background-color: #1a406b; */
  /* margin-top: 2px; */
  padding-top: 62px;
  min-height: calc(100vh - 237px);
  max-height: calc(100vh - 237px);
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid #e0ebf8;
padding-top: 5px;
min-width: 100%;
  /* margin-top: -19px; */
}

.showReqsTeamUpcoming {
  min-height: calc(100vh - 208px);
  max-height: calc(100vh - 208px);
}


.showFilterTeamShowReqs {
  min-height: calc(100vh - 270px);
  max-height: calc(100vh - 270px);
}

.borderRightGrey {
  border-right: 1px solid #e0ebf8;

}

.killTopPadding {
  padding-top: 20px;
  margin-top: -8px;
}

.pullUpABit {
  /* margin-top: -18px; */
}

.outstandingPullUp {
  margin-top: 22px !important; 
  margin-left: 3px;
  margin-bottom: 10px !important;
}

.awaitingPullUp {
  /* margin-top: -12px !important; */
  margin-bottom: 12px;
}

.paddingTop0 {
  padding-top: 20px;
  margin-top: -8px;
}

.upcomingsSoloHolder {
  margin-top: -6px;
}

.reqContentContainerApproved {
  display: flex;
  min-height: 72px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}


.reqContentContainerApproved:hover {
  background-color: #f1f6fb;

}


.reqContentContainedDeclined:hover {
  background-color: #f1f6fb;

}

.requestUnitApproved:active {
  transform: scale(0.98);
}

.requestUnitDeclined:active {
  transform: scale(0.98);
}

.approvedLeft {
  min-width: 15%;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.approvedMiddle {
  min-width: 50%;
  text-align: left;
  /* margin-top: -2px; */
  padding-top: 11px;
  min-height: 50px;
}

.approvedRight {
  background-color: pink;
  min-width: 36%;

  text-align: right;
  padding-right: 9.4%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.unitImgIcon {
  max-width: 15px;
}

.requestUnitDeclined {
  min-height: 72px;
  max-height: 72px;
  min-width: 90%;
  max-width: 90%;
  background-color: #fff;
  margin: 0 auto;
  /* border: 1px solid #d9d9d9; */
  border-radius: 5px;
  color: #143051;
  color: #484848;
  margin-top: 8px;
  /* border-bottom: 2px solid #dccaca; */
  border: 1px solid #e7ebef;
  /* border-bottom: 2px solid #e7ebef; */
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;

  /* opacity: 0.5; */
}

.requestUnitDeclined:first-of-type {
  margin-top: -10px;
}

.loadingReqsGifImg {
  max-width: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.reqInfoUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.reqInfoModalBox {
  background-color: #ebf0f7
;
  color: #143051;
  border-radius: 10px;
  min-height: 584px;
  max-height: 584px;
  min-width: 340px;
  max-width: 340px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;
  border: 1px solid #e7ebef;
}

.userReqInfoModalBox {
  background-color: #ebf0f7
;
  color: #143051;
  border-radius: 10px;
  min-height: 520px;
  max-height: 520px;
  min-width: 340px;
  max-width: 340px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;
  border: 1px solid #e7ebef;
}

.mobReqInfoModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 15px 15px 0 0;
  max-height: calc(100vh - 88px) !important;
  min-height: calc(100vh - 88px) !important;

}
.mobReqInfoModalBoxIos {
  max-height: calc(100vh - 68px) !important;
  min-height: calc(100vh - 68px) !important;
}
.mobUserReqBody {
  min-height: calc(100dvh - 196px) !important;
  max-height: calc(100dvh - 196px) !important;
  /* min-height: 541px; */
  /* max-height: 514px; */
}

.mobUserReqBodyIos {
  min-height: calc(100dvh - 216px) !important;
  max-height: calc(100dvh - 216px) !important;
  /* background-color: yellow; */
}

.mobMgrReqBodyLeft {
  min-height: calc(100dvh - 234px) !important;
  max-height: calc(100Dvh - 234px) !important;
}

.zoomIn {
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.slideUp {
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
}

.duplicateReqWarningModalBox {
  background-color: #f0f5ff;
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 247, 252, 1) 35%
  ); */
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  position: absolute;
  top: 45%;
  left: 50%;
  /* max-height: 400px; */
  /* overflow: scroll; */
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 300px;
  max-width: 300px;
  /* max-width: 290px; */
  /* min-width: 290px; */
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
}

.approvedModalBg {
  background-color: #ebf0f7;
  /* 
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(240, 247, 236, 1) 50%
  ); */
}

.declinedUnitOnMgrOnly {
  opacity: 0.4;
}

.declinedModalBg {
  background-color: rgb(245, 245, 245);
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 244, 244, 1) 50%
  ); */
}

.reqInfoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: grey; */
  min-height: 40px;
  margin-top: 10px;
  /* border-bottom: 1px solid #e0ebf8;
  border-bottom: 1px solid #143051; */
}

.closeReqInfoModalImg {
  max-width: 15px;
  min-width: 15px;
  margin-left: 20px;
  cursor: pointer;

}

.closeReqInfoModalImg:hover {
  opacity: 0.8;
}

.reqInfoTitleTxt {
  margin-top: 0px;
  font-size: 18px;
  font-weight: 500;
  /* margin-right: 3px */
}

.reqInfoTitleTxtMy {
  margin-top: 0px;
  font-size: 1.2em;
  font-weight: 500;
  margin-right: 24px
}

.submittedReqTxt {
  padding-bottom: 2px;
  text-align: left;
  margin-left: 10%;
  color: #214978;
  font-size: 12px;
  font-weight: 500;
  /* text-transform: uppercase; */
}

.madeReqDaysAgo {
  /* font-weight: 500; */
  padding-bottom: 8px;
  text-align: left;
  /* margin-left: 10%; */
  margin-top: 22px;
}

/* .requestDurationValue {
  text-align: left;
  margin-left: 12%;
  color: #496375;
  font-size: 1em;
} */

.submissionDateTxt {
  font-weight: 400;
  padding-top: 4px;
  max-width: 88%;
  margin-left: 10%;
  color: #516b8b;
  padding-right: 18px;
  font-size: 12px;
  line-height: 16px;
}

.reqInfoFooter {
  min-height: 50px;
  max-height: 50px;
  max-width: 90%;
  margin: 0 auto;
  border-top: solid 1px #e3e8ee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 6px;
  padding-right: 0px;
}

.deleteRequestBtn {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #747474;
  min-height: 35px;
  line-height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
}

.defoDeleteRequestBtn {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #516b8b;
  color: white;
  min-height: 35px;
  line-height: 35px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 30px;
  font-size: 0.93em;
}

.closeReqInfo {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 35px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  min-width: 62px;
  max-width: 62px;
}

.closeReqInfo:hover {
  background-color: #214978;
}

.reqInfoDuplicateContainer {
  /* background-color: #143051; */
  color: #143051;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 10px;
  margin: 0 auto;
  max-width: 80%;
}

.reqInfoTxtContainer {
  font-size: 0.9em;
  text-align: center;
  /* padding-left: 20px; */
}

.reqNotMadeTxt {
  font-weight: 500;
  font-size: 1.4em;
  color: #143051;
}

.reqNotMadeinfoTxt {
  /* padding-right: 10px; */
  padding-top: 20px;
  padding-bottom: 6px;
  font-size: 1em;
  color: #5d6f85;
  font-weight: 400;
}

.hmmEmoji {
  width: 45px;
}

.closeReqNotMadeNoteBtn {
  background-color: #36495f;
  border: 1px solid #143051;
  cursor: pointer;
  color: white;
  min-height: 30px;
  line-height: 28px;
  max-width: 75px;
  border-radius: 5px;
  margin: 0 auto;
  font-size: 0.9em;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
  /* padding-top: 2px; */
}

.reqOwnerNameTxt {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: -7px;
  /* margin-top: -1px; */
  color: #496375;
  /* color: #143051; */
  /* color: #9374d6; */
}

.mgrReqDurationString {
  margin-top: 10px;
  color: #859dbb;
  font-size: 14px;
  font-weight: 500;
}

.mgrSmallLeaveTypeNameTxt {
  font-size: 16px;
  margin-top: -8px;
  color: #9374d6;
  color: #496375;
  padding-left: 3px;
}

.mgrSmallLeaveDurationTxt {
  font-size: 14px;
  margin-top: 7px;
  color: #859dbb; /* font-size: 0.9em; */
  padding-left: 3px;
  font-weight: 500;
}

.rightMgrReq {
  min-width: 40%;
  padding-top: 0px;
  margin-top: -2px;
  /* margin-right: 2px; */
  /* padding-right: 2px; */
}

.leftMgrReq {
  max-width: 450%;
  min-width: 45%;
  padding-left: 0px;
  margin-left: -14px;
  /* background-color: orange; */
}

.teamReqsHeaderContainer {
  display: flex;
  justify-content: space-between;
  /* min-height: 110px; */
  margin-top: 30px;
  /* padding-top: 60px; */
  /* background-color: pink; */
  margin-bottom: 0px;
}

.teamReqsHeaderContainerMob {
  margin-top: 44px;
  margin-bottom: -3px;
}

.teamReqsHeaderLeft {
  /* background-color: orange; */
  display: flex;
  min-width: 70%;
  align-items: center;
  /* padding-top: 70px; */
  margin-top: 74px;
}

.teamReqsHeaderLeftMob {
  margin-top: 90px;
}

.teamReqsHeaderLeft {
  /* padding-top: 90px; */
  margin-left: -9px
}

.teamReqsHeaderRight {
  /* background-color: black; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 100px;
  max-width: 100px;
  padding-top: 62px;
}

.checklistAllows {
  /* max-width: 30px; */
  opacity: 0.6;
  margin-top: 5px;
  cursor: pointer;
}

.checklistActive {
  opacity: 1;
  margin-top: 5px;
  cursor: pointer;
}

.checklistAllows:active {
  transform: scale(0.9);
}

.checklistActive:active {
  transform: scale(0.9);
  /* max-width: 5px; */
}

.reqCog {
  max-width: 30px;
  margin-top: -5px;
  margin-right: 10px;
  opacity: 0.6;
  cursor: pointer;
}

.reqCogActive {
  max-width: 30px;
  margin-top: -5px;
  margin-right: 10px;
  opacity: 1;
  cursor: pointer;
}

.reqCogActive:active {
  transform: scale(0.9);
}

.reqCog:active {
  transform: scale(0.9);
  /* max-width: 5px; */
}

.checklistImgHolder {
  max-width: 30px;
  min-width: 30px;
  margin-top: 5px;
}

.staffReqModalContentContainer {
  /* background-color: lime; */
  display: flex;
  min-height: 120px;
  position: relative;
  /* max-height: 100%; */
  height: 100%;
  /* height: 180px; */
  /* overflow: scroll; */
  max-width: 90%;
  min-width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 0px;
  /* border-bottom: 1px solid #bac5d3 */
  /* padding-left: 10%; */
}

.staffReqModalContentContainerIos {
  /* padding-bottom: 20px;
  background-color: blueviolet */
  

}
.staffReqModalContentLeft {
  background-color: white;
  text-align: left;
  max-width: 73%;
  min-width: 73%;
  /* margin-left: -1px; */
  padding-left: 10px;
  padding-right: 3px;
  padding-top: 3px;
  padding-bottom: 10px;
  min-height: 180px;
  max-height: 437px;
  min-height: 437px;
  overflow-y: scroll;
  /* overflow-x: none; */
  border-top: solid 2px #e6eaee;
  border-right: solid 2px #e6eaee;
  border-left: solid 2px #e6eaee;

  border-radius: 6px 6px 0px 0px;
}

.staffReqModalContentLeftScroll {
  background-color: white;
  text-align: left;
  max-width: 40%;
  min-width: 58%;
  margin-left: 10%;
  padding-left: 10px;
  padding-right: 3px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-around; */
  padding-top: 15px;
  padding-bottom: 2px;
  min-height: 180px;
  max-height: 300px;
  overflow: scroll;
  border-top: solid 2px #e6eaee;
  border-right: solid 1px #e6eaee;
  border-left: solid 1px #e6eaee;

  border-radius: 15px 15px 0px 0px;
}

.staffReqModalContentLeftAbs {
  min-height: calc(90vh - 158px) !important;
  max-height: calc(90vh - 158px) !important;
}

.staffReqModalContentRight {
  /* background-color: yellow; */
  max-width: 26%;
  min-width: 26%;
  max-height: 429px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.staffReqApproveBtn {
  color: #143051;
  padding-left: 14px;
  padding-right: 14px;
  font-weight: 500;
  font-size: 16px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  min-width: 100%;
}

.staffReqApproveBtn:hover {
  background-color: #e7eef8;
}

.shortStaffTxt {
  line-height: 20px;
}

.staffReqDeclineBtn {
  color: #143051;
  padding-left: 14px;
  padding-right: 14px;
  font-weight: 500;
  font-size: 16px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  min-width: 100%;
}

.staffReqDeclineBtn:hover {
  /* color: #213855; */
  background-color: #e7eef8;

}


.sureDeclineBtn {
  min-height: 35px;
  font-size: 1em;
  line-height: 38px;
  padding-left: 14px;
  padding-right: 14px;
  max-width: 95px;
  padding-top: 4px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 1.1em;
  color: #ba0000;

}

.staffReqViewBtn {
  min-height: 35px;
  background-color: #143051;
  line-height: 35px;
  color: white;
  max-width: 95px;
  border-radius: 30px;
}

.mgrRequestModalBottomBar {
  min-height: 1px;
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: #8298b1;
  display: none;
}

.staffReqFooterContainer {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  padding-bottom: 12px;
  padding-top: 8px;
  max-height: 50px;
  min-height: 50px;
  border-top: 1px solid #bac5d3;

}

.staffReqFooterContainerMob {
  position: fixed;
  bottom: 2px
}

.reqFooterUnit {
  min-width: 45%;
  min-height: 57px;
  border-right: 1px #8298b1 solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reqFooter50pcW {
  min-width: 50%;

}

.reqFooterUnit:last-child {
  border: none;
}

.reqFooterNoBorder {
  border: none;
}

.viewStaffReqImg {
  max-width: 40px;
  min-width: 40px;
  margin: 0 auto;
  margin-top: -28px;
  /* margin-bottom: 18px; */
  /* margin-top: -10px; */
}

.staffReqUnderStaffedBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #d7e1ee;
  padding-bottom: 14px;
  padding-top: 14px;
  min-width: 100%;
}
.staffReqUnderstaffedInfo {
  font-size: 0.9em;
  margin-left: 10px;
  font-weight: 300;
  color: #496375;
}

.staffReqUnderstaffDotGreen {
  min-width: 7px;
  max-width: 7px;
  min-height: 7px;
  max-height: 7px;
  border-radius: 10px;
  background-color: #6db451;
}

.staffReqUnderstaffDotRed {
  min-width: 7px;
  max-width: 7px;
  min-height: 7px;
  max-height: 7px;
  border-radius: 10px;
  background-color: #ba0000;
}

.staffReqSubmittedAgo {
  /* margin-top: 5px; */
  margin-left: 10px;
  padding-top: 16px;
  color: #496375;
  font-size: 1em;
  font-weight: 500;
  padding-bottom: 15px;
}

.declineReqFirstBtn {
  color: #516b8b;
  padding-left: 14px;
  padding-right: 14px;
  max-width: 95px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 1.1em;
  padding-top: 4px;
}

.staffReqNote {
  /* margin-top: 5px; */
  /* margin-left: 10px; */
  padding-left: 10px;
  padding-right: 3px;
  padding-top: 16px;
  color: #496375;
  color: #0077ff;
  font-size: 1em;
  font-weight: 500;
  max-width: 95%;
  padding-bottom: 15px;
  border-top: 1px solid #d7e1ee;
}

.staffReqDeclineReasonTxt {
font-weight:500;
  margin-left: 6px;
  padding-top: 0px;
  color: #8ea7c5;
  font-size: 14px;
  padding-right: 12px;
  padding-bottom: 18px;
}

.editDateBarContainerFlex {
  display: flex;
  /* background-color: yellow; */
  /* max-width: 180px; */
  max-height: 60px;
}

.inlineEditReqDateImg {
  max-width: 12px;
  margin-left: 12px;
  position: relative;
  /* margin-top: -2px; */
  padding-bottom: 1px;
  opacity: 0.6;
  /* z-index: 9999999; */
}
.inlineEditReqDateImg:hover {
  opacity: 1
}


.inlineEditReqDateImg2 {
  max-width: 10px;
  margin-left: 5px;
  position: relative;
  /* margin-top: -2px; */
  margin-bottom: -1px;
  /* opacity: 0.6; */
  margin-right: 0px;
  cursor: pointer;
}
.inlineEditReqDateImg2:hover {
  /* opacity: 1 */
}

.staffReqModalDatesString {
  color: #143051;
  /* background-color: #496375; */
  /* color: white; */
  margin-right: 10px;
  border-bottom: 1px solid #d7e1ee;
  padding-bottom: 14px;
  padding-top: 14px;
  min-width: 96%;
  /* max-width: fit-content; */
  padding-left: 6px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* border-radius: 10px 10px 0 0; */
}

.staffReqDuration {
  /* margin-top: 5px; */

  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 14px;
  min-width: 96%;
  max-width: 96%;
  padding-left: 6px;
  font-weight: 500;
  color: #143051;
  font-size: 16px;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.staffReqBtn {
  background-color: #a2bddf;
  min-height: 24px;
  line-height: 24px;
  max-width: 48px;
  min-width: 48px;
  color: white;
  border-radius: 40px;
  margin: 0 auto;
  margin-top: 14px;
  font-size: 0.9em;
  font-weight: 500;
}

.declineReasonViewBtn {
  background-color: #a2bddf;
  min-height: 24px;
  line-height: 24px;
  max-width: 48px;
  min-width: 48px;
  color: white;
  border-radius: 40px;
  margin: 0 auto;
  margin-top: 14px;
  font-size: 0.7em;
}

.reqDurationAndDates {
  display: flex;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
}

.requestSubHeader {
  display: flex;
  justify-content: space-between;
  max-width: 86%;
  /* margin: 0 auto; */
  align-items: center;
  margin-left: 6%;
  padding-top: 5px;
  margin-bottom: -1px;
}

.leaveTypeSubBubble {
  /* background-color: #d2e6fd; */
  /* background-color: #496375; */
  /* border: 1px solid #496375; */
  border: 1px #e6eaee solid;
  color: #496375;
  /* margin: 0 auto; */
  padding-left: 13px;
  padding-right: 13px;
  min-height: 28px;
  line-height: 27px;
  font-size: 12px;
  /* margin-top: 9px; */
  margin-bottom: 8px;
  border-radius: 5px;
  max-width: fit-content;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 1px;
  background-color: white;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.4s !important; */
}

.reqNoteBtn {
  background-color: #214978;
  border: solid 1px #143051;
  color: #fff;
  min-width: 60px;
  max-width: 60px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 31px; */
  font-weight: 500;
  font-size: 14PX;
  margin-bottom: 6px;
  cursor: pointer;
}

.reqNoteBtnMgr {
  background-color: #8f71d1;
  border: #6c4bb5 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  min-width: 60px;
  max-width: 60px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  line-height: 29px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 6px;
  cursor: pointer;
}

.reqNoteBtnMgr:hover {
  background-color: #785cb6;
}

.noteNotViewedDot {
  min-width: 4px;
  max-width: 4px;
  min-height: 4px;
  max-height: 4px;
  background-color: #fff;
  border-radius: 100%;
  margin-right: -2px;
  margin-top: -20px;
  margin-left: 48px;
  margin-right: -50px;
}

.noteViewedBg {
  background-color: #fff;
  /* background-image: linear-gradient(to bottom, rgb(245, 250, 255), #d7e8fc); */
  color: #143051;
  border-color: #8298b1;
  color: #214978;
  min-height:35px;
}
.reqNoteBtnNoteImg {
  max-width: 11px;
  margin-right:4px;


}
.viewReqTeamCal {
  background-color: #1f5087;
  border: solid 1px #143051;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  min-width: 60px;
  max-width: 60px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding-bottom: 1px;
  /* line-height: px; */
  font-weight: 500;
  font-size: 14px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: #fff;
cursor:pointer;
  /* margin-bottom: 4px; */
}


.approvedDeclinedBubble {
  background-color: #a2bddf;
  color: white;
  /* margin: 0 auto; */
  padding-left: 9px;
  padding-right: 9px;
  min-height: 30px;
  line-height: 27px;
  font-size: 12px;
  /* margin-top: 3px; */
  margin-bottom: 8px;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 500;
  padding-top: 0px;
  
 
}

.greenBubble {
  background-color: #a8cc9a;
  border: 1px solid #6db451;

}

.redBubble {
  background: none !important;
  border-radius: 0px !important;
  /* border: 1px solid #e89292; */
border:none !important;
  border-left:2px solid #e89292 !important;
  color: #ba0000 !important;
  padding-right:0px;
}

.navyBubble {
  /* background-color: #214978; */
  /* border: 1px solid #143051; */
  color: #bbcada !important;
  border:2px dashed #bbcada !important;
  background-color: white !important;
  border-radius:5px  !important;
  min-height:30px !important;
  max-height:30px !important;
  line-height:25px !important
}
.declineReasonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  min-width: 100%;
  /* padding-left: 10%; */
  margin-bottom: 2px;
  /* min-height: 224px; */
}

.mobDeclineReasonContainer {
  min-height: 212px;
  max-height: 212px;

}

.declineReasonInput {
  margin: 0 auto;
  min-width: 100%;
  min-height: 144px;
  max-height: 144px;
  resize: none;
  /* padding: 10px; */
  border: 1px solid #8997a8;
  border-radius: 5px;
  background-color: white;
  color: #143051;
  overflow: scroll;
  padding: 10px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  background-color: white;
  font-size: 16px;
  overflow: scroll;
  line-height: 20px;
  font-weight: 500;
  color: #6a8bb1;
  margin-bottom: 19px;
}

.declineReasonInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
  padding:9px;
}

.mobDeclinReasonInput {
  min-height: 124px;
  max-height: 124px;
}

.pushDown2px {
  margin-top: 2px !important
}

.declineReasonSpanOptional {
  /* font-style: italic; */
  color: #516b8b;
  font-weight: 400;
  font-size: 0.8em;
}

*,
*:active,
*:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  -webkit-focus-ring-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

.declineReasonTitle {
  min-width: 100%;
  text-align: left;
  padding-bottom: 8px;
  font-weight: 500;
  color: #213855;
  margin-top: 20px;
  margin-left: 8px;
}

.cancelDecline {
  font-size: 1.1em;
  margin-top: 0px;
  color: #496375;
  font-weight: 500;
}

.backFromDeclineEditReqDecision {
  font-size: 1.1em;
  margin-top: 0px;
  color: #496375;
  font-weight: 500;
  padding-top: 4px;
}

.cancelEditDecisionBtnImg {
  margin-top: 1px;
  height: 16px;
  opacity: 0.5;
  margin-left: 10px;
  margin-right: 7%;
}

.reqApprovedBy {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 14px;
  min-width: 96%;
  max-width: 96%;
  padding-left: 6px;
}

.approveByTxtName {
  font-size: 18px;
  font-weight: 500;
  color: #143051;
  /* font-size: 1.05em; */
  margin-top: 3px;
}

.approveByTxtTitle {
  font-size: 1.1em;
  padding-bottom: 2px;
  color: #214978;
  font-size: 12px;
  /* text-transform: uppercase; */
  font-weight: 540;
}

.modalReqDecisionImg {
  margin-right: 25px;
  /* margin-top: 3px; */
  min-width: 20px;
  /* visibility: hidden; */
}

.noBorderBottom {
  border-bottom: solid 0px white;
}

.changeDecisionButton {
  font-weight: 500;
  font-size: 14px;
  color: #592eb6;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  /* display: flex; */
  min-height: 35px;
  max-height: 35px;
  /* line-height: 26px; */
  background-color: #0077FF;
  border: 1px solid #636f7e;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  color: white;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
}

.changeDecisionButton:hover {
  background-color: #1875df;
}


.disableOverflow {
  overflow: none !important;
}

.emptyFooter {
  opacity: 0;
  pointer-events: none;
  min-height: 20px;
  max-height: 20px;
}

.mgrReqHistoryBtn {
  /* background-color: black; */
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  position: fixed;
  top: calc(100% - 148px);
  border-radius: 100px;
  left: 45px;
  opacity: 0.9;
}

.mgrReqHistoryBtnFade {
  /* background-color: black; */
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  position: fixed;
  top: calc(100% - 148px);
  border-radius: 100px;
  left: 45px;
  opacity: 0.7;
}

.filterReqsBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 7%;
  max-height: 60px;
  margin-top: -10px;
  padding-bottom: 8px;
  /* paddin */
}

.mgrUpcomingOrPastTitle {
  margin-top:22px;
  margin-bottom: -8px;
  padding-left: 10%;
  color: #496375;
  font-weight: 500;
  font-size: 1.3em;
}

.filterBox {
  /* background-color: pink; */
  display: flex;
  padding-left: 6%;
  padding-top: 8px;
  padding-bottom: 3px;
  justify-content: flex-end;
  margin-right: 7%;
  /* margin-top: 10px; */
}

.filterReqsBtn {
  font-size: 0.95em;
  margin-top: 8px;
  color: #496375;
  border: 1px solid #496375;
  border-radius: 5px;
  min-height: 28px;
  line-height: 28px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 2px;
  margin-top: 20px;
  cursor: pointer;
}

.filterReqsBtnActive {
  font-size: 0.95em;
  margin-top: 8px;
  color: #fff;
  border: 1px solid #143051;
  display: flex;
  background-color: #214978;
  border-radius: 5px;
  align-items: center;
  min-height: 28px;
  line-height: 28px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: -22px;
  font-weight: 500;
  padding-top: 2px;
  margin-top: 20px;
  cursor: pointer;
}

.nameFilterDropdown {
  color: #6a8bb1;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 188px;
  min-width: 188px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #fff;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 164px;
  display: flex;
  cursor: pointer;
  /* margin-right: 12px; */
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  /* padding-right: 27px; */
}

.outstandingPullUpABit {
  margin-top: -10px;
  margin-bottom: -12px;
}

.filterAppDecContainer {
  /* background-color: orange; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 7%;
}

.unitCrossBlueImg {
  min-width: 20px;
  margin-right: 10px;
}

.unitTickBlueImg {
  min-width: 20px;
  margin-right: 12px;
}

.closeFilterImg {
  max-width: 10px;
  margin-left: 8px;
  margin-top: -2px;
}

.upcomingPastBar {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 110px;
  /* background-color: yellow; */
  margin-left: 10%;
  margin-top: 26px;
  font-weight: 500;
  font-size: 1.3em;
}

.upcomingPastInactive {
  /* background-color: black; */
  color: #bac5d3;
  cursor: pointer;
  /* margin-bottom: -8px; */
}

.upcomingPastActive {
  /* margin-top: 8px; */
  /* padding-left: 10%; */
  color: #496375;
  font-weight: 500;
  cursor: pointer;
}

.upcomingPastSpacer {
  /* background-color: pink; */
  min-width: 10px;
}

.deleteLoadingOpacLow {
  opacity: 1;
  pointer-events: none;
  margin-top: 22px;
  margin-bottom: 22px;
  min-width: 40px;
  max-width: 40px;
}

.emptyLoaderSpace {
  min-width: 40px;
  max-width: 40px;
  /* min-height: 2px; */
  margin-top: 21px;
  margin-bottom: 20px;
  min-height: 25px;
}

.contractedTitle,
.excessTitle {
  margin-top: 16px;
  padding-bottom: 6px;
  text-align: left;
  margin-left: 10%;
  color: #214978;
  font-size: 12px;
  font-weight: 400;
  /* text-transform: uppercase; */
}

.approvedByTxt {
  margin-top: 16px;
  padding-bottom: 4px;
  text-align: left;
  margin-left: 10%;
  font-weight: 500;
  font-size: 12px;
  color: #859dbb;
  text-transform: uppercase;
}

.mgrReqLeaveEndStartTitle {
  margin-top: 16px;
  font-weight: 500;
  padding-bottom: 4px;
  text-align: left;
  margin-left: 6px;
  color: #214978;
  font-size: 12px;
  font-weight: 500;
}

.pushDownABit {
  margin-top: 2px;
}

.approvedByMarginTop {
  margin-top: 6px !important;
}

.understaffedMarginTop {
  margin-top: 8px !important;
  margin-bottom: -9px;}
.requestDurationInfoSplitter {
  background-color: #e6eaee;
  min-height: 1px;
  min-width: 86%;
  max-width: 86%;
  margin: 0 auto;
  margin-top: 14px;
}

.mgrLeaveStartEndSplitter {
  background-color: #d7e1ee;
  min-height: 1px;
  min-width: 95%;
  max-width: 95%;
  /* margin: 0 auto; */
  margin-top: 14px;
}

.mgrLeaveStartEndSplitter:last-child {
  /* display: none; */
}

.requestDurationValue {
  text-align: left;
  margin-left: 10%;
  font-weight: 500;
  color: #143051;
  font-size: 16px;
  margin-top: 0px;
}

.mgrReqStartEndVal {
  text-align: left;
  margin-left: 6px;
  font-weight: 500;
  color: #143051;
  font-size: 18px;
  margin-top: 4px;
}

.youWillBePaidForContainer {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}

.estimateDiscTxt {
  color: #bbcada;
  background-color: #fff;
  border:1px solid #bbcada;
  padding-left: 4px;
  opacity: 1;
  /* text-transform: uppercase; */
  padding-right: 4px;
  border-radius: 5px;
  /* text-transform: uppercase; */
  margin-left: 5px;
font-size:10px;
padding-bottom:1px;




  /* text-transform: uppercase; */
  /* margin-left: 5%; */
}

.lastSplitter {
  margin-top: 14px;
}

.cancelReqImgContainer {
  background-color: #0bf;
  border: 1px solid #0af;
  min-width: 80px;
  display: flex;
  border-radius: 5px;
  color: white;
  align-items: center;
  margin-left: -5px;
  padding-left: 3px;
  cursor: pointer;
  min-height: 35px;
}

.cancelReqImgContainer:hover {
  background-color: #0af;
}

.cancelReqImgContainerDefault {
  /* background-color: #333; */
  /* background-color: #fff; */
  margin-left: -5px;

  min-width: 80px;
  display: flex;
  /* border-radius: 40px; */
  /* margin-left: 10px; */
  color: white;
  align-items: center;
  justify-content: flex-start;
}

.cancelReqCancelTxt {
  font-size: 0.9em;
  line-height: 24px;
  min-height: 24px;
  padding-right: 8px;
  font-weight: 500;
  /* text-indent: 0px; */
}

.payableTxt {
  font-weight: 500;
  /* padding-bottom: 2px; */
  margin-left: 6px;
  margin-top: 14px;
  min-height: 26px;
  color: #859dbb;
  font-size: 0.92em;
  font-weight: 500;
  display: flex;
  align-items: center;

  

}


.x198419 {
  padding-bottom: 2px; 
    margin-left: 6px;
    margin-top: 14px;
    margin-bottom: -12px;
    color: #214978;
    font-size: 12px;
    font-weight: 400;
}
.allowanceUsedTxt {
  /* padding-bottom: 2px; */
  /* color: #214978; */
  /* margin-left: 5%; */
  margin-left: 6px;
  margin-top: 14px;
  margin-bottom: -12px;
  color: #214978;
  font-size: 12px;
  font-weight: 400;
  /* text-transform: uppercase; */
}

.excessMinsMgrReqVal {
  margin-top: -12px;
}

.mgrRequestExcessMinsConfirmedOrEstimatedSpan {
  color: #a2bddf;
  /* color: white; */
  /* margin-right: 12%; */
  /* font-size: 1.2em; */
  /* margin-top: 2px; */
  /* background-color: #8298b1; */
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  /* padding-bottom: 4px; */
  border-radius: 5px;
  line-height: 20px;
  margin-left: 8px;
  font-size: 0.9em;
  font-weight: 500;
  /* margin-top: -2px; */
  /* background-color: #143051; */
  /* min-height: 30px;
}
*/
}
.confirmedDiscBgOnly {
  background-color: #a8cc9a; 
  border-color:#87a67a;
  color: #fff;
}



.pendingMgrReqBlueDot {
  background-color: #9374d6;
  min-width: 8px;
  max-width: 8px;
  min-height: 8px;
  border-radius: 10px;

}

.pendingMgrReqBlueDotUserPending {
  /* background-color: #a2bddf; */
  background-color: #143051;
  /* background-color: #9a74eb; */
  background-color: #00ccff;
  min-width: 8px;
  min-height: 8px;
  border-radius: 10px;
  /* margin-right: -22px; */
  /* margin-left: 15px; */
}
.approvedMgrReqBluedot {
  background-color: #b6daa8;
  min-width: 8px;
  min-height: 8px;
  border-radius: 10px;
  margin-right: -22px;
  margin-left: 15px;
}

.declinedMgrSmallTxt {
  color: #38084b;
}
.userPendingMainFontColour {
  color: #00ccff;
  color: #0077ff;
}

.editDatesModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  min-height: 100vh;
  min-width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.editDatesModalBox {
  background-color: white;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  position: absolute;
  /* display: block; */
  min-height: 100px;
  /* top: 50%;
  left: 50%; */
  /* max-height: 320px; */
  /* overflow: scroll; */
  /* -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  max-width: 350px;
  min-width: 350px;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
    animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  cursor: default;
}

.editDatesModalBoxMob {
  position: fixed;
  bottom: 0;
  border-radius: 5px 5px 0 0;
  min-width: 100vw;
  max-width: 100vw;
}

.editDateModalTitle {
  font-weight: 500;
  margin-top: 15px;
  font-size: 1.2em;
  margin-bottom: 26px;
}

.editDateStartTitle {
  margin-top: 20px;
  margin-bottom: 3px;
  font-size: 1em;
  color: #496375;
  font-weight: 500;
}
/* 
.editDateModalInput {
  border-radius: 30px;
  border: none;
  background-color: #8ea7c5;
  color: white;
  min-height: 35px;
  font-size: 1em;
  padding-left: 14px;
  padding-right: 8px;
  padding-top: 3px;
  letter-spacing: 1px;
  max-width: 174px;
  min-width: 174px;
  background: url("../img/general/calicon.svg") no-repeat right #8ea7c5;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 1px solid #36495f;
  font-weight: 500;
} */

.editDateModalInput {
  border-radius: 5px;
  border: 1px solid #dde9f9;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-size: 0.95em;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 162px;
  min-width: 162px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 130px;
  margin-bottom: 8px;
  margin-top: 8px;
  cursor: text;
}
.editDatesModalFooterContainer {
  /* background-color: orange; */
  display: flex;
  font-weight: 500;
  justify-content: space-around;
  align-items: center;
  min-height: 60px;
  margin-top: 20px;
}

.cancelEditDateTxt {
  color: #143051;
  font-size: 16px;
  font-weight: 400;
  font-size: 14px !important;
  margin-right: 12px;
  cursor: pointer;
}

.cancelEditDateTxt:hover {
opacity: 0.8;
}

.submitEditDateBtn {
  font-size: 16px;
  color: #fff;
  background-color: #07f;
  border: #0469de 2px solid;
  font-weight: 500;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 25px; */
  border-radius: 5px;
  min-height: 35px;
}

.submitEditDateBtn:hover {
  background-color: #0469de;
}

.overlappingEditDateTxt {
  font-size: 14px;
  max-width: 162px;
  margin: 0 auto;
  margin-top: 18px;
  color: #6a8bb1;
  font-weight: 500;
  margin-bottom: -0px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #dde9f9;  
  padding-bottom: 2px;
  padding-top: 2px;

  padding-left: 10px;
  padding-right: 0px;
  min-height: 38px;
  display: flex;
  justify-content: space-around;
}

.loadingChangingDatesImg {
  max-width: 40px;
}

.reqLoadingImg {
  max-width: 40px;
  min-width: 40px;
  margin-top: 6px;
}

.blackCornerBg {
  background-color: black;
  min-height: 20px;
  min-width: 100%;
  position: absolute;
  /* z-index: ; */
  display: none;
}

.pullUp15px {
  margin-top: -15px;
}

.reqTimelineDiv {
  min-width: 80px;
  min-height: 220px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: -12px; */
}

.timelineIcon {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 5px;
  /* border: 1px solid #143051; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: inset -2px 0 0 #1c3f68, inset 0 -2px 0 #1c3f68,
    inset 2px 0 0 #1c3f68, inset 0 2px 0 #1c3f68;
  margin-top: 6px;

  /* animation-name: zoomModalIn !important;
  animation-duration: 0.4s !important; */
}

.mgrTimelineIcon {
  background: #effaff;
}

.timelineIconGreenBg {
  background-color: #f6fef3;
}

.timelineIconRegBg {
  background-color: #ffeeee;
}

.timelineIconAbsenceBg {
  background-color: #ecf7ff;
}
.pushDownIfSingle {
  margin-top: 40px;
}

.iconCalDateNumber {
  font-size: 1.45em;
  padding-top: 2px;
  letter-spacing: 1px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.halfDayIconAppend {
  font-size: 0.45em;
  margin-left: 3px;
  margin-top: 2px;
}

.iconBlob {
  background-color: white;
  border: 2px solid #3b597c;

  color: #143051;
  font-size: 0.96em;
  padding-top: 2px;
  padding-bottom: 4px;
  font-weight: 500;
  min-width: 100%;
  border-radius: 5px;
}

.middleTimelineBar {
  /* background-color: orange; */
  min-height: 72px;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6px;
}

.timelineBarCircle {
  background-color: #8997a8;
  min-width: 5px;
  max-width: 5px;
  min-height: 5px;
  max-height: 5px;
  border-radius: 10px;
}

.timelineBarLine {
  min-height: 61px;
  min-width: 1px;
  margin-top: -1px;
  background-color: #8997ab;
}

.timelineDur {
  background-color: #fff;
  border: 2px solid #1c3f68;
  position: fixed;
  margin-top: 21px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: 500;
  color: #1c3f68;
  padding-top: 4px;
  /* padding-bottom: 2px; */
  padding-bottom: 5px;
  min-width: 60px;
  max-width: 80px;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.4s !important; */
}

.approvedTimelineDurColour {
  background-color: #a8cc9a;
  border: 2px solid #6db451;
  color:white
}

.declinedTimelineDurColour {
  background-color: #e89292;
  border: 2px solid #ba0000;
  color:white

}

.absenceTimelineDurColour {
  background-color: #214978;
  border: 2px solid #143051;
  color:white
}


.startHalfButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  /* background-color: #0077ff; */
  max-width: 162px;
  margin: 0 auto;
  margin-top: 8px;
  padding-bottom: 6px;
  padding-top: 2px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 5px;
  border: 1px solid #dde9f9;
  color: #496375;
  cursor: pointer;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.startHalfButtonContainer:hover {
  background-color: #edf3f9;
}

.selectedHalfWayBg {
  background-color: #3b597c;
  border: 2px solid #214978;
  color: white;
  margin-top:6px;
  margin-bottom: 6px;
}

.selectedHalfWayBg:hover {
  background-color: #214978;
}

.startHalfButtonTxt {
  max-width: 130px;
  text-align: left;
  font-size: 0.8em;
  margin-top: 3px;
  margin-right: -20px;
  font-weight: 500;
}

.regenSchedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 14px; */
  /* background-color: #0077ff; */
  max-width: 200px;
  margin: 0px auto;
  padding-right: 10px;
  padding-left: 10px;
  /* margin-top: 30px; */
  padding-bottom: 6px;
  padding-top: 2px;
  border-radius: 5px;
  border: 1px solid #dde9f9;
  color: #496375;
  cursor: pointer;
}



.selectedRegenSchedContainer {
  background-color: #0bf;
  color: #FFF;
  border: 2px solid #0af;
  margin-top: -2px;
  /* margin-bottom: -1px */


}

.selectedRegenSchedContainer:hover {
  background-color: #0af;
}

.regenSchedBtnTxt {
  /* max-width: 130px; */
  text-align: left;
  font-size: 0.8em;
  margin-top: 3px;
  margin-right: 10px;
  font-weight: 500;
  /* color: white; */
}

.startEndEditDividerBar {
  min-height: 1px;
  max-height: 1px;
  min-width: 80%;
  max-width: 80%;
  margin: 15px auto;
  margin-bottom: 6px;

  background-color: #dde9f9;
}

.userReqBody {
  display: flex;
  margin-bottom: -14px;
  justify-content: space-around;
  /* margin-top: 8px; */
  min-height: 390px;
  max-width: calc(100% - 32px);
  min-width: calc(100% - 32px);
  /* margin-left: 6px; */
  margin: 8px auto;
  margin-left: 15px;
  margin-bottom: 0px;
  border-bottom: solid 1px #bac5d3;
}

.userReqLeftContent {    background-color: white;
  text-align: left;
  max-width: 75%;
  min-width: 75%;
  padding-right: 3px;
  padding-top: 15px;
  min-height: 410px;
  max-height: 410px;
  overflow-y: auto;
  border-top: solid 2px #e6eaee;
  border-right: solid 1px #e6eaee;
  border-left: solid 1px #e6eaee;
  padding-bottom: 10px;
  border-radius: 5px 5px 0px 0px;
  -webkit-font-smoothing: antialiased;
  /* margin-bottom: -8px; */
  margin-left: 4px;
  padding-bottom:30px  
}

.mobUserReqLeftContent {
  min-height: calc(100dvh - 197px);
  max-height: calc(100dvh - 197px);
  /* background-color: yellow; */
  /* min-height:514px;
  max-height:514px */
}

.mobUserReqLeftContentIos {
  min-height: calc(100dvh - 217px);
  max-height: calc(100dvh - 217px);
}

.userReqRightContent {
  /* background-color: yellow; */
}

.payableContainer {
  display: flex;
}

.invisibleHeightOf15px {
  min-height: 15px;
}
.invisLeftContentOfReq {
  visibility: hidden;
}
.showHalfDayInfo {
  background-color: #a2bddf;
  color: white;
  font-weight: 500;
  font-size: 11px;
  /* min-width: 100%; */
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 4px;
  margin-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px #859ebd solid;
}

.bottomCurvedDeclined {
  /* border-radius: 5px; */
  /* border: solid 1px #9e7a7a; */
  /* margin-bottom: 0px; */
  /* padding-bottom: 0px; */
  /* border-bottom: solid 3px #9e7a7a; */
  /* border-top: solid 3px #9e7a7a; */
}

.bottomCurvedApproved {
  /* border-radius: 5px; */
  /* border: solid 1px #b6daa8; */
  /* border-top: solid 3px #b6daa8; */
  /* border-bottom: solid 2px #b6daa8; */
  /* margin-bottom: 3px; */
  /* padding-bottom: 0px; */
  /* max-height: 300px; */
  /* background-color: black; */
}

.halfDayInfoHook {
  min-width: 2px;
  background-color: #4d6987;
  min-height: 12px;
  margin-bottom: -10px;
}

.noReasonDeclinedLeftBox {
  /* max-height: 210px; */
  /* margin-bottom: -20px; */
}

.pushDownDeclineReasonPlaceholder {
  margin-top: 14px;
  color: #acc3e1;
}

.changeExcessIcon {
  max-width: 40px;
  /* margin-left: 9%; */
  transition-duration: 0.1s;
  transition-property: transform;
}

.changeExcessIcon:active {
  /* transform: scale(0.92); */
  transform: rotate(20deg);
}

.mgrReqestFooterHolder {
  display: flex;
  max-width: 90%;
  margin: 0px auto;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
      border-top: solid 1px #bac5d3;
  /* min-height: 57px;
  max-height: 57px; */

  /* margin-left: 10%; */
}

.mgrReqestFooterHolderMob {
  margin-bottom:80px !important;
}

.excessMgrLeftContentBox {
  /* background-color: yellow; */
  min-height: 281px;
  animation-name: slideModalRight !important;
  animation-duration: 0.08s !important;
  padding-bottom: 20px;
  /* margin-left: -100px; */
}

.excessHeaderContainer {
  display: flex;
  background-color: white;
  max-width: 196px;
  justify-content: space-between;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #143051;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px #e6eaee solid;
margin-top:8px}

.returnBackImgAccruedLeaveOnReqImg {
  /* margin-top: -16px; */
  max-width: 16px;
  cursor: pointer;
  /* margin-left: -12px; */
}

.returnBackImgAccruedLeaveOnReqImg:hover {
opacity: 0.5;}
.excessMgrTxtHeader {
  font-weight: 500;
  font-size: 12px;
  margin-top: -1px;
  /* margin-bottom: 12px; */
  margin-left: -1px;
}

.excessRow {
  max-width: 92%;
}

.excessRowHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 9px;
}
.excessRowTitle {
  font-weight: 500;
  color: #496375;
  margin-left: 5px;
  /* background-color: yellow; */
  font-size: 0.95em;
}

.excessRowKnob {
  margin: 0;
  min-width: 35px;
  margin-top: -18px;
  padding-bottom: 15px;
  margin-left: 0px;
}

.excessRowSubTxt {
  margin-top: -4px;
  font-size: 0.85em;
  padding-left: 0px;
  color: #859dbb;
  font-weight: 400;
  min-height: 40px;
margin-left:12px;}

.excessRowSplitter {
  min-width: 90%;
  margin: 0 auto;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 6px;
  max-width: 90%;
  min-height: 1px;
  max-height: 1px;
  background-color: #496375;
}

.disableCog {
  visibility: hidden;
  pointer-events: none;
}

.accruedLeaveDurationPreview {
  font-size: 0.9em;
  margin-top: 0px;
  line-height: 22px;
  margin-bottom: 6px;
  /* margin-left: 12px; */
}

.accruedMinsSpan {
  /* background-color: #496375; */
  color: #a2bddf;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 400;
  padding-left: 0px;
  padding-right: 7px;
  margin-left: 3px;
  border-radius: 15px;
}

.accruedMinsTxt {
  font-weight: 500;
  color: #496375;
  margin-left: 9px;
}

.nonExcessContent {
  /* background-color: pink; */
  min-height: 281px;
}

.previousPayExcessFade {
  opacity: 0.4;
}

.previousPayExcessModalUnderlay {
  background: rgba(0, 0, 0, 0.3);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999999;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.previousPayExcessModalBox {
  background-color: #fff;
  /* background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(244, 247, 252, 1) 35%
    ); */
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  position: absolute;
  top: 45%;
  left: 50%;
  /* max-height: 400px; */
  /* overflow: scroll; */
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 310px;
  min-width: 310px;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: default;
}

.excessWarningTxt {
  font-weight: 400;
  color: #496375;
  max-width: 80%;
  margin: 0 auto;
  margin-top: 25px;
  font-size: 16px;
}

.excessOverrideBtnContainer {
  /* background: #0077ff; */
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 1em;
  min-height: 40px;
  align-items: center;
  padding-left: 10px;
  max-width: 230px;
  
}

.overridePayExcessBtn {
  background-color: #0077cc;
  color: white;
  border: 1px solid #2066b6;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  padding-bottom: 2px;
  font-weight: 500;
}

.overridePayExcessBtn:hover {
  background-color: #2066b6;
}
.cancelOverrideButton {
  background-color: #214978;
  color: white;
  border: 1px solid #143051;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  padding-bottom: 2px;
  font-weight: 500;
}

.cancelOverrideButton:hover {
  background-color: #143051;
}

.editReqDatesWarningIcon {
  min-width: 24px;
  max-width: 24px;
}

.editReqDatesWarningTxt {
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
  line-height: 14px;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 400;
}

.excessMgrTxtHeaderSplitter {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin-left: 6px;
  /* margin-top: 8px; */
  /* margin-bottom: 10px; */
  /* margin-top: 3px; */
  /* background-color: #85a5cc; */
}

.accruedLeaveReqBtnContainer {
  /* background-color: yellow; */
  margin-left: 6%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 120px;
  cursor: pointer;
}

.accruedLeaveReqTxtBanner {
  /* background-color: #85a5cc; */
  max-height: 36px;
  min-height: 36px;
  line-height: 14px;
  padding-left: 12px;
  margin-left: -10px;
  position: relative;
  /* padding-right: 10px; */
  font-size: 0.8em;
  font-weight: 400;
  color: #94a3ad;
  z-index: 2;
  margin-top: 8px;
  max-width: 120px;
  text-align: left;
}

.navyAccruedBg {
  color: #143051;
}

.mgrPendingReqsTxt {
  color: #4b1caf;
  color: #9374d6;
}


.fadedReqNoteBtn {
  opacity: 0.6;
}

.reqNotePlusBtnCharacter {
  font-size: 1.3em;
  /* line-height: 1px; */
  /* margin-top: 2px; */
}

.reqNoteUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.reqNoteModalBox {
  background-color: #f0f5ff;
  color: #143051;
  border-radius: 10px;
  min-height: 35px;
  /* max-height: 400px; */
  /* overflow: scroll; */
  min-width: 290px;
  max-width: 290px;
  cursor: default;
  /* max-width: 290px; */
  /* min-width: 290px; */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.reqNoteBodyTxt {
  font-size: 14px;
  font-weight: 500;
  margin: 0 auto;
  max-width: 80%;
  margin-top: 35px;
  color: #516b8b;
  line-height: 16px;
}
.closeReqNoteModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 26px; */
  border-radius: 5px;
  min-height: 35px;
  max-width: 65px;
  margin: 0px auto;
  margin-top: 25px;
  margin-bottom: 20px;
}

.closeReqNoteModalBtn:hover {
  background-color: #214978;
}

.navyBg {
  background-color: #fff;
  max-width: 280px !important;
  /* color: #143051; */
}

.addNoteToReqUnderlay {
  background-color: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.addNoteToReqModalBox {
  background-color: #f0f5ff;
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 247, 252, 1) 35%
  ); */
  color: #143051;
  border-radius: 15px;
  min-height: 35px;
  /* position: absolute;
  top: 45%;
  left: 50%; */
  /* max-height: 400px; */
  /* overflow: scroll; */
  /* -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  min-width: 300px;
  max-width: 300px;
  /* max-width: 290px; */
  /* min-width: 290px; */
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.addNoteQuestionTxt {
  font-weight: 500;
  padding: 20px;
  padding-top: 28px;
  color: #143051;
  font-size: 1em;
}

.addNoteOptionBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 30px;
}

.addNoteYes {
  background-color: #0077ff;
  border: 1px solid #145051;
  color: #ffffff;
  min-width: 70px;
  min-height: 30px;
  line-height: 29px;
  font-size: 1em;
  font-weight: 500;
  border-radius: 10px;
}

.addNoteNo {
  /* background-color: #ffeeee; */
  border: 1px solid #a8c0da;
  /* color: white; */
  min-width: 70px;
  min-height: 30px;
  line-height: 29px;
  font-size: 1em;
  font-weight: 500;
  border-radius: 10px;
}

.addNoteInput {
  /* background-color:  */
  border: 1px solid #143051;
  border-radius: 10px;
  min-width: 80%;
  min-height: 100px;
  margin-bottom: 15px;
  font-size: 1em;
  padding: 10px;
  font-weight: 400;
}

.saveNoteBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  max-width: 220px;
  margin: 0 auto;
  /* margin-right: 10px; */
  /* margin-top: 10px; */
  margin-bottom: 20px;
}

.backAddNote {
  min-width: 57px;
  min-height: 35px;
  line-height: 34px;
  font-size: 0.9em;
  font-weight: 500;
  /* margin-left: -20px; */
}

.submitAddNote {
  background-color: #0077ff;
  border: 1px solid #145051;
  color: white;
  min-width: 70px;
  min-height: 30px;
  line-height: 29px;
  font-size: 1em;
  font-weight: 500;
  border-radius: 10px;
}

.disableSaveNoteBtn {
  opacity: 0.5;
  pointer-events: none;
}

.reqCostBubbleContainer {
  min-height: 24px;
  max-height: 24px;
  min-width: 90%;
  max-width: 90%;

  /* margin: 0 auto; */

  /* color: #143051; */
  /* color: #484848; */
  margin-top: -12px;
  display: flex;
  justify-content: flex-end;
}

.reqCostBubble {
  background-color: #e3e8ee;
  /* border: 1px solid #143051; */
  min-height: 24px;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 0px;
  font-size: 0.8em;
  font-weight: 500;
  color: #4f566b;
  z-index: 100;

  /* margin-bottom: 5px; */
  /* position: fixed; */
}

.greenCostBubble {
  /* background-color: #b6daa8;
  border: 1px solid #6db451; */
}

.redCostBubble {
  /* background-color: #dccaca;
  border: 1px solid #b88383; */
  display: none;
}

.reqRightBarDivider {
  min-height: 2px;
  background-color: #d9e4f2;
  min-width: 60px;
  max-width: 60px;
  margin-top: 5px;
  margin-bottom:10px;
}

.viewReqTeamUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);

}

.viewReqTeamModalBox {
  background-color: #f0f5ff;
  color: #143051;
  border-radius: 10px;
  min-height: 35px;
  /* max-height: 50%; */
  max-width: 90vw;
cursor:default;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  /* box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%); */
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  min-width:340px !important
}

.viewReqTeamModalBoxMob {
  background-color: #f0f5ff;
  color: #143051;
  border-radius: 5px 5px 0 0;
  min-height: 35px;
  min-width: 100vw;
  max-width: 100vw;
cursor:default;
position:fixed;

bottom:0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%);
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.viewReqTeamModalBoxIos {
  padding-bottom: 40px;

}
@media only screen and (min-width: 800px) {
  /* .viewReqTeamModalBox {
    max-width: 760px;
    min-width: 760px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

  } */
}

@media only screen and (max-width: 799px) {
  /* .viewReqTeamModalBox {
    max-width: 360px;
    min-width: 360px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

  } */
}

.loadingReqCalTeamPreview {
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
}

.reqTeamCalContainer {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;
  /* overflow-y: scroll; */
  padding-left: 4px;
  padding-right: 4px;
  max-height: 420px;
  overflow-y: scroll;

  max-width: 100%;
  border-bottom: solid 2px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-radius: 5px;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 6px;
  
}

.reqTeamCalHolder {
  display: flex;
  /* min-height: fit-content; */
  /* min-height: 800px; */
  /* padding-top: 100px; */
  /* overflow-y: scroll; */
  justify-content: space-between;
  /* max-height: 420px; */
  /* overflow-y: scroll; */
}

.reqTeamCalLeft {
  /* background-color: yellow; */
  min-width: 80px;
  max-width: 80px;
  margin-left: 10px;
}
.reqTeamCalName {
  min-width: 100%;
  max-width: 100%;
  min-height: 64px;
  max-height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 2px;
  font-weight: 500;
  border-top: #e6eaee solid 2px;
  /* text-transform: uppercase; */
  font-size: 12px;
  text-align: left;
  padding-left: 6px;
  cursor: pointer;
  
}

.reqTeamCalName:hover {
  background-color: #ebf0f7;
}

.reqTeamCalNameTwoItems {
  min-height: 80px;
  max-height: 80px;
}

.reqTeamCalNameThreeItems {
  min-height: 124px;
  max-height: 124px;
}

.reqTeamCalNameFourItems {
  min-height: 160px;
  max-height: 160px;
}

.reqTeamCalNameFiveItems {
  min-height: 200px;
  max-height: 200px;
}

/* .reqTeamCalName:first-of-type {
  border-top: none;
} */
.invisibleOffBoxPlaceholder {
  visibility: hidden;
  font-size: 1.4em;
  /* color: #c8e8f0; */
  font-weight: 500;
  /* background-color: #143051; */
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.reqTeamCalRight {
  min-width: calc(100% - 90px);
  max-width: calc(100% - 90px);
  display: flex;

  overflow: scroll;

  font-size: 8px;
  /* padding-right: 62px; */
  padding-bottom: 10px;
}



.reqTeamDayCol {
  min-width: 100px;
  margin-left: 3px;
}

.reqTeamDayCol:nth-child(odd) {
  background-color: #f7fbff;

}


.reqTeamShiftsContainer {
  display: flex;
  justify-content: space-around;
  padding-left: 5px;
  padding-right: 5px;

}

.reqTeamDayLimitContainer {
  /* min-width:300px;
  background-color: yellow; */
}
.reqTeamDayColUnit {
  border-top: #e6eaee solid 2px;
  /* max-height: 60px; */
  min-height: 64px;
  /* min-width: 100%;
  max-width: 100%; */
  /* min-width: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
  padding-top: 6px;
/* min-width: 300px */
/* overflow-y: scroll; */
  /* background-color: pink; */
}

.thisUserColUnit {
  border-bottom: 2px solid #a2bddf;
  background-color: #2066b6;
}

.vv1void {
  /* background-color: grey; */
  min-height: 30px;
  max-height: 30px;
}

.vv1void2 {
  /* background-color: grey; */
  min-height: 20px;
  max-height: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.reqTeamCalDayBarContainer {
  display: flex;
  max-height: 60px;
}

.topBarVoidHolder {
  min-width: 40px;
  max-width: 40px;
  background-color: black;
}

.reqTeamDayTitle {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8em;
  font-weight: 500;
  padding-top: 4px;
  background-color: #fff;
  border-right: 1px solid white;
  border-radius: 5px 5px 0 0;
}

.limitBox1 {
  min-height: 20px;
  max-height: 20px;
  background-color: #2066b6;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #143051;
  border-radius: 3px;
  margin-top: 2px;
  margin-bottom:2px;
}

.selectedReqTeamCalInitials {
  background-color: #0077ff;
  color: white;
  border-radius: 6px 0 0 6px;
}

.selectedReqTeamCalInitials:hover {
  background-color: #3488e8;
}

.reqTeamLowerDay {
  min-height: 20px;
  max-height: 20px;
  background-color: #e6eaee;
  border-right: 1px solid white;
  border-radius: 0px 0px 5px 5px;
  border-top: 1px #a2bddf solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  font-weight: 500;
  margin-top: 12px;
}

.reqTeamCalNameLastVoid {
  min-height: 30px;
  max-height: 20px;
  min-width: 10px;
}

.reqTeamShifts {
  border: 1px solid #e6eaee;
  background-color: white;
  border-radius: 5px;
  min-height: 30px;
  max-height: 30px;
  min-width: 84px;
  line-height: 27px;
  font-size: 14px;
  padding-left: 9px;
  padding-right: 9px;
  font-weight: 500;
  margin-top: 4px;
  /* margin-left: 3px; */
  margin-right: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reqTeamShifts:hover {
  border-color: #bac5d3
}
.cursPointerMgr {
  cursor: pointer;
}


.withinLimitBg {
  background-color: #a8cc9a;
  border: 1px solid #6db451;
}

.exceedsLimitBg {
  background-color: #e04040;
  border: 1px solid #ba0000;
  background-color: #e89292;
  border: 1px solid #ba0000;
  /* border-radius: 3px; */
  margin-top: 2px;
  margin-bottom: 2px;
}

.limitReachedBg { 
  background-color: #6a98d1;
}

.reqTeamOffBox {
  font-size: 12px;
  color: #c8e8f0;
  font-weight: 500;
  /* background-color: #143051; */
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: -6px;
  margin-bottom: -2px;
}

.reqTeamOffBox:hover {
  opacity: 1;
}

.leaveDurReqCalSpan {
  color: #fff;
  font-weight: 500;
}

.cursPointerMgr:hover {
  opacity: 0.8;
}

.noShiftsFullHalfOff {
  font-size: 1.6em;
  padding-right: 12px;
  padding-left: 12px;
  max-width: 80px;
}

.notWorkingToday {
  font-size: 1.6em;
  color: #bbcada;
  font-weight: 500;
}

.clickedInitialsNameShow {
  font-size: 0.6em;
}

.viewNameModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.viewNameModalBox {
  background-color: #f0f5ff;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  min-width: 280px;
  /* max-width: 80%; */
  max-width: fit-content;

  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  padding-top: 20px;
}

.closeNameModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  /* padding-left: 9px;
  padding-right: 9px; */
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  min-width: 90%;
  max-width: 90%;
  margin-top: 20px;
  margin-left: 5%;
  margin-bottom: 15px
}

.viewTeamCalNameTxtItem {
  font-weight: 500;
  font-size: 1em;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.italicJobTitle {
  color: #214978;
  font-weight: 500;
  font-size: 14px;
}
.dsOnNameBarName {
  /* position: relative; */
  background-color: #d5e3ee;
  max-height: 18px;
  min-height: 18px;
  line-height: 18px;
  /* border-right: #7493ba solid 1px; */
  background: rgb(254, 251, 255);
  background: linear-gradient(
    90deg,
    rgba(254, 251, 255, 1) 13%,
    rgba(213, 227, 238, 1) 100%
  );
}
.dsOnNameBar {
  /* position: relative; */
  background-color: #d5e3ee;
  max-height: 18px;
  min-height: 18px;
  line-height: 18px;

  /* min-width: 10px; */
  /* margin-top: -9px; */
}

.barDsViewTeamCalOnReqSpan {
  font-size: 1.2em;
  color: #8ea7c5;
  /* color: #8ea7c5; */
  font-weight: 500;
  /* font-style: italic; */
}

.halfDayBgColour {
  background-color: #516b8b;
}

.colWithSplitter {
  display: flex;
  min-width: fit-content;
}

.viewTeamCalSplitter {
  background-color: #d5e3ee;
  min-width: 1px;
  min-height: 1px;
  margin-left: 3px;
}

.viewTeamCalSplitterInitial {
  background-color: #d5e3ee;
  min-width: 2px;
  min-height: 1px;
  margin-left: 3px;
}

.reqTeamCalHeader {
  /* background-color: yellow; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 10px; */
  /* padding-left: 10px; */
  /* padding-bottom: 20px; */
  min-width: 100%;
  min-height: 60px;
}

.teamCalModalHeaderTxt {
  /* max-width: 70%; */
  font-weight: 500;
  font-size: 18px;
  max-width: fit-content;
  /* margin-top: 14px; */
  /* min-height: 60px; */
}

.closeTeamCalModalImg {
  max-width: 15px;
  min-width: 15px;
  /* margin-left: 2px; */
  margin-top: 0px;
  cursor: pointer;
}
.closeTeamCalModalImg:hover {
  opacity: 0.8;
}
.limitStatus {
  background-color: #a8cc9a;
  border: 1px solid #6db451;
  max-width: fit-content;
  color: white;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 22px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: -10px;
  margin-bottom: 10px;
}

.limitStatusExceeded {
  /* background-color: #e89292; */
  /* border: 1px solid #ba0000; */
  max-width: fit-content;
  color: #ba0000;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 22px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: -10px;
  margin-bottom: 10px;
}

.limitTxtBelow {
  margin-top: 4px;
  font-size: 1.4em;
  color: #36495f;
}

.reqTeamLoadingLong {
  margin-top: -7px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #2066b6;
  /* font-style: italic; */
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.reqsEndingMoreThan60DaysAgoTxt {
  max-width: 80%;
  margin: 0 auto;
  color: #94a3ad;
  font-size: 14px;
  margin-top: 20px;
  /* margin-bottom: 120px */
}

.blueLoadingReqs {
  margin-top: 35px;
}

.makeDsTxtOnReqSmaller {
  font-size: 0.95em;
}

.requestsJamTxt {
  background-color: #9a74eb;
  border: 1px solid #4b1caf;
  color: white;
  text-align: left;
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.jammedUsrNameSpan {
  margin-top: 10px;
}

.editReqBtnImg {
  margin-left: 10px;
  border-radius: 5px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
  min-height: 35px;
  min-width: 35px;
}


.editReqBtnImg:active {
  transform: scale(0.96);
}

.cancelReqBtnImg {
  border-radius: 5px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
  min-height: 35px;
  min-width: 35px;
}

.cancelReqBtnImg:hover {
  background-color: #bbcada;

}

.overlapWarningModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100%;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.overlapWarningModalBox {
  background-color: #f0f5ff;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  min-width: 290px;
  max-width: 290px;
  padding: 20px;
  padding-top: 10px;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  border: 1px solid #8ea7c5;
  font-size: 16px;
}

.overlapModalInfoTxt {
  margin-top: 20px;
}

.okButtonOverlapModal {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  /* max-width: fit-content; */
  margin: 30px auto;
  margin-bottom: 0px;
}

.loadingStartEndTimeImg {
  max-width: 40px;
  margin-left: 20px;
  margin-top: 6px;
  margin-bottom: -1px;
}

.editHalfKnobContainer {
  margin-top: -2px !important;
  margin-left: 0px;
}

.fadeEditStartAndEndDateReqContent {
  opacity: 0.6;
  pointer-events: none;
}

.removeReqBtn {
  /* margin-top: 8px; */
  margin-left: 20px;
  margin-right: -100px;
  min-width: 62px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 4px; */
  font-size: 1em;
  min-height: 28px;
  max-height: 28px;
  font-weight: 400;
  color: white;
  font-weight: 500;
  font-size: 14px;
  background-color: #cbd7e6;
  border: 1px solid #bbcada;  cursor: pointer;
  margin-top: 14px;
}

.removeReqBtn:hover {
  background-color: #bbcada;
}

.userReqGreenRedBubble {
  /* margin: 0px; */
  margin-left: 7%;
min-height:16px;
max-height:16px;
line-height:15px;
  border:none;
  background-color: #fff;
  color: #6db451;
  border-left: 2px solid #a8cca8;
  border-radius:0px;

  /* margin-left: 50%; */
  /* text-align: right; */
}

.submittedPushDown {
  margin-top: 17px;
  margin-bottom: 2px;
  /* text-transform: uppercase; */
}


.loadingViewTeamOnReqWidth {
  max-width: 200px;
  min-width: 200px;
}



.pendingReqsUserTitle {
  text-align: left;
  padding-left: 7%;
  font-weight: 500;
  color: #bbcada;
  margin-bottom: 14px;
  font-size: 16px;
  margin-top: 12px;
}

.colour143051 {
  color: #bbcada
}

.pushApprovedTitleDown {
  margin-top: 10px;
}

.mgrPastYearSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 78px;
  min-width: 78px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    rgb(83, 155, 237);
  border: 1px solid #2c7eb9;
  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  background-color: #0bf;
  background-color: white;
  color: #6a8bb1;
  background: url("../img/general/downArrowGrey.svg") no-repeat right #fff;
  background-position-x: 56px;
  margin-top: 24px;
  margin-left: 5%;
}

.loadedFromTeamViewerBottomBar {
  min-height: 8px;
  background-color: #e7ebef;
}

.defCursor {
  cursor: default;
}

.overtimeHolidayHelpIcon {
  background-color: #a387df;
  border: 2px solid #8e76c1;
  max-width: 22px;
  min-width: 22px;
  border-radius: 3px;
  padding: 3px;
  margin-left: 4px;
  cursor: pointer;
}

.overtimeHolidayHelpIcon:hover {
  background-color: #8e76c1;
}

.excessExplainDiv {
  background-color: #fff;
  color: #6a8bb1;
  font-weight: 300;
  border: 1px solid #e6eaee;
  max-width: 176px;
  font-size: 14px;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: -0px;
}

.excessExplainTitle {
  font-weight: 500;
  margin-bottom: 6px;
  color: #a387df
}

.excessExplainBridge {
  background-color: #a387df;
  border-left: 2px solid #8e76c1;
  border-right: 2px solid #8e76c1;
opacity: 0.7;
  min-height: 8px;
  max-height: 8px;
  min-width: 13px;
  max-width: 13px;
  margin-top: -10px;
  margin-left: 152px
}

.limitDisplayInfo {
  min-height: 108px;
  max-height: 108px;
}

.limitDisplayInfoRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  padding-left: 2px;
  padding-top: 2px;
  background-color: #e0ebf8;
  padding-bottom: 2px;
  border: #516b8b 1px solid;
  border-radius: 3px;
}

.limitDisplayInfoRowInline {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 4px;
  padding-bottom: 2px;
  /* max-width:100px; */
min-height:30px;
  border-bottom: 1px solid #6a8bb1;

}

.limitDisplayInlineWrapper {
  display: flex;
  justify-content: space-between;
  min-width: 105px;
  max-width: 105px;
}

.limitDisplayInfoTitle {
  font-weight: 500;
  margin-left: 2px;
  font-size: 10px;
  color: #214978;
  white-space: nowrap;
  display: flex;
  align-items: center;
  min-height: 15px;
  justify-content: center;
  border-radius: 2px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 3px;
  padding-right: 3px;

}


.limitDisplayInfoTitle2 {
  font-weight: 500;
  /* background-color: #516b8b; */
  margin-left: 2px;
  font-size: 11px;
  /* background-color: #dde9f9; */
  color: #214978;
  white-space: nowrap;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  min-height: 15px;
  justify-content: center;
  border-radius: 2px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  /* opacity: 0.5; */

}

.limitDisplayInfoValue {
  margin-left: 5px;
  color: #8ea7c5;
  padding-right: 6px;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 500;

}
.limitDisplayInfoValueCurrMax {
  margin-right: 2px;
  color: #214978;
  padding-right: 3px;
  padding-top: 3px;
  font-size: 12px;
  font-weight: 500;
  /* background: #ffffff; */
  min-width: 26px;
  padding-left: 3px;
  margin-top: 3px;
  max-height: 26px;
  text-align: right;
}

.hideLimitDisplayInfo {
  visibility: hidden;
}

.vv1void3 {
  min-height: 108px;
  max-height: 108px;
}


.noLeaveReqTimelineItem {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding-top: 30px; */
  /* font-size: 12px; */
  text-align: right;
  padding-right: 10px;
  line-height: 24px;

}

.noLeaveReqLightSpan {
  font-size: 14px;
  padding-right: 12px;
  color: #bbcada;
  font-weight: 500;
  line-height: 19px;
  margin-top: 13px;
  padding-bottom: 11px;
}

.mgrLeaveStartAndEndRow {
  min-width: 100%;
}


.isOwnReqTxt {
  text-align: right;
  max-width: 140px;
  font-size: 0.8em;
  font-weight:400;
  color: #a2bddf;
  margin-right: 7%;
  margin-top: 10px;
  /* background-color: black; */
}

.startHalfSpan {
  background-color: #e3e8ee;
  color: #4f566b;
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  max-width: fit-content;
  margin-top: 6px;

}

.regenSchedOnReq {
  margin-bottom: 20px;
  /* margin-top: 20px; */
}

.regenActiveOnReq {
  margin-bottom: 20px;
  /* margin-top: 19px; */
}

.closeAndViewProfileBox {
  display: flex;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 12px;

}

.closeVTORuserBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
max-height: 35px;  min-width: 48%;
}

.viewUserVTORuserBtn {
  min-height: 35px;
max-height: 35px;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
min-width: 48%;
font-size: 14px;
text-align: center;
border-radius: 5px;
font-weight: 500;
cursor: pointer;
background-color: #0bf;
border: 1px solid #0af;
}

.editUserIsShownOnVtor {
  /* background-color: yellow !important; */
  z-index: -1
}


.pastDecReqModalFooterApproved {
  min-height: 80px;
  border-top: 1px solid #e3e8ee;
  display: flex;
  max-width: 90%;
  margin-left: 5%;
  justify-content: flex-end;align-items: center;

}

.pastDecReqModalFooterDeclined{
  min-height: 80px;
  border-top: 1px solid #e3e8ee;
  display: flex;
  max-width: 90%;
  margin-left: 5%;
  justify-content: flex-end;align-items: center;
}

.closeDecisedReqBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
margin-top: -30px;
/* margin-right: 15px; */
}

.closeDecisedReqBtn:hover {
  background-color: #214978;
}

.mobDeclinedOrPastBottom {
  /* min-height: 8px; */
}
.removeRed {
  /* bottom: -44px; */
}


.reqsPageHeaderUser {
  /* background-color: #fff; */
  min-width: 100%;
  min-height: 95px;
  display: flex;
  justify-content: space-between;
}

.reqsPageHeaderTeam {
  min-width: 100%;
  min-height: 95px;
  display: flex;
  justify-content: space-between;
  background-color: #534f8c;
  border-bottom: 1px solid #d1dfee;

}

.reqsPageHeaderUserLeft {
  /* background-color: yellow; */
  min-width: 260px;
  margin-left: 40px;
  margin-top: 23px;

}

.reqsPageHeaderUserLeftMob {
  margin-left: 5%;

}


.myRequestsTitle {
  color: #143051;
  font-weight: 500;
  font-size: 23px;
  text-align: left;
}

.myRequestsTitleMob {
  color: #143051;
  font-weight: 500;
  font-size: 23px;
  text-align: left;
  margin-bottom: -3px;
  margin-top: 2px;
  }

  .teamRequestsTitleMob {
    color: #fff;
    font-weight: 500;
    font-size: 23px;
    text-align: left;
    margin-bottom: -3px;
    margin-top: 2px;
    }

    @media only screen and (max-width: 400px) {
      .teamRequestsTitleMob {
       font-size: 20px;
       margin-bottom:4px;
        
      }
  }
  

.myRequestsMenu {
  display: flex;
color: #143051;
font-weight: 400;
font-size: 16px;
margin-top: 4px;
margin-left: 3px;
min-width: fit-content;
/* align-items: cneter; */
}

.myRequestsMenuMob {
  display: flex;
color: #143051;
font-weight: 400;
font-size: 16px;
margin-top: 7px;
margin-left: 3px;
}



.myRequestsMenuItem {
  
  margin-left: 9px;
  min-height: 24px;
  cursor: pointer;
  min-width: 70px;
  max-width: 70px;
margin-top:5px;
color: #c2cce9;
font-weight: 500;
}

.myRequestsMenuItem:first-of-type {
  margin-left: 0px;
  margin-right: 4px;
  min-width: 77px;
  max-width: 77px;
}

.myRequestsMenuItem:hover {
  /* opacity: 1; */
  color: #4f79ab;
  font-weight: 500;
}

.myRequestsMenuItemMob:hover {
  color: white
}

.myReqestsMenuItemSelected {
  font-weight: 500;
  border-bottom: 1px #e3e8ee solid;
  opacity: 1;
  color: #143051 !important;
  pointer-events: none  !important;
}

.teamReqestsMenuItemSelected {
  font-weight: 500;
  border-bottom: 1px #e3e8ee solid;
  opacity: 1;
  color: #fff;
  pointer-events: none  !important;
}




.reqsPageHeaderUserRight {
  /* background-color: green; */
  min-height: 35px;
  min-width: 240px;
  max-width: 240px;
  position: fixed;
  right: 24px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}


@media only screen and (min-width: 1166px) {
    .reqsPageHeaderUserRight {
      /* background-color: green; */
      min-height: 35px;
      min-width: 400px;
      max-width: 400px;

      position: fixed;
      left: 725px;
      display: flex;
      justify-content: flex-end;
    }
}


@media only screen and (max-width: 450px) {
  .reqsPageHeaderUserRight {
    min-height: 35px;
    min-width: 240px;
    max-width: 240px;
    position: fixed;
    right: 24px;
    margin-top: -10px;
    display: flex;
    justify-content: flex-end;
  }
}

.mobReqsPageHeaderCorrection {
  margin-top: 6px;
}


.teamReqsBtn {
  background-color: #a387df;
  border: 1px #8e76c1 solid;

  display: flex;
  color: #fff;
  min-height: 36px;
  line-height: 34px;
  padding-left: 7px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  /* margin-left: 5px; */
  /* margin-right: 8px; */
  min-width: fit-content;
  max-width: fit-content;

  max-height: 35px;
  min-height: 35px;
  /* margin-top: 4px; */
  font-size: 16px;
  cursor: pointer;
  /* padding-bottom: 4px; */
}

.teamReqsBtn:hover {
  background-color: #8e76c1;
}



.reqStatsBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  display: flex;
  color: #fff;
  min-height: 36px;
  line-height: 34px;
  padding-left: 4px;
  padding-right: 0px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* margin-left: 5px; */
  margin-right: 8px;
  min-width: 80px;
  max-height: 35px;
  min-height: 35px;
  /* margin-top: 4px; */
  font-size: 16px;
  cursor: pointer;
  
}

.reqStatsBtn:hover {
  background-color: #0469de;
}

.mobReqStatsBtn {
  /* max-height: 30px;
  min-height: 30px; */
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 8px;
  font-size: 15px;

  /* min-width: 40px !important; */
}

.minMaxHeight30 {
  min-height: 30px;
  max-height: 30px;

}

.purpleColourTheme {
  border-color: #8e76c1 !important;
  background-color: #a387df !important;
}

.mobMyReqsBtnTxt {
  margin-top: -3px !important;
  margin-left: -2px;
}

.mobReqsStatsTxt {
  margin-top: 1px;
}
.mobReqsHeaderRight {
  /* background-color: yellow; */
  margin-top: -17px;
}

.reqStatsBtnTxt {
  font-weight: 500;
  /* font-size: 16px; */
  padding-left: 0px;
  cursor: pointer;
  margin-left: 2px;
}

.reqStatsImg {
  max-width: 15px;
  min-width: 15px;

  margin-left: -4px;
  margin-right: 6px;
  margin-top: -2px;
}
@media only screen and (max-width: 420px) {
  .reqStatsBtnTxt {
  display: none;
  }

  .reqStatsImg {
    max-width: 15px;
    margin-left: 1px;
    margin-top: -2px;
  }

  .reqStatsBtn {
    min-width: 32px;
    max-width: 32px
  }


}



.teamReqBtnTxt {
  min-width: 120px;
  padding-right: 3px;
  margin-top: -1px;
}

.myReqBtnTxt {
  min-width: 100px;
  padding-right: 4px;
  margin-top: -1px;

}


.minW101pc {
  padding-left: 0.5%; 
  min-height: calc(100vh - 215px);
  max-height: calc(100vh - 215px);

}

.minW101pcIos{
  padding-left: 0.5%; 
  min-height: calc(100vh - 245px);
  max-height: calc(100vh - 245px);

}

.minW101pc2 {
  /* padding-left: 0.5%;  */
  min-height: calc(100vh - 219px);
  max-height: calc(100vh - 219px);

}

.minW101pc2Ios {
  min-height: calc(100vh - 239px);
  max-height: calc(100vh - 239px);
}



.myReqsBtn {
  background-color: #07f;
  border: #0469de 1px solid;

  display: flex;
  color: #fff;
  min-height: 36px;
  line-height: 34px;
  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  /* margin-left: 5px; */
  /* margin-right: 8px; */
  min-width: fit-content;
  max-width: fit-content;

  max-height: 35px;
  min-height: 35px;
  /* margin-top: 4px; */
  font-size: 16px;
  cursor: pointer;
  /* padding-bottom: 4px; */
}

.myReqsBtn:hover {
  background-color: #0469de;
}

.mobMyReqsBtn {
  max-height: 30px;
  min-height: 30px;

}


.teamReqsTeamSelectContainer {
  /* background-color: blue; */
  position: fixed;
  top:86px;
  right: 24px;
  min-width: 284px;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (min-width: 1166px) {
  .teamReqsTeamSelectContainer {
    /* background-color: blue; */
    position: fixed;
    top:86px;
    left: 841px;
    right: auto;
    min-width: 284px;
    min-height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}



.teamReqsTeamTitle {
  max-width: 80px;
  /* position: absolute; */
  /* top: 92px; */
  /* right: 202px; */
  font-weight: 500;
  /* z-index: 999; */
  font-size: 14px;
  color: #6a8bb1;
  margin-right: 8px;
}

@media only screen and (max-width: 870px) {
  .teamReqsTeamTitle {
    display: none;
  }
}


.teamReqTeamsDropdown {
  min-width: 174px;
  min-height: 35px;
  max-width: 174px;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 150px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;

}

.teamReqTeamsDropdown:hover {
  border-color: #bac5d3
}






.teamReqsDropdownMobOnly {
  margin-top: -36px;
}



.reqPageFilterBarMy {
  min-height: 50px;
  max-height: 50px;
  /* background-color: #ebf0f7; */
  /* background-color: black; */
  /* margin-top: -34px; */
  /* min-width: 96%; */
  /* max-width: 96%; */
  padding-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-right: 4%; */
  position: relative;
  /* padding-right:5%; */
  z-index: 999;
  /* flex-direction: row-reverse; */
  /* margin-left: 17px; */

}

.rowReverse {
  flex-direction: row-reverse;
}


.reqPageFilterBar {
  min-height: 55px;
      max-height: 55px;
      background-color: #ebf0f7;
      /* margin-top: -34px; */
      min-width: 100%;
      max-width: 100%;
      padding-top: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* padding-right: 4%; */
      position: relative;
      z-index: 999;
      /* padding-right: 2%; */
      padding-left: 5%;
      padding-right:5%;

}

.reqFilterSplitterContainer {
  min-width: 100%;
  background-color: #ebf0f7;
  padding-top: 0px;
  margin-top: -2px;
  /* border-right: 1px solid #e0ebf8; */
}

.reqFilterSplitter {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #e6eaee;
  /* margin-bottom: 10px; */
}

.reqFilterSplitterMob {
  min-width: 100%;
  background-color: #f5f5f5 !important;
  min-height:2px;
  margin-top:2px;
}

.approvedTeamReqsTitle {
  text-align: left;
  padding-left: 7%;
  font-weight: 500;
  color: #bbcada;
  font-size: 14px;
  margin-top: 12px !important;
  min-height: 30px;
  margin-bottom: -4px;
}

.noPastReqs {
  margin-top: 13px !important;
}

.noUpcomingReqs {
    margin-top: 19px !important;
}




.reqsDataBoxYearSelect {
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
max-width: 76px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #e6eaee;
  color: #fff;
  font-weight: 500;
  background-position-x: 55px !important;
    border-radius: 5px;
    background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right;
    font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
  padding-bottom: 1px;
  min-height:35px;
  margin-top:-5px;
  background-color: #2b679f;

}
.reqsDataBoxYearSelect:hover {
  border-color: #bac5d3;
}
.leaveDataMinsSpan {
  /* background-color: #295281; */
  /* padding-top: 2px; */
  /* padding-bottom: 2px; */
  /* border-radius: 3px; */
  /* line-height: 15px; */
  font-size: 12px;
  padding-left: 9px;
  /* padding-right: 6px; */
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:22px;
  /* background-color: #7f57b9; */
    /* border: 1px solid #eeeeee; */
    color:#fff;
    border-left: 1px solid #ffffff5c;
}

.leaveDataMinsSpanMgr {
  background-color: #513392;
  display: flex;
  align-items: center;
  /* padding-top: 2px; */
  /* padding-bottom: 2px; */
  border-radius: 3px;
  /* line-height: 15px; */
  font-size: 12px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.customMappedItem {
  /* margin-top:10px; */
  /* background-color: rgb(64, 103, 148); */
  /* background-color: #5f98c0; */
  background-color: #19304f;
  /* padding-top: 10px; */
  border-radius: 5px;
  max-width: 90%;
  min-width: 90%;

  margin-right: 5%;
  /* border: 1px solid #dde9f9; */
  /* border-left: 3px solid #143051; */
  padding-top:18px;
  padding-bottom:8px;
  margin-bottom:10px;
  float:right;
  border: 1px solid #143051;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.shiftLeaveCustomMappedItem {
  background-color: #8ea7c5;
  border-color: #143051;}

  .shiftLeaveCustomMappedItemPurp {
    background-color: #ba97f1;
    border-color: #7e60af;
  }

.x348772344 {
  padding-bottom: 12px;
  background-color: #4f7ab4;
  border-color: #30517f;

}

.myReqSideboxTop {
  /* border: 1px solid #dde9f9 !important; */
  /* border-left: 3px solid #143051 !important; */
  max-width: 94%;
  border-radius:5px;
  /* padding-top:8px; */
  margin-left:3%;
}
.customMappedItemMgr {
  margin-top:20px;
  /* background-color: rgb(64, 103, 148); */
  /* background-color: #7b59c4; */
  background-color: rgb(255,255,255,0.9);
  padding-top: 10px;
  padding-bottom:4px;
  border-radius: 5px;
  max-width: 90%;
  margin-left: 5%;
  /* border: 1px solid #dde9f9; */
  border: 1px solid #7e60af;
  background: #b997f1;
  border-left: 3px solid #7e60af;

  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}


.customMappedItem:first-child {
}

.reqsDesktopBoxScroll {
  max-height: calc(100vh - 188px);
  padding-bottom: 0px !important;
  cursor: default !important;

}

.reqsDesktopBoxScrollMgr {
  max-height: calc(100vh - 181px);
  padding-bottom: 12px !important;
  cursor: default !important;

}


.oneCustomsDataBoxSize {
  max-height: 522px !important;
  min-height: 522px !important;
}


.twoCustomsDataBoxSize {
  max-height: 682px !important;
  min-height: 682px !important;
}


.noCustomsDataBoxSizeAbsence {
  max-height: 428px !important;
  min-height: 428px !important;

  /* background-color: pink; */

}


.customDataBoxTitle {
  border: none !important;
  padding-right: 16px !important;
  max-width: 90% !important;
  min-width: 90% !important;
  margin-left: 5% !important;
  border-radius: 5px !important;
  min-height: 50px !important;
  background-color: #284974;
  /* margin-top:10px; */
}

.customDataBoxTitlePurp {
  background-color:#a487df
}

.customDataBoxTitleMgr {
  /* background-color: #a387df !important;
  border: none  !important; */
}

.customLeaveDataMinsSpan {
  background-color: #386190 !important;

}
.reqDataCustomScrollBox {
  overflow-y: scroll;
  max-height: calc(100vh - 442px);
  background-color: #fcfdff;
  border-top:1px solid #e0ebf8;
}
.myAllowsSideBoxCustomsScroll {
  overflow-y: scroll;
  max-height: calc(100vh - 389px);
  /* background-color: #2c7eb9; */
  padding-top:8px;
  /* border-top:1px solid #e0ebf8; */
}
.reqDataCustomScrollBoxAbsence {
  overflow-y: scroll;
  max-height: calc(100vh - 545px);
  padding-top:8px;
  /* border-top:2px solid #e0ebf8; */
  /* background-color: #fcfdff; */
}

.reqDataCustomScrollBoxMgr {
  /* background-color: yellow; */
  overflow-y: scroll;
  max-height: calc(100vh - 490px);
  padding-bottom: 20px;
  /* border-top: 1px solid #e6eaee; */
  /* background-color: #fcfdff; */
  /* padding-bottom: 20px; */
}


.modalCustomScrollBox {
  /* max-height: calc(100vh - 366px);
  min-height: calc(100vh - 366px); */
  /* min-height:400px;
  max-height:400px; */
  min-height: calc(100dvh - 420px);
  max-height: calc(100dvh - 420px);

  /* background-color: #e8e7fb; */
  /* border-radius: 20px 20px 0 0 !important; */
  /* border: 1px solid #7874c0; */
  min-width:  100%;
  /* margin-left: -2px; */
  background-color: #9470ce;

  border-top: 1px solid #6d4298 !important;
  border-bottom: 1px solid #6d4298 !important;

  border-bottom: none;
  margin-top: 20px;
}


.modalCustomScrollBox2 {
  max-height: calc(100vh - 485px);
  min-height: calc(100vh - 485px);



}

.noBorderTopBottom {
  /* border-top: none !important; */
  /* border-bottom:none !important; */
}


.modalCustomScrollBoxMob {
  max-height: calc(100vh - 287px);
  min-height: calc(100vh - 287px);



}
.customLeaveDataTitle {
  text-align: left;
  font-size: 12px  !important;
  max-width: 60%;
  line-height: 15px;
  margin-left:10px;

}

.font12px {
  font-size: 12px !important;
}
.showCustomDataSplitter {
  background-color: white;
  min-height: 2px;
  min-width: 90%;
  max-width: 90%;
  margin-left: calc(5% + 1px);
  z-index: -1;
  /* border-radius: 0 0 2px 0; */
}


.myReqDataSplitter {
  background-color: white;
  min-height: 2px;
  min-width: 90%;
  max-width: 90%;
  margin-left: calc(5% + 1px);
  z-index: -1;
  border-top: 1px solid #e0ebf8
  /* border-radius: 0 0 2px 0; */
}


.showCustomDataSplitterMgr {
  background-color: white;
  min-height: 2px;
  min-width: 90%;
  max-width: 90%;
  margin-left: calc(5% + 1px);
  z-index: -1;
  opacity: 0.8;
  margin-top: -16px;
  /* border-radius: 0 0 2px 0; */
}


.modalShowCustomDataSplitter {
  min-width: 94%;
margin-left: 2%;
  max-width: 94%;
  margin-top:10px;
}

.otherCustomsContainer {
  display: flex;
  justify-content: flex-end;
  min-height: 72px;
  margin-bottom: -17px;
  padding-right: 15px;
  /* border-top: 1px solid white; */
  padding-top: 6px;
  max-width: 95%;
  margin-left: 4%;
/* background-color: #144051; */
}

.flexEnd {
  justify-content: flex-end;
}



.reqsDataBoxShowCustomsBtn {
  /* background-color: #386190; */
    max-height: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-width: 156px;
    min-width: 156px; */
    max-width: fit-content;
    min-width: fit-content;
    /* padding-left: 3px;
    padding-right: 3px; */
    /* padding-left: 2px;
    padding-right:2px; */
    /* border: 2px solid #acc3e1; */
    border: 1px solid #0077aa;

    color: #c0d4e2;
    font-weight: 400;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 14px;
    cursor: pointer;
    /* padding-bottom: 1px; */
}

.reqsDataBoxShowCustomsBtn:hover {
  background-color: #2c7eb9;
  border: 1px solid #e0ebf8;
  padding-left: 1px;
  /* padding-right: 1px; */
  max-width: fit-content;
  min-width: fit-content;
    color: #fff;

  /* padding-top: 12px; */
}

.reqsDataBoxShowCustomsBtnActive {

  min-width: 144px;
  border-bottom: 2px solid #fff;
  border-color: white; 
  border: none;
  border: 1px solid white;
  background-color: white;
  color: #0077bb;
  border-radius: 5px 5px 0px 0;
  z-index: 2;
  padding-top: 1px;
  font-weight: 500;
  /* padding-left: 1px; */
  font-size: 14px;
  max-width: fit-content;
  min-width: fit-content;

}

.reqsDataBoxShowCustomsBtnActive:hover {
  background-color: #f0f5ff;
  padding-left: 0px;
  color: #0077bb;

  /* opacity: 0.9; */
}

.reqsDataBoxShowCustomsBtnActiveMyReqsOnly {
  margin-top: 9px;
}
.closeOtherLeaveTypesImg {
  max-width: 10px;
  margin-left: 7px;
  margin-right: 10px;
  margin-top: 2px;
}

.closeOtherLeaveTypesImg:hover {
  /* opacity: 0.8; */
  transform: scale(0.95);
}

.customRemainingRow {
  /* min-width: 157px; */
  display: flex;
  justify-content: flex-end;
}

.reqStatsUnderlay {
  background: rgba(0, 0, 0, 0.5);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.modalReqDataBox {
  min-height: calc(100vh - 87px);
  max-height: calc(100vh - 87px);
  min-width: 90% !important;
  max-width: 90% !important;
  margin-left: 0px !important;
  border-bottom:none !important;
  min-height: fit-content !important;
  border-radius:10px !important

}

@media only screen and (min-width: 460px) {
.modalReqDataBox {
  min-width: 390px !important;
  max-width: 390px !important;
}
}

.customQtySpan {
  margin-right: 4px;
  margin-left: 10px;
}

.mgrReqDataBoxDropdown {
  background-color: #1468a4;
    max-height: 35px;
    min-height:35px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 88%;
    min-width: 88%;
    margin-left: 6%;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid #fff;
    color: white;
    font-weight: 500;
    background-position-x: 95% !important;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 14px;
    cursor: pointer;
    background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right;
    /* #7495ba; */
    /* padding-bottom: 1px; */
    /* margin-top: -10px; */
    margin-bottom: 5px;
    line-height: 26px;
}
.mgrReqsDataBoxColours {
  border: 1px solid #7f63bb !important;
  background-color: #9374d6 !important;
  cursor: default;
  /* min-height:640px !important */
  /* min-height: fit-content !important; */
  /* max-height: calc(100vh - 200px) !important; */
}

  
.reqDataBoxPurpleBg {
  background-color: #663fa7 !important;
  border: 1px solid #e6eaee !important;
  color: #FFF !important;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right;
}

.reqDataBoxPurpleBg:hover {
  border-color:#ddc6e6 !important}

.mgrReqDataBoxHighlightTitleBar {
  background-color: #a387df !important;
  border-color: #c2a9fa !important;
  margin-top: 15px !important;
  border-radius:5px;
  border-bottom: none !important;
}

.lightPurpleBorderColour {
  border-color: #c2a9fa !important;
  padding-left: 10px !important;
  padding-right: 13px !important;

}

.remainingMgrDataReqTitle {
  padding-left: 10px !important;
  padding-right: 13px !important;
}

.mgrShowCustomsBtn {
  background-color: #9374d6;
  border-color: #9374d6;
  color: white;
  opacity: 0.8;
  margin-top: 2px;
}

.mgrShowCustomsBtn:hover {
  background-color: #9374d6;
  opacity: 1

}

.reqsDataBoxShowCustomsBtnActiveMgr {
  background-color: white;
  border:none;
  color: #4b1caf;   border-radius: 5px 5px 0 0;
  opacity: 0.8;
margin-top: 10px;


}

.reqsDataBoxShowCustomsBtnActiveMgr:hover {
  background-color: white;
  border-radius: 5px 5px 0 0;
  border:none;
  color: #4b1caf;
  padding-left: 0px;
  opacity: 0.8

}

.teamReqsAnnLeaveUsersTitle {
  text-align: left;
  margin-top: 15px;
  margin-left: 25px;
  font-weight: 500;
  opacity: 1;
  color: #bbcada;
  font-size: 14px;
  display: none;
  
}

.mgrReqDataBoxAllStaffDataItem{
 

  min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px;
    padding-left: 13px;
    cursor: pointer;
    background-color: white;
    border-radius: 4px;
  color:white;
    max-width: 90%;
    margin-left: 5%;
    border-radius: 4px;
    margin-top: 8px;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
    background-color: #a387df !important;
    border: 1px solid #c2a9fa !important;
  /* padding-right: 15px; */
}

.mgrReqDataBoxAllStaffDataItem:hover {
  border: #e6eaee 1px solid !important; 
  padding: 0px;
  padding-left: 13px;
  padding-right:1px;
  border-radius:4px;
    /* background-color: #a387df !important; */
    /* border: 2px solid #c2a9fa !important;   */
  /* border-radius: 5px; */

}

.mgrReqDataBoxAllStaffDataItem:last-child {
  /* border-bottom: none !important */
}


.allStaffAnnLeaveDiv {
  /* background-color: black; */
  overflow-y:scroll;
  max-height: calc(100vh - 370px);
  margin-top: 10px;
  padding-bottom: 25px;
}

.allStaffAnnLeaveDivModal {
  /* min-height: calc(100vh - 510px) !important; */
  /* max-height: calc(100vh - 235px) !important; */
  /* background-color: #fcfdff; */
    /* border-top: 1px solid #e0ebf8 !important; */
  /* background-color: yellow; */
}


.allStaffAnnLeaveDivModalDesktop {
  /* min-height: calc(100vh - 390px) !important; */
  max-height: calc(100vh - 320px) !important;
  /* border-top: 1px solid #e6eaee; */
  /* background-color: #fcfdff; */
  /* border-top:1px solid #e0ebf8 !important */
}

.mgrReqFullName {
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  color:white;
  line-height:18px;
  /* margin-left: 10px; */
  /* margin-top: 10px; */
}

.mgrReqFullName:hover {
  /* color: #ecf0f5;
  transform: scale(1.05); */
}

.allStaffReqsDataValue {
  font-size: 14px;
  margin-right: 15px;
  font-weight: 500;
  color:#f0f5ff;
  opacity: 0.8;
  text-align: right;
  min-width: fit-content;}

.topWhiteBorder {
  border-top: #ffffff1c solid 2px;
}

.hoveredReqUserContainer {
  min-width: 72px;
  margin-right: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 40px;
  
}

.fixedReqUserContainer {
  min-width: 72px;
  /* margin-right: 5px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  margin-left: 12px;
  margin-top: -22px;
  
}


.viewReqFilterSideBoxImg {
  background-color: #5d2ec2;
  border: 2px solid #4b1caf;
  border-radius: 5px;
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
  padding: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 0px;
  cursor: pointer;

}

.viewReqFilterSideBoxImg:hover {
  background-color: #4b1caf;
}

.viewUserOnReqSideBoxImg {
  background-color: #0af;
  border: 2px solid #0562a2;
  border-radius: 5px;
  min-width: 34px;
  max-width: 34px;
  padding: 8px;
  cursor: pointer;

}

.amendAllowBtn {
  background-color: #d264ff;
  border: 1px solid #a058be;
  border-radius: 5px;
  min-width: fit-content;
  /* max-width: 128px; */
  color: white;
  padding: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  /* float:left */
/* 
  color: #516b8b;
  border-radius: 5px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 26px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  margin-left: 3px;
  border: 1px solid white; */
  /* background-color: white; */
}

.amendAllowBtn:hover {
  background-color: #ca5ef6;

}

.viewUserOnReqSideBoxImg:hover {
  background-color: #0077cc;
}


.reqsUserIDTab {
  color: #fff;
  background-color: #5d2ec2;
  border: 2px solid #4b1caf;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 5px;
  padding-left: 4px;
  padding-right: 10px;
  font-size: 14px;
  min-height: 30px;
  cursor: pointer;
}

.reqsUserIDTab:hover {
  background-color: #4b1caf;
}

.reqsUserIDTabMob {
  background-color: #c2cce9;
  color: #fff
}
.reqsUserTabNavyCrossImg {
  max-width: 12px;
  margin-right: 9px;
  margin-left: 4px;
  /* margin-top:1px; */
}

.myReqsBtnCorrectionUserID {
  margin-top:33px
}

.outstandingReqBlob {
  min-width: 12px;
  min-height: 12px;
  max-width: 12px;
  max-height:12px;
  background-color: #00ccff;
}

.typesDropdown {
  margin-top: 8px !important;
  color: #143951 !important;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff !important;
  background-position-x: 137px !important;
  border: 1px solid #e6eaee  !important;
  margin-left:5%  !important
}

.typesDropdown:hover {
  border-color: #d7dfe9 !important
}

.upcomingTeamStaffSelect {
  min-height: 55px;
  max-height: 55px;
  background-color: #ebf0f7;
  /* margin-top: -34px; */
  min-width: 300px;
  max-width: 300px;
  padding-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-right: 4%; */
  position: relative;
  /* background-color: black; */
  margin-left: 3px;
  z-index: 999;}

  .teamReqsSelectorBar {
    min-height: 50px;
    max-height: 50px;
    background-color: #ebf0f7;
    /* margin-top: -34px; */
    min-width: 100%;
    max-width: 100%;
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-right: 4%; */
    position: relative;
    flex-direction: row-reverse;
    padding-left:1%;
    /* background-color: black; */
    /* margin-left: 3px; */
    z-index: 999;
  }

  .x1378491 {
    padding-left:5%
  }


  .historyOrDeclinedFilterBar {
    justify-content: space-between !important
  }

  .myPreviousReqs {
    padding-top: 12px;
  }

  .mgrReqDataBoxFilterBar {
    display: flex;
    align-items: center;
  }

  .backSelUserIDReqDataImg {
    max-width: 19px;
    min-width: 19px;
    margin-top: -6px;
    margin-left: 21px;
    margin-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;

  }

  .backSelUserIDReqDataImg:hover {
    /* transform: scale(0.9); */
    opacity: 0.8;
  }

  .showBackIcon {
    max-width: calc(90% - 40px);
    min-width: calc(90% - 40px);
    margin-left: 5px;
  }


  .otherCustomsContainerOpen {
    margin-top: -10px
  }

  .teamReqsFilterBar {
    min-width: 33% !important;
    max-width: 33% !important;
    /* margin-left: 39px !important; */
  }

  .upcomingFilterBarTeam {
    margin-left: 15px !important
  }



  .userReqCostTxt {
    max-width: fit-content;
    min-width: fit-content !important;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
    max-height: 24px;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 0.8em;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0077bb;
margin-top:-1px;  }

.userReqCostTxtPast {
  max-width: fit-content;
  min-width: fit-content !important;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3e8ee;
  color: #4f566b;margin-top:-1px;  }

  .mgrReqItem {
    background-color: #fff;
    max-width: 90%;
    min-width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    max-height: 80px;
    margin: 0px auto 0;
    /* border: 1px solid #fff; */
padding:1px;
    cursor: pointer;
    border-radius:0 5px 5px 5px;
    /* -webkit-animation-name: zoomModalIn!important;
    animation-name: zoomModalIn!important;
    -webkit-animation-duration: .1s!important;
    animation-duration: .1s!important; */
    /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%); */
    /* border: 1px solid #dee5f0; */
    margin-bottom:10px;
    margin-top:20px  !important;
overflow:visible

  }


  .leaveReqItem {
    margin-bottom:0px;
    margin-top:10px;
  }


  .x134814 {
    margin-top: 20px;
  }


  .mgrReqItemCol1{
    /* background-color: blue; */
    min-width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 72px;
  }

  .mgrReqApprovedDeclinedIcon {
    min-width: 10px;
    /* margin-top: -37px; */
    /* margin-left: 16px;   */

  }


  .mgrReqItemCol2 {
    width: 97%;
    min-height: 50px;
    /* background-color: lime; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 2px;
    /* border-left: 2px dotted #5a9e3f; */
    max-height: 40px !important;
    margin-left: -19px;
    margin-top: 1px;
    overflow: visible;
  }

  .mgrReqItemCol2Mob {
   margin-left: -8px;
  }



  .reqColBarDiv {
    min-height:33px;
    max-height:33px;
    min-width: 2px;
    /* background-color: #a8cc9a; */
    margin-left:17px;
    margin-right:-17px;
    border-left: 2px dashed #a8cc9a;
    display: none;
   
  }
  .unclockColDiv {
    border-left: 1px solid #e0ebf8;
    margin-left:10px;

  }

  .divPend {
    border-color:#bbcada !important
  }


  .reqColBarAdjust1 {
    margin-left: 12px;
    margin-right: -12px;
  }


  .reqColBarAdjust2 {
    margin-top:8px;
    margin-left: -2px;
    margin-right: -2px;
  }

  .reqColBarAdjust3 {
    margin-left: 12px;
    margin-right: -14px;
    border-left: 2px dashed #143051 !important;
  }

  .reqColBarAdjust4 {
    margin-left: 6px;
    margin-right: -16px;
    margin-top: 10px;
  }

  .reqColBarAdjust5 {
    margin-left: -23px;
    margin-right: -27px;
  }
  .reqColBarAdjust6 {
    margin-left: 12px;
    margin-right: -14px;
    border-left: 2px dashed #bbcada !important;
  }

  .reqColBarAdjust7 {
    margin-left: 18px;
    margin-right: -19px;
  }

  .divDec {
    border-color:#ba0000 !important
  }


  .divApp {
    border-left:2px dashed #91d278 !important
  }


  


  .itemTongue {
    min-width: 19px;
    max-width: 19px;

    background-color: #8666a3;
    border-left: 1px solid #644b7a;
    background-color: #214978;
    border-left: 1px solid #143051;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1px;
    margin-right:-1px

    /* padding-right:4px;
    padding-left:0px; */
  }

  .leaveTongue {
    background-color: #214978;
    border-left: 1px solid #143051;

  }

  .leaveTongueDeclined {
    background-color: #b24e4e;
    border-left: 1px solid #971515;

  }

  .shiftTongue {
    background-color: #0af;
    border-left: 1px solid #1e88e5
  }

  .shiftTongueMgr {
    background-color: #2A67B2;
    border-left: 1px solid #37576C
    }

  .clockTongue {
    background-color: #699995;
    border-left: 1px solid #436562;
    display: none;
    /* kill */
    /* padding-left:1px; */
  }


  .tsTongue {
    background-color: #749fd2;
    border-left: 1px solid #5c7ea7;
    padding-left: 1px;
  }
  .tilTongue {
    background-color: #bbcada;
    border-left: 1px solid #8ea7c5;
  }

  .tilTongueMgr {
    background-color: #8AA9B7;
    border-left: 1px solid #76a1bd
    }


  .overtimeTongue {
    background-color: #859dbb;
    border-left: 1px solid #496375;
    padding-right:2px;
  }

  .overtimeTongueMgr {
    background-color: #6e4c90;
    border-left: 1px solid #5f3e81;
    display: none;
    /* padding-right:1px; */
    /* margin-left: -1px; */
    }

  .tongueTxt {
    transform: rotate(270deg);
    min-width: fit-content;
    color:white;
    font-size:10px; 
    font-weight:500;
    opacity: 0.7;
    text-transform: uppercase;
    white-space: nowrap;

  }

  .leaveTongueTxt {
    font-size:10px;
    /* margin-left:1px; */
  }

  .shTongueTxt {
    font-size:10px;
    /* margin-left:1px; */
  }
  .hoursTongueMgrTxt {
    margin-left:-2px;
  }

  .clockTongueTxt {
    font-size:9px;
    white-space: nowrap;
min-width: fit-content;
  }


  .mgrReqTopRow {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 5%;
    overflow:visible
  }

  @media only screen and (min-width: 820px) {

.canManageUsrLeaveBtn:hover {
  border-color: #d2d9e3;
  background-color:white;
}
    .viewReqTeamCal:hover {
      background-color: #bbcada;
    }

.staffLeaveAllowsBtn:hover {
  border-color: #d2d9e3;
  background-color:white;
}

    .mgrReqTopRow {

      padding-left: 5%;
    
    }

  }
  .mgrReqBottomRow {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 5%;
    min-height: 29px;
    margin-bottom: -13px;
    overflow:visible
  }
  
  .mgrReqName {
    color: #143051;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-top: 1px;
    margin-bottom: 2px;
    max-height: 18.5px;
  }

  .mgrReqName2 {
    color: #143051;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-top: 1px;
    margin-bottom: -2px;
    max-height: 18.5px;
    overflow:visible
  }

  .rotaSunReqs {
    width: 12px;
    min-width: 12px;
    max-width: 12px;
    margin-right: 8px;
    margin-bottom: -2px;
    opacity: 0.5;
  }
  @media only screen and (max-width: 820px) {
   
    .reqsTeamHoverBtn {
      color:#fff !important
    }
  
    .reqsTeamHoverBtn:hover {
      color: #fff !important;
    }
 .regenSchedContainer {
  background-color: white;
 }
    .mgrReqName {
      font-size: 16px;}
  }



.editReqBtnImg:hover {
  background-color: #bbcada;

}

@media only screen and (min-width: 820px) {

  .addStaffLeaveBtn:hover {
    border-color: #d2d9e3;
    background-color: white;
  }
  .regenSchedContainer:hover {
    background-color: #fff;
  }
.customDataBoxTitlePurp:hover {
  background-color:#a487df !important
}
  .shiftLeaveArrItemPurp:hover {
    background-color: #c862f1  !important;
 }
  .enableTilKnob:hover {
    opacity: 0.8;
  }
  .shiftLeaveArrItem:hover {
    background-color: #0c6fe2;
  }
  .editReqBtnImg:hover {
    background-color: #bbcada;
  
  }
  .x239849848533:hover {
    background-color: #1b375c !important;
  }
    .x239849848533b:hover {
    /* background-color: #8e71c8 !important; */
  }
}


  .pendingReqBord {
    /* border-left: 2px dashed #bbcada; */
    /* margin-left:2px; */
  }

  .declinedReqBor {
    border-left: 2px dotted #ba0000
  }

  .mgrReqTypeName {
    color: #143051;
    font-size: 12px;
    font-weight: 500;
    margin-top: -1px;
    padding-right: 3px;
  }

  .mgrReqDateStrip {
    color: #859dbb;
    font-weight: 500;
    font-size: 12px;
    margin-top: 2px;
    text-align: left;
    min-width: 145px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin-right: -20px;
    /* border-top: 1px solid #e0ebf8; */
    padding-top: 27px;
    padding-left: 3px;
  }

  .mgrReqDateStripLong {
    padding-top: 4px;
    border-top: 1px solid #e0ebf8;
    margin-top: 1px !important;
    margin-bottom: 1px;
    padding-top: 1px;
    overflow:visible
  }

  @media only screen and (max-width: 415px) {
    .mgrReqDateStripLong {
      font-size: 10px;}
  }
 
  .mgrReqDayLengthSpan {
    /* border-left: 1px solid #e0ebf8; */
    /* color: #143051; */
    /* padding-left: 6px; */
    /* margin-left: 4px; */
    font-weight:600;
    font-size: 14px;
    color:#0cbce8
  }

  .usrReqDayLengthSpan {
    border-left: 1px solid #e0ebf8;
    color: #95aaba;
    padding-left: 8px;
    margin-left: 10px;
    font-size: 11px;
  }
  .mgrReqDayLengthSpanNewLine {
    border: none;
    padding: 0px;
    margin-left: 0px !important;
    /* opacity: 0.5; */
    border-top: 1px solid #e0ebf8;
    padding-top: 0px;
    margin-top: 0px;
    line-height: 16px;
    font-size:10px
  }


  .leftBorderGreen {
    /* border-left: 5px solid #a8cc9a !important; */

  }



  .leftBorderRed {
    /* border-left: 5px solid #e89292 !important; */

  }

  .mgrReqItemPendingBorder {
    /* border: 2px dashed #bbcada !important;
    box-shadow: none !important; */
    background-color: #deedff !important;
    border-bottom: 2px solid #cce3ff !important;

    background-color: #deedff !important;
    border: 1px solid #8bc3ff !important;
    border-bottom: 2px solid #8bc3ff !important
  }




  .mgrReqItemPendingBorder {
    /* background-color: black !important; */
    border:2px dashed #214978 !important
  }

 

  .mgrReqItemPendingBorderMgr {
    border: 2px dashed #bbcada !important;
    box-shadow: none !important;
  }


  .mgrReqItemPendingBorderMob:hover {
    /* padding-left: 1px;
    padding-right:1px;
    border: 2px dashed #bbcada !important;
    box-shadow: none !important; */
  }


  .formLeftIncompleteBorder {
    /* border-left: 5px solid #0af !important; */
    /* border: 2px dashed #bbcada !important; */
    /* box-shadow: none !important; */

  }

  .formLeftIncompleteBorder:hover {
    /* padding-left: 1px !important; */
    /* border: 2px dashed #214978 !important; */
    /* box-shadow: none !important; */
  }

  .formLeftIncompleteBorderPurple {
    /* border-left: 5px solid #9374d6 !important; */

  }



  .formLeftIncompleteBorderPurple:hover {
    /* padding-left: 1px !important; */
  }


  .formLeftIncompleteBorderBlue {
    /* border-left: 5px solid #0077ff !important; */

  }

  .formLeftIncompleteBorderBlue:hover {
    /* padding-left: 1px !important; */

  }

  .formLeftGreen {
    border-left: 5px solid #6db451 !important;

  }

  .formLeftGreen:hover {
    padding-left: 1px !important;
  }

  .mgrReqItemNonPendHover:hover {
    padding-left:1px;
  }
  .mgrReqCostTxt {
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content !important;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
    max-height: 24px;
    /* line-height: 24px; */
    align-items: center;
    border-radius: 4px;
    /* border: 1px solid #859dbb; */
    color: #fff;
    font-weight: 600;
    font-size: 11px;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c6b5eb;
    margin-top: 3px;
    margin-bottom: -3px;
    margin-left:6px;
    border: #ac99d4 solid 1px;
    /* margin-left: 9px; */
    /* display:none */
  }


  .declinedCostReqColours {
    background-color: #e3e8ee;
    color: #4f566b;
  }

  

.mgrReqDurAndCostDiv {
  display: flex;
  min-width: 128px;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: -50px;
}





  .mgrReqDurTxt {
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content !important;
   
    font-weight: 600;
    padding-top: 3px;
    padding-bottom: 3px;


    margin-top: 3px;
    margin-bottom: -3px;

  /* margin-right: 8px; */




    font-size: 11px;
    text-align: left;
    padding-left: 6px;
    padding-right: 6px;
    /* border-radius: 4px; */

    max-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: #bbcada; */
    /* background-color: #f0f5ff; */
    border: 1px solid #bbcada;



  /* font-size: 10px; */
  /* font-weight: 500; */
  background: #f0f5ff;
  border-radius: 5px;
  border: 1px solid #bbcada;
  color: #516b8b;

  }

 

  .startsInTxt {
    font-size: 10px;
    opacity: 1;
    font-weight: 500;
    margin-top:0px;
    color:#0af;
    text-align: right;
    /* opacity: 0.5; */
    /* display: none; */
  }

  .x19844381 {
    margin-right:11px;
  }

  .startsInTxtAbsence {
    font-size: 10px;
    opacity: 1;
    font-weight: 600;
    margin-top:2px;
    color:#0af;
    text-align: right;
    color:#bbcada;
  }
  .myReqsFilterBar {
    min-width: 88%;
    max-width: 88%;
    /* background-color: orange; */
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    /* margin-left: 30px; */
    margin-top: 8px;
  }

  .myReqsFilterBarFull {
    margin-left:30px;
  }

  .x1038198 {
    margin-left:0px;
  }
  

  .myReqsYearDropdown {
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    padding-left: 9px;
    text-align: left !important;
    max-width: 70px;
    min-width: 70px;
    line-height: 26px;
    height: 35px;
    -webkit-appearance: none;
    display: flex;
    cursor: pointer;
    border-color: #2c7eb9;
    border: 2px solid #e6eaee;
    background-color: white;
    color: #143051;
    background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
    background-position-x: 48px;
  }

  .myReqsYearDropdownMob {
    background-position-x: 50px;
    border-color: #fff;
    padding-left: 0px;
    margin-top: -3px;
    margin-left: -1px;
    background-position-x: 121px !important;


  }

  .myReqsYearDropdownMobCal {
    border-color: #fff;
    padding-left: 0px;
    margin-top: 5px;
    margin-left: -1px;
    background-position-x: 46px !important;
  }
  .myReqsYearDropdownMobCal:hover {
    border-color:#fff !important
  }
  .myReqsYearDropdownMob:hover {
    border-color:#fff !important
  }
  .myReqsYearDropdown:hover {
    border-color: #dce2e9;

  }
  

  .myCalMonthsDropSize {
    background-position-x: 124px;
    max-width: 147px;
    min-width: 147px;
    margin-right: 4px;
  }

  .marginLeft17px {
    margin-left: 17px !important
  }


  .myReqsTypesDropdown {
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 12px;
    text-align: left !important;
    max-width: 220px;
    min-width: 220px;
    line-height: 26px;
    height: 35px;
  
    border: 1px solid #9374d6;
    -webkit-appearance: none;
    background-position-x: 66px;
    display: flex;
    cursor: pointer;
    border-color: #2c7eb9;
    border: 1px solid #e6eaee;
    /* background-color: #0bf; */
    background-color: white;
    color: #143051;
    background: url("../img/general/downArrowGrey.svg") no-repeat right #fff;
    background-position-x: 196px;
    /* margin-right:5% !important */
    /* text-overflow: ellipsis; */


  }

   @media only screen and (max-width: 410px) {
    .myReqsTypesDropdown {
      min-width: 200px !important;
      max-width: 200px !important;
      background-position-x: 176px; 
    }
  }


  .myReqsTypesDropdown:hover {
    border-color: #dce2e9;
  }



  .mobMyReqsFilterbarLeavePage {
    margin-left:0px !important;
  }

  .nonUpcomingFiltBarMob {
    /* margin-left: 5% !important; */
    min-width: 100%; 
    padding-bottom:8px

  }

  .teamMobFilterBar {
    /* background-color: blue; */
    /* margin-left: -10px */
  }

 



  .noBottomPadding {
    padding-bottom: 0px !important
  }


  .closeReqDataBoxImg {

    cursor: pointer;
    min-width: 15px;
    margin-left: 8px;
    cursor: pointer;
    margin-top: -8px;
    margin-right: -100px;
  }

  .closeReqDataBoxImg:hover {
    opacity: 0.8;
  }

  .font18px {
    font-size: 18px !important
  }

  .font16px {
    font-size: 16px !important
  }


  .approvedByNameTxt {
    /* background-color: yellow; */
    margin-bottom: -20px;
  }

  .usedRemainingIndent {
    margin-left: 7px;
    font-size: 12px !important
  }

  .colourNavy {
    color: #143051;
  }

  .backgroundNavy {
    background-color: #143051 !important;
  }

  .manageExcessOnReqBtn {
    color: #fff;
    border: solid 1px #bbcada;
    background-color: #cbd7e6;
    font-size: 14px;
    padding-left: 7px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-radius: 5px;
    margin-left: 10px;
    min-height: 29px;
    cursor: pointer;
    padding-bottom: 1px;
}



.manageExcessWhiteGoImg {
  max-width: 12px;
  margin-left: 5px;
  margin-right: 2px;
  /* margin-top: 1px; */
}

.expandCostArrImg {
  max-width: 8px;
  margin-left: 5px;
  margin-right: 2px;
  margin-top: 1px;
}

.startEndTimeHolder {
  min-height: 22px;
  max-height: 22px;

}

.requestProfPicImg {
  cursor: pointer;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
border-radius: 5px;
  object-fit: cover;
  border: 2px solid #143051;
  /* margin-bottom: 12px; */
  margin-top: -4px;

}

.requestProfPicImg:hover {
border-color: #2066b6;}



.reqBlankProfPicHolder {
  cursor: pointer;
  background-color: #516B8B;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
border-radius: 5px;
  border: 2px solid #143051;
  margin-top:-5px
}

.reqBlankProfPicHolder:hover {
border-color: #2066b6}

.reqBlankProfilePicPlaceholderImg {
  cursor: pointer;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  object-fit: cover;
padding-right: 4px;
padding-bottom:2px;
}



.reqBlankProPicInitialsHolder {
  color: white;
  display: flex;
  font-size: 30px;
  margin-top: -66px;
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  font-weight:500
}


.overlapsRenewalModalUnderlay {
  background: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.overlapsRenewalModalBox {
  min-height: 100px;
  max-width: 300px;
  min-width: 300px;
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
  justify-content: space-between;
  background-color: white;
  color: #143051;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  animation-name: zoomBtnIn !important;
  animation-duration: 0.1s !important;
  cursor: default;
}

.overlapsRenTxt {
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.reqsIndividualNameTitle {
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
  margin-top: -4px;}

  .dataBoxMin {
    max-height:280px !important
  }


  .minHeightFitContent {
    min-height: fit-content
  }

  .nOutOfDaysSpan {
    color: #143051;
    border-radius: 5px;
    padding-left: 2px;
    /* padding-right: 5px; */
    font-size: 18px;
    /* padding-top: 2px; */
    /* padding-bottom: 2px; */
    font-weight: 500;
    cursor: pointer;
    font-size: 15px;
 }

 .nOutOfDaysSpan:hover {
  opacity: 0.8;
 }

 .colourDarkRed {
  color: #ba0000
 }

    .limitExceededReqTxt {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      color: #496375;
    }

  
    
    .exceededDaysDiv {
      display: flex;
      justify-content: flex-start;
      margin-top: 8px;
      margin-bottom: -7px;
    }


    .limitsDrop {
      cursor: pointer;
    min-width: 22px;
    max-width: 22px;
    max-height: 22px;
    min-height: 22px;
    background-color: #cbd7e6;
    border: 1px solid #bbcada;
    padding: 6px;
    border-radius: 5px;
    margin-left: 10px;
    /* margin-top: -2px; */
    }

    .limitsDrop:hover {
      background-color: #bbcada;
    }

    .reqLimitsArray {
margin-top:14px;   
animation-name: costDivDown !important;
animation-duration: 0.12s !important;
border-left: 1px solid #d7e1ee;
border-bottom: 1px solid #d7e1ee;
max-width:90%;
border-radius: 0px 0px 0px 5px; }

    .reqLimitRow {
      min-height: 30px;
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      justify-content: center;
      color: white;
      font-weight: 500;
      /* border-radius: 5px; */
      /* margin-top: 6px; */
      max-width: 92%;
      border-left: 1px solid #d7e1ee;
      border-bottom: 1px solid #d7e1ee;
      margin-left: 5%;
      min-height: 50px;}

      .limRowFlex {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-height: 30px;
        min-height: 30px;
        margin-bottom: -15px;
        border-bottom: 1px solid #e3e8ee;
        padding-bottom: 7px;
        margin-left: 6px;
        max-width: 90%;
        border-left: 1px solid #e3e8ee;
        border-radius: 0 0 0 5px;
        padding-top: 8px;
        margin-top: -7px; 
      padding-left:6px;}

    .firstLimRowFlex {
      margin-top: 7px;
    }

      .limitByJtOrTeamSpan {
        color: #143051;
        font-weight:500;
        font-size:12px;
      }

      .costContractedExcessSpan {
        margin-left:10px;
      }

      .limRowTitleSpan {margin-right:8px;
        font-size: 12px;
        color: #bbcada;
        margin-left: 4px;
        font-weight: 500;}

      



.limArr {
  color: #143051;
  margin-left: 2%;
  font-size: 16px;
  font-weight: 500;
  margin-top: 6px;
}


.limitBtLimRowTxt {
  font-size: 12px;
  margin-left: 0px;
  font-weight: 500;
  border-bottom: 1px solid #e3e8ee;
  max-width: 125px;
  margin-left: 6px;
  border-left: 1px solid #e3e8ee;
  border-radius: 0 0 0 5px;
  padding-left: 10px;
  margin-top: -7px;
  padding-top: 8px;
  padding-bottom: 7px;
  color: #bbcada;
}


.reqLimitsContainer {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 20px;
  min-width: 96%;
  max-width: 96%;
  padding-left: 10px;
  background-color: #f0f5ff;
  border-radius: 5px;
  margin-top: 18px;
  padding-bottom: 24px;
  border: 1px solid #e6eaee;
  cursor: pointer;
}


.userReqLimitsContainer {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 18px;
  min-width: 196px;
  max-width: 196px;
  padding-left: 10px;
  background-color: #f0f5ff;
  border-radius: 5px;
  margin-top: 18px;
  padding-bottom: 24px;
  border: 1px solid #e6eaee;
  cursor: pointer;
  margin-left: 18px;
}
.userReqLimitsContainer:hover {
  background-color: #fafcff;
}
.reqLimitsContainer:hover {
  background-color: #fafcff;
}

.viewReqTeamCalWarning {
  background-color: #ba0000;
  border: #931414 1px solid;
  min-width: 60px !important;
  max-width: 60px !important;
  padding-left: 3px;
  padding-right: 2px;
  margin-left: 4px;
  font-weight: 500;
  font-size: 12px;

}

.viewReqTeamCalWarning:hover {
  background-color: #a22222;
}

.limitsRedTriangleImg {
  max-width:14px;
}
.reqUnderstaffedTxt {
  color: #143051;
  font-weight: 700;
  font-size: 10px;
  margin-top:3px;
margin-left:4px}

.requestsPageUnderstaffedWarnImg {
  max-width: 13px;
  margin-left:8px;
}



.costBoxNonSelect {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 2px;
  min-width: 86%;
  max-width: 86%;
  padding-left: 10px;
  background-color: #f0f5ff;
  border-radius: 5px;
  margin-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #e6eaee;
  /* cursor: default; */
  margin-left: 18px;
  margin-top: 22px}

  .understaffedRed {
    color: #ba0000 !important
  }

  .closeTeamCalModalImgMob {
    margin-left:10px
  }
  .reqTeamCalContainerMobModalHeight {
    max-height: 480px
  }

  .teamBtnContainerUserReq {
    min-height:50px;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: space-between; */
    /* background-color: blue; */
  }

  .txtFontSize16px {
    font-size: 16px !important;
    line-height: 18px  !important;
  }

  .reqsMenuTeamBtnSel {
    color:#143051  !important;
    /* border-bottom: 3px solid #ffa8ff; */
    /* padding-bottom: 5px */
  }

  .reqsMenuTeamBtnSel:hover {
    /* color:#9576cc  !important; */
    /* border-bottom: 3px solid #ffa8ff !important; */
    /* padding-bottom: 5px */
  }

  .staffReqsTitleDynamic {
    font-size: 28px;
  }

  /* @media only screen and (max-width: 400px) {
    .staffReqsTitleDynamic {
font-size:24px !important;
  
    }
  }



  @media only screen and (max-width: 375px) {
    .staffReqsTitleDynamic {
font-size:22px !important;
  
    }
  }


  @media only screen and (max-width: 343px) {
    .staffReqsTitleDynamic {
font-size:18px !important;
  
    }
  } */

  .reqsTeamHoverBtn {
    color:#214978 !important
  }

  .reqsTeamHoverBtn:hover {
    color: #143051 !important;
  }

  .namedReqsBackBtn {
    background-color: #b49ce7;
    border: 1px solid #965ade;
    max-width: 124px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    min-height: 30px;
    margin-top: 2px;
    margin-bottom: 3px;
    justify-content: space-around;
    padding-left: 1px;
    padding-right: 1px;
    cursor: pointer;
}

.namedReqsBackBtnMob {
  max-width: 130px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 3px;
  color: white;
  display: flex;
  align-items: center;
  min-height: 30px;
  justify-content: space-around;
  padding-left: 1px;
  padding-right: 2px;
  cursor: pointer;
  background-color: #9a74eb;
  border: 1px solid #965ade;
}

.namedReqsBackBtn:hover {
  background-color: #967ccf
}

    .namedReqsBackBtnImg {
      max-width: 12px;
      margin-right: -9px;
      margin-left: -2px;
    }

    .pullStaffHoursBtnUp {
      margin-top: -18px !important;
    }

    .pullStatsBtnUp {
      margin-top: -22px !important
    }



    .incSal {
      background-color: #c6b5eb;
      margin-top: 21px;
      margin-left: 5px;
      color: white;
      max-width: 56px;
      text-align: center;
      border-radius: 3px;
      margin-bottom: -11px;
      font-size: 14px;
      font-weight: 500;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;  
     }
  


    .incSal2 {
      background-color: #c6b5eb;
      margin-top: 0px;
      margin-left: 0px;
      color: white;
      min-width: 56px;
      text-align: center;
      border-radius: 3px;
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 500; 
    border: 1px solid #b3a2d7 ;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;  
  }


    .incSal3{
      background-color: #c6b5eb;
      margin-top: 8px;
      margin-left: 8px;
      color: white;
      max-width: 56px;
      text-align: center;
      border-radius: 3px;
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid #b3a2d7 ;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;  
    }
    
    .incSal3unpaid {
      background-color: #cbd7e6;
      border: 1px solid #bbcada;
      min-width: fit-content;
      font-size: 12px;
      text-align: left;
      padding-left: 6px;
      padding-right: 6px;
      line-height: 15px;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    .incSal4{
      background-color: #c6b5eb;
      margin-top: 8px;
      margin-left: 8px;
      color: white;
      max-width: fit-content;
      text-align: left;
      border-radius: 3px;
      margin-bottom: 0px;
      font-size: 13px;
      font-weight: 500;
      border: 1px solid #b3a2d7;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 7px;
      line-height: 16px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right:5px;

    }
    
    

      .marginTop126px_ {
        margin-top: 19px !important
      } 


      .myReqsTitle2 {
margin-top: -18px !important;
   }




/* .pageHeaderMain {
  min-width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);
  background: blue;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pageHeaderRow1 {
  background-color: orange;
  margin-left:5%;
  min-height:100px;
}

.pageHeaderRow1Title {
  font-size:33px; 
  color: #143051;
  font-weight:500;
}

.pageHeaderRow2 {
  max-height:1px
} */

.reqsPageTeamsSelector {
  margin-top: 8px;
}

.reqsFiltBarLeft {
      /* min-width: 37%; */
    /* max-width: 37%; */
    /* background-color: orange; */
    min-height: 100%;
    display: flex;
    justify-content: flex-start;
    /* margin-left: 8%; */
    margin-top: 8px;
}

.reqSalPlusSpan {
  margin-left:4px;
  font-size:11px
}

.mobReqsAllowancesBtn {
  max-height: 35px !important;
  min-height: 35px !important;
  background-color: #9a74eb !important;
  border: 1px solid #965ade !important;
  color: white !important;
  min-width: 112px !important;
  max-width: 112px !important;
  border-radius: 5px;
  font-weight:600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:8px;
  cursor: pointer;
  border: 1px solid #e6eaee  !important;
  background-color: white !important;
color: #8ea7c5 !important
  
}


.mobReqsAllowancesBtn:hover {
  border-color: #d7dfe9 !important;
}
.mobReqsAllowancesBtnTxt {
  margin-top:-1px;
  font-size:14px;
  margin-right:3px;
  
  font-weight: 500;
  /* margin-left:-2px; */
}

.mobReqStatsImg {
  max-width: 14px;
  margin-left: 1px;
  margin-right: 0px;
}

.reqPageFilterBarMyUpcomingNonFull {
  min-width: 100%;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 2px solid #e6eaee;
  min-height: 55px;
  max-height: 55px;
}

.reqPageFilterBarMyUpcomingMedium {
  border-bottom: none
}


  .myReqsFilterBarUpcomingNonFull {
    min-width: auto;
    max-width:auto;
  }

  
  .isAbsenceLeaveTypeBorderLeft {
    border-radius: 3px;
    border: 1px solid #cbd7e6;
    border-left: 3px solid #c95858;
  cursor: pointer;  }

    .isLeaveTypeBorderLeft {
      min-width: fit-content;
      max-width: fit-content;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      border-radius: 5px;
      border: #e3e8ee solid 2px;
      padding-left: 7px;
      padding-right: 6px;
      cursor: pointer;
      min-height: 30px;
      max-height: 30px;
      display: flex;
      align-items: center;
      color: #143051;
      background-color:white;
      /* border-left: 3px solid #9374d6; */
    cursor: pointer;  }


    

    .mobMyReqsTypesDropFixedPos {
      position: fixed;
      right:30px;
    }

    .reqsPageFilterHolder {
      /* background-color: yellow ; */
      /* max-width:96% */
    }

    .myReqsTypesDropdownMob {
      /* margin-right:5%; */
    }


    .mgrDesktopDataRowBorder {
      border-bottom: 2px solid #516b8b !important;
      padding-bottom:12px;
      margin-top:8px;
      margin-bottom:4px;
      /* min-height:0px; */
    }

    .showingUsrReqsTxt {
      font-weight:600;
      font-size:14px;
      color: #496375;
      margin-top:14px;
    margin-right:20px;
    border: 1px solid #bbcada;
    border-radius:5px;
    padding:5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left:10px;
    padding-right:9px;
    min-height:35px;
    max-height:35px;
    cursor: pointer;
    background-color: white;
    }

    .showingUsrReqsTxt:hover {
      border-color: #496375
    }
    .closeShowNamedReqsImg {
      max-width:10px;
      margin-left:6px;
      margin-right:3px;
    }

    .makeTxtPrimary {
      /* color: #0077ff !important; */
      background: none !important;
      font-size:14px;
      border:none
    }

    .showingNameReqsOnlyTxt {
      margin-left: 5%;
      font-size: 14px;
      text-align: left;
      margin-top: 27px;
      /* padding-bottom: 20px; */
      color: #bbcada;
      font-weight: 600;
    }

    .totSalIncludedSize {
      max-width: fit-content !important;
      min-width: fit-content !important;

      padding-left:6px;
      padding-right:6px
    }

    .totSalIncl {
      margin-left:6px;
      font-size:10px;
    }

    .reqsSideBoxHeader {
      margin-bottom:6px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .amendAllowsImg {
      max-width:10px;
      margin-left:8px;
    }

    .topMgrLeaveInfo {
      border: 1px solid #7e60af;
      background: #b997f1;

      border-radius: 5px;
      max-width: 90%;
      margin-left: 5%;
      margin-top: 10px;
  
    }

    .modalDataBoxHeading {
      font-size:20px !important;
      padding-top:0px !important;
      margin-left:38px; 
      margin-top:-8px !important;
    }

    .firstDataVal {
      margin-top: 14px;
    }

    .modalMyReqsDataBoxTitle {
    /* margin-top: -8px; */
    margin-left: 114px;    }

    .modalMyAbsTitle {
      /* margin-top:-10px; */
      font-size:18px !important;
      /* margin-left:64px; */
      margin-left:115px;
      padding-bottom:9px
    }

    .modalMyAbsNoCustoms {
      max-height:345px;
      min-height:345px;
    }


    .addAbsenceItemsBtn {
      background-color: #0bf;
      border-radius: 5px;
      max-width: fit-content;
      border: 1px solid #0af;
      max-height: 35px;
      color: white;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      padding-left: 8px;
      padding-right: 8px;
      cursor: pointer;
      margin-left: 7%;
      margin-top: 10px;    }

    .addAbsenceItemsBtn:hover {
      background-color: #0af;
    }

    .closeAddAbsItemsBtn {
      border-radius: 5px;
      max-width: fit-content;
      border: solid 1px #bbcada;
      background-color: #cbd7e6;
            max-height: 35px;
      color: white;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      padding-left: 12px;
      padding-right:12px;
      cursor: pointer;
      margin-left: 7%;
      margin-top: 10px;
    }

    .closeAddAbsItemsBtn:hover {
      background-color: #bbcada;

    }

    .addAbsenceItemsContainer {
      border: 2px solid #e0ebf8;

    max-width: 90%;
    margin-left: 5%;
    border-radius: 5px;
    min-height: 40px;
    overflow: auto;
    max-height: 210px;
    margin-top: 10px;
    padding-bottom:10px;
    background-color: #f0f5ff;
    }

    


    .addAbsItemUnit {
      display: flex;
      justify-content: space-around;
      min-width: 94%;
      max-width: 94%;
      /* border: 1px solid #e0ebf8; */
      border-radius: 5px;
      max-height: 50px;
      margin-top: 10px;
      margin-left: 10px;
      border: 1px solid rgba(89, 89, 89, 0.267);
      border-bottom: 2px solid rgba(89, 89, 89, 0.267);
      background-color: white;
      cursor: pointer;
    }


    .addAbsItemUnitForm {
      display: flex;
      justify-content: space-around;
      min-width: 94%;
      max-width: 94%;
      /* border: 1px solid #e0ebf8; */
      border-radius: 5px;
      max-height: 50px;
      margin-top: 10px;
      margin-left: 10px;
      border: 1px solid rgba(89, 89, 89, 0.267);
      border-bottom: 2px solid rgba(89, 89, 89, 0.267);
      background-color: white;
      /* cursor: pointer; */
    }


    .addAbsItemUnitForm2 {
      display: flex;
      justify-content: space-around;
      min-width: 94%;
      max-width: 94%;
      /* border: 1px solid #e0ebf8; */
      border-radius: 5px;
      max-height: 50px;
      margin-top: 10px;
      margin-left: 10px;
      border: 1px solid rgba(89, 89, 89, 0.267);
      /* border-bottom: 1px solid rgba(89, 89, 89, 0.267); */
      background-color: white;
      cursor: pointer;
    }

    .addAbsItemUnitForm2:hover {
      border-color: #a7b3c0
    }

    .addAbsItemUnitDesktop:hover {
      border-color: #859dbb;
      background: white;  }

    .addAbsItemLeft {
      /* background-color: purple; */
      text-align:left;
      min-width:50%;
    }


    .addAbsDate {
      max-height: 25px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin-top:4px;
    padding-left:13px;

    }

    .addAbsPeriod {
      /* background-color: yellow; */
      max-height:25px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      margin-top: -9px;
      color: #bbcada;
      padding-left:13px;

    }



    .addAbsDate2 {
      color: #516b8b;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin-top: -4px;
      padding-left: 13px;

    }

    .addAbsPeriod2 {
    /* background-color: yellow; */
    /* color: #516b8b; */
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin-top: -30px;
    color: #bbcada;
    padding-left: 13px;

    }

    .addAbsItemRight {
      /* background-color: blue; */
      text-align:right;
      min-width:40%;


    }

    .addAbsTitle{
      /* background-color: orange; */
      max-height:25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: 500;
      padding-right: 13px;
      margin-top:4px;
    }

    .addAbsName {
      /* background-color: green; */
      max-height:25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: 500;
      margin-top:-11px;
color: #bbcada;
      padding-right: 13px;    }

      .addAbsTitle2{
        color:#516b8b;
        max-height:25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 500;
        padding-right: 13px;
        margin-top:4px;
      }
  
      .addAbsName2 {
        /* background-color: green; */
        max-height:25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: 500;
        margin-top:-9px;
  color: #bbcada;
        padding-right: 13px;    }

      .selectedAddAbsItem {
        /* border-color: #143051 !important;
        background: #0077ff;   */
          }

        .selectedAddAbsItem:hover {
          /* border-color: #143051 !important;
          background: #0077bb;    */
        }


        .formPeriod {
  font-weight: 500;
          margin-left: 7%;
          margin-top: -6px;
        }

        .noPaddingBottom {
          padding-bottom:0px !important
        }


.addFormTitleInput {
  min-width: 80%;
  color: #6a8bb1;
  min-height: 40px;
  font-weight: 500;
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 8px;
  background-color: #fdfeff;
  max-width: 80%;
  margin-left: 6%;
  margin-top: 6px;
  resize:none
}

.formInfoInput {
  min-height:100px;
  padding-top:10px;
}
.noAbsItemsToAdd {
  color: #bbcada;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  margin-bottom: -10px;
  padding-left: 16px;
}

.questionsContainer {
  /* border: 2px solid #e0ebf8; */
  max-width: 90%;
  margin-left: 5%;
  /* border-radius: 5px; */
  /* min-height: 40px; */
  /* overflow: auto; */
  /* max-height: 210px; */
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding-right:2px
  /* padding-bottom: 10px; */
  /* background-color: #f0f5ff; */
}




.rtwQitem {
  border: 1px solid #bbcada;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  background: #f0f5ff;  
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.rtwQitemToAnswer {
  border: 1px solid #bbcada;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  background: #f0f5ff;  
  border-left:5px solid #214978
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.removeRtwQimg {
  max-width: 12px;
  min-width: 12px;
  margin-left: 12px;
  margin-right: 5px;
  cursor: pointer;
}

.removeRtwQimg:hover {
  opacity: 0.8;
}

.addRTWqToFormBtn {
  background-color: #a387df;
  border: 1px solid #896ec3;
  color: white;
  font-weight: 500;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.addRTWqToFormBtn:hover {
  background-color: #896ec3;
}

.addQuestionInput {
  min-width: 87%;
  color: #6a8bb1;
  min-height: 50px;
  font-weight: 500;
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 8px;
  background-color: #fdfeff;
  max-width: 87%;
  /* margin-left: 6%; */
  margin-top: 6px;
  resize: none;
  padding-top: 12px;
  line-height: 22px;
  overflow: auto;
}

.addQuestionContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 13%;
  margin-top: 7px;
}

.addQuestCanx {
  font-size: 14px;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  padding-left: 12px;
  font-weight: 500;
  padding-right: 12px;
  cursor: pointer;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;}

  .addQuestCanx:hover {
    background-color: #bbcada;
  }

.addQuestAdd {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: solid #0af 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 60px;
  min-width: 60px;
margin-left:8px;}

  .addQuestAdd:hover {
    background-color:#0af ;
  }


  .createFormSubmitBtn {
    font-size: 14px;
    color: #fff;
    background-color: #1e88e5;
    border: #0469de 1px solid;
    font-weight: 500;
    cursor: pointer;
    padding-left: 9px;
    padding-right: 9px;
    max-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    min-height: 35px;
    max-width: 70px;
    min-width: 70px;
  }
  .createFormSubmitBtn:hover {
    background-color: #1374e4;
  }
  

  .formCreatedModal {
    min-height: 100px;
    max-width: 300px;
    min-width: 300px;
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto;
    justify-content: space-between;
    background-color: white;
    color: #143051;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    animation-name: zoomModalIn !important;
    animation-duration: 0.2s !important;
    cursor: default;
    font-weight:500
  }

  .formCreatedModalClose {
    font-size: 16px;
    color: #fff;
    background-color: #3b597c;
    border: solid #214978 1px;
    font-weight: 500;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
    max-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    min-height: 35px;
    margin-top: 20px;
  }

  .formCreatedModalClose:hover {
    background-color: #214978;
  }

  .reqPlaneDiv {
    min-width: 30px;
    margin-right: 0px;
  }

  .reqPlaneIcon {
    max-width: 19px;
    margin-left: 10px;
    margin-top: 4px;
    margin-right: 2px;
   }

    .reqPlaneIconMob {
      max-width:19px;
      margin-left: 9px !important;

      margin-right:3px !important;
    }

    .qIndex {
      color: #bbcada
    }

    .rtwQanswerInput {
      background-color: white;
    }

    .toReviewSplitter {
      margin-top: 20px;
      margin-bottom: 25px;
      background-color: #143051;
      min-width: 80%;
      min-height: 3px;
      max-width: 80%;
      margin-left: 10%;
      border-radius: 5px;
    }

    .pendingReviewFormsTitle {
      font-weight: 500;
      color: #00aaff;
      font-size: 14px;
      text-align: left;
      margin-left: 7%;
      margin-top: 3px;
      margin-bottom: 16px;
    }

    .pendingReviewFormsTitleMob {
      margin-bottom:22px
    }
    .upcomingMgrReqsSpan {
      /* background-color: yellow; */
      /* margin-left:4px; */
      margin-top:0px;
      font-weight: 400;
      color:#516b8b;
      display: flex;
    }

    .upcomingMgrReqsSpanDur {
      margin-top:4px;
      overflow:visible;
      min-width: fit-content;
      display: flex;
      align-items: center;
    }

.x384849283455 {
  margin-left:4px;
}
    .addStaffLeaveBtn {
      min-width: -moz-fit-content;
      min-width: fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content;
      cursor: pointer;
      display: flex;
      max-height: 30px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 5px;
      border: #e3e8ee solid 2px;
      padding-left: 7px;
      padding-right: 6px;
      cursor: pointer;
      min-height: 30px;
      display: flex;
      align-items: center;
      color: #143051;
      background-color: white;
      min-height: 35px;
      margin-top: 8px;
    }


    .filterBarWithAddLeave {
      /* background-color: orange; */
        min-width: 240px;
      justify-content: space-between !important;
      margin-left: 18px !important;
      margin-top: 9px !important;
    }

    .colourPink {
      /* color: #ffd6ef !important */
    }

    .myLeaveDataMinsSpan {

    margin-left: 10px;
    padding-left:8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 22px;
    color: #fff;
    border-left: 2px solid #143051;
    /* padding-right: 10px; */
    }

 

    .highlightReqField2 {
      border:2px solid #00ccff !important
    }


.pHolSpecDayRow {
  background-color: #214978;
  border-radius:5px;
  min-height:100px;
  max-width:94%;
  margin-left:3%;
  align-items: center;
  margin-bottom:10px;
}

.pHolDataRow {
border-bottom:1px solid #7d9ec8;
max-width: 92%;
    margin-left: 4%;
display: flex;
color:white;
font-weight:600;
font-size:14px;
min-height:50px;
align-items: center;
padding-left:12px;
justify-content: space-between;
padding-right:5px;
}

.maxHeight50 {
  max-height:50px !important;
  min-height:50px !important;

}

.customScrollBoxWhenOnlyOnePHolRowShown {
  max-height:calc(100vh - 450px)
}

.customScrollBoxWhenBothPHolRowShown {
  max-height:calc(100vh - 550px)

}

.customScrollBoxWhenOnlyOnePHolRowShownModal {
  max-height: calc(100vh - 347px) !important;
  min-height: calc(100vh - 347px) !important;}

.customScrollBoxWhenBothPHolRowShownModal {
  max-height: calc(100vh - 397px) !important;
  min-height: calc(100vh - 397px) !important;
}


.bankPopup {
  max-width:14px;
  margin-left:2px;
  padding:2px;

}



.bankPopUpViewBtn {
  border:1px solid #bbcada;
  border-radius:3px;
  cursor: pointer;
  background-color: #143051;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:2px;
  padding-left:5px;
  font-size:9px;
  margin-left:6px;
}

.bankPopUpViewBtn:hover {
  background-color: #214978;
}

.showPHolsUnderlay {
  position: fixed;
  top:0;
  left:0;
  background-color: rgb(0,0,0,0.25);
  min-width: 100vw;
  min-height:100vh;
  z-index:999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.pholsShowModalBody {
  padding-left:10px;
  padding-right:10px;
  padding-bottom:10px;
}

.unsurpassedItem {
  background-color: white !important;
  border-color: #bbcada  !important;
  border-left: #00ccff 3px solid !important;
}

.unsurpassedItemTxt {
  color:#214978 !important
}

.allowStatus {
  background-color: #c6b5eb;
  /* margin-top: 21px; */
  /* margin-left: 5px; */
  color: white;
  max-width: 85px;
  text-align: left;
  padding-left:7px;
  padding-right:5px;
  /* text-align: center; */
  border-radius: 4px;
  margin-bottom: -11px;
  font-size: 12px;
  font-weight: 500;
  padding-top:5px;
  padding-bottom:5px;
  /* min-height: 24px; */
  display: flex;
  line-height:12px;
  align-items: center;
  /* margin-top */
  /* justify-content: center;   */
 }



.suffLeaveReq {
  background-color: #a8cc9a;
  border: 1px solid #6db451;
}

.insuffLeaveReq {
  background-color: #b24e4e;
  border-left: 1px solid #971515;
}


.suffDur {
  border-left: 3px solid #6db451;
  max-height:24px;
  margin-top:20px;
  margin-bottom:12px;
  margin-left:6px;
}

.insuffDur {
  border-left: 3px solid #971515;
  max-height:24px;
  margin-top:20px;
  margin-bottom:12px;
  margin-left:6px;
}


.unpaidCostColours {
  background-color: #bbcada !important;
  border-color: #859dbb !important
}

.mgrAllowsFooter {
  min-height: 50px;
  border-top: #ffffff1c solid 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  max-width: 90%;
  margin-left: 5%;
}
.myAllowsFooter {
  min-height: 50px;
  border-top: #ffffff1c solid 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  max-width: 90%;
  margin-left: 5%;
}


.modalAllowsHeightForFooter {
  max-height: calc(100vh - 339px);
  min-height: calc(100vh - 339px);
}

.modalMgrReqsTitle {
  /* margin-top:-9px; */
  margin-left:10px;
}

.x981971 {
  visibility: hidden;
  min-width: 4px;
  max-width: 4px;
  min-height: 4px;
  max-height: 4px;
  background-color: #fff;
  border-radius: 100%;
  margin-right: -2px;
  margin-top: -20px;
  margin-left: 48px;
  margin-right: -50px;
}

.noAni {
  animation-name: none !important;
  animation-duration: 0.5s !important;
}


.x917491 {
  /* background-color: red; */
  /* margin-top:2px; */
}


.x131314 {
  margin-top:-50px !important;
}

.x19749171 {
  margin-top: -2px !important;
  margin-bottom: -10px !important;
display: block !important;
}

.closeMgrReqsBtn {
  min-width: 30px;
  padding:8px;
  cursor: pointer;
  /* background-color: blue; */
}


.mgrAllowsHeaderLeft {
  display: flex;
  align-items: center;
  margin-top:-8px
}

.x23141 {
  margin-top:0px
}

.x29842981 {
  opacity: 0.25;
}

.reqCostLoadImg {
  max-width: 40px;
  margin-left:10px;
  margin-top:23px;
  margin-bottom:14px;
}

.x248981 {
  background-color: #ba0000;
  color: white;
  opacity: 0.7;}


  .mgrReqNameTab {
    background-color: rgb(255, 255, 255);
    /* border: 1px solid #e7ebef; */
    border-bottom: none;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: 5%;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    padding-right: 12px;
    font-weight: 500;
    border-radius: 5px 5px 0 0;
    font-weight: 500;
    margin-top: -110px;
    margin-bottom: 99px;
    font-size: 16px;
    font-weight: 600;
    color: #143051;
    cursor: pointer;
   }

  .reqUnitParent {
    padding-top: 10px;
    margin-bottom:4px;
    /* min-height: 120px !important; */
  }

  .x29382983 {
    /* margin-left:40px !important;  */
  }

  .mgrReqsShoulderTopRight {
    display: flex;
    flex-direction: row-reverse;
  }

  .nonFullAddLeaveBtn {
    margin-right:10px;
    min-height: 34px;
    max-height: 34px;

  }


  .mgrPrevScrollHeightMob {
    min-height: calc(100vh - 221px);
    max-height: calc(100vh - 221px);
  }

  .mgrPrevScrollHeightMobIos {
    min-height: calc(100vh - 241px);
    max-height: calc(100vh - 241px);
  }


  .mgrCtaColours {
    background-color: #d58eff !important;
    border: 1px solid #a368c5 !important;
  }

  

  .mgrCtaColours:hover {
    background-color: #c582ed !important;
  }


  .x238312 {
    line-height:16px;
    font-size:12px !important;
    margin-top:12px;
    margin-bottom:12px;
    max-width: 150px;
    color:#143051 !important;
    font-weight: 500;
  }

  .x7187372 {
    display: flex;
    justify-content: space-between;
    min-width: 100%  !important;
    padding-right:24px;
    align-items: center;
  }

  .x98763274 {
    /* background-color: pink !important; */
    margin-top:-3px;
  }


  .x3388242 {
   margin-left:30px  !important;
  }


  .x33883121 {
    margin-left:0px !important
  }

  .x29488845342 {
    margin-right:15px !important
  }



  .mgrReqJt1 {
    color: white;
    opacity: 0.5;
    font-size: 10px;
    /* margin-top: -3px; */
    margin-bottom: 0px;
    line-height: 10px;
  }

    .mgrReqJt2 {
      color: white;
      opacity: 0.3;
      font-size: 10px;
      margin-top: 1px;
      line-height: 10px;
    }

    .mgrReqDataName {
      line-height:18px;
      margin-top:4px;
    }
    .colPur{
      color: #fff  !important
    }

    .x37467283 {
margin-left:3px;    }

.x485839823 {
  background-color: #482a7a;
  border: 1px solid #143051;}

.txtColPur {
  color:#653fa7 !important
}

.txtColPur2 {
  color:#a68ad6 !important

}
.txtColPur3 {
  color:#fff !important

}

.enableTilKnob {
      padding-bottom: 2px;
      margin-top: -7px !important;
}

.fixedSchedLeaveDiv {
color:#214978;
font-weight:400;
font-size:12px;
}

.x29482983298311 {
  min-width: 100%;
  /* margin-left: 5%; */
}



  .x2948911342 {
    font-size:14px !important; 
  }

  .x660o {
    margin-left:6px
  }

  .x7ssd7 {
    max-height: calc(100vh - 450px);
    min-height: calc(100vh - 450px);
    }

    .marginLeftMinus5 {
      margin-left:-5px !important
    }


.noCustomsDataBoxSize {
  max-height: fit-content !important;
  min-height: fit-content !important;
}

.noCustomsDataBoxSizeIos {
  max-height: 367px !important;
  min-height: 367px !important;
}

.x182348129d {
  max-height: 101px !important;
  min-height: 101px !important;
}

.x292929dd {
  max-height: 307px !important;
  min-height: 307px !important;
}

.x1839181s {
  max-height: 357px !important;
  min-height: 357px !important;
}

.x8198111333 {
  min-width: 80px !important;
  max-width: 80px  !important;
  background-position-x: 60px !important
}

.lineHeight18 {
  line-height:18px  !important
}

.pendReqDash {
  color: #143051;
  font-size: 12px;
  font-weight: 500;
  margin-right: 2px;}


  .reqsDataDaySpan {
    margin-right: 10px;
    color: #b9e8ff;
    border-right: 2px solid #143051;
    padding-right: 10px;
  }

  .regBgToil {
    background-color: #ba0000;
    color: white;
    border-radius: 4px;
    min-height: 30px;
    padding-left: 6px;
    padding-right: 6px;
  }



  .pendDateDiv {
    border-color:#97c7ff
  }


  .annLeaveSubYrly {
    opacity: 0.8;
    font-size:10px
  }

  .x13843782111 {
    margin-bottom:3px;
  }

.mobAllowIosBox {
  padding-bottom:20px !important
}

.whiteGoShiftLeaveArrImg {
  width: 8px;
  transform: rotate(180deg);
  margin-left: 5px;
  margin-bottom: -1px;
}

.whiteGoShiftLeaveArrImgActive {
  transform: rotate(90deg);

}
.shiftLeaveArrWrap {
  display:flex;
  align-items:center
}

.x239849848533 {
  cursor:pointer
}
.x239849848533b {
  cursor:pointer
}

.shiftLeaveArrDownList {
  background: #284974;
  max-width: 90%;
  margin-left: 5%;
  color: white;
  border-radius: 0 0 5px 5px;
  padding-bottom: 10px;
  border-top: 1px solid #516b8b;}

.shiftLeaveArrDownListPurp {
background: #8c68c8;
    max-width: 90%;
    margin-left: 5%;
    color: white;
    border-radius: 0 0 5px 5px;
    padding-bottom: 10px;
    border-top: 1px solid #815bb5;
    }

.x239849848533Active {
  border-radius: 5px 5px 0 0 !important;
  border-bottom: none !important;}

  .x239849848533bActive {
  border-radius: 5px 5px 0 0 !important;
  border-bottom: none !important;}

  .shiftLeaveArrItem {
    display: flex;
    border: 1px solid #143051;
    margin-top:10px;
    max-width: 90%;
    margin-left: 5%;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
    font-size: 12px;
    background: #0077ff;
    cursor:pointer;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;
    animation-name: zoomModalIn !important;
    animation-duration: 0.5s !important;
  }

  .shiftLeaveArrItemPurp {
    background-color: #d264ff;
    border-color: #a058be;  }

  .shiftLeaveArrItemLower {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 4px;
  }

  .shiftLeaveArrItemTitle {
    text-align: left;
    margin-top: 4px;
    opacity: 0.5;
  }

  .shLeaveRightDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .shLeaveInstances {
    font-size: 10px;
    color: white;
    font-weight: 500;
    margin-right: 13px;
    opacity: 0.7;
    
  }


  .mobMyReqsFilterbarMobCal {
    min-width: 90% !important;
    margin-left: 22px !important;
  }


  .x238998489484 {
    border: none;
    background-position-x: 42px;
    max-width: 59px;
    min-width: 59px;
    padding-left:0px;
    /* background-color: black !important; */
  }

  .leaveNewItemWidth {
    min-width: 91dvw !important;
    max-width: 91dvw ! important;
    margin-left: 4dvw ! important;
  }


  @media only screen and (min-width: 1000px) and (max-width: 1040px) {


  .absAddCornerBtn {
right:410px !important;
  }

}

@media only screen and (min-width: 1041px)  {


  .absAddCornerBtn {
right: calc(50dvw - 80px) !important;
  }

}

@media only screen and (max-width: 819px)  {


  .reqsTeamHoverBtn {
color:white !important
  }
  .reqsTeamHoverBtn:hover {
    color:white !important
      }

}


.absTimelineValReqs {
  margin-top: -12px;
  margin-left: 17px;
  margin-bottom: -12px;
  margin-right:8px;
  flex-direction: row !important;
}


.absCalDivLowerDateReqs {
  font-size: 23px !important;
  color: #143051 !important;
  margin-bottom: 0px;
  margin-top: 0px;
}

.absCalDivLowerMonthReqs {
  color:#00aaff  !important;
  font-weight: 600  !important;
  margin-bottom: 0px;
  margin-top: -6px;
}


.reqsLeaveNameAboveCalIcons {
  /* background-color: yellow; */
  margin-bottom:3px;
  font-size: 16px;
}

.x29898988444 {
margin-left:2px;
overflow: visible;
min-width: fit-content;
  padding-top: 0;
  margin-top: -18px;
  margin-bottom: 30px;}

  .mgrReqApprStatusImg {
    width: 14px;
    margin-top: 0px;
    margin-bottom: -3px;
    margin-left: 6px;
    /* opacity:0.3 */
  }

  .leaveNameBurger {
    width: 8px;
    margin-bottom: 1px;
    margin-left: 1px;
    margin-right: 7px;
    max-width: 10px !important;
    min-width: 10px !important;
   }

    .mgrReqApprStatusImgApproved {
      opacity: 1;
    margin-bottom: -1px;
    width: 13px;
    margin-left: 6px;
    }

    .mgrReqApprStatusImgDeclined {
      opacity: 1;
    margin-bottom: -1px;
    width: 11px;
    margin-left: 6px;
    }

    .x338994 {
      margin-left:2px
    }


    @media only screen and (min-width: 820px)  {
      .isLeaveTypeBorderLeft:hover {
        border-color: #d2d9e3;
        background-color:white;    }
      .x338994 {
        margin-left:-14px
      }
  

      .manageExcessOnReqBtn:hover {
        background-color: #bbcada;
      }
    }
  

    @media only screen and (min-width: 412px)  {
      .x338994 {
        margin-left:8px
      }
  

    }
  

    .borderRadius10x {
      border-radius: 0 7px 7px 7px;
    }

    .reqAppSpanColourInline {
      color:#82c26a
    }


    .x23984937842 {
      margin-right:9px !important;
    }
    

    .teamShiftsSubAppBar {
      margin-top: -21px;
      margin-right: 43px;
      margin-bottom: 30px;    }

      .teamShiftsSubAppBarRows5 {
        margin-top:-19px
      }

      .subBarLoggedTitle {
        color:#143051;
        font-weight: 400;
        margin-right:5px;
      }

      .subBarLoggedTitle2 {
        color: #143051;
    font-weight: 500;
      }
      .reqActpopup {
        width:14px !important;
      }


      .reqActBtn {
        background-color: #ef5353 !important;
        border: 1px solid #ba0000 !important;
      }



      .staffLeaveAllowsBtn {


        min-width: fit-content;
max-width: fit-content;
cursor: pointer;
display: flex;
font-size: 12px;
font-weight: 500;
border-radius: 5px;
border: #e3e8ee solid 2px;
padding-left: 7px;
padding-right: 6px;
cursor: pointer;
min-height: 30px;
max-height: 30px;
display: flex;
align-items: center;
color: #143051;
background-color:white;
      }

      .staffCalBtn520to820 {
     margin-top:18px !important
      }

      .teamShiftStringTxt {
        /* background-color: yellow; */
        min-width: 160px;
        overflow: visible;
        margin-right: -60px;
        padding-top:1px
      }


      .newLeaveReqInlineBtn {
        margin-left: 35px !important;
        margin-top: 18px;
        font-size: 14px !important;
        padding-left: 9px;
        padding-right: 6px;      }

        .x94959355 {
          background-color: yellow;
          min-width: 100px;
          min-height:30px;
        }


        .x349898455 {
          margin-left:4% !important
        }

.canManageUsrLeaveBtn {
  min-width: fit-content;
max-width: fit-content;
cursor: pointer;
display: flex;
font-size: 12px;
font-weight: 500;
border-radius: 5px;
border: #e3e8ee solid 2px;
padding-left: 7px;
padding-right: 6px;
cursor: pointer;
min-height: 30px;
max-height: 30px;
display: flex;
align-items: center;
color: #143051;
background-color:white;
margin-left: 14px;
margin-top: 8px;

}

.mgrReqEditNavyImg {
  width:10px;
  margin-left: 3px;
}