.leaveReqUpperContainer {
  max-width: 92vw;
  min-width: 92vw;
  display: block;
  /* position: absolute; */
  margin-left: auto;
  margin-right: auto;
  /* background-color: black;
  opacity: 0.3; */
}

.teamGradientOut {
  height: 35px;
  width: 31;
  z-index: 1;
  position: absolute;
  right: 13px;
  top: 75px;
}

h1 {
  font-family: 'Work Sans';
  color: #143051;
  font-weight: 500;
  font-size: 1.5em;
  margin-top: 45px;
  margin-left: 4px;
}

.txtMyRequests {
  display: flex;
}

.txtTeamRequests {
  display: none;
}

.submenu {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  font-family: 'Work Sans';
  color: #143051;
  padding-left: 4px;
  padding-right: 4px;
  /* background-color: black; */
  min-height: 44px;
}

.upcoming,
.past,
.declined {
  color: #516b8b;
  font-weight: 300;
}

.sel {
  color: #143051;
  font-weight: 400;
}

.team {
  color: #fff;
  min-height: 40px;
  font-weight: 400;
  background-color: #9a74eb;
  border-radius: 25px;
  padding: 8px 12px;
  margin-top: -5px;
}

.teamHidden {
  visibility: hidden;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #4b1caf;
  position: relative;
  right: -40px;
  margin-top: -20px;
}

/* .backRequests {
  min-width: 80px;
  min-height: 20px;
  display: inline-flex;
  position: relative;
  margin-top: 92px;
  margin-left: -5px;
} */

.teamsXbar {
  display: block;
  width: 100%;
  /* justify-content: space-around; */
  align-items: center;
  font-family: 'Work Sans';
  color: #143051;
  position: relative;
  top: 14px;
  padding: 0px 4px;
  /* background-color: black; */
  min-height: 44px;
  overflow: scroll;

  white-space: nowrap;
}

.teamsXbar::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent;
}

.teamsInnerContainer {
  max-width: auto;
  display: inline-flex;
  justify-content: space-between;
  margin-top: 0px;
  float: left;
  font-size: 0.9em;
}

.teamName {
  margin-left: 0px;
  min-width: auto;
  color: white;
  opacity: 0.4;
}

.teamSel {
  color: white;
  border-bottom: 1px solid #b091f5;
  padding-bottom: 3px;
  opacity: 1;
  font-weight: 400;
}

.teamMenuHolder {
  padding-right: 20px;
  padding-bottom: 3px;
}

teamMenuHolder:nth-last-child(1) {
  padding-right: -20px;
}

.backGroup {
  display: inline-flex;
  margin-top: 38px;
  padding-bottom: 12px;
  float: left;
}

.TeamRequests {
  color: rgb(255, 255, 255);
  font-family: 'Work Sans';
  font-size: 1.3em;
  font-weight: 400;
  margin-left: 9%;
  z-index: 0;
  float: left;
  opacity: 1;
  margin-top: 34px;
}

.txtTeamReqs {
  display: flex;
  min-width: 200px;
  color: #fff;
  font-weight: 400;
  float: right;
  opacity: 0.4;
  position: absolute;
  top: -32px;
  right: 0%;
}

.iconsM {
  position: absolute;
  top: -25px;
  right: 0%;
  width: 100px;
}

.checklist {
  margin-top: 58px;
  max-width: 28px;
  margin-left: -20px;
  opacity: 0.6;
}

.cog {
  max-width: 25px;
  position: relative;
  margin-left: 20px;
  opacity: 0.6;
  padding-bottom: 2px;
  cursor: pointer;
}

.inactive {
  opacity: 0.1;
  pointer-events: none;
}

@media only screen and (max-device-width: 373px) {
  .TeamRequests {
    font-size: 1.15em;
    padding-top: 1px;
    margin-left: 22px;
  }
}

.iconSelected {
  opacity: 1;
  z-index: 9999999009999999999;
}

.purpDivider {
  width: 100%;
  height: 3px;
  background: #143051;
  background: -webkit-linear-gradient(bottom, #8b7baa, #9a74eb);
  background: -moz-linear-gradient(bottom, #8b7baa, #9a74eb);
  background: linear-gradient(to top, #8b7baa, #9a74eb);
  position: absolute;
  top: 111px;
}

.returnBack {
  min-width: 17px;
  margin-left: 10px;
}

.loadBlank {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: absolute;
  top: -58px;
  z-index: 99999999999999;
}

.cornerRounded1 {
  max-width: 12px;
  position: absolute;
  top: 114px;
  left: 0;
}

.cornerRounded2 {
  max-width: 12px;
  position: absolute;
  top: 114px;
  right: 0;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
