.infoModalBehind {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
}

.infoModalBox {
  background-color: white;
  border-radius: 15px;
  min-height: 35px;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 90%;
  min-width: 90%;
}

.infoModalText {
  padding: 45px 20px 35px;
  color: #496375;
}

.infoModalClose {
  margin-top: 35px;
  color: #143051;
  font-weight: 500;
}

.none {
  display: none;
}

/* stats modal */
.statsModalBox {
  background-color: #214978;
  color: white;
  border-radius: 15px;
  min-height: 35px;
  position: absolute;
  top: 45%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 90%;
  min-width: 90%;
}

.statsModalText {
  padding: 25px 20px 25px;
  color: white;
}

.statsModalClose {
  margin-top: 25px;
  color: white;
  font-weight: 500;
  font-weight: 100;
}

.statsTitleTxt {
  font-weight: 500;
  padding-bottom: 14px;
}

.statsRow {
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  line-height: 45px;
  border-bottom: solid 1px #8ea7c5;
}

.statsRowBottom {
  border-bottom: solid 0px;
}

/* ERROR MODAL IN ADMIN AREA */
.none {
  display: none;
}

.errorModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
}

.errorModalBox {
  background-color: #214978;
  color: white;
  border-radius: 15px;
  min-height: 35px;
  position: absolute;
  top: 45%;
  left: 50%;
  padding-top: 40px;
  padding-bottom: 30px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 90%;
  min-width: 90%;
}

.errorCodeSpan {
  font-weight: 500;
  color: #00ccff;
}

.errorCloseBtn {
  background-color: white;
  color: #143051;
  line-height: 30px;
  height: 30px;
  border-radius: 15px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
