.footer-menu {
  height: 55px;
  background-color: #fff;
  /* background-color: #143051; */
  width: 100%;
  min-width: 100%;

  /* max-width: 200px !important; */
  /* background-color: yellow; */
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  /* padding-bottom: 4px; */
  z-index: 999999;
  /* border-radius: 10px 10px 0 0; */
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 16%);
  /* border-top: 1px rgb(185, 190, 213) solid; */
  padding-bottom: 1px;
}

.homeFooterMobile {
  background-color: #143051;
  /* background-color: yellow; */
  border-top: none;
  /* margin-bottom: 4px; */
}

.footer-menu-hidden {
  display: none;
}
.footerBtn {
  /* min-width: 28%; */
  /* max-width: 20%; */
  /* background-color: blue; */
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -4px;
  min-height: 52px;
}

.footerBtnToucher:active {
  /* transform: scale(0.9); */
}

/* .footerBtn {
  margin: auto;
  text-align: center;
  flex: 1;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: blue;
  max-width: 20%;
  min-width: 20%;
} */

.footerTouchHolder {
  background-color: rgb(209, 209, 212);
  min-height: 52px;
  max-height: 52px;
  min-width: 52px;
  max-width: 52px;
  border-radius: 100%;
  margin-top: -50px;
  margin-left: 8px;
  animation-name: zoomToucherIn !important;
  animation-duration: 0.5s !important;
  opacity: 0;
}

.accountToucher {
  margin-left: 3px;
}

.calToucher {
  margin-left: 6px;
}

.homeToucher {
  margin-left: 17px;
}

@keyframes zoomToucherIn {
  from {
    /* bottom: -10%; */
    opacity: 0.6;
    transform: scale(0.55);
  }
  to {
    /* bottom: 0; */
    opacity: 0.4;
    transform: scale(1);
  }
  to {
    opacity: 0;
  }
}
img.f2:active {
  /* width: 24px; */
  display: none;
  margin-top: 16px;
  transform: scale(0.92);

  /* z-index: -1; */
}
img.f3:active {
  /* width: 24px; */
  margin-top: 16px;
  transform: scale(0.92);
  /* z-index: -1; */
}

/* #footerBtn:active {
  max-width: 10px;
} */

.f1 {
  margin-top: 10px;
  margin-left: 12px;
  min-width: 22px;
  max-width: 22px;
  position: relative;
  z-index: -1;
}

.f2 {
  margin-top: 10px;
  min-width: 22px;
  max-width: 22px;
  position: relative;
  z-index: -1;
}

.f4Container {
  border-radius: 5px;
  max-width: 36px;
  min-width: 36px;
  min-height: 36px;
  max-height: 36px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  background-color: #07f;
  border: #0469de 2px solid;
  cursor: pointer;
}

.f4ContainerActive {
  background-color: #214978;
  border-color: #496375;
}

.f4 {
  /* margin-top: 10px; */
  min-width: 16px;
  max-width: 16px;
  z-index: 100;
  overflow: hidden;
  transition-duration: 0.05s;
  transition-property: transform;
  transform: rotate(-45deg);

  /* background-color: #0077ff;
  padding: 10px; */
}

.f4:active {
  transform: rotate(-45deg);

  /* min-width: 20px; */
  /* max-width: 20px; */
}

.rotate45degs {
  transform: rotate(0deg);
  overflow: hidden;
  transition-duration: 0.05s;
  transition-property: transform;
}

.rotate45degs:active {
  transform: rotate(-45deg);
}

.f3 {
  margin-top: 10px;
  min-width: 21px;
  max-width: 21px;
}

.cancelShowAddOptions {
  /* margin-top: -1px; */
  min-width: 40px;
  max-width: 40px;
}

.cancelShowAddOptions:active {
  /* margin-top: 1px; */
  min-width: 40px;
}

.f5 {
  margin-top: 10px;
  position: relative;
  z-index: -1;
  margin-right: 12px;
  max-width: 20px;
  min-width: 20px;
}

.addOptionsUnderlay {
  /* background: rgba(0, 0, 0, 0.82); */
  min-height: calc(100% - 58px);
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 75%);
}

.addOptionsUnderlayIos {
  /* bottom:100px !important; */
  bottom:20px;
  top: auto;
}
.showOptionsBtnsContainer {
  position: fixed;
  bottom: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.jamBtnsContainer {
  opacity: 0.15;
  z-index: -1;
  /* pointer-events: none; */
}

.jamNavbarTxt {
  color: white;
  max-width: 300px;
  border-radius: 20px;
  border: 2px solid white;
  padding: 20px;
  background-color: #143051;
}

.addLeaveOption {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: orange; */
  min-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  color: #214978;
  font-weight: 600;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #9bafc7;
  animation-name: zoomModalIn !important;
  animation-duration: 0.15s !important;
  animation-delay: 0ms;
  cursor: pointer;
}

.addLeaveOptionMgr {
  background-color: #214978;
  border-color: #143051;
  color: white;}
.addLeaveOptionSplitter {
  background-color: #dcebfa;
  min-height: 2px;
  min-width: 220px;
  margin-bottom: 20px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.15s !important;
}

.mobNavClockOnBtn {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: orange; */
  min-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  color: #214978;
  font-weight: 600;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #496375;
  background-color: #56a5ff;
  border: #3982d6 1px solid;
  color:#fff;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.15s !important; */
  animation-delay: 0ms;
  cursor: pointer;

}

.mobNavClockOnBtnPulsate {
  background-color: blanchedalmond;
  animation: f4pulsate 2s infinite, zoomModalIn 0.15s; /* Combine both animations */

}

.leaveBubbleSolo {
  color: #fff;
  margin-bottom: 0px;
  /* background-color: #00ccff;
  border: #0af 1px solid; */
  background-color: #ff93fc;
  border: #b77db5 1px solid;
  cursor: pointer;
}

.reqModalOnTheNavBar {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 99999999999;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
    display: flex;
  align-items: center;
  justify-content: center;
}

.mobBurger {
  /* background-color: #143051; */
  /* border-radius: 0 0 7px 0px;
  position: absolute;
  min-height: 30px;
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 8px;
  left: 12px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 5px;
  padding-top: 5px;
  z-index: 9999;
  min-width: 18px;
  cursor: pointer; */
}

.whiteCrossMobMenu {
  position: absolute;
  top: 8px;
  left: 10px;
  padding-left: 4px;
  padding-right: 8px;
  padding-bottom: 5px;
  padding-top: 3px;
  z-index: 9999;
  min-width: 28px;
}

.mobMenuDiv {
  min-height: 100vh;
  max-height: 100vh;
  background-color: #142437;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 80vw;
  max-width: 80vw;
  position: absolute;
  top: 0;
  left: 0;
  /* padding-top:15px; */
  animation-name: slideMenuFromLeft !important;
  animation-duration: 0.1s !important;
  /* border-right: 2px #3333 solid; */
  border-radius: 0px 20px 0 0;
  z-index: 9999;
}

.mobMenuUnderlay {
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.managerMenuList {
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 60px;
  min-height: 180px;
  /* padding-left: 20px; */
}

.myMenuList {
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 60px;
  min-height: 135px;
  /* padding-left: 20px; */
}

.manageMenuItem {
  min-height: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  font-size: 1.3em;
  font-weight: 500;
  padding-left: 30px;
  min-width: 100%;
  border-bottom: 1px solid #2e4657;
  color: white;
}

.manageMenuItem:last-child {
  border: none;
}

.indicate {
  position: fixed;
  top: 0px;
  background-color: #0af;
  padding-left: 18px;
  padding-right: 18px;
  min-height: 40px;
  min-width: calc(100vw - 10px);
  /* margin-right: 0.5vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  left: 5px;
  top: 5px;
  border-radius: 5px;

  /* right: 0%; */
  display: flex;
  justify-content: center;
  /* margin-right: 394px; */
  font-size: 1.25em;
  font-weight: 500;
  /* border-radius: 0 0 8px 8px; */
  padding-bottom: 2px;
  z-index: 999999999 !important;
  border: 1px solid #07f;
  /* goaty */
  /* margin-right: 48vw; */
  /* margin: 0 auto; */

  cursor: pointer;
}

.animateIndicate {
  animation-name: heightDown !important;
  animation-duration: 0.3s !important;
}

.desktopIndicate {
  left: 0px;
  top: 0px;
  min-width: 100vw;
  border-radius: 0px;
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;


  left: 0px;
  top: 0px;
  min-width: 100vw;
  border-radius: 0px;
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  background: white;
  color: #143051;
  font-size: 14px;
  font-weight: 500;
  color: #516b8b;
  border: 1px solid #bbcada;
  border-radius: 5px;
  max-width: 630px;
  min-width: 630px;
  margin-left: calc(50% - 315px);
  margin-top: 10px;
}

.instructIndicate {
  background-color: #fa903d;
  border: 1px solid #ca7837;
}

.approveIndicate {
  background-color: #6dba4f;
  border: 1px solid #5a9e3f;
}

.declineIndicate {
  background-color: #e26666;
  border: 1px solid #ac4848;
}

.blueIndDesktopBlob {
  min-width: 12px;
  min-height:12px;
  max-width: 12px;
  max-height:12px;
  border-radius: 40px;
  margin-right:10px;
  background-color: #00aaff;

}

.greenIndDesktopBlob {
  background-color: #6dba4f;
}
.redIndDesktopBlob {
  background-color: #e26666;
}
.orangeIndDesktopBlob {
  background-color: #fa903d;
}


.indicateTxt {
  display: flex;
  align-items: center;
}
.mobIndicateTxt {
  font-size: 18px;

}

.desktopHeaderHolder {
  min-height: calc(100vh - 30px);
  max-height: calc(100vh - 30px);
  position: fixed;
  top: 0;
  /* left: 0; */
  min-width: 240px;
  max-width: 240px;
  background-color: white;
  z-index: 9;
  /* border-left: 1px solid #e6eaee; */
}

.desktopBottomBar {
  min-height: 30px;
  background-color: white;
  border-top: 1px solid #e6eaee;
  max-height: 50px;
  min-width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content:flex-start;
  padding-left: 18px;
  align-items: center;
  z-index:9999
}

.device {
  color: #c2cce9;
  /* background-color: orange; */
  font-size: 12px;
}

.rotaCalPageBottomBarHeight {
  min-height: 10px;
  max-height: 10px;
}

.pageLeft240pxDesktop {
  margin-left: 240px;
  max-width: calc(100vw - 240px) !important;
  min-width: calc(100vw - 240px) !important;
}

.borderLeftGrey {
  border-left: 2px solid #e6eaee !important;
}
.desktopHeaderTopBarLogoAndClockOnDiv {
  min-height: 66px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  flex-direction: row-reverse;
  justify-content: flex-end;
  cursor: pointer;
}
.headerLogoTimelify {
  max-width: 20px;
  margin-top: 17px;
  margin-bottom: 10px;
  margin-left: 7px;
}
.headerLogoTimelifyTxt {
  max-width: 83px;
  margin-top: 13px;
  margin-bottom: 8px;
  margin-left: 5px;
}

.headerLogoTimelifyRota {
  margin-left: 7px;
}

.headerLogoTimelifyRotaTxt {
  margin-left: -16px;
  max-width: 70px;
}
.desktopHeaderMenuDiv {
  margin-top: 95px;
  overflow-y: scroll;
  /* overflow-x: none; */
  max-height: calc(100vh - 203px);
  padding-top: 20px;
  /* background-color: pink; */
}

.headerMenuIfClockOnNotAllowed {
  max-height: calc(100vh - 209px);
  /* background: blue; */
  border-top: 1px solid #f1f5fa;
  margin-top: 37px;
}
.desktopHeaderMenuBtn {
  /* background-color  : yellow; */
  min-width: 140px;
  /* min-width: 100%; */
  display: flex;
  justify-content: flex-start;
  min-height: 34px;
  max-height: 34px;
  /* border-bottom: 1px solid #f3f6f8; */
  align-items: center;
  font-size: 14px;
  color: #143051;
  font-weight: 500;
  padding-left: 10px;
  cursor: pointer;
}

.desktopHeaderMenuBtnMgr {
  /* min-width: 100%; */
  /* border-bottom: 1px solid #f3f6f8; */
  display: flex;
  justify-content: flex-start;
  min-height: 30px;
  max-height: 30px;
  align-items: center;
  font-size: 16px;
  color: #496375;
  font-weight: 300;
  padding-left: 55px;
  cursor: pointer;
}

.desktopHeaderMenuBtn:hover {
  /* color: #0077ff; */
  /* font-weight: 400; */
  min-height: 34px;
  max-height: 34px;
  /* margin-left: -1px; */
}

.desktopHeaderMenuBtnMgr:hover {
  color: #a387df;
  font-weight: 400;
  min-height: 40px;
  max-height: 40px;
  margin-left: -1px;
}

.desktopHeaderBtnSelected {
  /* min-width: 75%;
  max-width: 75%; */
  /* margin-left: 15%; */
  display: flex;
  justify-content: flex-start;
  min-height: 40px;
  align-items: center;
  font-size: 16px;
  color: #143051;
  padding-left: 20px;
  cursor: pointer;
  font-weight: 500;
  background-color: #0077ff;
  color: white;
  border-top: 1px solid #1270db;
  border-bottom: 1px solid #1270db;
  cursor: default;
  border-radius: 5px;
  margin-top: 10px;
}

.desktopHeaderBtnSelectedWithSub {
  border-radius: 5px 5px 0 5px !important;
}

.desktopHeaderBtnSelectedMgr {
  min-width: 75%;
  max-width: 75%;
  margin-left: 15%;
  display: flex;
  justify-content: flex-start;
  min-height: 40px;
  align-items: center;
  font-size: 16px;
  color: #143051;
  padding-left: 20px;
  cursor: pointer;
  font-weight: 500;
  font-weight: 500;
  background-color: #4b1caf;
  color: white;
  border-top: 1px solid #3e1c88;
  border-bottom: 1px solid #3e1c88;
  cursor: default;
  border-radius: 5px;
}

.desktopHeaderPanel {
  display: flex;
  min-width: 168px;
  max-width: 168px;
  /* margin: 20px auto; */
  margin-top: 0px;
  margin-left: 40px;
  justify-content: space-between;
  margin-bottom: -26px;
}

.desktopNotifbtn {
  font-weight: 500;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  max-height: 38px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 38px;
  max-width: 38px;
  cursor: pointer;
}

.desktopAddbtn {
  font-weight: 500;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  max-height: 38px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  max-width: 70px;
  cursor: pointer;
  color: #2d5a8f;
}

.addBtnShield {
  position: absolute;
  top: 66px;
  left: 138px;
  font-weight: 500;
  border: 1px solid #cbd7e6;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;

  border-bottom: 1px solid #f4f8fe;
  max-height: 39px;
  min-height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  max-width: 70px;
  cursor: pointer;
  color: #2d5a8f;
  z-index: 999999;
  padding-bottom: 1px;
}

@media only screen and (min-width: 1140px) {
  .addBtnShield {
    left: calc(50vw - 432px);
  }

  .addDesktopBox {
    left: calc(50vw - 432px) !important;
  }
}

.teamRotaAddBtnShield {
  /* background-color: orange; */
  top: 66px;
  left: 113px;
}

.desktopAddbtnSelected {
  background-color: #fff;
  border: #143051 1px #cbd7e6;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #fff;
  z-index: 999999;
  color: #214978;
}

.desktopAddbtn:hover {
  background-color: #f4f8fe;
}

.desktopNotifbtn:hover {
  background-color: #f4f8fe;
}

.desktopAddbtnSelected:hover {
  background-color: #214978;
  border: #143051 1px solid;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #214978;
  z-index: 999999;
  color: white;
}

.desktopHeaderNotifImg {
  max-width: 20px;
}

.desktopHeaderCalImg {
  max-width: 18px;
}

.desktopHeaderAddImg {
  max-width: 12px;
  margin-top: 1px;
  margin-right: 1px;
}

.desktopNotifBtnBg {
  background-color: #214978;
  border: #143051 1px solid;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #214978;
  z-index: 999999;
}

.desktopNotifBtnBg:hover {
  background-color: #214978;
  border: #143051 1px solid;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #214978;
  z-index: 999999;
}

.desktopPlusTxt {
  font-size: 14px;
  margin-left: 3px;
}

.desktopNotifBox {
  /* background-color: #1e3249; */
  background-color: #fff;

  border: #e6eaee 1px solid;
  border-radius: 8px;
  position: fixed;
  top: 100px;
  left: calc(50vw - 560px);
  z-index: 99999;
  min-width: 375px;
  max-width: 375px;
  min-height: fit-content;
  max-height:60vh;
  animation-name: slideDesktopMenuDown2 !important;
  animation-duration: 0.1s !important;
  cursor: default;
}

.desktopUpcomingBox {
  background-color: #1e3249;
  border: #143051 1px solid;
  border-radius: 5px;
  position: fixed;
  top: 100px;
  left: calc(50vw - 530px);
  z-index: 99999;
  min-width: 375px;
  max-width: 375px;
  max-height: 70vh;
  cursor: default;
  animation-name: slideDesktopMenuDown2 !important;
  animation-duration: 0.25s !important;
  cursor: default;
  /* overflow-y: auto; */
}

@media only screen and (max-width: 1159px) {
  .desktopNotifBox {
    left: 10px;
  }

  .desktopUpcomingBox {
    left: 62px;
  }
}

.rotaNotifsBoxPosition {
  left: 10px;
}

.rotaUpcomingBox {
  left: 60px;
}

.desktopNotifUnderlay {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 999999;
  /* background-color: transparent; or use opacity: 0; */
  /* pointer-events: none; */
  background-color: rgb(0, 0, 0, 0.3) !important;
  /* cursor: pointer; */
  /* -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgb(119 119 119 / 50%); */
}

.shadowUnderlay {
  background-color: rgb(119 119 119 / 30%) !important;
}

.notifGhost {
  min-height: 40px;
  min-width: 40px;
  position: fixed;
  top: 130px;
  margin-left: 42px;
  z-index: 111;
  cursor: pointer;
}

.upcomingGhost {
  min-height: 40px;
  min-width: 40px;
  position: fixed;
  top: 124px;
  margin-left: 0px;
  z-index: 111;
  cursor: pointer;
}

.addGhost {
  cursor: pointer;
  min-height: 40px;
  min-width: 70px;
  position: fixed;
  top: 124px;
  margin-left: 96px;
  z-index: 111;
  cursor: pointer;
}

.upcomingGhost2 {
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
  position: fixed;
  top: 124px;
  left: 88px;
  z-index: 99999;
  cursor: pointer;
}

.notifGhost2 {
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
  position: fixed;
  top: 124px;
  left: 40px;
  z-index: 99999;
  cursor: pointer;
}

.addDesktopBox {
  background-color: #f4f8fe;
  border: #cbd7e6 1px solid;
  border-radius: 0 5px 5px 5px;
  position: fixed;
  top: 104px;
  left: 138px;
  z-index: 99999;
  min-width: 135px;
  max-width: 135px;
  /* min-height: 170px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 12px;
  cursor: default;
  padding-bottom: 5px;
  animation-name: slideDesktopMenuDown6 !important;
  animation-duration:0.15s !important;
  height:352px;
  /* max-height: 211px; */
}


.addDesktopBoxMgr {
  max-height: auto;

}

.addDesktopBoxUser {
  height:149px;
  animation-name: slideDesktopMenuDown7 !important;
  animation-duration:0.15s !important;

}
@keyframes slideDesktopMenuDown6 {
  from {  

    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height:352px;
    max-height:352px;
  
  }
}

@keyframes slideDesktopMenuDown7 {
  from {  

    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height:149px;
    max-height:149px;
  
  }
}



.desktopAddBtnsDivider {
  min-height: 2px;
  min-width: 100px;
  background-color: #cbd7e6;
  margin-top: 10px;
  margin-bottom: 2px;
}

.desktopAddBtnsDividerTxt {
  font-weight: 500;
  color: #bbcada;
  font-size: 11px;
  margin-top: 6px;
  margin-bottom: -2px;
  padding-left: 7px;
}

.teamRotaAddDesktopBox {
  top: 102px !important;
  left: 113px !important;
}

.desktopLeaveReqBtn {
  color: #fff;
  margin-bottom: 0px;
  background-color: #07f;
  border: #0469de 1px solid;
  cursor: pointer;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  min-width: 92%;
  margin-top: 12px;
  font-size:14px;
}

.desktopLeaveReqBtn:hover {
  background-color: #036ce4;
}

.desktopAddBtns {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  padding-left: 9px;
  padding-right: 9px;
  color: #214978;
  margin-top: 8px;
  font-weight: 600;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #eceff2;
  animation-name: zoomModalIn !important;
  animation-duration: 0.15s !important;
  animation-delay: 0ms;
  cursor: pointer;
  font-size:14px;
  text-align: left;
}

.desktopAddBtns:hover {
  background-color: #f3f6f8;
}

.desktopAddBtns:last-child {
  margin-bottom: 8px;
}

.desktopClockOnDiv {
  min-height: 58px;
  max-height: 58px;
  background-color: #ebf0f7;
  margin-top: 37px;
  border-top: 2px solid #e6eaee;
  border-bottom: 2px solid #e6eaee;
  border-right: 1px solid #ebf0f7;
  min-width: 242px;
  margin-bottom: -30px;
  display: flex;
  justify-content: flex-start;
  padding-right: 10px;
  align-items: center;
  position: fixed;
  top: 10;
  /* left: 0; */
  z-index: 999;
}

.desktopHeaderClockOnBtn {
  font-weight: 600;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  max-height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  cursor: pointer;
  color: #143051;
  margin-left: 7px;
  padding-left: 10px;
  padding-right: 3px;
  background-color: white;
  font-size: 14px;
  margin-right: 5px;
}

.desktopHeaderClockOnBtn:hover {
  background-color: #f4f8fe;
}

.clockOnModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.swapModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}
.clockOnModalBox {
  background-color: #f4f8fe;
  border-radius: 10px;
  min-width: 380px;
  max-width: 380px;

  /* min-height: 400px; */
  cursor: default;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
}

@media only screen and (max-width: 375px) {
  .clockOnModalBox {
    min-width: 350px;
    max-width: 350px;
  }

  .clockOnDataTitle {
    display: flex;
    font-size: 14px !important;
  }
}

.swapModalHeader {
  background-color: #f4f8fe;
  border-radius: 10px;
  min-width: 380px;
  /* min-height: 400px; */
  cursor: default;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.clockOnModalHeader {
  min-height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 0px;
  /* background: yellow; */
  margin-bottom: -12px !important;
}


.xx283819812 {
  min-height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 0px;
  /* background: yellow; */
  margin-bottom: 1px !important;
  margin-top: -5px !important;

}

  .x2398911d {
    min-height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 14px;
    padding-right: 14px;
    margin-top: 0px;
    padding-top:3px;
    /* background: yellow; */
    margin-bottom: 1px !important;
    margin-top: -10px !important;
  }

.clockOnModalHeaderSideUnit {
  min-width: 30px;
  display: flex;
  justify-content: space-between;
}

.clockTicker {
  min-width: 68px;
  font-family: "Courier New", Courier, monospace;
  /* background-color: green; */
  margin-left: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  /* padding: 8px; */
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  /* padding-top: 5px; */
  background-color: #916fdb;
  border: 1px solid #7656bc;
  font-size: 18px;
  line-height: 18px;
  /* margin-top: -6px; */
}

.clockTickerInvis {
  visibility: hidden;
  margin-left: -35px;
}

.clockedOnClockAndTeamDiv {
  display: flex;
  justify-content: flex-start;
  /* background-color: yellow; */
  /* max-width: 100%; */
  /* max-height: 60px; */
  margin-top: -10px;
}

.clockOnModalHeaderTitle {
  font-weight: 500;
  font-size: 18px;
  color: #140351;
  min-width: fit-content;
  /* padding: left 10px; */
  /* padding-right:10px; */
}

.closeClockOnModalImg {
  cursor: pointer;
  margin-left: 8px;
}
.closeClockOnModalImg:hover {
  opacity: 0.8;
}

.liveClock {
  display: flex;
  font-size: 30px;
  font-weight: 500;
  min-width: 104px;
  max-width: 104px;
  /* margin: 20px auto; */
  justify-content: center;
  align-items: center;
  color: #0af;
  background-color: #dde9f9;
  /* border: 2px solid rgb(206, 211, 218); */
  border: 2px solid #cbd7e6;
  border-radius: 5px;
  padding-bottom: 2px;
  position: relative;
  z-index: 999;
  margin-left: 40px;
  margin-top: 13px;
  margin-bottom: 16px;
  padding-right: 14px;
}

.liveClockHours,
.liveClockMins,
.liveClockColon {
  /* font-family: "Courier New", Courier, monospace; */
}

.clockOffClockColours {
  background-color: #fff;
  border: 2px solid #d3d9e0;
  color: #496375;
  font-size: 50px;
  min-width: 160px;
  max-width: 160px;
  margin: 20px auto;

  /* border-radius: 5px; */
}

.hideColon {
  opacity: 0.25;
  /* visibility: hidden; */
}

.scheduledClockOnContainer {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  align-items: center;
  border-bottom: 1px solid #e0ebf8;
  min-width: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  margin-top: -16px;
  margin-bottom: 12px;
}

.x2345676543 {
  margin-top: -30px !important;
}

.x76543456 {
  /* margin-top:-40px !important */
}

.schedClockOnLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #e0ebf8;
  padding-right: 8px;
  min-width: 37%;
}

.schedClockOnRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-left: 1px solid #e0ebf8;
  padding-left: 12px;
  min-width: 37%;
}

.schedClockBreakMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 26%;
}

.schedClockBreakMiddleTitle {
  font-size: 12px;
  color: #214978;
  margin-bottom: 4px;
  /* padding-left: 3px; */
  max-width: 70px;
  font-weight: 500;
  text-align: center;
}
.schedClockBreakMiddleValue {
  font-weight: 500;
  font-size: 12px;
  min-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  min-height: 24px;
  align-items: center;
  border-radius: 5px;
  /* color: #496375; */
  font-weight: 500;
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  cursor:pointer
}

.schedClockOnLeftTitle {
  font-size: 12px;
  color: #214978;
  margin-bottom: 4px;
  padding-left: 4px;
  padding-right: 8px;
  text-align: left;
  font-weight: 500;
  max-width: 69px;
}

.schedClockOnLeftTitleClockedOn {
  font-size: 10px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #214978;
  margin-bottom: 2px;
  font-weight: 400;
  text-align: left;
  /* background-color: #7fbb67; */
  /* border: 1px solid #5a9e3f; */
  border-radius: 5px;
  padding-left: 5px;
  /* padding-right: 7px; */
  min-height: 20px;
  display: flex;
  align-items: flex-end;
}

.schedClockOnRightTitle {
  font-size: 12px;
  color: #214978;
  margin-bottom: 4px;
  padding-right: 7px;
  text-align: right;
  max-width: 70px;
  font-weight: 500;
  max-width: 69px;

}

.schedClockOnLeftValue {
  font-weight: 500;
  font-size: 13px;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  cursor:pointer
}

.schedClockOnRightValue {
  font-weight: 500;
  font-size: 13px;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  cursor:pointer
}


.schedClockSmallTxtL {
  min-height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: left;
  max-width: 80px;
  font-size: 12px;
}

.schedClockSmallTxtR {
  min-height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: right;
  max-width: 80px;
  font-size: 12px;

  /* font-size: 12px;
  padding-left: 2px; */
}

.clockOnContentBox {
  background-color: #fff;
  max-width: 90%;
  margin: 0 auto;
  /* min-height: 300px; */
  margin-top: -50px;
  z-index: -1;
  padding-top: 40px;
  border-radius: 5px 5px 0 0;
  border-top: solid 2px #e6eaee;
  border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  border-bottom: 1px solid #bac5d3;
  min-height: 200px;
  margin-bottom: 40px;
}

.loadingClockBox {
  /* min-height: 80px; */
}

.clockOnActionsDiv {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding-right: 5%;
  padding-left: 5%;
  margin-top: -40px;
}

.actionsClockOnBtn {
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 110px;
  font-size: 1.1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
}

.actionsClockOnBtn:hover {
  background-color: #0af;
}

.closeClockOnDiv {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.closeClockOnDiv:hover {
  background-color: #214978;
}

.undoClockBtn {
  font-size: 14px;
  color: #fff;
  background-color: #cbd7e6;
  border: solid #bbcada 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.undoClockBtn:hover {
  background-color: #bbcada;
}

.clockOnBtnContainer {
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.clockOffBtnContainer {
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 0px;
  margin-top: -3px;
  display: flex;
  justify-content: space-between;
}

.clockedActive {
  background-color: #fff;
  color: #07f;
  padding-left: 5px;
  /* padding-right: 25px; */
  margin-top: 0px;
  /* max-width: 50px; */
  text-align: left;
  font-size: 15px;
  line-height: 14px;
  margin-top: -4px;
  font-weight: 500;
}

.hideClockedOff {
  visibility: hidden;
}

.startBreakBtn {
  min-width: 48%;
  background-color: rgb(58, 101, 150);
  border: 1px solid rgb(45, 84, 107);
}

.startBreakBtn:hover {
  background-color: #07b;
  border: 1px solid #07b;
}

.clockOffBtnSmall {
  min-width: 48%;
}

.disableBrkBtn {
  pointer-events: none;
  visibility: hidden;
}

.clockBreakMap {
  display: flex;
  justify-content: space-around;
  /* background-color: yellow; */
  min-width: 100%;
  margin-top: 10px;
}

.breakClockUnit {
  min-width: 93px;
  max-width: 93px;
  min-height: 120px;
  padding-left: 8px;
  padding-bottom: 4px;
  padding-top: 4px;
  /* padding-right: 3px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* max-height: 24px; */
  align-items: flex-start;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  /* background-color: #e3e8ee; */
  color: #143051;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  border-right: 1px solid #e0ebf8;
}

.breakClockUnitInactive {
  min-width: 88px;
  max-width: 88px;
  visibility: hidden;
}

.smallerBreak1 {
  margin-bottom: 3px;
  max-height: 60px;
  min-height: 60px;
  margin-top: 5px;
}
.breakClockUnit:last-child {
  border: none;
}

.breakClockNum {
  color: #496375;
  margin-bottom: -8px;
  /* margin-top:4px; */
  /* padding: 2px 5px;
  font-size: 12px;
  border-left: 2px solid #bbcada; */

  font-size: 10px !important;
  color: #214978;
  font-weight: 400;
}

.breakClockStartTitle {
  font-size: 10px;
  margin-top: 8px;
  color: #859dbb;
  /* border-top: 1px solid #e0ebf8; */
  min-width: 60px;
  text-align: left;
  padding-top: 5px;
  font-weight: 500;
  /* text-transform: uppercase; */
}

.breakClockStartTitleDuration {
  margin-top: -5px;
  visibility: hidden;
}

.colorLightBlue {
  color: #00ccff !important;
}
.breackClockStartSpan {
  color: #516b8b;
  font-weight: 500;
  font-size: 15px;
  margin-top: 2px;
  /* border-bottom: 1px solid #140351; */
  min-width: 100%;
  text-align: left;
  /* padding-bottom: 5px; */
}

.breakEndUnit {
  text-align: left;
  margin-bottom:10px;
}

.clockTeamDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 17px;
  text-align: left !important;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 20px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 95%;
}

.clockTeamDropdown:hover {
  border-color: #bac5d3;
}
.clockedOnDataBox {
  min-width: 185px;
  max-width: 185px;
  /* max-height: 52px;
  min-height: 52px; */
  /* background-color: #d7e9fe; */
  border-radius: 5px;
  margin-left: 138px;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-bottom: -121px;
  padding-left: 17px;
  /* justify-content: space-between; */
}

.clockedOnDataBoxMob {
  padding-left: 10px;
}

@media only screen and (min-width: 457px) and (max-width: 499px) {
  .clockedOnDataBox {
    min-width: calc(100vw - 227px);
    max-width: calc(100vw - 227px);
    margin-left: 152px;
  }

  .clockOnDataTitle {
    display: flex;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 386px) and (max-width: 456px) {
  .clockedOnDataBox {
    min-width: calc(100vw - 214px);
    max-width: calc(100vw - 214px);
    margin-left: 146px;
  }

  .clockOnDataTitle {
    display: flex;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 355px) {
  .clockedOnDataBox {
    min-width: calc(100vw - 206px);
    max-width: calc(100vw - 206px);
    margin-left: 148px;
  }

  .clockOnDataTitle {
    display: flex;
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 356px) and (max-width: 365px) {
  .clockedOnDataBox {
    min-width: calc(100vw - 193px);
    max-width: calc(100vw - 193px);
    margin-left: 142px;
  }
}

@media only screen and (min-width: 366px) and (max-width: 385px) {
  .clockedOnDataBox {
    min-width: calc(100vw - 200px);
    max-width: calc(100vw - 200px);
    margin-left: 138px;
  }
}

.clockTeamName {
  border-bottom: 1px solid #e0ebf8;
  max-height: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  color: #143051;
}

.clockTeamName:last-child {
  border: none;
}
.clockOnDataValue {
  font-weight: 500;
  color: #516b8b;
  font-size: 15px;
}
.desktopHeaderSubDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding-left: 0px;
  background-color: #fafcff;
  max-width: 157px;
  margin-left: 50px;
  padding-bottom: 6px;
  padding-top:6px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
  animation-name: slideDesktopSubMenuDownMyShifts;
  /* fits three items */
  animation-duration: 0.2s;
  min-height: auto;
  min-width: auto;
}

.desktopHeaderDivTwoItems {
  animation-name: slideDesktopSubMenuDownMyAbsence;
  animation-duration: 0.2s;
}

.desktopHeaderDivFourItems {
  animation-name: slideDesktopSubMenuDownFourItems;
  animation-duration: 0.2s;
  /* display: none; */
  /* margin-left: -2px; */
}

.desktopHeaderDivFiveItems {
  animation-name: slideDesktopSubMenuDownFourItems;
  animation-duration: 0.2s;
  /* display: none; */
  /* margin-left: -2px; */
}
.desktopHeaderSubBtn {
  font-size: 12px;
  font-weight: 500;
  color: #496375 !important;
  cursor: pointer;
  text-align: left;
  min-width: 100%;
  padding-left: 25px;
  /* background: yellow; */
  min-height: 25px;
  padding-top: 6px;
}
.desktopHeaderSubBtn:hover {
  /* color: #0077cc !important; */
  background-color: #ebf0f7;
  /* padding-top: 8px;
  margin-top: 2px;
  margin-bottom: -7px;
  min-height: 30px;
  min-width: 100%; */
}

.mgrSubBtnHover:hover {
  color: #9a74eb !important; 
  background-color: #ebf0f7;
}

.desktopHeaderSubBtnSelectedMob {
  font-weight: 600;
  color: #4b1caf !important;
  cursor: none;
  pointer-events: none;
  /* border-left: 2px solid #4b1caf; */
  padding-left: 8px;
  margin-left: 17px;
}

.pushDataBoxDown {
  margin-top: 0px;
  margin-left: 140px;
  margin-bottom: -105px;
}

@media only screen and (min-width: 500px) {
  .pushDataBoxDown {
    margin-left: 128px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 499px) {
  .pushDataBoxDown {
    min-width: calc(100vw - 238px) !important;
    max-width: calc(100vw - 238px) !important;
    margin-left: 164px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 479px) {
  .pushDataBoxDown {
    min-width: calc(100vw - 228px) !important;
    max-width: calc(100vw - 228px) !important;
    margin-left: 158px;
  }
}

@media only screen and (min-width: 429px) and (max-width: 449px) {
  .pushDataBoxDown {
    min-width: calc(100vw - 228px) !important;
    max-width: calc(100vw - 228px) !important;
    margin-left: 158px;
  }
}

@media only screen and (min-width: 396px) and (max-width: 428px) {
  .pushDataBoxDown {
    min-width: calc(100vw - 208px) !important;
    max-width: calc(100vw - 208px) !important;
    margin-left: 138px;
  }
}

@media only screen and (min-width: 386px) and (max-width: 395px) {
  .pushDataBoxDown {
    min-width: calc(100vw - 208px) !important;
    max-width: calc(100vw - 208px) !important;
    margin-left: 138px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 385px) {
  .clockUnpaidBreakSpan {
    display: none !important;
  }
}

.pullClockOnUp {
  margin-top: -30px;
  /* min-height: 60px; */
}

.assocItemDataRow {
  border-left: 1px solid #e0ebf8;
  padding-left: 7px;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 74px;
  margin-top: 0px;
  padding-bottom: 2px;
  min-width: 33%;
  max-width: 33%;
}

.assocItemDataRow:first-child {
  border: none;
  padding-left: 0px;
  /* margin-top: 8px; */
}
.assocItemTitle {
  font-size: 12px;
  text-align: left;
  min-width: 100%;
  color: #214978;
  font-weight: 400;
  /* opacity: 0.6; */
  /* text-transform: uppercase; */
  margin-top: -6px;
}

.assocSchedRow {
  display: flex;
  text-align: left;
  min-width: 100%;
  /* margin-top: -20px; */
}

.assocDateRow {
  min-width: 33%;
  max-width: 33%;}

.assocTimeRow {
  min-width: 33%;
  max-width: 33%;
}

.assocItemValue {
  margin-top: -24px;
  color: #143051;
font-weight: 600;
  margin-top: -12px;
  color: #143051;
  line-height: 15px;
  font-size: 14px;
  margin-bottom: 8px;
}

.clockedOnOffTitle {
  /* color: #0077ff !important; */
  font-size: 12px !important;
  /* background-color: yellow; */
  /* min-height: 32px; */
  padding-right: 20px;
  /* line-height: 15px; */
  /* margin-top: 8px; */
  /* margin-bottom: 12px; */
  opacity: 1;
}

.clockedOnRowTitle {
  background-color: #f4f8fe;
  min-width: 100%;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  color: #859cb6;
  padding-left: 15px;
  margin-bottom: -10px;
}

.clockedDataRowBg {
  background-color: #f4f8fe;
  min-width: 100%;
  border-top: 2px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
  border-radius: 5px 5px 0 0;
  padding-right: 0px !important;
  /* min-height: 150px !important; */
}

.clocked1 {
  border-radius: 5px;
  border-bottom: 2px solid #e6eaee !important;
}
.clock3 {
  border-radius: 0 0 0px 0px;
  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
  background-color: #f9fafc;
}
.clock2 {
  border-radius: 0 0 5px 5px;
  border-bottom: 2px solid #e6eaee !important;
}

.brkClockedSpan {
  /* color: black; */
  opacity: 0.5;
  margin-left: 2px;
  display: none;
}

.earlyLateClockTxt {
  font-size: 14px;
  max-height: 18px;
  /* margin-top: -20px; */
}

.clockedScoreOnOff {
  min-width: 33%;
  max-width: 33%;
  padding-left: 7px;
  font-weight: 500;
  margin-top: -8px;
  font-size: 12px;
  border-left: 1px solid #e0ebf8;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4px;
  padding-right: 6px;
  /* max-width: 20px; */
}

.clockedScoreOnOff:first-child {
  padding-left: 0px;
  border: none;
}

.offScore90 {
min-width: 33%;
max-width: 33%;}

.clockedScoreBrk {
  min-width: 30%;
  max-width: 30%;
  padding-left: 7px;
  font-weight: 500;
  margin-top: -8px;
  font-size: 12px;
  border-left: 1px solid #e0ebf8;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4px;
}

.clockedScoreRow {
  max-height: 46px !important;
  min-height: 46px !important;
  background-color: #f4f8fe;
  color: #385f8c;
  font-weight: 400 !important;
  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
  padding-right: 0px !important;
}
.colourRed {
  /* color: #e89292 !important; */
  color: #ba0000 !important;
}

.colourGreen {
  color: #5a9e3f;
}

.clockPayRow {
  display: flex;
  justify-content: space-between !important;
  min-width: 100% !important;
  padding-bottom: 10px;
}

.myShiftRowLRighTitle {
  color: #858e9c;
  font-weight: 500;
}

.clockPayRowR {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: fit-content;
}

.clockPayRowVal {
  color: #143051;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-top: 13px;
}

.deleteAndShiftHolder {
  display: flex;
  max-height: 50px;
  align-items: center;
  padding-top: 1px;
}

.viewAssocShiftBtn {
  font-weight: 500;
  font-size: 14px;
  /* font-weight: 500; */
  /* color: #592eb6; */
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  min-height: 35px;
  max-height: 35px;
  /* line-height: 26px; */
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  /* padding-top: 2px; */
  justify-content: center;
  cursor: pointer;
  color: white;
  /* margin-left: -3px; */
  /* min-width: 70px;
  max-width: 70px; */
}

.font16px {
  /* font-size: 16px !important; */
}

.viewAssocShiftBtn:hover {
  background-color: #6987a9;
}

.invalidClockShiftModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.invalidClockShiftModalBody {
  background-color: white;
  min-width: 320px;
  max-width: 320px;
  /* min-height: 90vh;
max-height: 90vh; */
  /* height: 80vh; */
  border-radius:10px;
  /* margin-top: -40px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  color: #143051;
  margin-top: -100px;
  text-align: center;
  padding: 15px;
  padding-top:20px;
  /* position: fixed;
bottom: 0;
text-align: center; */
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
  cursor: default;
}

.closeInvalidShiftModalBtn {
  font-size: 16px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 35px;
  /* margin-bottom: -10px; */
}

.closeInvalidShiftModalBtn:hover {
  background-color: #214978;
}

.overShortSchedClockedSpan {
  font-size: 12px;
  margin-left: 0px;
  font-weight: 400;
  color: #858e9c;
  /* margin-right: -10px; */
}

.fadeViewAssoc {
  opacity: 0.3;
}

.unlinkedTab {
  cursor: pointer;
}

.unlinkedTab:hover {
  background-color: #5b728d;
}

.blankModalContentBox {
  background-color: #fff;
  max-width: 90%;
  margin: 0 auto;
  margin-top: -50px;
  z-index: -1;
  border-radius: 5px 5px 0 0;
  border-top: solid 2px #e6eaee;
  border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  border-bottom: 1px solid #bac5d3;
  margin-top: 10px;
}

.blankModalFooter {
  /* background-color: purple; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
  min-height: 50px;
  padding-left: 5%;
  padding-right: 5%;
}

.mobBlankModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 542px !important;
  min-height: 542px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobUnclockModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  /* border-radius: 5px 5px 0 0; */
  /* max-height: 600px !important; */
  /* min-height: 600px !important; */
  /* border-radius: 5px 5px 0 0 !important; */
  background-color: white;
  z-index: 9999999 !important;
}

.mobUnclockModalBoxIos {
  /* max-height: 80dvh !important; */
  /* min-height: 80dvh !important; */
  /* background-color: blue !important; */
  /* margin-bottom:20px; */
  /* HARIBO */
  padding-bottom:20px !important;
}

.unclockedContainer {
  overflow: scroll;
  overflow-x: hidden;
  min-height: 450px;
  max-height: 450px;
  padding-bottom: 10px;
  padding-top:10px;
  background-color: #ebf0f7;
}


.unclockedContainerMob {
  min-height: 70dvh;
  max-height: 70dvh;
}
.activeClocksContainer {
  overflow: scroll;
  overflow-x: hidden;
  /* min-height: 498px;
  max-height: 498px; */
  min-height: 80dvh;
  max-height: 80dvh;
  padding-bottom: 10px;
  padding-right: 5px;
}

.minus140pxForActions {
  min-height: 266px;
  max-height: 266px;
}

.minus140pxForActionsMob {
  min-height: calc(100dvh - 490px);
  max-height:calc(100dvh - 490px);
}
.unclocksModalContentBox {
  background-color: #f4f8fe;
  max-width: 90%;
  margin: 0 auto;
  margin-top: -50px;
  z-index: -1;
  border-radius: 5px;
  border-top: solid 2px #143051;
  /* border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee; */
  border-bottom: 2px solid #143051;
  margin-top: 10px;
  border: 1px solid #bfccdc;
}

.whiteBg {
  background-color: white;
}

.unclockedUnit {
  background-color: white;
  max-width: 95%;
  min-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  min-height: 74px;
  max-height: 74px;
  margin-top: 8px;
  border-radius: 5px;
  cursor: pointer;

  padding-right: 10px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: #d5e2f0 1px solid;
  /* padding-left:5px; */
}

.unclockedUnit:hover {
  border-color: #bbcada
  /* box-shadow: 0px 0px 3px #143051; */
}

.clockedOnUnit {
  background-color: white;
  max-width: 95%;
  min-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  min-height: 200px;
  max-height: 200px;
  margin-top: 8px;
  border-radius: 5px;
  cursor: pointer;
padding-top:3px;padding-right:3px;
padding-bottom:3px;
  padding-right: 10px;
  padding-left:8px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  /* border: #dde9f9 1px solid; */
  /* padding-left: px; */
}

.clockedOnUnitWithBreakSecs {
  min-height: 200px;
  max-height: 200px;
}

.unclockUnitBodyBreakActive {
margin-top:-2px  !important;
}

.zoomIn {
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}
.zoomInInstall {
  animation-name: zoomModalIn !important;
  animation-duration: 0.15s !important;
}

.clockedOnUnitX:hover {
  /* border: #bbcada 1px solid; */

  /* border-color: #bac5d3 */
  /* box-shadow: 0px 0px 3px #143051; */
}

.multiSelectUnclocks {
  /* background-color: #214978 !important;
  border-color: #143051 !important; */
  /* margin-right:8px */
}

.multiSelectUnlockRadio {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  /* background-color: white;
  border: 2px solid #143051; */
  /* border-radius: 100%; */
  margin-right: 5px;
  margin-left: -5px;
}

.unclockTickImg {
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
  border: 1px solid #cbd7e6;
  border-radius: 3px;
  padding: 3px;
}

.unselectedUnclockRadio {
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
  background-color: white;
  border: 1px solid #cbd7e6;
  border-radius: 3px;
}

.mobUnclocksModalBody {
  /* min-height: calc(90vh - 162px) !important; */
  /* max-height: calc(90vh - 162px) !important; */
}

.actionsUnclockActive {
  background-color: #0bf !important;
  border: 1px solid #0af !important;
  cursor: pointer;
  margin-right: 8px;
  color:white !important
}

.unclockUnitBody {
  display: flex;
  justify-content: space-between;
  min-width: 96%;
  max-width: 96%;
  margin-top: 4px;
  margin-left: 2%;
}

.unclockUnitBodyBreakInactive {
  margin-top:-4px
}
.unclockNameString {
  display: flex;
  justify-content: flex-start;
  /* padding-left: 14px; */
  margin-top: -2px;
  /* margin-left: 2px; */
  color: #143051;
  font-weight: 600;
  font-size: 16px;
  /* max-height: 20px; */
}

.unclockSection2 {
  padding-right: 0px !important;
}

.unclockSection1 {
  margin-left: -14px;
  /* min-width: 180px !important; */
  /* min-width: 10px; */
  /* background-color: orange; */
}

.x2312121 {
  margin-left: 6px;
}

.unclockSection1Desktop {
  margin-left: -26px;
  min-width: 150px !important;
}

.unclockSection1DesktopX {
  margin-left: 6px;
  min-width: 150px !important;
}

.unclockItemMenuDiv {
  min-height: 159px;
  /* min-height: fit-content; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  background-color: #214978;
  background-color: #ffffff;
  color: white;
  border-radius: 0 0 5px 5px;
  /* border-top: solid #143051 2px; */
  animation-name: slideUnclockMenuDown;
  animation-duration: 0.1s;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  margin-top: -19px;
}

@keyframes slideUnclockMenuDown {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 159px;
    min-height: 159px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

.unclockItemMenuDiv2 {
  min-height: 159px;
  /* min-height: fit-content; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  background-color: #214978;
  background-color: #214978;

  color: white;
  border-radius: 0 0 5px 5px;
  border-top: solid #143051 2px;
  animation-name: slideUnclockMenuDown2;
  animation-duration: 0.1s;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
}

@keyframes slideUnclockMenuDown2 {
  from {
    max-height: 99px;
    min-height: 99px;

    /* opacity: 0; */
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 159px;
    min-height: 159px;

    /* opacity: 1; */
    /* transform: scale(1); */
    z-index: -1;
  }
}

.unclockItemMenuDivNoSched {
  min-height: 99px;
  /* min-height: fit-content; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  background-color: #214978;
  background-color: #667c96;
  color: white;
  border-radius: 0 0 5px 5px;
  border: solid #143051 2px;
  animation-name: slideUnclockMenuDownNoSched;
  animation-duration: 0.1s;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: -2px;
  border-top: none;
}

@keyframes slideUnclockMenuDownNoSched {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 99px;
    min-height: 99px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

.unclockItemMenuDivSched {
  min-height: 142px;
  /* max-height: 142px; */
  /* min-height: fit-content; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  /* background-color: #214978; */
  background-color: #214978;
  /* border-bottom: #143051 1px solid; */
  color: white;
  border-radius: 0 0 5px 5px;
  border-top: solid #143051 2px;
  animation-name: slideUnclockMenuDownSched;
  animation-duration: 0.1s;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  /* margin-bottom: 16px; */
  border-left: #bbcada 1px solid;
  border-right: #bbcada 1px solid;
  border-top: none;
  margin-top: -2px;
}

@keyframes slideUnclockMenuDownSched {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 142px;
    min-height: 142px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

.unclockMenuL {
  min-width: 70%;
  /* min-height: 20px; */
  /* background-color: yellow; */
}

.unclockMenuLActive {
  min-width: 100%;
  /* background-color: yellow; */
}

.unclockMenuR {
  min-width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid white;
}

.viewUnclockItem {
  min-width: fit-content;
  max-width: fit-content;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  color: #143051;
  background-color:white;
}

.viewUnclockItem:hover {
  border-color: #d2d9e3;
  background-color:white;}

  .viewShUnclPopUp {
    width:14px;
    margin-left:5px;
  }
.chatActionItem {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 64px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #a387df;
  border: 1px solid #8e76c1;
  margin-top: 8px;
}

.chatActionItem:hover {
  background-color: #9a82ce;
}

.unclockActionBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 40px;
  max-height: 40px;
  border: solid 1px #dde9f9;
  background-color: #bbcada;
  border-radius: 5px;
  cursor: pointer;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 8px;
}

.unclockActionBtnSmallerTxt {
  font-size: 12px;
  min-height: 30px;
  max-height: 30px;
}

.unclockActionBtn:hover {
  /* background-color: #00ccff; */
  color: #214978;
}

.unclockActionBtn:first-child {
  margin-top: 0px;
}

.unclockBtnDivider {
  min-height: 2px;
  background-color: #e3e8ee;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 8px;
}

.selectedUnclockUnit {
  border: 1px solid #214978;
  margin-top: 6px;
  padding-bottom: 2px;
  padding-top: 2px;
  margin-bottom: 2px;
}

.selectedUnclockUnit:hover {
  box-shadow: none;
  border: 1px solid #214978;

}

.unclockAbsBtn {
  background-color: #fff;
  color: #143051;
  border: #e3e8ee solid 2px;
  min-height:35px;
  font-size: 14px;
}

.unclockAbsBtn:hover {
  /* border: solid 1px #09aed8;
  background-color: #00ccff;
  color: white; */
  border-color:#d2d9e3
}

.unclockDeleteBtn:hover {
  /* background-color: #e26666;
  border-color: #ba0000; */
}

.unclockMarkWorkedBtn:hover {
  /* background-color: #6dba4f;
  border-color: #427030; */
}

.unclockMultiActionsBox {
  min-width: 330px !important;
  min-height: 181px;
  min-width: 70%;
  max-width: 70%;
  margin-left: 3%;
  color: white;
  margin-top: 9px;
  /* border-radius: 5px 5px 0 0; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 3px;
  padding-bottom: 0px;
  padding-right: 88px;
  /* border-top: 1px solid #bfccdc; */
  border-bottom: 1px solid #bfccdc;
}

.desktopMultiActionBox {
  /* background-color: #00ccff; */

  min-height: 154px !important;
  padding-top: 0px;
  padding-bottom: 10px;
  margin-left: 12px;
}

.noBorder:hover {
  border: none;
}

.unclockedSelectedQty {
  color: #143051;
  font-weight: 500;
  margin-left: 20px;
  margin-bottom: 4px;
  margin-top: -8px;
  font-size: 14px;
}

.showActionsWhiteCrossImg {
  margin-right: 5px;
  margin-left: -2px;
}

.paddingLandR15px {
  padding-left: 15px;
  padding-right: 15px;
}

.clockOnNotAllowed {
  min-height: 260px;
  /* background-color: white; */
  /* max-width: 90%; */
  /* margin-left: 5%; */
  /* padding-top: 80px; */
  /* padding-right: 30px;
  padding-left: 30px; */
  color: #214978;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clockOnNotAllowedTxt {
  max-width: 200px;
  margin-top: -20px;
}

.disableUnclockActions {
  pointer-events: none;
  opacity: 0.3;
}

.clockCardDateLeft {
  min-width: 78% !important;
  max-width: 78% !important;
}

.editClockedImg {
  margin-top: 7px;
  margin-right: 12px;
  cursor: pointer;
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding: 9px;
  border: #e3e8ee solid 2px;
  background-color: white;
}

.editClockedImg:hover {
  border-color: #d2d9e3;
}

.editClockDateImg {
  cursor: pointer;
  margin-top: 28px !important;
  margin-left: 26px !important;
  /* margin-right: 18px;
    margin-top: 20px; */
  cursor: pointer;
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding: 9px;
  border: #e3e8ee solid 2px;
  font-weight: 500;
}

.editClockDateImg:hover {
  border-color: #d2d9e3;
}

.clockedTitleTxtSolo {
  font-weight: 400;
  font-size: 12px;
  color: #214978;
  display: flex;
  align-items: center;
}

.clockedTimesTitleDiv {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  margin-bottom: -20px;
}

.savedEditClockedBtn {
  background-color: #07f;
  max-height: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  /* margin-bottom: -2px; */
  cursor: pointer;
  min-width: 60px;
}

.savedEditClockedBtn:hover {
  background-color: #0469de;
}

.deleteClockBtn {
  margin-right: 8px !important;
  margin-left: 0px !important;
}

.clockedTimeInput {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  margin-top: -12px;
  /* margin-left: -1px; */
  /* padding-left: 4px; */
  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* font-size: 16px; */
  cursor: text;
  margin-bottom: 4px;
}

.clockedTimeInput:hover {
  border-color: #bbcada;
}

.invalidClockSave {
  /* pointer-events: none; */
  opacity: 0.1;
}

.invalidClockInput {
  border: #e0b7b7 2px solid !important;
}

.offClock {
  /* background-color: blue; */
  min-width: 90px;
}

.clockBreakFixed {
    min-width: 124px !important;
    max-width: 124px;
    padding-right: 30px;
    font-size: 14px;
    line-height: 18px;
    padding-top: 14px;
    padding-bottom: 10px;
}

.clockBrkDurMins {
  display: flex;
  /* margin-bottom: -5px; */
}

.clockBrkUntilSpan {
  font-size: 12px;
  padding-right: 6px;
  color: #859cb6;
}

.noMinHeight {
  min-height: 0px !important;
}

.overlapBrkClockSpan {
  text-transform: none;
  margin-left: 6px;
  background-color: #cd7171;
  color: white;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  max-height: 17px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-weight: 500;
  font-size: 10px;
}

.breakClockTitleDiv {
  min-width: 190px;
}

.breakClockTitleDivSmaller {
  min-width: 160px;
}

.removeClockBrkImg {
  cursor: pointer;
  max-width: 22px;
  min-width: 22px;
  margin-right: 7px;
  margin-top: -4px;
  background-color: white;
  padding: 3px;
  border: 1px solid #bbcada;
  max-height: 22px;
  min-height: 22px;
  border-radius: 4px;
  margin-top: 8px;
}

.removeClockBrkImg:hover {
  background-color: #e9f3fe;
}

.addBrkBtn {
  max-width: 30px;
  margin-right: 10px;
  /* margin-bottom:  */
  cursor: pointer;
}

.addBrkRowDiv {
  /* background-color: blue; */
  max-height: 35px;
  margin-bottom: -19px;
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.addBrkClockBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  font-weight: 500;
  font-size: 12px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
  padding-bottom: 1px;
}

.addBrkClockBtn:hover {
  background-color: #bbcada;
}

.clockBreakTimeStrip {
  font-size: 14px;
}

.pullAddBreakBtnUpDueToSched {
  margin-top: -110px;
  margin-bottom: 74px;
}

.clockedOverUnderSpan {
  font-size: 12px;
  color: #ba0000;
  /* color: white; */
  /* border-radius: 3px; */
  /* padding: 3px; */
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  font-weight: 500;
  /* opacity: 0.8; */
  line-height: 14px;
  max-width: 110px !important;
  margin-top: 6px;
}

.originallyClockedMobFont {
  font-size: 10px;
  text-align: left;
  max-width: 58px;
}

@media only screen and (max-width: 1054px) {
  .createdByDynamic {
    font-size: 10px;
    text-align: left;
    max-width: 58px;
  }
}

.relinkBtn {
  margin-left: 6px;
}

.teamDesktopHeaderSubBg {
  background-color: #fafcff;
}

.approveAndDeclineClockBtns {
  /* background-color: blue; */
  min-width: 166px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shorterAppDecClockBtnContainer {
  min-width: 90px;
}

.approveClockBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #a8cc9a;
  border: 1px solid #9cb194;
  margin-right: 10px;
}

.approveClockBtn:hover {
  background-color: #9cb194;
}
.declineClockBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #e89292;
  border: 1px solid #9e7373;
}

.declineClockBtn:hover {
  background-color: #da8787;
}
.sureDeleteClockBtnColours {
  background-color: #214978 !important;
  border: 1px solid #143051 !important;
}

.declinedClockTab {
  background-color: #e89292 !important;
  border: 1px solid #ba0000 !important;
}

.clockCardNotesArr {
  /* background-color: blue; */
  border-left: 2px solid #143051;
  margin-top: 10px;
  margin-bottom: 20px;
}

.clockCardNoteRow {
  /* background-color: yellow; */
  margin-top: 3px;
  font-size: 14px;
  border-top: 1px solid #cbd7e6;
  padding-bottom: 8px;
}

.clockCardNoteRow:first-child {
  border-top: none;
}

.clockCardNameAndDateDiv {
  display: flex;
  justify-content: space-between;
}

.clockCardNoteName {
  font-weight: 500;
  color: #00ccff;
}

.clockCardNoteTime {
  font-size: 12px;
  color: #859cb6;
}

.clockCardNoteValue {
  line-height: 16px;
  margin-top: -6px;
  min-width: 100%;
  color: #8ea7c5;
}

.clockCardNoteTitleAndAddNoteBtnDiv {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  margin-top: 12px;
}

.addClockNoteBtn {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 17px;
  margin-bottom: 8px;
  color: #496375;
  border: #e3e8ee solid 1px;
  background-color: #fff;
}

.addClockNoteBtn:hover {
  border-color: #bbcada;
}

.addClockNoteBtnActive {
  /* background-color: #fff; */
  /* border: 1px solid #143051; */
}

.addClockNoteBtnActive:hover {
  /* background-color: #143051; */
}

.addClockNoteInputContainer {
  display: flex;
  min-height: 120px;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 4px;
  max-width: 100%;
  padding-left: 5%;}

.addClockNoteTextInput {
  min-width: 73%;
  max-width: 73%;
  min-height: 130px;
  resize: none;
  border-radius: 5px;
  background-color: #fff;
  font-size: 1em;
  overflow: scroll;
  line-height: 20px;
  font-size: 16px;
  margin-top: 0;
  font-weight: 400;
  color: #496375;
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  color: #6a8bb1;
  padding: 5px;
  font-weight: 500;
}

.addClockNoteTextInput::placeholder {
  color: #bbcada;
}

.addClockNoteTextInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.clockNoteSaveAndClearBtns {
  min-width: 24%;
  /* padding-left: 3%; */
  /* padding-right: 3%; */
}

.saveClockNoteBtn {
  background-color: #07f;
  max-height: 35px;
  min-height: 35px;

  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  /* margin-top: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: -2px;
  cursor: pointer;
  min-width: 60px;
}
.saveClockNoteBtn:hover {
  background-color: #0469de;
}

.clearClockNoteBtn {
  max-height: 35px;
  min-height: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  font-size: 0.9em;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  /* margin-bottom: -2px; */
  cursor: pointer;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  min-width: 60px;
}

.clearClockNoteBtn:hover {
  background-color: #6987a9;
}

.endIsNextDaySpan {
  /* background-color: #140351; */
  /* color: white; */
  color: #859cb6;
  font-size: 14px;
  padding-left: 0px;
  /* padding-right: 4px; */
  border-radius: 5px;
  padding-right: 7px;
}

.closeAddNoteClockBtn {
  margin-right: 5px;
  max-width: 10px;
}

.clockNoteDiv {
  min-width: 100%;
}

.fadeSaveBtnClockNote {
  opacity: 0.2;
  pointer-events: none;
}

.clockTickImg {
  max-width: 18px;
}

.clockCrossImg {
  max-width: 20px;
  margin-right: -1px;

  margin-left: -1px;
}

.viewAssocClockItemInline {
  margin-top: 10px;
  margin-right: 1px;
  max-width: fit-content;
}

.indiOnlyUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.indiOnlyModalBox {
  background-color: #f4f8fe;
  border-radius: 5px;
  min-width: 340px;
  /* min-height: 400px; */
  cursor: default;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.indiOnlyStaffList {
  /* background-color: blue; */
  border-top: 1px solid #ddebf4;
  border-bottom: 1px solid #ddebf4;
  min-height: 100px;
  max-height: 220px;
  padding-top: 15px;
  padding-bottom: 15px;
  max-height: 20px;
  overflow-y: scroll;
  margin-top: 20px;
  margin-bottom: 20px;
}

.individualUserManagesNav {
  margin-top: 6px;
  font-size: 14px;
  text-align: left;
  margin-left: 6%;
  color: #859dbb;
}

.individualUserManagesNav:first-child {
  margin-top: 0px;
}

.clockOnSchedTeamNameContainer {
  display: flex;
  justify-content: center;
  max-height: 20px;
  min-height: 20px;
  margin-bottom: -10px;
  min-width: 100%;
  margin-top: -5px;
  margin-bottom: 10px;
  /* padding-right: 5%; */
}

.clockOnSchedTeamNameContainerClockedOn {
  display: flex;
  justify-content: flex-end;
  max-height: 20px;
  min-height: 20px;
  margin-top: -20px;
  margin-bottom: 10px;
  min-width: 100%;
  padding-right: 5%;
}

.clockOnSchedTeamNameTxt {
  text-align: left;
  /* background-color: #e3e8ee; */
  /* color: #4f566b; */
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  max-width: fit-content;
  font-weight: 500;
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  /* margin-bottom:6px; */
  /* float:left */
}

.clockUnpaidBreakSpan {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #e3e8ee; */
  color: #8da4bc;
  border-left: 1px solid #e1eaf4;
  font-size: 10px;
  /* border-radius: 5px; */
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 6px;
  font-weight: 400;
  /* padding-bottom: 1px; */
}

.clockOnDataTitle {
  display: flex;
  font-size: 10px !important;
  color: #214978;
  font-weight: 400;
}

.unclockedTabMessagesImg {
  max-width: 10px;
  margin-right: 5px;
}

.topBarMsgIcon {
  max-width: 13px;
  margin-right: 4px;
}

.topBarClockedOnIcon {
  max-width: 14px;
  margin-right: 4px;
}

.blueBg {
  background-color: #0077ff;
  /* animation: colorFade 2s infinite; Adjust the duration as needed */
}


/* 
@keyframes colorFade {
  0% {
    background-color: #0077ff;
  }
  50% {
    background-color: #36495f;
  }
  100% {
    background-color: #0077ff;
  }
} */

.mobMsgDiv {
  min-width: 50px;
  max-width: 50px;
  min-height: 46px;
  max-height: 46px;
  background-color: #234f83;
  border: 1px solid #143051;
  position: absolute;
  right: 0px;
  bottom: 30vh;
  border-radius: 5px 0 0 5px;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
  /* animation-name: slideMobSideBtnIn;
  animation-duration: 0.1s; */
}

.mobMsgQty {
  background-color: #07f;
  max-width: fit-content;
  border-radius: 3px 0 0 3px;
  color: white;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: calc(30vh - 11px);
  z-index: 999;
  /* animation-name: zoomModalIn;
  animation-duration: 0.1s; */
}

.mobMsgIcon {
  margin-left: 0px;
  margin-top: 0px;
  min-width: 24px;
}

.mobNotifDiv {
  min-width: 50px;
  max-width: 50px;
  min-height: 46px;
  max-height: 46px;
  background-color: #234f83;
  border: 1px solid #143051;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 64px);
  border-radius: 5px 0 0 5px;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
  /* animation-name: slideMobSideBtnIn;
  animation-duration: 0.1s; */
}

@keyframes slideMobSideBtnIn {
  from {
    /* margin-left: -50px; */
    /* max-width: 50px;
    min-width: 50px; */
    z-index: 1;
    right: -50px;
    /* opacity: 0.1; */
    /* transform: scale(0.4); */
  }
  to {
    z-index: 1;
    right: 0px;
    /* opacity: 1; */
    /* transform: scale(1); */
  }
}

.mobNotifQty {
  background-color: #07f;
  max-width: fit-content;
  border-radius: 3px 0 0 3px;
  color: white;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 52px);
  z-index: 999;
  /* animation-name: zoomModalIn;
  animation-duration: 0.1s; */
}

.mobNotifIcon {
  margin-left: 0px;
  margin-top: -2px;
  min-width: 24px;
  max-width: 24px;
}

.mobMgrBtnDiv {
  min-width: 50px;
  max-width: 50px;
  min-height: 46px;
  max-height: 46px;
  background-color: #592eb6;
  border: 1px solid #4b1caf;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 128px);
  border-radius: 5px 0 0 5px;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
  /* animation-name: slideMobSideBtnIn;
  animation-duration: 0.1s; */
}

.mobMgrBtnQty {
  background-color: #a387df;
  max-width: fit-content;
  border-radius: 3px 0 0 3px;
  color: white;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 117px);
  z-index: 999;
  /* animation-name: zoomModalIn;
  animation-duration: 0.1s; */
}

.activeClocksMobQty {
  background-color: #7fbb67;
  max-width: fit-content;
  border-radius: 3px 0 0 3px;
  color: white;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 158px);
  z-index: 999;
  /* animation-name: zoomModalIn;
  animation-duration: 0.1s; */
}

.mobMgrBtnIcon {
  margin-left: 0px;
  margin-top: -2px;
  min-width: 24px;
  max-width: 24px;
}

.mobClockDiv {
  min-width: 50px;
  max-width: 50px;
  min-height: 46px;
  max-height: 46px;
  background-color: #7fbb67;

  border: 1px solid #5a9e3f;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 192px);
  border-radius: 5px 0 0 5px;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  cursor: pointer;
  /* animation-name: slideMobSideBtnIn;
  animation-duration: 0.1s; */
}

.mobClockIcon {
  margin-left: 0px;
  margin-top: -2px;
  min-width: 24px;
  max-width: 24px;
  animation: blinkLiveClockBtn 2s linear infinite;
}

.pushLiveClockBtnDown {
  bottom: 328px;
}

@keyframes blinkLiveClockBtn {
  50% {
    opacity: 0.25;
    /* background-color: #5a8d64; */
  }
}

.mobCollapseSiteBtnDiv {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  background-color: #bac5d3;
  border: 1px solid #a8b6ca;
  position: absolute;
  right: 0px;
  bottom: calc(30vh - 60px);
  border-radius: 5px 0 0 5px;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
}

.collapseMobSideBtnsImg {
  margin-left: 0px;
  margin-top: -2px;
  min-width: 24px;
  max-width: 24px;
}

.mobCollapseQty {
  background-color: #6987a9;
  max-width: fit-content;
  border-radius: 3px 0 0 3px;
  color: white;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: calc(30vh - 74px);
  z-index: 999;
}

.horizRevCollapse {
  transform: rotate(180deg);
}

.clockSideUnderlay {
  background: rgba(0, 0, 0, 0.85);
  min-height: calc(100% - 50px);
  /* min-height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 3999000;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.clockSideBtnsContainer {
  min-width: 200px;
  min-height: 90px;
  /* background-color: blue; */
  position: absolute;
  bottom: calc(30vh + 52px);
  right: 66px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.showClockSideTab {
  min-height: 40px !important;
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.showClockSideTabPushDown {
  margin-top: 14px;
}

.showClockSideTabTxt {
  font-size: 16px;
}

.showClockSideTabQtySpan {
  background-color: #a387df;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 6px;
  font-size: 16px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.clockSideCloseImg {
  min-width: 16px;
  max-width: 16px;
  position: absolute;
  bottom: calc(30vh + 89px);
  right: 14px;
}

.unclocksExplainer {
  max-width: 340px;
  color: #143051;
  line-height: 18px;
  font-size: 14px;
  padding-right: 20px;
  padding-left: 25px;
  text-align: left;
  margin-top: 7px;
  margin-bottom: 0px;
  font-weight: 400;
  padding-bottom: 4px;
}

.clockedOnStripHolder {
  color: #95aaba;
  font-weight: 500;
  margin-top: -7px;
  max-width: fit-content;
  display:flex;
  align-items: center;
}

.clockActiveStartDiv {
  display: flex;
  margin-top: 1px;
}

.activeClockAwaitingClockOffSpan {
    /* background-color: #07b; */
    color: #143051;
    font-size: 12px;
    /* margin-bottom: 10px; */
    /* padding-left: 4px; */
    /* padding-right: 4px; */
    border-radius: 5px 0 0 5px;
    margin-top: 5px;
    margin-left: 1px;
}

.activeClockOnStartSpan {
  color: #0af;
  font-size: 14px;
  padding-left: 4px;
  /* padding-right: 4px; */
  /* border-radius: 0px 5px 5px 0px; */
  font-weight: 600;
  margin-top: 5px;
}

.unclockUnitNameAndTeamDiv {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  padding-right: 6px;
  /* max-height:26px; */
  overflow:visible
}

.unclockTeamName {
  display: flex;
  justify-content: flex-start;
  /* padding-right: 14px; */
  margin-top: 12px;
  /* margin-bottom: 6px; */
  color: #9a74eb;
  font-weight: 500;
  font-size: 12px;
}

.activeClockItemMenuDiv {
  min-height: 159px;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  background-color: #214978;
  background-color: #214978;

  color: white;
  border-radius: 0 0 5px 5px;
  border-top: solid #143051 2px;
  animation-name: slideUnclockMenuDown;
  animation-duration: 0.1s;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
}

.unclockNameAndIconDiv {
  display: flex;
  margin-bottom: -4px;
  margin-top: 6px;
  overflow:visible

}

.unclockedIcon {
  max-width: 16px;
  margin-top: 9px;
  margin-left: 11px;
  margin-right: 1px;
}

.colourOrange {
  color: #dc8744 !important;
}

.clockedOnIcon {
  max-height: 30px;
  object-fit: cover;
  /* margin-left: 5px; */
  margin-right: 8px;
  /* animation: Pulsate 1s linear infinite; */
  max-width: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 3px;
  border: 2px solid #143051;
}

.pulsate {
  animation: Pulsate 1s linear infinite;
}
.clockedOnAgoSpan {

    padding-left: 4px;
    color: #bbcada;
    font-weight: 500;
    font-size: 12px;
}

.scheduledActiveClockOffset {
  font-size: 11px;
  /* margin-left: 4px; */
  color: #ba0000;
  max-width: -moz-fit-content;
  max-width: fit-content;
  text-align: left;
  line-height: 12px;
  margin-top:2px;
}

.onTimeTickImg {
  min-width: 15px;
  max-width: 15px;
  margin-left: 8px;
}

.clockOffAbsentTxtR {
  max-width: 50px;
  text-align: right;
  line-height: 14px;
  font-size: 10px;
}

.clockOffAbsentBtn {
  justify-content: space-between;
  padding-left: 15px;
}

.clockOffAbsentTxtL {
  /* background-color: yellow; */
  min-width: fit-content;
}

.clockOffAbsentUnpaidTxtL {
  margin-left: -8px !important;
  /* background-color: blue; */
}

.activeClockAbsentTitle {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  padding-left: 18px;
  padding-top: 6px;
}

.activeClockAbsentBtns {
  display: flex;
}

.activeClockAbsentButton {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 40px;
  max-height: 40px;
  border: solid 1px #143051;
  background-color: #bbcada;
  border-radius: 5px;
  cursor: pointer;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 5px;
  min-height: 35px;
}

.activeClockAbsentButton:hover {
  background-color: #a1b4c8;
}

.activeClockAbsentButton:last-child {
  margin-left: 4px;
}

.activeClockViewShift {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 64px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
  margin-top: 8px;
}

.activeClockViewShift:hover {
  background-color: #0af;
}

.activeClockGPSBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 64px;
  max-width: 64px;

  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer !important;
  background-color: #143051;
  border: 1px solid #142a46;
  margin-top: 8px;
}

.clockedDataIconImg {
  max-width: 18px;
  margin-left: 0px;
  margin-right: 2px;
}

/* .clockedDataIconImg2 { */
/* max-width: 10px;
    margin-left: 4px;
    margin-right: 0px;
  }
   */
.clockGpsSplit {
  margin-left: -2px;
  margin-right: 0px;
  opacity: 0;
}

.activeClockGPSBtn:hover {
  background-color: #142a46;
}

.activeClockStatusDiv {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-start; */
  font-size: 13px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 26px;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
  /* align-items: ; */
}

.activeClockBrkTxt {
  background-color: #fff;
  font-weight: 500;
  color: #0af;
  padding-left: 6px;
  padding-right: 4px;
  border-radius: 0 0 0px 5px;
  min-height: 26px;
  display: flex;
  align-items: center;
  /* border-top: 1px solid #5f9de4; */
  /* border-left: 1px solid #e0ebf8; */
  /* border-bottom: 1px solid #e0ebf8; */
font-size:12px
  /* font-weight: 500; */
}

.activeClockBrkTxtAgo {
  background-color: #fff;
  font-weight: 500;
  color: #8ea7c5;
  /* padding-left: 6px; */
  /* border-top: 1px solid #5f9de4; */

  padding-right: 6px;
  border-radius: 0 0px 5px 0px;
  min-height: 26px;
  display: flex;
  align-items: center;
  /* border-right: 1px solid #e0ebf8; */
  /* border-bottom: 1px solid #e0ebf8; */
  font-size:12px;
  /* font-weight: 500; */
}

.messagesModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.mobMessagesUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* background-color: #fff; */
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
  /* padding-bottom: 20px; */
}

.messagesModalBox {
  background-color: #f4f8fe;
  /* background-color: white; */
  border-radius: 10px;
  min-width: 780px;
  max-width: 780px;
  cursor: default;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.mobMessagesModalBox {
  /* min-height:80vh; */
  /* position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 602px !important;
  min-height: 602px !important;
  border-radius: 5px 5px 0 0 !important;
  background-color: #f4f8fe;
  z-index: 9999999 !important;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%) */
}

.messagesModalBodyDesktop {
  min-height: 300px;
  /* border: 1px solid #bac5d3; */
  max-width: 96%;
  margin-left: 2%;
  margin-top: 10px;
  background-color: white;
  display: flex;
}

.messagesLeft {
  min-width: 40%;
  max-width: 40%;
  min-height: 358px;
  max-height: 358px;
  overflow-y: scroll;
  background-color: #f4f8fe;
  border-right: 1px solid #bac5d3;
  border-top: 1px solid #bac5d3;
  padding-top: 3px;
}

.messagesRight {
  min-width: 60%;
  max-width: 60%;
  border-top: 1px solid #bac5d3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 5px;
  /* background-color: lime; */
}

.chatBoxScrollBox {
  padding-bottom: 20px;
  min-width: 100%;
  min-height: 314px;
  max-height: 314px;
  overflow-y: scroll;
}
.chatBox {
  /* background-color: orange; */
  min-height: 100%;
  /* max-height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.messagesModalFooterDesktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  border-top: 1px solid #bac5d3;
  max-width: 96%;
  margin-left: 2%;
}

.clockMessagesModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}
.clockMessagesModalBtn:hover {
  color: #fff;
  background-color: #214978;
}

.refreshMsgBtn {
  font-size: 14px;
  color: #fff;
  background-color: #cbd7e6;
  border: solid #bbcada 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}
.refreshMsgBtn:hover {
  color: #fff;
  background-color: #bbcada;
}

.chatHeadUnit {
  min-height: 66px;
  padding-right: 10px;
  /* background-color: blue; */
  /* margin-top: 4px; */
  border-bottom: 1px solid #bac5d3;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chatHeadUnit:hover {
  background-color: #e9eef7;
}

.chatHeadProfPicImg {
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 5px;
  object-fit: cover;
}

.chatHeadL {
  min-width: 50px;
}

.chatHeadR {
  min-width: calc(100% - 60px);
  max-width: calc(100% - 60px);

  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 4px;
}

.chatHeadName {
  font-weight: 500;
  font-size: 16px;
  color: #140351;
}

.chatHeadMsgPreview {
  font-size: 13px;
  color: #859dbb;
  font-weight: 500;
}

.unreadColour {
  color: #0077ff;
  font-weight: 500;
}

.unreadDiv {
  min-width: 8px;
  max-width: 8px;
  min-height: 8px;
  max-height: 8px;
  border-radius: 10px;
  background-color: #0077ff;
  min-height: 100%;
  margin-left: 6px;
  margin-top: -30px;
}

.selectedChatHead {
  background-color: #0077ff;
  /* border-radius: 5px 0 0 5px; */
  border: none;
  padding-bottom: 1px;
}

.selectedChatHead:hover {
  background-color: #0077ff;
}

.messageNoBorder {
  /* border: none; */
  padding-bottom: 1px !important;
}

.colourWhite {
  color: white !important;
}

.chatInputBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  min-width: 100%;
}
.chatInput {
  border: none;
  border: 2px solid #dde9f9;
  background-color: #fdfeff;
  border-radius: 5px;
  margin-right: 4px;
  color: #143051;
  min-height: 35px;
  /* padding-left: 5px; */
  font-weight: 400;
  resize: none;
  min-width: calc(100% - 55px);
  font-size: 14px;
  padding: 10px;
  max-height: 40px;
  z-index: 999;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  /* padding-left: 6px; */
  background-color: #fdfeff;
  padding-top: 8px;
  padding-left: 8px;
}

.chatInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
  padding-top: 8px;
  padding-left: 8px;
}

.chatInput::placeholder {
  color: #bbcde3;
}

.chatSend {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 50px;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
  margin-top: 0px;
}

.chatSend:hover {
  background-color: #0af;
}

.disableSendBtn {
  /* opacity: 0.5; */
  pointer-events: none;
  border: none;
  background-color: white;
  color: #cbd7e6;
}

.chatItemParent {
  min-width: 96vw;
  max-width: 96vw;
  margin-left: 2vw;
}

.chatItemParentDesktop {
  min-width: 430px;
  max-width: 430px;
}

.chatItem {
  /* background-color: blue; */
  min-width: 100%;
  margin-top: 8px;
  display: flex;
  text-align: left;
  max-height: fit-content;
}

.chatItemDesktop {
  /* background-color: blue; */
  min-width: 430px;
  max-width: 430px;

  margin-top: 8px;
  display: flex;
  text-align: left;
  max-height: fit-content;
}

.flexLeft {
  justify-content: flex-start;
  /* background-color: yellow; */
}

.flexRight {
  justify-content: flex-end;
  /* background-color: orange; */
}

.chatTxtIn {
  max-width: 335px;
  background-color: #e0ebf8;
  color: #516b8b;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  font-weight: 400;
  border: 1px solid #cddcee;
}

.chatTxtOut {
  max-width: 350px;
  background-color: #0077ff;
  border: 1px solid #0046ff;
  color: white;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
  margin-right: 5px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  font-weight: 400;
}

.threadProPic {
  object-fit: cover;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 0px;
}

.msgsNoPicInitialDiv {
  background-color: #cfd8e2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 5px;
  font-weight: 500;
}
.chatDs {
  font-size: 12px;
  color: #a1b4c8;
  margin-top: 5px;
  /* margin-left: 4px; */
  text-align: left;
  margin-left: 54px;
  margin-bottom: 10px;
}

.chatDsRight {
  font-size: 10px;
  color: #a1b4c8;
  margin-top: 5px;
  /* margin-left: 12px; */
  text-align: right;
  margin-right: 7px;
  margin-bottom: 10px;
}

.disable {
  pointer-events: none;
  opacity: 0.5;
}

.loadAllChatBtn {
  /* background-color: blue; */
  min-width: 100%;
  color: #00ccff;
  font-weight: 500;
  min-height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
}

.loadAllChatBtn:hover {
  background-color: #edf5fa;
}

.searchUserMessages {
  min-height: 40px;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #bbcde3;
}

.messagesSearchInput {
  border: none;
  font-size: 16px;
  /* border-bottom: 1px solid #dde9f9; */
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 5px;
  font-weight: 500;
  min-width: 250px;
  margin-top: -2px;
}

.messagesSearchInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff;
}

.messagesSearchInput::placeholder {
  color: #bbcde3;
  font-weight: 500;
  padding-left: 2px;
}

.messagesMagniImg {
  max-width: 18px;
  min-width: 18px;
  margin-left: 10px;
  margin-top: -2px;
}

.closeMobMessagesBtnImg {
  min-width: 32px;
  max-width: 32px;
  padding: 8px;
  position: fixed;
  bottom: calc(30vh + 3px);
  right: 7px;
}

.refreshMobMsgIcon {
  min-width: 54px;
  max-width: 54px;
  padding: 11px;
  position: fixed;
  bottom: calc(30vh - 63px);
  right: -4px;
  /* margin-left: 4px; */
}

.closeMobMessagesBtnImg2 {
  bottom: calc(30vh + 19px);
  right: 45px;
}

.mobMessagesTitle {
  color: #143051;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  min-width: 160px;
  margin-right: -32px;
  margin-top: 4px;
}

.msgNotifPushBtn {
  /* border: 1px solid #bbcada;  */
  /* background-color: #fff; */
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 7px;
  padding-right: 2px;
  padding-left: 6px;
  max-width: 37px;
  margin-left: 9px;

}

.mobChatHeadsDiv {
  min-height: 100px;
  max-height: 100px;
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: scroll;
  /* padding-bottom: 4px; */
  /* margin-left: 5%;
  margin-top: 20px; */
  padding-left: 1vw;
  display: flex;
  border-bottom: 1px solid #bac5d3;
  padding-right: 20px;
  /* background-color: blue; */

  /* background-color: blue; */
}
.mobChatHeadsDiv::-webkit-scrollbar-thumb {
  background-color: #c7daef;
  border-radius: 20px;
}

.mobChatHeadItem {
  /* background-color: pink; */
  min-width: 88px;
  max-width: 88px;
}

.mobChatHeadProfPic {
  border-radius: 5px;
  max-width: 54px;
  min-width: 54px;
  max-height: 50px;
  min-height: 50px;
  object-fit: cover;
  /* border: 1px solid #2d5a8f; */
  margin-top: 4px;
  margin-bottom: -3px;
}

.mobChatHeadNameRow {
  color: #143051;
  font-size: 12px;
  line-height: 12px;
}

.mobChatBox {
  min-height: calc(100vh - 123px);
  max-height: calc(100vh - 123px) !important;

  /* margin-bottom: 10px; */
  min-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-y: scroll; */

  /* margin-left:  */
  /* min-width: calc(90vw - 30px); */
}

.mobChatChatsDiv {
  /* background-color: orange; */
  min-height: calc(100vh - 164px);
  margin-bottom: 10px;
  min-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.mobChatNameHolder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 17px;
  margin-top: 3px;
}

.selectedMobChatHeadItem {
  background-color: #143051;
  border-radius: 5px;
  /* padding-top: 5px; */
  padding-bottom: 5px;
  margin-bottom: 2px;
  /* min-width: 90px; */
  /* margin-left: -5px; */
  /* margin-right: -5px; */

  /* margin-top: -5px; */
}
.selectedMobChatName {
  /* background-color: white;
  color: #0077ff;
  max-width: fit-content;
  text-align: center;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px; */
  font-weight: 500;
  color: #fff;
  margin-left:-5px
}

.selectedMobChatHeadImg {
  border: 2px solid white;
  margin-left:-10px;
  /* margin-top: 4px;
  margin-bottom: -3px;
  margin-left: -3px; */
}
.mobChatThread {
  min-height: calc(100vh - 230px);
  max-height: calc(100vh - 230px);
  overflow-y: scroll;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-right: 8px;
  /* background-color: yellow; */
  min-width: 100%;
  padding-bottom: 10px;
  padding-top: 12px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: flex-end; */
  /* display: flex; */
/* align-items: flex-end; */
  /* border-right: 1px solid #e6eaee /* display: flex; flex-direction: column; justify-content: flex-end; */
  animation-name: slideModalUp !important;
  animation-duration: 0.2s !important;
}
.mobChatThread::-webkit-scrollbar-thumb {
  background-color: #c7daef;
  border-radius: 20px;
}

.mobChatPreviews {
  min-height: calc(100vh - 173px);
  max-height: calc(100vh - 173px);
  overflow-y: scroll;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 100%;
  max-width: 82%;
  padding-right: 8px;
  padding-left: 5%;
  /* border-right: 1px solid #bac5d3; */
  padding-bottom: 10px;
}
.mobChatPreviews::-webkit-scrollbar-thumb {
  background-color: #c7daef;
  border-radius: 20px;
}
.mobChatItem {
  background-color: yellow;
  min-width: 80vw;
  max-width: 80vw;
}
.mobChatInputBox {
  min-height: 40px;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 3px;
}

.mobChatTxtInput {
  min-width: calc(90% - 20px);
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
  min-height: 44px;
  font-size: 16px;
  color: #214978;
  padding-left: 10px;
  font-weight: 400;
}

.mobSendChatBtn {
  min-height: 44px;
  max-height: 44px;
  /* margin-top: 2px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 50px;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 2px solid #0af;
}

.mobChatPreviewUnit {
  background-color: rgb(255, 255, 255, 0.03);
  max-height: 60px;
  min-height: 60px;
  margin-top: 8px;
  align-items: center;
  /* border: 1px solid #414a55; */
  border-radius: 5px;
  max-width: 90vw;
  min-width: 90vw;
  display: flex;
  justify-content: space-between;
  background-color: white;
  /* border: rgba(172, 195, 225, 1) 1px solid; */
  border: 1px solid #deedff;
  /* border-bottom: 2px solid #deedff; */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
}

.mobChatPreviewProfilePicImg {
  max-width: 46px;
  min-width: 46px;
  max-height: 46px;
  min-height: 46px;
  object-fit: cover;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 3px;
  /* border: 1px solid #2d5a8f; */
}

.mobChatPreviewUnitRight {
  color: #140351;
  text-align: left;
  min-width: calc(100% - 50px);
  padding-left: 10px;
  font-weight: 500;
}

.unreadMobChatNameStrip {
  color: #00ccff;
}
.mobChatPreviewUnitRightPreview {
  font-size: 14px;
  font-weight: 400;
  color: #496375;
}

.unreadMobChatUnit {
  border: 2px solid #143051;
  background-color: #ffffff;
}

.unreadMobChatPicBorder {
  border: 2px solid #143051;
}

.chatHeadMobCrossImg {
  cursor: pointer;
  margin-left: -65px;
  margin-right: 52px;
  margin-bottom: 38px;
}

.mobChatThreadHeader {
  min-height: 50px;
  /* background-color: blue; */
  border-bottom: 1px solid #e6eaee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
}

.mobChatThreadNameTitle {
  color: #214978;
  margin-left: 10px;
  font-weight: 500;
  font-size: 18px;
  margin-top: 2px;
  text-align: left;
  /* font-size:14px; */
}

.mobChatHeaderThreadLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lastActiveTxtMob {
  color: #516b8b;
  font-weight: 400;
  font-size: 12px;
  /* padding-top: 2px; */
  /* padding-right: 11px; */
  text-align: right;
  min-width: fit-content;
  line-height: 13px;
}

.lastActiveTxtDesktop {
  color: #8ea7c5;
  font-weight: 400;
  font-size: 12px;
  margin-top: -64px;
  margin-bottom: 38px;
  margin-left: 10px;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 5px;
  border: 1px solid #e0ebf8;
}

.whiteGoBackMsgsImg {
  background-color: #214978;
  border-radius: 5px;
  /* min-width: 30px; */
  min-height: 30px;
  max-height: 30px;
  max-width: 18px;
  margin-left: 4px;
  margin-right: 2px;
  /* border: 1px solid #143051; */
}

.mobMessagesHeader {
  /* background-color: yellow; */
  min-height: 54px;
  min-width: 100vw;
  max-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.mobMessagesRefresh {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  margin-right: 5%;
  margin-top: 5px;
}

.desktopNavBarHeading {
  color: #a8b6ca;
  font-size: 12px;
  text-align: left;
  padding-left: 50px;
  margin-bottom: 7px;
  font-weight: 500;
}

.desktopNavItem {
  display: flex;
  min-width: 75%;
  justify-content: center;
  max-width: 75%;
  margin-left: 14%;
  border-radius: 5px;
  color: #140351;
}

.desktopNavItem:hover {
  background-color: #f5f9ff;
  color: black;
}

.desktopNavIcon {
  max-width: 15px;
  margin-left: 5px;
}

.desktopNavIconRota {
  display: none;
}

.desktopItemSelected {
  background-color: #ebf0f7;
  border-color: white !important;
  border-left: 3px solid #0077ff !important;
  border: 1px solid black;
  /* border:none !important */
}

.desktopItemSelectedMgrBorder {
  border-left: 3px solid #4b1caf !important;
}

.desktopItemSelectedAdminBorder {
  border-left: 3px solid #516b8b !important;
}

.desktopItemSelected:hover {
  background-color: #ebf0f7;
}

.navBtnBlue {
  color: #0077ff;
}

.navBtnPink {
  color: #9264f3;
}

.navBtnPurple {
  color: #664ca0;
}

.navBtnLightGrey {
  color: #8ea7c5;
}

.navBtnDarkGrey {
  color: #516b8b;
}

.desktopNavAdmin {
  margin-bottom: 30px;
}

.updateLoading {
  opacity: 0.2;
  pointer-events: none;
}

.clockedOnLightSpan {
  background-color: #82c26a;
  min-width: 4px;
  min-height: 23px;
  max-width: 6px;
  max-height: 6px;
  border-radius: 8px;
  margin-right: 6px;
  margin-left: -11px;
  margin-top: 1px;
  animation: blinkLiveClockBtn 1s linear infinite;
}

.submitDesktopBtn {
  font-weight: 500;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  max-height: 38px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  max-width: fit-content;
  cursor: pointer;
  color: #2d5a8f;
  /* margin-left: 40px; */
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;
}

.submitDesktopBtn:hover {
  background-color: #e7f1fb;
}

.deviceInfo {
  background-color: black;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  min-height: 20px;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  opacity: 0.6;
  border-radius: 0 0 0 5px;
  display: none;
}

.scheduledFixContainer {
  /* background-color: blue; */
  min-height: 110px;
  padding-top: 16px;
}

.installModalContentBox {
  /* background-color: blue; */
  /* min-height: calc(100vh - 70px); */
  /* max-height: calc(100vh - 70px); */
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* display: flex;   */
  /* flex-direction: row; */
}

.installModalContentBoxAndroid {
  min-height: 188px;
}

.dockImg {
  margin-top: 20px;
  max-width: 150px;
  margin-bottom: 10px; /* display: none; */
}

.dockInstructDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 322px;
  margin-bottom: 15px;
  /* background-color: blue; */
  padding-top: 2px;
  padding-bottom: 2px;
  
  /* margin-left: -20px; */
}

.dockInstructTxt {
  font-size: 14px;
  color: #214978;
  font-weight: 400;
  display: flex;
  min-height: 20px;
  align-items: center;
}
.addToHSiosImg {
  max-width: 22px;
  margin-left: 8px;
  margin-right: 8px;
  background: white;
  min-width: 30px;
  padding: 6px;
  max-height: 30px;
  border-radius: 4px;
  border: 1px solid #e7e5ed;
}

.addToHSChromeImg {
  max-width: 22px;
  margin-top: -12px;
}

.chromeJustTap {
  margin-top: -16px;
  margin-left: 1px;
}

.addToHSspan {
  font-weight: 500;
}

.lookForTheLogo {
  max-width: 24px;
  max-height: 24px;
  min-height: 24px;
  min-width: 24px;
  padding: 4px;
  background-color: #012754;
  border-radius: 5px;
}
.inSafari {
  margin-top: 24px;
  margin-bottom: 2px;
  text-align: left;
  min-width: 100%;
  padding-left: 20px;
  font-weight: 500;
  margin-bottom: 13px;
  min-width: 300px;
  /* background-color:   yellow; */
  margin-left: -80px;
}

.chromeInstructWidth {
  max-width: 329px;
  min-width: 329px;
  margin-top: 40px;
  margin-left: 10px;
}

.orAths {
  margin-top: 2px;
  font-size: 12px;
  opacity: 0.6;
}

.installOrATHSdiv {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 50px;
  margin-top: 8px;
}

.autoInstallAndroidBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
}

.autoInstallAndroidBtn:hover {
  background-color: #0af;
}

.installModalFooter {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  min-height: 50px;
  /* padding-right: 20px;
  padding-left: 30px; */
  margin-top: 30px;
  border-top: 1px #e3e8ee solid;
  padding-top:40px;
}

.deviceTogglerContainer {
  min-height: 50px;
  max-height: 50px;
  min-width: 120px;
  max-width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  margin-top: 20px;
}

.mobInstallModal {
  position: fixed;
  /* bottom:  0px; */
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 360px !important;
  min-height: 360px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobSwapModal {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 15px  15px 0 0  !important;
  max-height: 531px !important;
  min-height: 531px !important;
  /* border-radius: 5px 5px 0 0 !important; */
}

.mobSwapModalIos {
  max-height: 551px !important;
  min-height: 551px !important;
}

.nonSelectMobSwapModal {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 324px !important;
  min-height: 324px !important;
  /* border-radius: 5px 5px 0 0 !important; */
}

.nonSelectMobSwapModalIos {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 344px !important;
  min-height: 344px !important;
  /* border-radius: 5px 5px 0 0 !important; */
}

.selectedShiftMobSwapModal {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  /* border-radius: 15px 15px 0 0; */
  max-height: 351px !important;
  min-height: 351px !important;
  border-radius: 15px 15px 0 0 !important;
}

.selectedShiftMobSwapModalIos {
  max-height: 371px !important;
  min-height: 371px !important;
}
.deviceToggler {
  font-weight: 500;
  color: #bac5d3;
  min-height: 30px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.deviceToggler:hover {
  color: #143051;
}

.deviceTogglerActive {
  background-color: #214978;
  color: white;
  min-height: 30px;
  border-radius: 5px;
  border: 1px solid #143051;
}

.deviceTogglerActive:hover {
  color: white;
}

.saveClockCardDateBtn {
  background-color: #07f;
  max-height: 30px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  min-height: 35px;
  /* margin-bottom: -2px; */
  cursor: pointer;
  min-width: 60px;
  margin-top: 20px;
}

.editClockDateInput {
  margin-top: 9px !important;
}
.schedClockDay {
  /* background-color: #8997a8; */
  /* border: 1px #5b728d solid; */
  /* color: white; */
  max-height: 30px;
  display: flex;
  align-items: center;
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  /* border-radius: 5px; */
  margin-top: 12px;
  margin-left: 0px;
  max-width: fit-content;
  margin-bottom: -8px;
  font-size: 14px;
  /* padding-top:1px; */
  color: #143051;
  font-size: 18px;
  /* background-color: #f4f8fe; */
  /* border: 1px solid #e6e6e6; */
}

.onYourMob {
  min-width: 329px;
  text-align: left;
  padding-left: 4px;
  margin-top: 20px;
  margin-bottom: -28px;
  color: #214978;
  font-size: 15px;
  min-height: 30px;
  line-height: 30px;
}

.onYourAndroidSpan {
  border-radius: 5px;
  margin-bottom: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 500;
  margin-left: 2px;
}

.desktopLeftShield {
  min-width: 11%;
  min-height: calc(100vh - 30px);
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 1450px) {
  .desktopLeftShield {
    min-width: 16%;
  }
}

@media only screen and (min-width: 1675px) {
  .desktopLeftShield {
    min-width: 20%;
  }
}

@media only screen and (min-width: 1865px) {
  .desktopLeftShield {
    min-width: 25%;
  }
}

@media only screen and (min-width: 2280px) {
  .desktopLeftShield {
    min-width: 30%;
  }
}

@media only screen and (min-width: 2800px) {
  .desktopLeftShield {
    min-width: 36%;
  }
}

.desktopLeftGreyBar {
  min-width: 100%;
  min-height: 58px;
  background-color: #ebf0f7;
  border-bottom: 2px solid #e6eaee;
  border-top: 2px solid #e6eaee;
  margin-top: 115px;
}

.desktopLeftGreyForNonClockOn {
  background-color: white;
  border-bottom: none;
  border-top: 1px solid #f1f5fa;
}

.desktopRightShield {
  min-height: 89px;
  min-width: 25px;
  background-color: white;
  position: fixed;
  top: 28px;
  right: 0px;
  border-bottom: 2px solid #e6eaee;
  border-top: 2px solid #e6eaee;
}

.desktopRightShieldAdmin {
  min-height: 89px;
}

@media only screen and (max-width: 1140px) {
  .desktopRightShield {
    display: none;
  }
}
@media only screen and (min-width: 1170px) {
  .desktopRightShield {
    min-width: 36px;
  }
}
@media only screen and (min-width: 1205px) {
  .desktopRightShield {
    min-width: 45px;
  }
}

@media only screen and (min-width: 1230px) {
  .desktopRightShield {
    min-width: 60px;
  }
}
@media only screen and (min-width: 1255px) {
  .desktopRightShield {
    min-width: 6vw;
  }
}

@media only screen and (min-width: 1292px) {
  .desktopRightShield {
    min-width: 7vw;
  }
}

@media only screen and (min-width: 1326px) {
  .desktopRightShield {
    min-width: 8vw;
  }
}

@media only screen and (min-width: 1357px) {
  .desktopRightShield {
    min-width: 9vw;
  }
}

@media only screen and (min-width: 1390px) {
  .desktopRightShield {
    min-width: 10vw;
  }
}

@media only screen and (min-width: 1424px) {
  .desktopRightShield {
    min-width: 11vw;
  }
}

@media only screen and (min-width: 1460px) {
  .desktopRightShield {
    min-width: 12vw;
  }
}

@media only screen and (min-width: 1500px) {
  .desktopRightShield {
    min-width: 13vw;
  }
}

@media only screen and (min-width: 1542px) {
  .desktopRightShield {
    min-width: 14vw;
  }
}

@media only screen and (min-width: 1585px) {
  .desktopRightShield {
    min-width: 15vw;
  }
}

@media only screen and (min-width: 1625px) {
  .desktopRightShield {
    min-width: 16vw;
  }
}

@media only screen and (min-width: 1670px) {
  .desktopRightShield {
    min-width: 17vw;
  }
}

@media only screen and (min-width: 1730px) {
  .desktopRightShield {
    min-width: 320px;
  }
}

@media only screen and (min-width: 1780px) {
  .desktopRightShield {
    min-width: 340px;
  }
}

@media only screen and (min-width: 1820px) {




  .desktopRightShield {
    min-width: 360px;
  }


}

@media only screen and (min-width: 1850px) {
  .desktopRightShield {
    min-width: 380px;
  }
}

@media only screen and (min-width: 1890px) {
  .desktopRightShield {
    min-width: 400px;
  }
}

@media only screen and (min-width: 1938px) {
  .desktopRightShield {
    min-width: 420px;
  }
}

@media only screen and (min-width: 1970px) {
  .desktopRightShield {
    min-width: 440px;
  }
}

@media only screen and (min-width: 2019px) {
  .desktopRightShield {
    min-width: 460px;
  }
}

@media only screen and (min-width: 2056px) {
  .desktopRightShield {
    min-width: 480px;
  }
}

@media only screen and (min-width: 2096px) {
  .desktopRightShield {
    min-width: 500px;
  }
}

@media only screen and (min-width: 2134px) {
  .desktopRightShield {
    min-width: 520px;
  }

  
} 

@media only screen and (min-width: 2179px) {
  .desktopRightShield {
    min-width: 540px;
  }
}

@media only screen and (min-width: 2218px) {
  .desktopRightShield {
    min-width: 560px;
  }
}

@media only screen and (min-width: 2259px) {
  .desktopRightShield {
    min-width: 580px;
  }
}

@media only screen and (min-width: 2300px) {
  .desktopRightShield {
    min-width: 600px;
  }
}

@media only screen and (min-width: 2336px) {
  .desktopRightShield {
    min-width: 620px;
  }
}

@media only screen and (min-width: 2378px) {
  .desktopRightShield {
    min-width: 640px;
  }
}

@media only screen and (min-width: 2418px) {
  .desktopRightShield {
    min-width: 660px;
  }
}

@media only screen and (min-width: 2460px) {
  .desktopRightShield {
    min-width: 680px;
  }
}

@media only screen and (min-width: 2497px) {
  .desktopRightShield {
    min-width: 700px;
  }
}

@media only screen and (min-width: 2538px) {
  .desktopRightShield {
    min-width: 720px;
  }
}

@media only screen and (min-width: 2580px) {
  .desktopRightShield {
    min-width: 740px;
  }
}

@media only screen and (min-width: 2614px) {
  .desktopRightShield {
    display: none;
  }
}

.mobSideBtns {
  /* z-index: 999999999999 */
}

.mobMessagesModalBox {
  /* background-color: white;
  max-height:80% !important;
  min-height:80% !important;
  max-width: 100vw !important;
  min-width: 100vw !important;
  position: fixed;
  bottom: 0; left: 0; */

  position: fixed;
  bottom: 0px;
  left: 0;
  min-width: 100vw !important;
  max-width: 100vw !important;
  /* border-radius: 5px 5px 0 0; */

  /* max-height:590px !important;
  min-height:590px !important; */
  /* border-radius: 5px 5px 0 0 !important; */
  background-color: #f4f8fe;
  z-index: 9999999 !important;
  /* box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%); */
}

.mobChatMsgFooter {
  /* background-color: orange; */
  /* position: fixed; */
  /* bottom: 0px; */
  min-width: 100%;
  min-height: 50px;
  border-top: 1px solid #bac5d3;
}

.unclockedShiftTilTxt {
  color: #516b8b;
  font-size: 9px;
  text-align: left;
  margin-left: 15px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  /* padding-top: 2px; */
  /* min-height: 30px; */
  /* padding-bottom: 2px; */
  background-color: #8ea7c5;
  /* line-height: 15px; */
  max-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  /* border: 1px solid #7f93ac; */
  margin-top: 4px;
}

.downMsgModalImg {
  cursor: pointer;
  padding: 5px;
}

.mobChatHeaderThreadRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.refreshMobMsgBtnImg {
  /* background-color: #214978; */
  max-width: 30px;
  min-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;
  /* border: 1px solid #143051; */
  cursor: pointer;
  background-color: #0077ff;
  border: 1px solid #0077aa;
}

.refreshMobMsgBtnImg:hover {
  background-color: #0af;
}

.opac06 {
  opacity: 0.6;
}

.msgImgUserSchedImg {
  max-width: 12px;
  margin-right: 7px;
  margin-left: 2px;
}

.mobNavTopLeftDiv {
  display: flex;
  position: fixed;
  top: 0px;
  oveflow:visible;
  left: -6px;
}

.mobNavMsgIcon {
  min-width: 36px;
  margin-left: 7px;
  padding: 6px;
  padding-right: 6px;
  margin-right: 3px;
}

.mobNavMsgIcon:first-child {
  min-width: 48px;
  margin-top: -4px;
  margin-right: -1px;
  padding: 15px;
}

.mobMsgQtyBlob {
  background-color: #ef5353;
  border: 1px solid #ba0000;
  color: white;
  font-weight: 500;
  min-width: 17px;
  min-height: 17px;
  border-radius: 3px;
  max-width: 17px;
  max-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding-bottom: 1px;
  position: fixed;
  top: 9px;
  left: 69px;
}

.unclockParent {
  padding-bottom: 23px;
}

.navBarQty {
  color: white;
  border: 1px solid #9119ca;
  min-width: 18px;
  margin-left: -38px;
  max-width: 18px;
  border-radius: 4px;
  max-height: 18px;
  min-height: 18px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding-bottom: 2px;
  background-color: #a387df;
  font-size: 10px;
  margin-left: -24px;
  border: 1px solid #896ec3;
}

.blueNavBarQty {
  border-color: #0af;
  background-color: #0af;
}

.fadeStaffRotaLink {
  opacity: 0.5;
}

.mobHelpIcon {
  min-width: 22px;
  max-width: 22px;
  margin-left: 18px;
  /* padding-top:2px; */
  /* opacity: 0.7; */
}

.unreadMsgName {
  font-weight: 500;
}

.staffRotaNavBtn {
  border-bottom: 2px solid #f1d0f6;
  border-top: 2px solid #f1d0f6;
  cursor: pointer;
  border-radius: 0px;
  /* background-color: #f7f9ff !important; */
}

.staffRotaNavBtnMy {
  border-bottom: 2px solid #9bcdff;
  border-top: 2px solid #9bcdff;
}

.borderRadius5 {
  border-radius: 5px !important;
}

.borderTopWhite {
  border-top: 1px solid white;
}

.borderBottomWhite {
  border-bottom: 1px solid white;
}

.liveClockSecs {
  font-size: 12px;
  margin-top: -24px;
  margin-left: -9px;
  min-width: 34px;
  margin-right: -24px;
  opacity: 0.5;
}

.liveClockSecsInline {
  font-size: 12px;
  margin-top: 14px;
  margin-left: -9px;
  min-width: 34px;
  margin-right: -24px;
}

.statsIconFloat {
  position: fixed;
  top: 13px;
  left: 184px;
  max-width: 24px;
  z-index: 9999;
}
.gpsLoadingClockOnModalImg {
  /* background-color: blue; */
  max-width: 40px;
  max-height: 12px;
  margin-top: 3px;
  margin-left: 3px;
}

.gps1only {
  opacity: 0.5;
  max-height: 20px;
}

.clockGPSBar {
  /* background-color: yellow; */
  display: flex;
}

.gpsIcon {
  max-width: 30px;
}

.gpsLoadedTxt {
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
  color: #a8cc9a;
  text-align: right;
  min-height: 17px;
  display: flex;
  align-items: center;
}

.gpsConfDiv {
  text-align: left;
  min-width: 186px
}

.gpsLoadedSubTxt {
  color: #143051;
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
  float: right;
}

.gpsLoadedSubTxtAcc {
  color: #143051;
  font-weight: 500;
  font-size: 10px;
  opacity: 0.6;
  margin-left: 4px;
  border-left: 1px solid #bbcada;
  padding-left: 6px;
}

.gpsLoadedSubTxtDist {
  color: #bac5d3;
  font-weight: 500;
  font-size: 10px;
  /* opacity: 0.6; */
  margin-left: 0px;
  /* border-left: 1px solid #143051; */
  padding-left: 6px;
}

.gpsLoading {
  font-weight: 500;
  font-size: 12px;
  color: #0077bb;
  margin-top: 2px;
  margin-left: 8px;
  margin-right: 4px;
  opacity: 1;
  min-width: 186px;
  display: flex;
  justify-content: flex-end;
  animation: Pulsate 1s linear infinite;
}

.deniedFailedTxt {
  font-weight: 500;
  font-size: 12px;
  color: #ba0000;
  margin-top: 2px;
  margin-left: 8px;
  margin-right: 4px;
  opacity: 1;
  /* animation: Pulsate 1s linear infinite; */
  min-height: 20px;
  padding-top: 2px;
}

@keyframes Pulsate {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.clockOnStatusDiv {
  font-weight: 500;
  text-align: right;
  background-color: #214978;
  border: 1px solid #143051;
  border-radius: 5px;

  padding: 3px 8px 3px 8px;
  color: white;
}

.clockOnStatusTitle {
  font-size: 14px;
  font-weight: 500;
  color: #143051;
  /* opacity: 0.6; */
}

.clockOnStatusValue {
  font-size: 12px;
  margin-top: 2px;
}

.clockStatusBox {
  font-weight: 500;
  text-align: left;
  background-color: #f4f8fe;
  border: 3px solid #cbd7e6;
  border-radius: 5px;
  padding: 3px 8px 3px 8px;
  color: #516b8b;
  margin-top: 8px;
}

.workingStatus {
  background-color: #ade098;
  border: 3px solid #5a9e3f;
  color: #5a914c;
}

.gpsLoadingDisableClockOnBtn {
  opacity: 0.2;
  pointer-events: none;
}

.awaitGPStxt {
  min-height: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #214978;
  min-width: 100%;
  justify-content: center;
}

.deniedGPSdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 3px;
  margin-top: 14px;
}

.deniedGPStxt {
  font-weight: 500;
  color: #0af;
  text-align: left;
  max-width: 60%;
  font-size: 12px;
}

.ignoreGPSdenied {
  background-color: #07f;
  border: #0469de 1px solid;
  border-radius: 5px;
  min-height: 35px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.ignoreGPSdenied:hover {
  background-color: #0469de;
}

.gpsIgnored {
  font-weight: 500;
  color: #bbcada;
  margin-left: 10px;
  font-size: 12px;
}

.gpsIgnoredTopRight {
  font-weight: 500;
  color: #bbcada;
  /* margin-left: -10px; */
  font-size: 12px;
  margin-right:10px
}

.topRightGPS {
  text-align: right;
  /* background-color: blue; */
  display: flex;
  max-height: 40px;
  max-width: 200px;
  float: right;
}

.topRightGPSicon {
  max-width: 18px;
  margin-top: 0px;
  margin-right: 2px;
  margin-bottom: -1px;
}

.gpsBreakDiv {
  background-color: yellow;
  min-width: 160px;
  border: 1px solid #143051;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.clockOnGPSbox {
  background-color: black;
  min-height: 120px;
}

.clockGPSrow {
  flex-direction: column;
  /* background-color: blue; */
  /* max-width:100%; */
}

.clockedRowGps {
  /* display: flex; */
  margin-top: 14px;
  margin-bottom: 8px;
  min-height: 120px;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  background-color: #f4f8fe;
  /* width: 200px; */
  /* height:200px; */
}

.clockRowGpsRow {
  display: flex;
  min-height: 66px;
  align-items: center;
  min-width: 100%;
  border-bottom: 1px solid #e0ebf8;
  padding-bottom: 5px;
  padding-right: 5px;
}

.clockRowGpsRowLeft {
  /* background-color: blue; */
  width: 73%;
  font-weight: 500;
  color: #143051;
  padding-left: 10px;
}

.clockRowGpsRowRight {
  /* background-color: yellow; */
  width: 27%;
}

.viewMapBtn {
  display: flex;
  font-weight: 500;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
  color: white !important;
  border-radius: 5px;
  font-size: 14px;
  justify-content: center;
  max-width: 76px;
  max-height: 35px;
  align-items: center;
}

.viewMapBtn:hover {
  background-color: #bbcada;
}
.viewInMapsPopUpImg {
  max-width: 18px;
  margin-left: 6px;
}

.clockedOnZoneTxtTitle {
  font-size: 12px;
  line-height: 16px;
  color: #214978;
}

.clockedOnZoneTxt {
  font-size: 12px;
  color: #143051;
  line-height: 14px;
  margin-top: 5px;
  padding-right: 10px;
}

.clockedOnZoneTxtAcc {
  font-size: 10px;
  color: #bbcada;
  line-height: 14px;
  margin-top: 2px;
  padding-right: 10px;
}

.clockGPSRowImgAndTitle {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.gpsRowGpsIcon {
  max-width: 20px;
  margin-right: 4px;
}

.gpsAwayFromSpan {
  opacity: 0.5;
}

.clockedOnZoneTxtTitleUnknown {
  font-size: 12px;
  line-height: 16px;
  color: #214978;
  margin-top: 9px;
  margin-left: 4px;
}

.coordSize {
  font-size: 14px;
}

.xSmallCoords {
  font-size: 9px;
}

.clockedGPSheaderRow {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clockGPSToggleBrksBtn {
  max-height: 30px;
  color: white;
  border: solid 1px #143051;
  background-color: #214978;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 5px;
  cursor: pointer;
  margin-top: 12px;
  /* margin-bottom:-4px; */
}

.clockGPSToggleBrksBtn:hover {
  background-color: #143051;
}

.showBrkGpsCheckboxImg {
  /* max-width:15px */
  max-width: 96%;
  padding: 2px;
  padding-top: 3px;
  /* padding-left:2px; */
  /* padding-bottom:12px; */
  /* margin-top:-12px; */
}
.showBrkCheckBoxDiv {
  border-radius: 3px;
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  max-height: 14px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 3px;
  margin-top: -1px;
}

.showBrkOpac {
  opacity: 0.5;
}

.breakGpsRowAni {
  display: flex;
  align-items: center;
  min-width: 100%;
  border-bottom: 1px solid #e0ebf8;
  padding-bottom: 5px;
  padding-right: 5px;
  background-color: white;
  animation-name: breakGPSrowAnimation !important;
  animation-duration: 0.5s !important;
}

@keyframes breakGPSrowAnimation {
  from {
    height: 0px;
  }
  to {
    height: 70px;
  }
}

.awaitingGPSAlreadyClockedOn {
  min-height: 60px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #214978;
  min-width: 100%;
  justify-content: center;
  padding-top: 0px;
  margin-top: -30px;
}
.x2831421 {
  margin-top: -30px;
}

.x98349724 {
  margin-top: -16px;
}

.x1398471 {
  margin-top: -30px;
}

.x324567 {
  margin-top: -17px;
}
.awaitingGPSAlreadyClockedOnMob {
  padding-top: 10px;
}

.x3719741 {
  margin-top: -33px;
}

.x9173811 {
  margin-top: -30px;
}

.closeGPSsub {
  max-width: 9px;
  margin-left: 6px;
}

.activeClockGPSbox {
    /* min-height: 142px; */
    min-width: 82%;
    max-width: 82%;
    margin-left: 12%;
    background-color: #ffffff;
    color: white;
    border-radius: 0 0 5px 5px;
    border: solid #143051 2px;
    animation-name: slideUnclockMenuDownSched;
    animation-duration: 0.1s;
    /* display: flex; */
    /* padding-top: 8px; */
    padding-bottom: 24px;
    margin-bottom: 16px;
    /* border-left: #bbcada 1px solid; */
    /* border-right: #bbcada 1px solid; */
    border-top: none;
    padding-top:20px;
    padding-left:10px;
}

.cTimelineRowBrkBorder {
  border-color:#bbcada !important;
}

.activeClockGPSrow {
  background-color: #6481a6;
  max-width: 88%;
  margin-left: 8%;
  border-radius: 0 0 4px 4px;
  min-height: 40px;
  /* margin-top: -1px; */
  /* border: 1px solid #0d2644; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -28px;
  padding-top: 1px;
  position: relative;
  margin-bottom: 0px;
}

.acitveClockGPScol2 {
  width: 100%;
  /* background-color:  green; */
  min-height: 40px;
}

.activeClockGPSUpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #627f9e;
  max-width: 94%;
  margin-left: 2.5%;
  padding-top: 0px;
  font-weight: 500;
  font-size: 13px;
  min-height: 14px;
  max-height: 14px;
  margin-top: 3px;
}
.activeClockGPSLower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #627f9e; */
  max-width: 95%;
  margin-left: 2.5%;
  padding-bottom: 0px;
  font-weight: 500;
  font-size: 12px;
  min-height: 33px;
  max-height: 33px;
}

.activeClockGPSrowGPSimg {
  max-width: 16px;
  /* margin-top:4px; */
  margin-right: 4px;
}

.gpsAccSmallTxt {
  font-size: 10px;
  opacity: 0.5;
}
.gpsClockedStatus {
  /* background-color: #193752; */
  /* border-radius: 3px; */
  /* padding: 2px 6px; */
  margin-top: 1px;
  max-height: 20px;
  display: flex;
  align-items: center;
  user-select: text;
  cursor: text;
}

.gpsClockedItemStatus {
  background-color: #0077ff;
  border: 1px solid #0050ab;
  border-radius: 3px;
  padding: 2px 6px;
  margin-top: 1px;
  max-height: 20px;
  display: flex;
  align-items: center;
  min-width: fit-content;
  user-select: text;
  cursor: pointer;
}

.gpsClockedItemStatus:hover {
  opacity: 0.8 !important;
}

.clockedDataTimeRow {
  width: 94%;
  margin-left: 3%;
  border-radius: 4px;
  min-height: 62px;
  margin-top: 53px;
  /* border: 1px solid #0d2644; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* align-items: center;
  justify-content: space-between; */
  color: #0af;
  border: 1px solid #bfccdc;
  background-color: #f1f7ff;
  border-left: 7px solid #4578b5;
}

.clockedDataTimeRow:first-of-type {
  margin-top: 20px;
}

.clockedDataTimeRowL {
  width: 50%;
  font-weight: 500;
  /* padding-left: 8px; */
  font-size: 12px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-start; */
  margin-top: 6px;
  text-align: left;
  margin-left: 12px;
}

.clockedDataTimeRowR {
  width: -moz-fit-content;
  width: fit-content;
  /* background-color: black; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* opacity: 0.7; */
  font-weight: 500;
  /* padding-right: 8px; */
  color: #0af;
  margin-top: 1px;
  font-size: 16px;
  margin-left: 12px;
}

.activeclockGPSUpperLeft {
  opacity: 0.8;
  font-size: 10px;
}

.locRecordedDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activeClockGPSmapBtn {
  background-color: #0af;
  padding-left: 6px;
  padding-right: 1px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  border: 1px solid #0373ab;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  max-height: 28px;
  display: flex;
  align-items: center;
  min-width: 49px;
  font-weight: 600;
  min-height: 25px;
  min-width: 60px;
  margin-top: -2px;
  justify-content: space-around;
  padding-right: 5px;
}

.gpsMapPopUpIcon {
  max-width: 13px;
  margin-left: 2px;
  opacity: 1;
}


.activeclockGPSUpperRight {
  font-size: 10px;
  opacity: 0.4;
  margin-top:-2px;
  /* margin-left: 4px; */
}

.activeClockNoGPSdiv {
  /* background-color: #6481a6; */
  max-width: fit-content;
  float: right;
  margin-right: 20px;
  min-height: 35px;
  max-height: 35px;

  border-radius: 0px 0px 4px 4px;
  /* border: #143051 1px solid; */
  /* border-top: none; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #859dbb;
  border: 1px solid #bfccdc;
  background-color: #f5f5f5;
  border-top: none;

  font-size: 12px;
  padding-right: 9px;
  padding-left: 9px;
}

.clockItemGPSscrollBox {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
  min-width: 100%;
  /* min-width: calc(100% - 20px); */
  color: #143041;
  border-radius: 5px;
  border-top: solid #143051 2px;
  /* animation-name: slideUnclockMenuDownSched; */
  /* animation-duration: 0.1s; */
  /* display: flex; */
  margin-top: 6px;
  padding-bottom: 44px;
  margin-bottom: 10px;

  /* padding-left: 10px; */
  /* padding-right: 10px; */

  background-color: #fff;
  max-width: 90%;
  margin: 0 auto;
  /* min-height: 300px; */
  /* margin-top: -50px; */
  /* z-index: -1; */
  /* padding-top: 40px; */
  border-radius: 5px 5px 0 0;
  border-top: solid 2px #e6eaee;
  border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  border-bottom: 1px solid #bac5d3;
  padding-top: 10px;
}

.clockActiveDivider {
  min-width: 90%;
  min-height: 1px;
  background-color: #516b8b;
  margin-top: 51px;
  margin-bottom: -30px;
  margin-left: 5%;
  max-width: 90%;
  display: none;
}

.clockActiveDividerTxt {
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  /* border-radius: 3px; */
  /* padding: 2px 7px; */
  /* border: 1px solid #cbd7e5; */
  /* margin: 20px 10px; */
  /* margin-bottom: 20px; */
  /* min-width: 140px; */
  max-height: 70px;
  display: flex;
  align-items: center;
  color: #143051;
  margin-left: 5%;
  /* margin-top: 20px; */
  border-left: 4px solid #00aaff;
  min-width: 100%;
  min-height: 70px;
  margin-bottom: -5px;
  padding-left: 30px;
  overflow: visible
}
.clockActDivPart {
  font-weight: 500;
  margin-right: 3px;
  background-color: white;
  display: flex;
  align-items: center;
  min-height:30px;
  margin-left:-10px
  /* opacity: 0.65; */
}

.clockActDivPartPeriod {
  margin-left:-40px
}

.clockActiveDividerTxtBrk {
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  /* border-radius: 3px; */
  /* padding: 2px 7px; */
  /* border: 1px solid #cbd7e5; */
  /* margin: 20px 10px; */
  /* margin-bottom: 20px; */
  /* min-width: 140px; */
  max-height: 70px;
  display: flex;
  align-items: center;
  color: #143051;
  margin-left: 5%;
  /* margin-top: 20px; */
  border-left: 4px solid #00aaff;
  min-width: 100%;
  min-height: 80px;
  margin-bottom: -5px;
  /* padding-left: 30px; */
  overflow: visible;
}

.clockedItemTimelineGPSrowBg {
  background-color: #e0ebf8;
  border: 1px solid #bbcada;
  border-top: none;
  color: #8ea7c5;
}

.colourGreyBlue {
  color: #143051;
}

.clockedItemBorderBottom {
  border-bottom: 1px solid #cbd7e6;
  margin-bottom: 2px !important;
}

.cancelClockCardModalBox {
  min-width: 280px;
  min-height: 180px;
  background-color: #f4f8fe;
  border-radius: 5px;
  z-index: 99999999;
  cursor: default;
  color: #143051;
  max-width: 270px;
  padding: 20px;
}

.cancelClockCardTxt {
  font-weight: 500;
  color: #143051;
  text-align: center;
  font-size: 14px;
}

.cancelClockCardModalBtnsDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  max-width: 170px;
  margin: 20px auto;
  margin-bottom: 0px;
}

.cancelClockCardBtns1 {
  color: white;
  font-weight: 500;
  background-color: #1e88e5;
  border: #0469de 1px solid;
  min-width: 70px;
  border-radius: 5px;
  min-height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.cancelClockCardBtns1:hover {
  background-color: #1374e4;
}

.cancelClockCardBtns2 {
  color: white;
  font-weight: 500;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  min-width: 70px;
  border-radius: 5px;
  min-height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.cancelClockCardBtns2:hover {
  background-color: #bbcada;
}

.greyColour {
  color: #516b8b !important;
}

.gpsSupportBtnTopRight {
  background-color: #bbcada;
  color: white !important;
  border: 1px solid #bac5d3;
  /* float: right; */
  border-radius: 5px;
  padding: 1px;
  padding-left: 5px;
  padding-right: 4px;
  margin-left: 8px;
}

.gpsSettingsSupportPopup {
  max-width: 9px;
  margin-left: 2px;
}

.gpsSupportBtnTopRight:hover {
  background-color: #bac5d3;
}

.selectedActiveClockUnit {
  border: 2px solid #214978;
  margin-top: 6px;
  /* padding-bottom: 2px; */
  padding-top: 3px;
  margin-bottom: 2px;
  padding-left: 11px;
  padding-right: 8px;
  min-height: 200px;
}

.selectedActiveClockUnitWithBreakSecs {
  min-height: 220px;

}

.clockOnDurSub {
  color: #516b8b;
  border-top: 1px solid #bbcada;
  padding-top: 4px;
  margin-top: -5px;
  font-size: 10px;
}

.shoulder {
  min-width: 30px;
  min-height: 30px;
  background-color: #fff;
  position: fixed;
  top: 123px;
  left: 0px;
  z-index: 9999;
}

.shoulderIn {
  border: 2px solid #e6eaee;
  min-width: 30px;
  min-height: 30px;
  border-radius: 25px 0 0 0;
  border-bottom: none;
  border-right: none;
  background-color: #ebf0f7;
}

.rightShoulder {
  left: auto;
  right: 0px;
  transform: scaleX(-1);
}

.shoulderCenter {
  min-width: calc(100vw - 54px);
  min-height: 2px;
  background-color: #e6eaee;
  /* background-color: blue; */
  position: fixed;
  top: 123px;
  left: 30px;
}

.shoulder {
  position: fixed;
  top: 190px;
  /* min-height:50px; */
  /* min-width: calc(100vw + 4px); */
  width: calc(100vw + 4px);
  max-height: 10px !important;
  margin-left: -2px;
  background-color: none;
  z-index: 1;
  /* background-color: blue; */
}

.rotaSizeFont {
  font-size: 14px !important;
  min-width: 112px !important;
  padding-left: 0px !important;
  margin-right: 5px;
}

.rotaNavItem {
  min-width: 131px;
  max-width: 131px;
  margin-left: 14px;
}

.rotaNavBarHeading {
  padding-left: 24px !important;
  font-size: 10px;
  margin-bottom: 5px;
}

.rotaDesktopHeaderHolder {
  min-width: 160px !important;
  max-width: 160px !important;
  left:0  !important
}

.rotaDesktopClockOnDiv {
  min-width: 162px !important;
  max-width: 162px !important;
}

.desktopHeaderClockOnBtnRota {
  font-size: 12px;
  max-width: 55px !important;
  min-width: 92px !important;
  padding-right: 8px;
}

.marginLeft10 {
  margin-left: 10px !important;
}
.minusAppToil {
  font-size: 12px;
  max-height: 50px;
  line-height: 14px;
  margin-top: 10px;
  font-weight: 500;
  color: #859dbb;
  border-right: 2px solid #0077ff;
  padding-right: 8px;
  margin-bottom: 10px;
}

.minusAppToilSpan {
  color: #0077ff;
}

.selectAllBtn {
  margin-right: 8px;
}

.getSupportFooterBtn {
  background-color: #0bf;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #0af;
  max-height: 24px;
  min-height: 24px;
  font-size: 12px;
  cursor: pointer;
  margin-right: 3px;
  margin-top: 0px;
  padding-left: 7px;
  padding-right: 7px;
  margin-left:19px;
  /* margin-left:440px; */
}

.getSupportFooterBtn:hover {
 background-color: #07b5f5; 
}

.desktopTerms {
  display: flex;
  align-items: center;
  /* margin-right: 168px; */
  /* margin-left: -66px; */
}

.desktopTermItem {
  font-size: 12px;
  color:#516b8b;
  
}


.termsLink {
  font-size: 12px;
  margin-left:20px;
}

@media only screen and (max-width: 1139px) {
 

  .getSupportFooterBtn {
    position: fixed;
    bottom: 3px;
    right: 1px;
    margin-left: 0px;
    max-height: 23px;
    min-height: 23px;
  }


}



.backContactBtnImg {
  width: 30px;
  position: fixed;
  top: 20px;
  left: 20px;
  /* background-color: blue; */
  cursor: pointer;
  padding-right: 10px;
}

.backContactBtnImg:hover {
  opacity: 0.8;
}

.vhIssue {
  padding-bottom: 150px !important;
}

.mgrReqsVHissueSideBoxHeight {
  min-height: 479px !important;
  max-height: 479px !important;
  /* max-height: calc(100vh - 565px) !important; */
}

.x178141 {
  /* min-height: 436px !important;
  max-height: 436px !important; */
  min-height: calc(100dvh - 297px) !important;
  max-height: calc(100dvh - 297px) !important;
}

.x49829752 {
  min-height: calc(100vh - 248px) !important;
  max-height: calc(100vh - 248px) !important;
}

.x49829752Ios {
  min-height: calc(100vh - 268px) !important;
  max-height: calc(100vh - 268px) !important;
}
.cusVh1 {
  max-height: calc(100vh - 620px) !important;
}

.cusVh2 {
  max-height: calc(100vh - 570px) !important;
}

.cusVh3 {
  max-height: calc(100vh - 670px) !important;
  max-height: calc(100vh - 6270px) !important;
  padding-bottom: 200px;
}

.cusVh4 {
  max-height: calc(100vh - 670px) !important;
  max-height: calc(100vh - 6270px) !important;
  padding-bottom: 240px;
}

.vhIssueModalMyAllows {
  /* max-height:800px !important; */
  /* min-height:800px !important; */
  margin-top: -50px !important;
  border-bottom: 1px solid #2b679f !important;
}

.vhIssueEditUser {
  /* background-color: blue !important; */
  min-height: 80vh !important;
  max-height: 80vh !important;
  min-width: 100vw !important;
  max-width: 100vw !important;
  bottom: 80px !important;
}

.viewPortHeight {
  background-color: blue;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
}

.closeUpcomingImg {
  min-width: 25px;
  padding: 5px;
  margin-top: 7px;
  margin-bottom: -7px;
  margin-right: 5px;
  cursor: pointer;
}

.addTeamModalBox {
  background-color: #ebf0f7;
  color: #143051;
  min-width: 340px;
  max-width: 340px;
  border-radius: 10px;
  padding-top: 0px;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0 auto;
  max-height: 200px;
  cursor: auto;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  border: 1px solid #e7ebef;
}

.addTeamModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
  min-height: 50px;
  max-width: 90%;
  margin-left: 5%;
}

.createTeamBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  max-height: 35px;
  background-color: #0bf;
  border: 1px solid #0af;
  border-radius: 5px;
  cursor: pointer;
}

.createTeamBtn:hover {
  background-color: #0af;
}

.installMobBtn {
  background-color: #6c00ff;
  position: fixed;
  /* bottom: 114px;
  right: 40px; */
  bottom: 101px;
  right: 35px;
  max-width: 120px;
  min-width: 120px;
  max-height: 50px;
  display: flex;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #523266;
  /* box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 40%); */
  z-index: 9999;
}

.mobInstallBtnImg {
  max-width: 20px;
  min-width: 20px;
  min-height: 1px;
  margin-right: 3px;
  animation: rotate 2s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.clockedOnF4Pulsate {
  background-color: #07f;
  border: #0469de 2px solid;
  animation: f4pulsate 2s infinite;
}

@keyframes f4pulsate {
  0% {
    /* opacity: 1 !important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
  50% {
    /* opacity: 0.5!important; */
    background-color: #5edea6;
    border: #23b843 2px solid;
  }
  100% {
    /* opacity: 1!important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
}

.workingPulsate {
  animation: workingPulstate 2s infinite;
}
@keyframes workingPulstate {
  0% {
    color: #5a914c;
  }
  50% {
    color: #8fc681;
  }
  100% {
    color: #5a914c;
  }
}

.breakPulsate {
  animation: breakPulsate 2s infinite;
}
@keyframes breakPulsate {
  0% {
    color: #859dbb;
  }
  50% {
    color: #0af;
  }
  100% {
    color: #859dbb;
  }
}

.clockedOnBrkF4Pulsate {
  background-color: #07f;
  border: #0469de 2px solid;
  animation: f4pulsateBrk 2s infinite;
}

@keyframes f4pulsateBrk {
  0% {
    /* opacity: 1 !important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
  50% {
    background-color: #00ccff;
    border: #0fa7cc 2px solid;
  }
  100% {
    /* opacity: 1!important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
}

.tapToInstallParent {
  color: white;
  font-weight: 500;
  text-align: left;
  margin-top: 0px;
  margin-left: 4px;
  min-height: 50px;
}

.tapToInstall {
  font-size: 12px;
  margin-top: -2px;
  opacity: 0.75;
  /* margin-bottom:-1px; */
}

.tapToInstall2 {
  font-size: 14px;
  margin-top: -2px;
}

.notAppStoreLink {
  color: #859dbb;
  max-width: 290px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-top: 15px;
  border-left: 3px solid #859dbb;
  padding-left: 10px;
}

.chromeInstallModalBox {
  /* background-color: orange !important; */
  min-height: 0px !important;
  padding-bottom: 3px;
  max-width: 170px;
  min-width: 380px !important;
}

.flexCenter {
  justify-content: center !important;
}

.x81811 {
  margin-left: -4px;
  margin-right: 8px;
}

.flexStart {
  display: flex;
  justify-content: flex-start !important;
}

.x0873 {
  min-width: 75px;
  text-align: right;}

.sendMsgArrImg {
  max-width: 14px;
  transform: rotate(-90deg);}

.mobMsgFooter {
  max-width: 90%;
  margin-left: 5%;
}

.activeClockBannerLeft {
  display: flex;
}

.activeClockBannerRight {
  background-color: #ffffff;
  min-width: fit-content;
  min-height: 30px;
  border-radius: 0 0 5px 5px;
  /* border-bottom: 1px solid #d5e2f0;
  border-left: 1px solid #d5e2f0;
  border-right: 1px solid #d5e2f0; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.clockedGpsStatusImg {
  max-width: 20px;
}

.lastClockedDistance {
  font-size: 11px;
  font-weight: 500;
  margin-left: 2px;
  margin-top: 0px;
  color: #143051;
  margin-right: 2px;
}

.x183911 {
  /* background-color: yellow; */
  margin-top: 16px;
}

.x88349829 {
  margin-top: 14px;
  /* margin-bottom:4px; */
}

.x3891813 {
  /* background-color: orange; */
  min-height: 40px;
  margin-top: -15px;
}

.installAppImg {
  max-width: 60px;
  position: fixed;
  bottom: 120px;
  right: 35px;
  cursor: pointer;
}

.installCloudImg {
  max-width: 90%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.dockInstructNum {
  font-weight: 500;
  margin-right: 15px;
}

.underlayInstall {
  background: rgba(0, 0, 0, 0.82);
  min-height: calc(100% - 58px);
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;

  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(2px);
  background-color: rgb(187 187 187 / 50%);
}

.addToHomeScreenBubble {
  max-width: fit-content;
  margin-left: 8px;
  margin-right: 8px;
  background: white;
  min-width: 30px;
  padding: 3px;
  min-height:35px;
  max-height: 35px;
  border-radius: 4px;
  border: 1px solid #e7e5ed;
  align-items: center;
  display: flex;
  padding-left:9px;
  padding-right:9px;
}

.addToHomeScreenTxt {
  font-size: 13px;
  font-weight: 500;
  min-width: fit-content;
  color: #143051;
}

.addToHomeScreenTxt2 {
  font-size: 13px;
  font-weight: 500;
  min-width: fit-content;
  color: #143051;
}

.aths {
  max-width: 16px;
  margin-left: 6px;
  margin-right: 2px;
}

.aths2 {
  margin-left: 0px;
  margin-right: 8px;
  min-width: 13px;
  max-width: 13px;

}

.fontSize16 {
  font-size: 16px !important;
}

.androidBub {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 9px;
}

.x3841941 {
  max-width: 330px;
  min-width: 100vw !important;
  min-height:100vh !important;
  border-radius: 0px;

}

.clockedOnGpsRow {
  font-size: 12px;
  text-align: left;
  max-width: 90%;
  margin-left: 5%;
  opacity: 0.7;
  font-weight: 500;
  border-left: 2px solid #bbcada;
  padding-left: 8px;
  padding-right: 6px;
  color: white;
  /* color: #98aabd */
}

.x2913081 {
  flex-direction: row !important;
}

.teamTagsBubble {
  background-color: #00a9ff !important;
  margin-top: 90px;
  border-color: #0097e4 !important;
  cursor: pointer;
}

.teamTagsBubble:hover {
  background-color: #05a2f3 !important;
}
/* 
        .viewTeamEditTagsPencil {
          max-width: 20px;
          margin-top: -49px;
          margin-bottom: 28px;
          margin-left: 37px;        } */

.defaultCursor {
  cursor: default !important;
}

.tagsGoBack {
  display: flex;
      align-items: center;
      background-color: white;
      border: 2px solid #bbcada;
      color: #143051;
      font-weight: 500;
      min-height: 30px;
      border-radius: 5px;
      padding-left: 6px;
      padding-right: 7px;
      font-size: 12px;
      cursor: pointer;
      margin-left: 14px;
      margin-bottom: 0px;
      padding-bottom: 1px;
}

.tagsGoBack:hover {
  border-color: #a4b3c3;
}
.tagsBackImg {
  max-width: 8px;
  margin-right: 4px;
  margin-left: -1px;
  margin-top: 0px;}

.teamTagIemX {
  border-radius: 5px;
  margin-top: 8px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 500;
  padding: 4px 9px;
  font-weight: 500;
  font-size: 14px;
  color: #4f566b;
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.removeTeamTagImgX {
  min-width: 19px;
  margin-left: 3px;
  cursor: pointer;
  padding: 5px;
  margin-right: -4px;
}

.removeTeamTagImgX:hover {
  opacity: 0.7;
}

.x234895372 {
  margin-bottom: 6px !important;
  margin-right: 10px !important;
}

.tagsGoImg {
  max-width: 5px;
  margin-top: -46px;
  margin-bottom: 26px;
  margin-left: 47px;
}

.addTagInlineImgTeam {
  max-width: 12px;
  margin-left: 6px;
}

.addTagToTeamBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: 100px;
  /* margin-left: 2%; */
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #07f;
  border: #0469de 1px solid;
  margin-top: 20px;
}

.addTagToTeamBtn:hover {
  background-color: #0469de;
}

.clockFooterLeft {
  display: flex;
  align-items: center;
}

.clockTagsBtn {
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.clockTagsBtn:hover {
  background-color: #849fbe;
}

.clockTagsIcon {
  max-width: 14px;
  margin-right: 4px;
}

.clockTagSelector {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 130px;
  min-width: 130px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #8ea7c5;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 106px;
  /* margin-top:10px; */
}

.clockTagSelector:hover {
  border-color: #d7dfe9;
}

.x13145361 {
  margin-right: 8px;
}

.noAppliedTags {
  font-size: 14px;
  color: #bbcada;
  font-weight: 500;
  text-align: center;
  min-width: 100%;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  /* display: flex; */
}

.moreInfoAvailTagsBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white !important;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 4px auto;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.moreInfoAvailTagsBtn:hover {
  background-color: #bbcada;
}

.moreInfoTagsAvailPopupImg {
  max-width: 12px;
  margin-left: 2px;
}

.clockItemTagsContainer {
  display: flex;
  flex-wrap: wrap;
}

.clockCardTagSel {
  margin-top: 10px;
}

.upcomingActiveClocksSplitter {
  min-height: 1px;
  max-height: 1px;
  min-width: 90%;
  background-color: #bbcada;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: none;
}

.upcomingActiveClocksTitle {
  text-align: left;
  font-weight: 500;
  color: #143051;
  font-size: 14px;
  /* margin-left: 2%; */
  padding-bottom: 3px !important;
  margin-top: 18px;
  /* border-left: 2px solid #214978; */
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-left: 7px;
  margin-bottom: 6px;
}

.currClockOnTitle {
  max-width: 340px;
  color: #143051;
  line-height: 18px;
  font-size: 14px;
  padding-right: 20px;
  padding-left: 9px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;
  font-weight: 500;
  padding-bottom: 8px;
}

.upcomingActiveItem {
  background-color: white;
  max-width: 95%;
  min-width: 95%;
  margin: 0 auto;
  display: flex;
  border:1px solid #d5e2f0;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  min-height: 92px;
  /* max-height: 92px; */
  margin-top: 8px;
  border-radius: 5px;
  cursor: pointer;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
  padding-right: 10px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  padding-left: 8px;
  margin-bottom: 6px;
}

.marginTop12 {
  margin-top: 12px;
}

.upcomingActiveItem:hover {
  /* box-shadow: 0px 0px 3px #143051; */
  border-color:#bbcada
}

.upcomingActiveItemLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 50%;
  /* background-color: yellow; */
  /* padding-top: 5px; */
  margin-top: -3px;
}

.upcStartStrip {
  font-size: 12px;
  color: #516b8b;
  font-weight: 400;
  text-align: left;
  padding-top: 5px;
  padding-left: 4px;
}

.upcNameTxt {
  color: #143051;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom:2px;
}

.dueToStartSpan {
  color: #516b8b;
  font-weight: 600;}

.clockRequiredStatus {
  color: #516b8b;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.upcomingActiveItemRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 50%;
  /* background-color: lime; */
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right:2px !important
}

.x9742152 {
  /* opacity: 0.4; */
}

.clockOnReqStopwatchImg {

  max-width: 12px;
  margin-left: 4px;
  margin-bottom: 0px;
  margin-top: 0px;
  
}

.actClocksTags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 10px;
}

.actClockTagItem {
  margin-top: 2px;
  border: 1px solid #dce7f3;
  color: #143051;
  font-size: 12px;
  margin-bottom: 0px;
  margin-left: 4px;
  text-align: right;
  padding: 2px;
  padding-left: 3px;
  padding-right: 5px;
  border-radius: 4px;
  font-weight: 500;
}

.highlightClockActiveItem {
  /* border-left: 2px dashed #143051; */
  border-left: 4px solid #ba0000;

  border-radius: 5px;
}

.highlightClockActiveWorkingNow {
  border-left: 4px dashed #7fbb67;
  border-radius: 0px 5px 5px 0px;
}

.highlightClockActiveWorkingNow2 {
  border-left: 4px solid #7fbb67;
  border-radius: 0px 5px 5px 0px;
}

.highlightClockActiveWorkingNow2:hover{
  border-left: 4px solid #7fbb67 !important;
}

.activeClocksSection {
  background-color: #ebf0f7;
  border: 1px solid #bfccdc;
  min-width: 100% !important;
  border-radius: 5px;
  padding-bottom: 15px;
}
.activeClocksSectionIos {
  /* malteasers */
  /* background-color: yellow; */
}

.x10841085 {
  background-color: white;
  border: none;
  border-bottom: 1px solid #bfccdc;
  border-radius: 0;
}

.x081284 {
  margin-top: 4px;
  font-size: 12px;
  color: #516b8b !important;
  margin-bottom: -4px;
  text-align: right;
  font-weight: 500;
}

.msgBackBtnDiv {
  background-color: #214978;
  border: 1px solid #143051;
  color: white;
  background-color: #214978;
  border: 1px solid #143051;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  padding-right: 8px;
}

.highlightClockActiveItem:hover {
  border-left:4px solid #ba0000
}
.desktopBurgerIcon {
  border: 1px solid #e0ebf8;
  background-color: white;
  padding: 2px;
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  margin-left: 40px;
  /* margin-left:2px; */
}

.desktopBurgerIconRota {
  margin-left: 20px;
}

.x248921 {
  min-width: 70px !important;
  max-width: 76px !important;
  padding-right: 10px;
}

.x1308428411 {
  min-width: 80px !important;
  max-width: 100px !important;
}

.x1308428e411 {
  min-width: 100px !important;
  max-width: 100px !important;
  /* font-size:12px; */
}

.desktopMenuFloater {
  /* border: 1px solid #bbcada; */
  border-radius: 5px;
  position: fixed;
  top: 127px;
  left: calc(50% - 535px);
  min-width: 200px;
  min-height: 654px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5px;
  padding-bottom: 5px;
  animation: slideDesktopActionMenuDown 0.185s forwards;
}

@media only screen and (max-width: 1140px) {
  .desktopMenuFloater {
    left: 35px;
  }
}

.desktopMenuFloaterRota {
  left: 15px;
}

@keyframes slideDesktopActionMenuDown {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 654px; /* Adjust this value to control the final height of the rectangle */
    max-height: 654px; /* Adjust this value to control the final height of the rectangle */
  }
}

/* @keyframes slideDesktopMenuDown {
    from {  
  
      min-height: 0px;
      max-height: 0px;
    }
    to {
      min-height:550px;
      max-height:550px;
    
    }
  } */

.closeDesktopBurer {
  cursor: pointer;
  max-width: 35px;
  min-width: 35px;
  padding: 11px;
  background-color: #fff;
  border: 1px solid #bbcada;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  margin-top: -619px;
}

.desktopBurgerMenu {
  background-color: #fff;
  min-width: 170px;
  min-height: 586px;
  max-height: 586px;
  border: 1px solid #bbcada;
  border-radius: 0 5px 5px 5px;
  z-index: -1;
  margin-top: 33px;
  overflow: hidden;
}


.mobBurgerMenu {
  min-height: calc(100vh - 45px);
  max-height: calc(100vh - 45px);
  /* border: 1px solid #bbcada; */
  /* border-radius: 0 5px 5px 5px; */
  z-index: -1;
  padding-top:15px;
  /* padding-bottom:125px; */
  /* margin-top: 33px; */
  overflow: auto;
  padding-bottom:30px;
  min-width: calc(100% - 20px);
}

.desktopBurgerTitle {
  color: #bbcada;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 12px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.desktopBurgerItem {
  /* border-left: 2px solid #214978; */
  max-width: 160px;
  display: flex;
  padding-left: 2px;
  padding-right: 4px;
  border-radius: 5px;
  margin-left: 7px;
  text-align: left;
  font-size: 12px;
  margin-top: 1px;
  align-items: center;
  min-height: 24px;
  color: #214978 !important;
  font-weight: 500;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mobMenuBackBar {
  min-width: 20px;
  min-height: 100vh;
  background-color: #2b4a71;

}

.mobMenuBackImg {
  width: 16px;
  position: fixed;
  top: 45%;
  left: calc(80vw - 18px);
  z-index: 9999999999;
}
.mobBurgerItem {
  max-width: 90%;
  display: flex;
  padding-left: 2px;
  padding-right: 4px;
  border-radius: 5px;
  margin-left: 7px;
  text-align: left;
  font-size: 16px;
  margin-top: 9px;
  align-items: center;
  min-height: 44px;
  color: #fff !important;
  font-weight: 500;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 261px;
}

.desktopBurgerItem:hover {
  background-color: #ebf0f7;
}

.desktopBurgerItemBlob {
  min-width: 3px;
  background-color: #0077ff;
  min-height: 20px;
  border-radius: 5px;
  margin-right: 6px;
  margin-left: 3px;
}
.mobBurgerItemBlob {
  min-width: 3px;
  background-color: #0077ff;
  min-height: 20px;
  border-radius: 5px;
  margin-right: 12px;
  margin-left: 3px;
}

.x239814 {
  background-color: #a58fd4;
}

.teamTagsInfoTxt {
  font-size: 12px;
  color: #516b8b;
  font-weight: 400;
  max-width: 90%;
  margin-top: 16px;
  margin-left: 14px;
  line-height: 16px;
}

.x1841984 {
  border-left: 4px solid #00ccff;
}

.showDesktopMenuUnderlay {
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: rgb(119 119 119 / 27%);
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.breakPulsateTick {
  border-right: 2px solid #214978;
  padding-right: 5px;
  animation: brkClockPulsate 1s infinite;
  color: #0077ff;
}

@keyframes brkClockPulsate {
  0% {
    border-right: 2px solid #fff;
  }
  50% {
    border-right: 2px solid #214978;
  }
  100% {
    border-right: 2px solid #fff;
  }
}

.profAdminNavBar {
  background-color: #214978 !important;
  color: #ebf1f8 !important;
  border-color: #143051 !important;
  padding-bottom:10px;
  z-index:9999
}

.profAdminNavBarIos {
  bottom:60px  !important
}

.accBtnSel {
  font-weight: 500;
  color: white;
  cursor: pointer;
}

.innerd {
  height: 100%;
  /* background-color: blue; */
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -6px;
  /* min-width: 25vw; */
}

.accountNavBtn {
  margin-top: 12px;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
}

.inactiveAccBtn {
  opacity: 0.7;
}

.selectPoint {

  margin-bottom: 2px !important;
}

.invis {
  visibility: hidden;
}

.none {
  display: none;
}

.activeClockOnTitle {
  font-size: 11px;
  font-weight: 500;
  color: #bbcada;
  margin-bottom: -12px;
  padding-left: 2px;

}

.activeClockedDurMins {
  font-size: 10px;
  font-weight: 500;
  color: #bbcada;
  margin-right: 5px;
}

.invisImg {
  max-width: 0px !important;
  min-width: 0px !important;
  position: fixed;
  top: -100px;
  left: -100px;
  visibility: hidden;
}

.noMsgsYet {
  opacity: 0.6;
}

.awaitingSwapTxt {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  border-left: 3px solid #bbcada;
  margin-top: 10px;
}

.editClockedImgWh {
  max-width: 35px;
  margin-left: 2px;
  /* border: 1px solid white; */
  border-radius: 3px;
  min-width: 22px;
  padding: 6px;
  cursor: pointer;
  margin-top: 2px;
}

.editClockedImgWhDesktop:hover {
  background-color: #e3e8ee;
}

.saveEditClockTimeBtn {
  background-color: #1e88e5 !important;
  border: #0469de 1px solid !important;
  cursor: pointer;
  border-radius: 5px;
  min-height: 32px;
  margin-left: 5px;
  /* margin-top: 3px; */
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.saveEditClockTimeBtn:hover {
  background-color: #1f78de !important;
}

.saveActiveClockEditPen {
  max-width: 15px;
}

.activeClockInputEdit {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
  cursor: text;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top:2p */
}

.activeGpsLowerLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -4px;
  flex-direction: column;
  align-items: flex-start;
}

.clDurSpan {
  border-top: 1px solid #dde9f9;
  color: #214978 !important;
  margin-top: -4px !important;
  padding-top: 5px;
  margin-bottom: 13px;
}

.x389213 {
  border-top: 1px solid #bfccdc;
  padding-top: 10px;
}

.imagesHidden {
  visibility: hidden;
  position: fixed !important;
  top: 0px;
  max-width: 0px;
}

.shiftLoadingBox {
  min-width: 200px;
  /* max-width: 140px; */

  background-color: #ebf0f7;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 16%); */
}

.shiftLoadingBoxDesktop {
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.shiftLoadingBoxMob {
  position: fixed;
  bottom: 0px;
  min-width: 100vw;
  border-radius: 15px 15px 0 0;
  min-height: 100px;
  animation-name: slideLoaderUp;
  animation-duration: 0.1s;
}

.shiftLoadingBallsImgMob {
  min-width: 160px;
}

@keyframes slideLoaderUp {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 100px;
    min-height: 100px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

.x090910923091 {
  background-color: orange;
  color: white;
  max-height: 30px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999999;
}

.mgrModal {
  /* background-color: #f4f4fe !important; */
}

.x198492823 {
  margin-right: 10px;
}

.x239849272 {
  color: #214978;
}

.unreadNotifQty {
  margin-top: -30px;
  font-size: 10px;
  margin-left: 27px;
  color: #fff;
  position: absolute;
  background: red;
  min-width: 17px;
  max-width: 17px;
  min-height: 17px;
  max-height: 17px;
  border-radius: 3px;
  padding-top: 2px;
  font-weight: 600;

}

.unreadNotifQtyReqs {
  margin-top: -30px;
  font-size: 12px;
  margin-left: -27px;
  color: #fff;
  position: absolute;
  background: #a67aff;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 3px;
  padding-top: 3px;
  font-weight: 600;
}

.marginLeft27px {
  margin-left:27px
}

.unreadNotifQty99plus {
  font-size: 8px;
  padding-top:3px
}

.unreadNotifBtn {
  background-color: #0077ff;
  border-color: #075dbf;
  /* animation: PulsateNotif 1s linear infinite; */
  /* max-width: 30px; */
}
.unreadNotifBtn:hover {
  background-color: #2e8dff;
  border-color: #3d82d3;
}
@keyframes PulsateNotif {
  0% {
    background-color: #609ee5;
  }
  50% {
    /* background-color: #95acc5; */
  }
  100% {
    background-color: #609ee5;
  }
}

.notifItem {
  border: 2px solid #cbd7e6;
  min-height: fit-content;
  max-width: 94%;
  border-radius: 5px;
  margin-left: 3%;
  background-color: #e0ebf8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #143051;
  padding-right: 11px;
  padding-top:10px;
  padding-bottom:10px;
  border-color:white
}

.notifItem:last-of-type {
  /* border-bottom:none !important */
}
.zoomNotifIn {
  /* animation: zoomInNotif 0.1s ease-in-out; Adjust the duration and timing function as needed */

}

@keyframes zoomInNotif {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}


.notifLinkable {
  cursor: pointer;
}


.notifLinkable:hover {
  /* background-color: #244061; */
}

.hoveredNotifHovered {
  background-color: #f4f8fe !important;
}

.notifBold {
  font-weight: 500;
  /* white-space: nowrap; */

}

.notifBoldMsg {
  color:#00ccff
}

.notifImg {
  border: 2px solid #143051;
  max-width: 50px;
  border-radius: 5px;
  margin-left: 4px;
  min-height: 50px;
  object-fit: cover;
  max-height: 50px;
  min-width: 40px;
  max-width: 40px;
}

.notifImgMgr {
  /* border-color:#decdff */
}

.notifTxt {
line-height:18px;
min-width: 100%;
max-width:100%;
/* padding-right:15px; */

}

.notifTxtMob {
  font-size:14px
}

.notifMid {
  text-align: left;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 15px;
  /* min-width: 225px;
  max-width: 225px; */
  /* min-width: 233px; */
  min-width: 70%;
  max-width: 70%;
  min-height:52px;
  display: flex;
  align-items: center;
}

/* .notifMid1 {
  min-width: 255px;
  max-width: 255px;
}

.notifMid2 {
  min-width: 305px;
  max-width: 305px;
} */


.notifStatus {
  font-size: 10px;
  text-align: right;
  /* min-width: 80px;
  max-width: 80px; */
  margin-right: 15px;
  color: #516b8b;
  min-width:38px;
  max-width: 38px;
}

.notifIsRead {
  background: none;
  border: none;
  border-radius: 0;
  /* border-bottom: 1px solid; */
  /* opacity: 0.8; */
  padding-top: 15px;
  padding-left: 0px;
  padding-bottom: 15px;
}

.notifIsRead:last-child {border:none}

.notifIsRead:hover {
  opacity: 1;
}

.notifBlob {
  min-width: 5px;
  min-height: 40px;
  background-color: #8ea5c0;
  border-radius: 5px;
  /* margin-right:5px; */
  /* animation-delay: 0.2s; */
  /* animation: notifsFadeIn 0.1s ease-in-out; Adjust the duration and timing function as needed */

}

.notifBlobApproved {
  background-color: #82c26a;
}

.notifBlobDeclined {
  background-color: #f63b3b;
}
.notifHead {
  /* background-color: yellow; */
  margin-top:-4px;
}

.notifHeadMob {
  justify-content: space-between;
  display: flex;}

.mgrNotifKey {
  height: 44px;
  margin-top: -4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0ebf8;
  max-width: 94%;
  margin-left: 3%;
}

.mgrNotifKeyTitle {
  color: #143051;
  font-weight: 500;
  font-size: 16px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  margin-top: 0px;
}

.x314565342 {
  margin-top: -8px;
  margin-right: 5px;
  margin-left: -5px;
  max-width: 22px;
  min-width: 22px;

}

.x992910011 {
  margin-left:-0px;
  padding:4px;
  margin-top:-10px;
  margin-right:7px;
}

.mgrNotifKeyTitleMob {
  font-size: 18px;
  margin-bottom: 7px;
}

.mgrNotifKeyRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: fit-content;
}

.mgrNotifKeyTxt {
  font-size: 12px;
  color: #fff;
  border: solid 1px #e6eaee;
  background-color: #fff;
  font-weight: 500;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  max-height: 22px;
  line-height: 22px;
  border-radius: 5px;
  min-height: 22px;
  /* margin-right: 5%; */
  /* max-width: 100px; */
  text-align: center;
  /* margin-top: 18px; */
  /* display: flex; */
  /* align-items: center; */
  margin-left: 4px;
}

.notifKeyAsTxt {
  font-size:9px;
  margin-bottom:-12px;
  margin-top:-4px;
  text-align: left;
  color:#bbcada;
  font-weight: 500;
  /* opacity: 0.65; */
}

.mgrNotifkeyImg {
  max-width: 12px;
  margin-left: 6px;
  margin-top: -6px;
}

.mgrNotifkeyImgMob {
  /* margin-top:0px */
}

.mobNotifBox {
  background-color: #fff;
  /* border: #143951 1px solid; */
  position: fixed;
  bottom: 0;
  left: 0;
  min-height: 96vh;
  min-width: 100vw;
  max-width: 100vw;
  border-radius: 20px 20px 0 0;
  z-index:9999999999
}

.closeNotifImg {
  max-width: 15px;
  margin-top: 2px;
  margin-right: 14px;
  min-width: 16px;
  margin-left: 3px;
}

.mgrNotifKeyMob {
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
  /* justify-content: flex-start; */
  padding-top: 4px;
  padding-right: 3px;
  padding-left: 2px;
  /* padding-bottom:4px */
}

.x39248231 {
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.x98248423 {
  min-width: 37px;
  max-width: 37px;
  min-height: 34px;
  max-height: 34px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 90px;
  cursor: pointer;
}

.x09482834 {
  left: 68px;
}

.x49849357843 {
  min-width: 68px;
  max-width: 68px;
  min-height: 34px;
  max-height: 34px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 139px;
  cursor: pointer;
}

.x84746265233 {
  min-width: 38px;
  max-width: 38px;
  left: 114px;
}

.x239829833 {
  min-width: 37px;
  max-width: 37px;
  min-height: 34px;
  max-height: 34px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 40px;
  cursor: pointer;
}

.x848887423 {
  left: 20px;
  min-height: 38px;
}

.x9375877823 {
  min-width: 38px;
  max-width: 38px;
  min-height: 34px;
  max-height: 34px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 114px;
  cursor: pointer;
}

.x8478268642323 {
  min-width: 37px;
  max-width: 37px;
  min-height: 37px;
  max-height: 37px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 40px;
  cursor: pointer;
}

.x848887423 {
  left: 20px;
  min-height: 38px;
}

.x32982874423 {
  min-width: 37px;
  max-width: 37px;
  min-height: 37px;
  max-height: 37px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 90px;
  cursor: pointer;
  z-index: 999999999;
}

.x94829873233 {
  left: 68px;
}

.x12345 {
  background-color: orange;
  color: white;
  max-height: 30px;
  position: fixed;
  top: 0;
  right: 45px;
  z-index: 9999999999;
}

.x54321 {
  background-color: pink;
  color: white;
  max-height: 30px;
  position: fixed;
  top: 0;
  right: 90px;
  z-index: 9999999999;
}

.nonMobModalClockContentBox {
  padding-bottom: 10px;
}

.minHeight170 {
  min-height: 150px;
}

.mobBreak1FinishedNoSchedStartContentHeight {
  min-height: 477px !important;
  max-height: 477px !important;
}

.mobBreak1FinishedNoSchedStartContentHeightIos {
  min-height: 497px !important;
  max-height: 497px !important;
}

.notifWrapper {
  /* background-color: orange; */
  min-height: 0px;
  max-height: 0px;
  /* min-height: calc(96vh - 102px);
  max-height: calc(96vh - 102px); */
padding-bottom:5px;
  overflow-y: auto;
  animation: notifDropSlideDown 0.075s ease-in-out; /* Adjust the duration and timing function as needed */

}


@keyframes notifDropSlideDown {
  from {
    min-height: 0px;
    max-height: 0px;
    
  }
  to {
    min-height: calc(96vh - 102px);
    max-height: calc(96vh - 102px);
  }
}
.notifFooter {
  min-height: 50px;
  min-width: 94%;
  max-width: 94%;
  /* padding-left:5px; */
  margin-left: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e0ebf8;
}

.notifWrapperDesktop {
  min-height: calc(100vh - 210px);
  max-height: calc(100vh - 210px);
  /* background-color: blue; */
  overflow-y: auto;
}

.notifWrapperMob {
  min-height: calc(100vh - 131px);
  animation:none !important;
  max-height: calc(100vh - 131px);}

.x13984927123 {
  background-color: #143051;
  border-color: #061f3c;
}

.x13984927123:hover {
  background-color: #143051;
}
.mobMgrNotifKey {
  min-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  padding-top:2px;
}

.desktopMgrNotifKey {
  min-height: 30px;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 12px;
}
.notifMobTitleTxt {
  font-size: 22px;
  color: #142051;
  font-weight: 500;
  margin-left:2px;
  margin-top:-3px;
}

.vhIssueNotifWrapper {
  min-height: 448px;
  max-height: 448px;
}

.vhIssueNotifBox {
  min-height: 550px;
  max-height: 550px;
  /* background-color: yellow; */
  border-radius: 20px;
  bottom: 100px;
}



.x889988 {
  margin-top: 4px;
  margin-bottom: 0px;
}


.x9183812 {
  background-color: #cbd7e6;
  border: 1px solid #bbcada;
  padding-left:15px;
  padding-right:15px;
  max-width: fit-content;
  margin-top:15px;
  margin-left:5%
}


.x9183812:hover {
  background-color: #cbd7e6;
}

.x233245211 {
  margin-left:10px
}

.shActiveDiv {
  /* background: white; */
  max-width: calc(100% - 140px);
  /* border-radius: 5px; */
  float: right;
  /* padding-right: 14px; */
  /* padding-left: 10px; */
  max-height: 80px;
  line-height: 23px;
  min-width: calc(100% - 150px);
  margin-right: 0px;
  margin-top: -27px;
  margin-bottom: 10px;
  text-align: right;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 20px;
  justify-content: flex-end;
}

.mgrShAvailPopup {
  width: 16px;
  margin-left: 6px;
  margin-top: 20px;
}

.mgrShAvailPopupUnavail {
  margin-top: 0px;
  margin-right: 12px;
  width: 14px;
  margin-left: 4px;}

.shActiveDivTxt {
  font-size:12px;
  font-weight:500
}

.z728731 {

  font-size: 14px;
  color: #49b63e;
  line-height: 15px;
  font-weight: 600;
}


  .x8283242312111 {
margin-top:0px  }

.resolIcon {
  margin-left: 8px;
  margin-right: 8px;
  background: white;
  min-width: 24px;
  max-width: 24px;

  padding: 3px;
  max-height: 30px;
  border-radius: 4px;
  border: 1px solid #e7e5ed;
  align-items: center;
  display: flex;
  color: black;
  font-weight: 500;
  /* padding-left: 5px; */
  max-height: 24px;
  /* padding-top: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.resWarnInfo {
  color: #214978;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 40px;
  border-left: 2px solid #bbcada;
  padding-left: 14px;
  padding-right: 30px;}

  .x32892984221 {
    margin-top:30px;
  }

  .resInsturctTxt {
    color: #214978;
    /* display: flex; */
    /* align-items: center; */
    font-size: 14px;
    min-width: 80%;
    max-width: 80%; 
    margin-bottom: 7px;
    text-align: left;
    line-height:18px;
  }

  .greyThreeDotsResImg {
    background-color: white;
    border-radius:4px;
    border:1px solid #bbcada;
    padding:4px;
    transform: rotate(90deg);
    max-width: 22px;
    margin-bottom:-5px;
    margin-left:5px;
    margin-right:5px;
    min-height:22px

  }

  .noRotate {
    transform: rotate(0deg);
  }

  .displayFlex {
    display: flex;
    align-items: center;
  }

  .iosZoomBar {
    border: 2px solid #f2f2f2;
    border-radius: 10px;
    max-width: 240px;
    margin-top: 10px;  }

    .notifSubTxt {
      display: flex;
      /* margin-left:-5px; */
      margin-top:2px;
      font-size:10px;
      font-weight: 500;

    }

    .notifSubTxtBubble {
      border: 1px solid #011934;
      border-radius: 5px;
      padding-left: 4px;
      padding-right: 4px;
      /* margin-right: 5px; */
      /* margin-left: 5px; */
      background-color: #516b8b;
white-space: nowrap;
font-weight:600;
max-height: 20px;
font-size:12px;
    }
    .deleteNotifImg {
      max-width: 24px;
      padding-left:4px;
      padding-right:4px;
      background-color: #bbcada;
      margin-top:10px;
      margin-bottom:3px;
      cursor: pointer;

    }

    .deleteNotifImgMob {
      background-color: #bbcada;
      min-height: 23px;
      border-radius: 3px;
      padding: 5px;  
    display: none;
    }
   
    .deleteNotifImgDesktop:hover {
      background-color: #2d5a8f;
      border-radius:3px;
      /* border:1px solid #bbcada; */
    }

    .x883811ex {
      min-width: 38px;
      padding-top:5px;
      padding-bottom:5px;
      border-radius:4px;
      /* background-color: yellow; */
      /* padding-left:4px; */
      /* padding-right:2px; */
      margin-top:1px;
margin-right:12px;
margin-left:3px;
padding-right:3px;
padding-left:4px;
padding:10px  !important

    }

    .mobNotifHolder {
      margin-top: -3px;
      margin-left: 0px;
    overflow:visible;
    padding-right:8px;
    }
    
    .mobNotifIconSep {
      min-width: 42px !important;
      margin-left: -3px !important;
      margin-top: 6px !important;
      margin-bottom: 0px;
      padding: 11px !important;
      overflow:visible
    }


    .mobUnreadNotifIcon {
    /* align-items: center; */
    /* justify-content: center; */
    /* color: white; */
    /* border: 1px solid #3f6aac; */
    background-color: #ef5353;
    border: 1px solid #ba0000;
    color: white;
    font-weight: 500;
    min-width: 17px;
    min-height: 17px;
    border-radius: 3px;
    max-width: 17px;
    max-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    padding-bottom: 1px;
    margin-top: -40px;
    margin-left: 19px;
    position: relative;
    z-index: 9999;
    }


    .xi28381 {
      font-size:6px
    }

    .viewDayBurger {
      display: flex;
      flex-direction: column;
      margin-bottom:1px;
      align-items: center;
      justify-content: center;  }

    .viewDayBurgerLine {
      background-color: white;
      min-height: 2px;
      min-width: 16px;
      max-width: 9px;
      max-height: 2px;
      margin-top: 2px;
      margin-bottom: 2px;
      border-radius: 4px;
    }

    .notificationsBoxDesktop {
      max-height: calc(100vh - 160px) !important   }

      .pushNoteSpan {
        color: #516b8b;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        /* margin-bottom:10px; */
      }

      .sendPushNotifBtnMgs {
        margin: 20px auto;
        padding-right:0px;
      }

      .xb192381 {
        min-width: 100%;
        margin-left: 0px; 
      margin-top:20px
      }


        .xc209233 {
          margin-left:2px;
          background-color: #07f;
        }

        .redBg {
          background-color: #e57676;
        }

        .x238929j {
          margin-right:3px;
          max-width: 12px;
        }

      

        .mobHomeBtnQty {
          background-color: #00aaff;
          color: #00aaff;
          position: absolute;
          margin-top: -25px;
          margin-left: 25px;
          border-radius: 3px;
          font-weight: 500;
          font-size: 10px;
          padding: 2px;
          border: 1px solid #00aaff;
          width: 9px;
          height: 9px;
          display: flex
      ;
          align-items: center;
          justify-content: center;
        }

        .mobHomeBtnQtyHomeLeft {
          margin-left:35px;
        }

        .mobHomeBtnQtyHours {
          margin-left:23px;
        }
        .mobHomeBtnQtyFontSmall {
          font-size:8px
        }


        .mgrNotifInner {
          min-width: 5px;
          max-width: 5px;
          min-height:5px;
          background-color: #ef5353;
          max-height:7px;
        }

        .bgEf5353 {
          background-color: #ef5353 !important;
          border: 1px solid #ba0000;

        }
      .bgBa0000 {
        background-color: #ef5353 !important;
        border: 1px solid #ef5353;

      }

.notifTag {
  background-color: #8ea7c5;
  border: 1px solid #214978;
  border-radius: 4px;
  font-size:12px;
  padding: 1px;
  padding-left: 4px;
  padding-right: 4px;}


  .x298497113 {
    margin-top:15px !important;
    color:#214978 !important
  }



  .x92499101042:hover {
    /* background-color: #305f96 !important; */
  }

  .noNotifs {
    color: #143051;
    font-weight: 500;
    font-size: 14px;
    margin-top: 30px;
  opacity: 0.4;  }


    .swapNotifImg {
      max-width: 12px;
      min-width: 12px;
      margin-right: 12px;    }

      .notifHowever {
        /* color:#ffb400 */
      }

      .extSuppImg {
        margin-left: 0px;
        max-width: 11px;
        margin-bottom: -2px;
        margin-top: 2px;
         }

       .msgBorderTopScrolled {
        border-top:1px solid #e0ebf8
       }
       .x2f34222 {
        /* bottom: 15px !important; */
    }

 
    .clearNotifs {
      /* background-color: blue; */
      color:white;
      font-size:12px;
      font-weight:500;
    max-width: fit-content;
    margin: 50px auto;
    /* border:1px solid white; */
    height:24px;
    display: flex;
    align-items: center;
    padding-left:5px;
    padding-right:5px;
    border-radius:5px;
    opacity: 0.5;
    cursor: pointer;
    }

    .clearNotifs:hover {
      opacity: 1;
    }

    .notifBottomBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top:-20px;
    }

    .x920901ss {
      /* background-color: blue; */
      min-width:30%;
    }

    .loadMoreNotifsHolder {
      min-width: 33%;
    }

    .desktopHeaderSubBtnSelected {
      font-weight: 600;
      color: #07f !important;
      cursor: none;
      pointer-events: none;
      /* border-left: 2px solid #07f; */
      padding-left: 6px;
      margin-left: 19px;
    }
    

    .x298429712s {
      margin-bottom:-12px  !important
    }

    .x298429712sss {
      margin-bottom:-9px  !important

    }

    .x394304023d {
      margin-bottom: -22px !important
    }

    .breakOnToNewLine {
white-space: wrap;    }

.indicateSmall {
  font-size:14px;
  padding-left:10px;
  padding-right:10px;
}

.x32984197411 {
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
  padding-right: 2px;
margin-left:5%;
}
  /* margin: 0 auto;} */


  .notifTxtJsx2 {
    min-height: 27px;
    display: flex;
    align-items: flex-end;
  }

  .addShiftNotifJsx {
    display: flex;
    margin-left: 0px;
    margin-top: 8px;
    background: #f0f5ff;
    border-radius: 5px;
    padding: 0px;
    max-width: fit-content;
    padding: 3px;
  }

  .addShJsxDate {
    font-size: 12px;
    font-weight: 500;
    margin-left:4px;
    margin-right: 4px;  }


    .needToVerifNotifBox {
      background-color: #4784ae;
      /* border:1px solid #bbcada; */
      border-radius:5px;
      width: 90%;
      margin-top:10px;
      margin-left:5%;
      margin-bottom:10px;
      min-height:70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .notifVerifTxt {
      color:white;
      text-align: left;
      font-weight:500;
      font-size:14px;
      /* margin-top:12px; */
      margin-left:10px;
    }

    .notifVerifTxtSub {
      color:white;
      text-align: left;
      font-weight:500;
      font-size:12px;
      opacity: 0.6;
      /* margin-top:8px; */
      margin-left:10px;
    }

    .verifNotifBtn {
      color:white;
      background-color: #214978;
      border:1px solid #143051;
      border-radius:5px;
      padding-left:10px;
      padding-right:10px;

      margin-right:10px;
      font-size:14px;
      font-weight:500;
      min-height:35px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .verifNotifBtn:hover {
      background-color: #143051;
    }

    .notifKeyDiv {
      display: flex;
      color:#81b3ee
    }

    .notifIsMgr {
      /* background-color: #4b3589; */
      color:#9b51ce !important;
      border-color:white;
    }

      .notifLinkableMgr:hover {
        /* background-color: #563d9c  !important; */
      }

      .mgrNotifDeleteHover:hover {
        /* background-color: #4b3589  !important; */
        cursor: pointer;

      }

      .asAMgr {
        color:#b39def
      }


      .swapWithTitle {
        font-size:15px;
      }

      .x29849283211 {
        font-size: 14px;
        font-weight: 400;
        padding-left: 3px;
        padding-right: 20px;
        line-height: 16px;
      }



      .x2984298291111 {
        min-width: 117px;
        background-color: #a387df;
        border: 1px solid #5f4d85;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-left: 5px;
        padding-right: 5px;
      }

   

      .fontSize12Notif {
        font-size:14px  !important;
        line-height:16px !important
      }
      .aa1010:hover {
        background-color: #9b7fd6;
      }

      .x892 {
        background-color: #3b597c !important;
        color: white !important;
        /* border: solid #214978 1px !important; */
        padding-right:4px;
      }

      .x109100001 {
        width: 10px;
        margin-right:0px;
      }

      .swapsQtySpanNotif {
font-weight:600      }

.x1091091a {
  min-height:74px
}

.swapNotifBlob {
  min-width: 4px;
  min-height: 40px;
  background-color: #cfb8fd;
  border-radius: 5px;
  margin-left: 10px;
}

.histCheck {
  margin-right: -1px;
  margin-left: 8px;
  max-width: 14px;
  background: #e0ebf8;
  border: 1px solid #bbcada;
  border-radius: 3px;
}

.histSwapBtn {
  padding-left:9px !important;
  padding-right:10px !important
}

.showHistTxt {
  font-size: 12px;
  font-weight: 400;
  color: #143051;
  margin-top: 12px;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 10px;
}

.histSwap {
  border-bottom: 1px solid #dde9f9;
  max-width: 96%;
  margin-left: 2%;
  padding-bottom: 20px;
  padding-top: 20px;
}
.histSwapTxt {
  color: #143051;
  font-size: 12px;
  text-align: left;
  max-width: 90%;
  margin-left: 10px;
  /* margin-top: 20px; */
  border-left: 2px solid #143051;
  padding-left: 10px;
  line-height: 18px;

}

.histSub {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  margin-left: 22px;
  margin-top: 10px;}

  .histTeamName {
    color:#859dbb;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    margin-left: 22px;
    margin-top: 10px;
  }

  .swapHistoryDs {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    margin-left: 22px;
    margin-top: 10px;
    color: #bbcada
  }

  .mySwapsFooterLeft {
    display: flex;
    justify-content: space-between;
    min-width: 200px;
    /* background-color: lime; */
    min-width: 253px;
  }
  .x10s00 {
    margin-left:0px !important

  }

  .userSwapsBox {
    background-color: #0af;
    border-color: #143051  }

    .x272721 {
      background-color: #8ad7fd;
    }

    .x373718111 {
      background-color: #0077ff;
      border: 1px solid #0046ff;    }
      .x828288111:hover {
        background-color: #0771eb;
      }

      .nonAdminInnerd {
        min-width: 33vw;
      }

      .unReadNotifMgr {
        background-color: #f7e5f7;
      }

 

      .installPWAinfo {
        /* font-size: 14px;
        color: #214978;
        text-align: left;
        margin-bottom: 30px;
        border-left:2px solid #214978;
        padding-left:15px;
        max-width: 90%;
        line-height: 19px;
        font-weight: 500;   */
      
        font-size: 14px;
        color: #516b8b;
        text-align: left;
        margin-bottom: 10px;
        border-left: 2px solid #214978;
        padding-left: 15px;
        max-width: 90%;
        margin-top:10px;
        margin-bottom:30px;
        line-height: 18px;
        font-weight: 400;}

        .unclockCal {
          opacity: 0.3;
          max-width: 10px;
          margin-right: 7px;        }

          .unclockCal1{
            margin-bottom:2px;
          }


          .adminTopRight {
            background-color: blue;
            position: fixed;
            top:0;right:0;
            margin-top:2px;
            margin-right:5px;
           
            min-height:24px;
            z-index: 999;

            background-color: #214978;
            border: 1px #143051 solid;
            color: white;
            display: flex;
            align-items: center;
            max-height: 24px;
            min-height: 24px;
            min-width: -moz-fit-content;
            min-width: fit-content;
            max-width: -moz-fit-content;
            max-width: fit-content;
            padding-left: 6px;
            padding-right: 6px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 3px;
            cursor: pointer;
            /* margin-left: 6px; */
          }

          .adminTopRight:hover {
            background-color: #143051;
          }

          .adminActionsCog {
            width:11px;
            margin-left:5px;
          }

.adminActionsMenu {
  background-color: #214978;
  position: fixed;
  top: 25px;
  padding-top:5px;
  right: 5px;
  border: 1px solid #143051;
  border-radius: 5px 0 5px 5px;
  min-width: fit-content;
  z-index: 9999;
}

.adminTopRightActive {
  border-radius: 5px 5px 0 0;
  border-bottom:1px solid #214978
}


.adminActionsRow {
  min-height:40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color:white !important;
  font-weight: 500;
  font-size:14px;
  padding-left:20px;
  padding-right:20px;
  cursor: pointer;
}

.adminActionsRow:hover {
  background-color: #143051;
  color:white
}

.adminActionCross {
  padding:1px;
}




.mgrReqItemPendingBorder:hover {
  border-color:#143051 !important
}


.pendClock {
  background-color: white !important;
  border:2px dashed #214978 !important

}

.pendClock:hover {
  border-color:#143051 !important
}

.paddingRight8 {
  padding-right:8px  !important
}

.asMgr {
  margin-top:-7px
}



.desktopIndicateUnderlay {
  min-width: 100vw;
  min-height:100px;
  background-color: blue;
  position: fixed;
  top:0; left:0;
  z-index:99999;
opacity: 0.3;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  animation: fadeIn 0.5s forwards;
}
@keyframes fadeIn {
  /* Start at fully opaque (black) */
  from {
    opacity: 0;
  }
  /* End at fully transparent */
  to {
    opacity: 0.3;
  }
}

.finalLineInstallModal {
  margin-top:-19px
}


.multi-widget-ui {
  /* display: none  !important; */
}

.fc-widget-normal {
  bottom: 77px !important;
  right: 15px !important;
}

.fourContainer {
  color: #143051;
  min-width: 300px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 300px !important;
  margin: 0 auto;
  margin-left:calc(50vw - 150px);
}

.fourImg {
  width:190px
}

.fourTxt {
  font-weight: 600;
  margin-top: 20px;
  font-size: 100px;
  color: #0077ff;
}

.fourTxtSub {
  color: #214978;
  font-weight: 500;
  margin-top: 30px;}

.fourCalBtn {
  background-color: #7c4a8f;
  color: white !important;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
  
  font-weight: 500;}

  .progressier-widget-icon {
    border-radius: 50px 10px 50px 50px !important;
    background: none !important;
  }
  .progressier-widget-logo {
    border-radius:0px !important;
  }

  .liveChatBtn {
    background-color: #0077ff;
    position: fixed;
    top: 2px;
    right: 119px;
    font-size: 12px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid #0469de;
    padding-left: 6px;
    padding-right: 6px;
    cursor: pointer;
    min-height: 24px;
    z-index: 999;
  }
  .liveChatImg {
    max-width: 14px;
    margin-left:4px;
  }

  @media only screen and (min-width: 820px) {
.activeClocksMenuBarBtn:hover {
  border-color:#b3c1d2
}
    .userHoursEditBtn:hover {
      border-color: #d2d9e3;
    }
    .userHoursAbsenceBg:hover {
      background-color: #d8e1eb !important;
    }

    .userHoursLeaeveBg:hover {
      border-color:#bbcada
    }
    .userHoursItem:hover {
      border-color:#bbcada
    }
    .viewWeekHoursBtn:hover {
    color:#214978
      /* border-color: #d2d9e3; */
     /* background-color:white; */
    }
    .lightBlueColours:hover {
      background-color: #0af !important
    }
    .decShBtn:hover {
      background-color:#ebf4ff
        }
    .x889988:hover {
      background-color: #d4e5ff !important;

    }
    .notifReqsMenuItem:hover {
      opacity: 0.9
    }
    .editClockedTimeBtn:hover {
      border-color:#d2d9e3
     }
    .unReadNotifMgr:hover {
      background-color: #fad0fa !important;
    }
    .x298497113:hover {
      background-color: #f4f8fe !important;
      color:#143051  !important
  
    }
    .termPicBtn:hover {
      background-color: #0da0ea;
      }
    .activeClockGPSmapBtn:hover {
      background-color: #0b99e1;
    }
    
    .clockPicBtn:hover {
      background-color: #9ec091 ;
      }
    .liveChatBtn:hover {
      background-color: #1f78de !important; 
    }
  }

  .liveChatOnline {
    min-width: 5px;
    min-height: 5px;
    border-radius: 5px;
    background-color: #00f500;
    margin-top: -11px;
    margin-right: -3px;
  }

  @media only screen and (min-width: 911px) and (max-width: 1040px) {
.liveChatBtn {
  right:3px
}
.adminTopRight {
  display: none;
}
  }




  @media only screen and (min-width: 820px) and (max-width: 910px) {
    .liveChatBtn {
      right:3px;
      padding-left:3px;
    }
    /* .adminTopRight {
      display: none;
    } */
      }


      @media only screen and (max-width: 819px) {
        .liveChatBtn {
          right:3px
        }

          }


  .colourLightRed {
    color:#ff9b9b !important
  }

  .mobMenuLogo {
    width: 35px;
    /* float: right; */
    /* margin-top: 8px; */
    position: fixed;
    top: 26px;
    left: calc(80vw - 68px) 
  }


  .mobVersion {
    color: white;
    text-align: left;
    margin-left: 30px;
    font-size: 14px;
    opacity: 0.5;
    margin-top: 60px;
    letter-spacing: 2px;
    border-left: 3px solid #47ff47;
    padding-left: 8px;
  }
  .mobVersionOffline {
    color: white;
    text-align: left;
    margin-left: 30px;
    font-size: 12px;
    opacity: 0.5;
    margin-top: 60px;
    /* letter-spacing: 2px; */
    border-left: 3px solid #ff4747;
    padding-left: 8px;
  }

  .versionOnline {
    color: #309b41;
    letter-spacing: 0px;
    font-weight: 500;  }



    .mobMenuFadeTop {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    min-height: 44px;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 80vw;
    border-radius: 0 30px 0 0;
    animation-name: slideMenuFromLeft !important;
    animation-duration: 0.1s !important;
    }



    .mobMenuFadeBottom{
      background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    min-height: 44px;
    position: fixed;
    bottom: 45px;
    left: 0;
    min-width: 80vw;
    border-radius: 0 30px 0 0;
    animation-name: slideMenuFromLeft !important;
    animation-duration: 0.1s !important;
    }

  
    .initToDoBtnNav {
      font-size: 14px;
      font-weight: 500;
      border-radius: 5px;
      background-color: #e78df7;
      border: 1px solid #9a7ba0;
      padding-left: 7px;
      padding-right: 9px;
      cursor: pointer;
      min-height: 40px;
      display: flex;
      align-items: center;
      color: #fff;
      max-height: 30px;
      text-align: left;
      line-height: 14px;
      max-width: -moz-fit-content;
      max-width: fit-content;
      margin-left: 20px;
      margin-top: -10px;
      margin-bottom: 20px;
      z-index:999999999999
    }

    .initToDoTxt {
      font-weight: 600;
    }

    .initToDoTxtMob {
      font-size:16px;
      line-height: 17px;
      margin-top: 2px;  
      }

    @media only screen and (min-width: 820px) {

      .initToDoBtnNav:hover {
              background-color: #d07ae0;
          }
      }

      .todoCheckListNAV {
      width:16px;
      margin-right:8px;
      }

      .todoCheckListNAVMob {
        width: 20px;
    margin-right: 9px;
    margin-left: -3px;
        }

      .checkListSpan {
        font-size:11px;
        display: flex;
      }

      .checkListSpanmob {
        font-size:14px; margin-top:1px;
        line-height: 13px;
        display: flex;
        align-items: flex-end;
      }

      .wideInitTodo {
        min-width: 150px;
        margin-left: 40px;      }

        .mobInitTodo {
          border-radius: 0 0 6px 6px;
          min-width: 175px;
          margin-left: 140px;
          margin-top: 0px;
          font-size: 15px;
          min-height: 43px;
          position: fixed;
          top: 0;
          z-index:1;
          border-top: none;
          padding-left: 12px;
        }

        .checkRotaBlobs {
          display: flex;
          margin-left: 3px;
          margin-top:4px;
        }

        .checkBlob {
          min-width: 8px;
          min-height:8px;
          background-color: #f8c0ff;
          max-width: 8px;
          max-height:8px;
          margin-left:2px;
          border-radius: 2px;

        }

        @media only screen and (max-width: 819px) {
.checkBlob {
  min-width: 9px;
  min-height:9px;
  margin-left:3px;
}

        }

        .checkBlobMob {
          min-width: 4vw;
          min-height: 14px;
          background-color: #f8c0ff;
          max-width: 4vw;
          max-height: 14px;
          margin-left: 5px;
          border-radius: 3px;
        }

        .checkBlobDone {
          background-color: white;
        }

        .checkListTitleClient {
          max-width: 92%;
        }

        .checkListItemNumClient {
          font-size: 14px;
          font-weight: 500;
          color: #e78df7;
          /* margin-top: -15px; */
          margin-right: 10px;
          margin-top:0px !important;
        }

        .checkListItemClient {
          padding-top:10px;
          padding-bottom: 10px;
          cursor: default  !important;
          align-items: flex-start !important;
          border: 1px solid #dde9f9;
          background: white;
          max-width: 98% !important;
          min-width: 98% !important;
          margin-left: 0% !important;
          border-radius: 5px;
          min-height: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
          margin-bottom: 6px;
        }

        .checkListInputRow {
          display: flex;
          align-items: center;
          margin-top:10px;
        }

.checklistYearStartInputDate {
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 56px;
  min-width: 56px;
  /* margin-bottom: 20px; */
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 82%;
}

.checklistYearStartInputDateMonth {
  margin-left:6px;
  min-width: 72px;
  max-width: 72px;
}

.checklistSaveBtn {
  color: white;
  background-color: #07f;
  border: #0469de 2px solid;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 33px;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 56px;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  padding-bottom:1px;
  margin-left: 8px;
}

  @media only screen and (min-width: 520px) {
    .checklistSaveBtn:hover {
 background-color: #036ce4;
    }


    
  } 


  .checkListDoItemClient {
    margin-right: 2px;
    border: 1px solid #dde9f9;
    min-width: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
  }

  .lightBlueCta {
    background-color: #0bf;
    border: 1px solid #0af;
  }

  .lightBlueCta:hover {
    background-color: #0af;
  }


  .checklistpopupimg {
    width:    15px;
    margin-left:7px
  }

  .pinkCta {
    background-color: #e78df7;
    border: 1px solid #9a7ba0;
  }

  .pinkCta:hover {
    background-color: #9a7ba0;
  }

  .checkListTxtInput {
    /* color: #fff; */
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 174px;
  min-width: 174px;
  /* margin-bottom: 20px; */
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: text;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #143051;


  }


  .checkListTxtAndShowMeClient {
    display: flex;
    flex-direction: column;
    /* background: blue; */
    min-width: 84%;
    align-items: flex-start;

  }

  .checkListTxtInput::placeholder {
    color:#bbcada
  }

  .checkListTxtInput:focus {
    border-color:#cedaec
  }

  .rightAuto {
    right: auto !important
  }

  .checklistDateInput {
    border-radius: 5px;
    /* margin-top: -30px; */
    border: none;
    background-color: #fff;
    color: #496375;
    min-height: 35px;
    line-height: 35px;
    font-size: 16px !important;
    padding-left: 14px;
    padding-right: 8px;
    letter-spacing: 1px;
    padding-top: 1px;
    /* text-align: center; */
    font-weight: 500;
    max-width: 174px;
    min-width: 174px;
    background: url("../img/general/calicongrey.svg") no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 142px;
    border: 2px solid #e6eaee;
    margin-left: 0px;
    /* margin-top: 4px; */
    cursor: text;
  }

  .checklistDateInput:focus {
    border-color:#cedaec

  }


  .beforeTodayCheckInputs {
    border-top: 1px solid #dde9f9;
    margin-top: 14px;
    min-width: 100%;
    /* border-radius: 3px; */
    min-height: 812px;
    max-height: 812px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
      animation-name: slideCheckInputsDown !important;
  animation-duration: 0.4s !important;
  }


  @keyframes slideCheckInputsDown {
    from {
      min-height: 0px;
      max-height: 0px;
    }
    to {
      min-height: 812px;
      max-height: 812px;
    } 
  }
  
  .checklist3subTitle {
    font-size: 14px;
    display: flex;
    padding-left: 10px;
    padding-top: 14px;
    padding-right: 10px;
    text-align: left;
    font-weight: 500;
  }

  .check3subNum {
    color:#00aaff;
    min-width: fit-content

  }

  .sub3atitleSpan {
    color: #214978;
    margin-left: 8px; 
  max-width: 240px;
  font-size: 12px;
  line-height: 17px;
 }

  .check3Ainfo {
    color: #bbcada;
    font-weight: 500;
    font-size: 10px;
    margin-top: 4px;
    margin-left: 33px;
    max-width: 240px;
    text-align: left;
    }

    .clientCheckDiv {
      border-top: 1px solid #dde9f9;
      overflow: auto;
      max-height: 66vh;
      padding-bottom: 10px;
      max-width: 90%;
      min-width: 90%;

      margin-left: 5%;
      border-bottom: 1px solid #dde9f9;
      padding-top: 10px;
      min-height:66vh;
      overflow-x: hidden;
    }

    .clientCheckDivDesktop {
      max-width: 90%;
      min-width: 90%;
    }

  

    .payRollUserDiv {
     min-width: 90%;
     max-width: 90%; 
    }
    .hrMinPerWeekDiv {
      display: flex;
      align-items: flex-end;
      margin-left:33px;
      margin-top:8px;
    }

    .hrMinInputter {
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      /* padding-left: 13px; */
      /* text-align: left !important; */
      max-width: 38px;
      min-width: 38px;
      /* margin-bottom: 20px; */
      line-height: 26px;
      height: 35px;
      -webkit-appearance: none;
      display: flex;
      cursor: text;
      border: 2px solid #e6eaee;
      background-color: white;
      color: #143051;
      text-align: center;
    }
    .preHpwValTxt {
      font-size: 10px;
      color: #859dbb;
      font-weight: 500;
      margin-bottom: 4px;
      margin-left: 5px;
      margin-right: 10px;
      text-align: left;   }

      .checklistSubRow {
        padding-bottom:14px;  
        display: flex;
        border-top: 1px solid #dde9f9;      }
        
              .checkListSubTickBox {
                border:1px solid black;
                min-width: 24px;
                min-height: 24px;
                max-width: 24px;
                max-height: 24px;
                margin-top:20px;
                border: 1px solid #dde9f9;
                border-radius: 3px;
                cursor: default;
              }
        
              .checklistSubGreenTickImg {
                margin-top:6px;
                width:14px;
              }
        
              .showTickOnHover {
                opacity: 0;
              }
        
              .showTickOnHover:hover {
                opacity: 0.2;
              }

  .checklistSubRowLeft {
    /* background-color: yellow; */
  }

  .check3RightCol {
    margin-right: 2px;
    min-width: 35px;
    min-height: 35px;
  }

  .check3arrImg {
    width: 35px;
    margin-top: 5px;
    cursor: pointer;
    margin-right: 2px;
    padding: 10px;
    border: 1px solid #dde9f9;
    border-radius: 5px;
    margin-bottom: 2px;
  }

  .uncollap3 {
    transform: rotate(270deg);
    border:none
  }



  .userCheckListHeaderMobModal {
    padding-left: 23px !important;
    padding-right: 20px !important;
  }


  .checkListItemClientMob {
    min-width: 100% !important;
    max-width: 100% !important;
  }



  .checkListItemMob {
    margin-left:0px !important;
  }

  .opac08 {
    opacity: 0.8  !important;
  }

  .checkShowMeBtn {
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    background-color: #e78df7;
    border: 1px solid #9a7ba0;
    padding-left: 10px;
    padding-right: 9px;
    cursor: pointer;
    min-height: 35px;
    display: flex;
    align-items: center;
    color: #fff !important;
    max-height: 30px;
    text-align: left;
    line-height: 14px;

  }

  .checkShowMeBlue {
    /* background-color: #0bf;
    border-color:#0af */
  }
  .pinkColours {
    background-color: #e78df7 !important;
    border: 1px solid #9a7ba0 !important;
    color:white !important
  }

  .navyColours {
    background-color: #3b597c !important;
    color:white !important;
    border: solid #214978 1px!important;
  }

  .darkPurpleColours {
    background-color: #3f2178 !important;
    border: 1px solid #301660 !important;
    color: white !important;
    cursor: pointer;
  }
  @media only screen and (min-width: 820px) {

    .darkPurpleColours:hover {
      background-color: #301660 !important;
      color:white !important

    }
    .navyColours:hover {
      background-color: #214978 !important;
      color:white !important
    }

.pinkColours:hover {
  background-color: #d17fe0 !important;
}
    .purpleColours:hover {
      background-color: #a58fd4 !important;
    }
    .dashTodayCCbreakRowItem:hover {
      border-color:#bbcada

    }

.checkShowMeBtn:hover {
  background-color: #d17fe0;
}

.checkShowMeBlue:hover {
  /* background-color: #0af; */
}

  }
  

  .showMeCheckPopUp {
    width: 14px;
    margin-left: 4px;
    margin-bottom: -2px;
  }

  .showMeCheckPopUpGo {
    width:10px;
    margin-left:6px;
    margin-right:2px;
  }

  .checkPadTop {
    padding-top:12px
  }

  .amendNowDoneBtn {
    opacity: 0.35;
  }

  .checklistLoadImg {
    width:120px;
    margin-top:30px;
    margin-bottom:30px;
    margin-left: 158px;
    margin-right: 212px;
  }

  .checklistLoadImgMobModdal {
    margin: 15px auto !important;
    width: 140px;  }
  .x18938311123255 {
    min-width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
  }

  .deleteTaskSpan {
    border-left:2px solid #0bf;
    padding-left:10px;
    margin-top:10px;
  }

  .lineHeight18 {
    line-height: 18px !important;
  }

  .deleteMyAccRow {
    padding-left: 14px;
    padding-right: 26px;
    margin-bottom: 24px;
  }

  .check3AinfoEditUser {
    margin-left:26px
  }

.noFlex {
  display: block;
}


.noShiftsTxtHoursMob {

  margin-top: 1px !important;
  padding-left: 0px;
  margin-left: -2px; 
}

.noShiftsTxtMobClocks {
  /* margin-top: 1px !important; */
  padding-left: 0px;
  margin-top:-4px !important;
  margin-left: -2px;}



.noUpcomingMgrReqs {
  margin-top: 19px !important;

}

.noUpcomingMgrReqsMob {
  margin-top:-23px !important
}


.noShiftsTxtLeaveMob2 {
margin-top:31px !important}
.noTimesheetsTxt {
  margin-left:30px;
}
.noTimesheetsTxtMob {
  margin-left:10px;
  margin-top:6px
}
.noShiftsTxtLeaveMob3 {
  margin-top:-6px !important}

.noShiftsTxtLeaveMob4 {
margin-top:-19px !important}


.noShiftsTxtLeaveMob5 {
  margin-top: 9px !important;
}

.noShiftsTxtLeaveMob6 {
  margin-top: 11px !important;
  padding-left:6px;
}

.noShiftsTxtLeaveMob6Full {
  padding-left:12px
}

.opac07 {
  opacity:0.7
}


.nightShiftModalBox {
  background-color: #3c407b  !important;
  border-color:#143051 !important
}

.nightBubble {
  border: solid 1px #1c4b7d !important;
  background-color: #84b6f6 !important;
}

.teamRotaNightShiftImgOtherTeam {
  opacity: 0.2;
}

.marginLeftMinus1 {
  margin-left: -1px !important

}

.slideUp {
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
 

}


.mobMenuTopper {
  /* background-color: orange; */
  min-height:70px;
  margin-bottom:10px;
  margin-top:-15px;
  display: flex;
  align-items: center;
  padding-left:15px;
  position: relative;
  z-index:999999;
  border-bottom: 2px solid #375981;
  /* justify-content: space-between; */
}

.mobMenuTopProfileDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 40px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-right: 2px solid #375981;
  padding-right: 14px;
  margin-right:9px

}

.mobMenuTopProfileDiv2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 40px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  /* border-right: 2px solid #375981; */
  /* padding-right: 9px; */
  /* margin-right:9px */
}

.mobMenuTopProfileDiv2Admin {
  margin-left:7px
}

.x82727718111 {
  padding-right:18px;
}
.mobMenuTopperProPic {
  max-width: 35px;
  min-height:35px;
  border-radius:5px;
  object-fit: cover;
  max-width: 35px;
  max-height: 35px;
  margin-right:8px;
}

.mobMenuTopperPlaneImg {
  min-width: 24px;
  max-width: 24px;
  margin-right:8px;
  

}

.mobMenuTopperPlaneImgAdmin {
  min-width: 16px;
  max-width: 16px;
  margin-right:10px;
  

}


.devicePop {
  width: 50px;
  min-height:20px;
  background-color: blue;
  color:white;
  position: fixed;
  right:0;
  top:0;
  z-index:99999999;
  /* display:none */
}

.devicePopSize {
  font-size: 10px;
}
.ios-footer-menu {
  height:65px;
}

.menuUpcomingHolderIos {
  /* background-color: yellow; */
  min-height: calc(100vh - 304px) !important;
  max-height: calc(100vh - 304px) !important;}

  .mobBurgerMenuIos {
    min-height: calc(100vh - 65px);
    max-height: calc(100vh - 65px);
  }

  .notifWrapperIos {
    min-height: calc(96vh - 122px);
    max-height: calc(96vh - 122px);  }

    .calendarSubBarIos {
      bottom:60px  !important
    }

    .absBoxMobIos {
      min-height: calc(100vh - 278px);
      max-height: calc(100vh - 278px);  
    }

      .showFilterCalBoxMobIos {
        /* background-color: orange !important; */
        min-height: calc(100vh - 386px) !important;
        max-height: calc(100vh - 386px) !important;
      }

      .absBoxColCalMobIos {
                /* background-color: orange !important; */
                min-height: calc(100vh - 334px);
                max-height: calc(100vh - 334px);
      }


      .seeUserModalMobIos {
        padding-bottom:35px !important;
      
      }


.showDayModalBoxIos {
  padding-bottom:20px  !important;
}


.paddingBottom20 {
  padding-bottom:20px  !important;

}

.iosDateInput {
  /* background-color: blue; */
  text-align: left !important;
  padding-left:0px !important;
  min-width: 150px !important;
  max-width: 150px !important;
  background-position-x: 117px !important;
  display: flex !important;
  justify-content: flex-start!important;
  padding-right: 30px !important;
}

.iosTimeInput {
  /* background-color: blue !important; */
  min-width: 56px !important;
  max-width: 56px !important;
}

.purpleBgCalSubbarIos {
  bottom: 60px;

}

.teamCalContainerMobIos {
  max-height: calc(100vh - 278px);
    min-height: calc(100vh - 278px);
    margin-top:-8px;
}

.teamSchedContentIos {
  display: flex;
  overflow-x: auto;
  font-size: 8px;
  padding-right: 30px;
  min-width:calc(100vw - 322px);
  /* background-color: purpl e; */
}


.formsShowReqsMobIos {
  min-height: calc(100vh - 285px) !important;
  max-height: calc(100vh  - 285px) !important;
}

.absBoxMobStaffIos {
  min-height: calc(100vh - 274px);
  max-height: calc(100vh - 274px);
}

.absBoxFilterActiveStaffIos {
  min-height: calc(100vh - 324px);
  max-height: calc(100vh - 324px);
}

.shiftsContainerMobOnlyIos {
  min-height: calc(100vh - 281px)!important;
  max-height: calc(100vh - 281px) !important;
}


.showFilterMobOnlyShiftsContainerIos {
  min-height: calc(100vh - 371px) !important;
  max-height: calc(100vh - 371px) !important;
  padding-top: 8px !important;
}

.clockOnsContainerMobNoUnlinkedIos {
  /* background-color: lime; */
  position: fixed;
  top: 175px;
  z-index: 999999;  /* margin-top:80px; */
  min-height: calc(100vh - 280px);
  max-height: calc(100vh - 280px);
  padding-bottom: 45px;
}


.mobUnlinkedExistContainerIos {
  min-height: calc(100vh - 314px);
  max-height: calc(100vh - 314px);
}


.tsContainerStaffMob {
  min-height: calc(100vh - 260px);
  max-height: calc(100vh - 260px);
  min-width: 100dvw !important;
  max-width: 100dvw !important;
}

.trayTxtDiv {
  /* background-color: yellow; */
  position: fixed;
  bottom:11px;
  left:0;
  min-width: 100vw;
  max-width: 100vw;
  min-height:14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index:1111111; 
  font-size:11px;
  font-weight: 500;
  pointer-events: none;

}

.trayTxtDivIos {
  bottom: 23px;
}

.trayTxt1 {
  min-width: 20vw !important;
  max-width: 20vw !important; 
  color:#869bb2

}
.trayTxtHome {
  margin-left:7px;
  margin-right:-7px;
}
.trayTxtAcc {
  margin-left:-8px;
  margin-right:8px;
}

.colourPurple {
  color: #9a74eb !important;
}

.clockPicBtn {
  background-color: #a8cc9a;
  color: white;
  border-radius: 3px;
  min-height: 26px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding-bottom: 1px;
  font-size: 13px;
  border: 1px solid #94b787;
  margin-right: -5px;
  cursor: pointer;
}

  .clockPicPopUp {
    width: 12px;
    margin-left: 2px;
    margin-right: -2px;
    margin-top: 1px;  }

    .clockImgCapt {
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      max-height:80dvh
    }

    .showIamgeModal {
      min-width: 800px;
    }

    .showImageModalTxt {
      font-weight: 500;
      margin-top: 16px;    }

      .purpleColours {
        background-color: #b49ce7 !important;
        border: 1px solid #a47fd0 !important;
      }

      @media only screen and (max-width: 819px) {
        .x2984298291111:hover {
          background-color:#a387df !important ;
        }

        .x373718111:hover {
background-color: #0077ff !important;
        }
        .showIamgeModal {
          min-width: 100dvw;
          max-width: 100dvw;
          position: fixed;
          bottom: 0;
          border-radius: 15px 15px 0 0;
        }
   
        .showImageModalTxt {
          font-size: 14px;
        }
      }


      .x23893299328984 {
        border-color: #72fe36;
        color: #e3ffd7;      }


.x4954984659863 {
  margin-top: -90px;
  margin-bottom: 70px;
}

.clockTimelineLine {
  /* background-color: #87b2ec; */
  background-image: linear-gradient(to bottom, #87b2ec 50%, #fff 50%);
  background-size: 2px 10px;
  min-width: 6px;
  min-height: 50px;
  max-width: 4px;
  margin-left: 30px;
  margin-bottom: -26px;
}

.clockTimelineLine2 {
  margin-top: 40px;
  margin-bottom: -78px;

}

.clockTimelineLineBrk {
  /* background-color:#c9b9f0; */
  background-image: linear-gradient(to bottom, #c9b9f0 50%, #fff 50%);
  background-size: 2px 10px;
  /* margin-left: 50px; */

}

.clockTimelineLineBrk2 {
  margin-bottom: -65px;
}

.terminalTimelineRow {
  background-color: #0077ff;
  border-color: #005ac0;
  color: #fff;}


  .termPicBtn {
    background-color: #0af;
    border-radius: 4px;
    max-height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 6px;
    border: 1px solid #0a6ad8;
    padding-right: 4px;
    margin-right: -4px;
    margin-left: 9px;
    cursor: pointer;
   }

   .termPicBtnPopup {
    width:13px;
    margin-left:2px
   }

   .clockDenyPerm {
    color: #ba0000 !important;
    cursor: pointer;
    text-align: right;
    font-size: 12px;
    font-weight: 600;
    max-width: 120px;
    margin-right: 10px;
    line-height: 13px;
   }

   .activeClocksShBlockImg {
    width: 12px;
    margin-top: 6px;
    margin-left:3px;
    margin-right: 6px;   }


    .schedBlockSchedShImg {
      width:15px;
      margin-right:5px;
    }


    .notifReqsBtn {
      display: flex;
      align-items: center;
      min-height: 35px;
      border-radius: 5px;
      padding-left: 7px;
      padding-right: 4px;
      font-weight: 600;
      line-height: 15px;
      font-size: 14px;
      cursor: pointer;
      color: #143051;
      border: #e3e8ee solid 2px;
    }

    .notifReqsBellImg {
      width:10px;
      margin-top:1px;
      margin-right:3px;
      margin-left:0px;
    }

.disFlexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.disFlexStartMob {
  /* background-color: blue; */
  min-width: calc(100dvw - 150px);
  max-width: calc(100dvw - 150px);
  min-height:44px;

}


.lightBlueColours {
  color: #fff  !important;
  border: solid 1px #0af !important;
  background-color: #0bf !important;
}


.x23949849882442 {
  background-position-x: 170px !important;

}
.rotaClockCLockedOnImg {
  width:12px;
  margin-right:8px;

margin-top:-1px
}



.actClockDataRow {
  display: flex;
  align-items: center;
  min-height: fit-content;
  background-color: #ebf0f7;
  border-radius: 10px;
  border: 2px solid #fff;
  margin-top:10px;
}

.actClockDataBlock {
  display: flex;
  flex-direction: column;
  min-height: 74px;
  border-left:4px solid #fff;
  /* padding-left:5px; */
}

.actClockHighlight {
  min-width: 100%;
  min-height: 8px;
  background-color: #0af;
  /* border-radius: 2px; */
  /* margin-top: 2px; */
}

.actClockDataBlockContent {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left:8px;
  padding-top:2px;
  padding-bottom:5px;
  min-height:86px;
}

.actClockDataBlockTitle {
  font-size: 11px;
  font-weight: 600;
  color: #143051;
  margin-top: 2px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  /* background-color: yellow; */

}

.inlineActClCoffeeImg {
  width:16px;
  margin-right:5px;
  margin-top:-3px;
}

.actClockDataBlockValue {
font-size:14px;
font-weight: 600;
color:#00aaff;
line-height:20px;
}

.w25pc {
  width: 25%;
}

.w33pc {
  width: 33.33%;
}

.w50pc {
  width: 50%;
}

.c516b8b {
  color: #516b8b
}


.activeClockRightSec {
  margin-top: 8px;
  padding-right: 0px !important;
  overflow: visible;
  margin-left: -37px;
}

.paddingTop8 {
  padding-top:8px  !important
}

.c143051 {
  color:#143051 !important
}

.actClTeamName {
  font-size: 12px;
}

.rotaTeamActCloImg {
  width: 14px;
  margin-top: -9px;
  margin-left: 7px;}



  .editClockedTimeBtn {
    min-width: 28px;
    border-radius: 5px;
    border: #e3e8ee solid 2px;
    cursor: pointer;
    padding:5px;
    cursor: pointer;
    margin-left: 15px;
    background-color: #fff;  }



    .installModalBox {
      background-color:white;
      border-radius:10px;
      min-height:432px;
      min-width: 340px;
      max-width: 340px;
      overflow: visible;
      box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 16%);

      /* overflow-x: hidden */
    }

    .installModalBoxUpper {
      display: flex;
      /* background-color: yellow; */
      min-height:447px;
      margin-top:-90px;
    }

    .installModalLeft {
      margin-top:90px;
      width:220px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .closeInstallImg {
      min-width: 18px;
      margin-top: 20px;
      margin-left:20px;
    }

    .installInfoTxt {
      text-align: left;
      font-weight: 600;
      color:#143051;
      font-size:20px;
      margin-top:24px;
      margin-left:5px;

    }
    .installInfoTxt2 {
      text-align: left;
      font-weight: 400;
      color:#143051;
      font-size:16px;
      margin-top:16px;
      margin-left:5px;

    }

    .installAndroid {
      width: 141px;
      height: auto;
      object-fit:cover;
      margin-top: 0px;    }

      .installIos {
        width: 141px;
        height: auto;
        object-fit:cover;
        margin-top: 0px;    }

    .installModalBoxLower {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left:15px;
      margin-top:20px;
      padding-right:15px;
    }

    .installModalBoxLowerIos {
      margin-bottom:15px
    }


    .installCtaBtn {
      min-height:40px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width:150px;
      color:white;
      border-radius:5px;
      cursor: pointer;
      font-size: 18px;
    }


    .installUnderlay {
      /* background: rgba(0, 0, 0, 0.82); */
      /* min-height: calc(100% - 58px); */
      min-height: 100dvh;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100vw;
      position: fixed;
      z-index: 999999999;
      top: 0;
      left: 0;
      animation-name: underlayFadeIn;
      animation-duration: 0.1s;
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      background-color: rgb(119 119 119 / 35%);
    }

    .zIndex1 {
      Z-index: 1  !important
    }

  .subBarQtyNum {
    background-color: #0af;
    padding: 2px;
    border-radius: 3px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 9px;
    font-weight: 600;
    height: 14px;
    display: flex
;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 4px;
    margin-top: 0px;
    color: white;
  }

  .subBarQtyNumMgrMgr {
    background-color: yellow;
  }

  
  .shiftsHeaderQty {
    background-color: #0af;
    padding: 2px;
    border-radius: 3px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 9px;
    font-weight: 600;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 2px;
    margin-top: -10px;
    color: white;  } 

    .subBarQtyNumMgr, .shiftsHeaderQtyMgr {
      background-color: #ef5353;
    }

    .paddingLeft0 {
      padding-left: 0px !important
    }

    .needToVeri {
      padding-left:10px;
    }

    .desktopNavQtySpan {
      background-color: #00aaff;
      border-radius: 3px;
      color: white;
      padding-left: 5px;
      padding-right: 5px;
      font-weight: 600;
      font-size: 12px;
      margin-left: 5px;
      min-height: 20px;
      display: flex;
      align-items: center;
      min-width: 18px;
      justify-content: center;
    }


    .x98763274:hover {
      background-color: #30537b !important;
    }



.showNotifReqsUnderlay {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: transparent; or use opacity: 0; */
  /* pointer-events: none; */
  background-color: rgb(0, 0, 0, 0.3) !important;
  /* cursor: pointer; */
  /* -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgb(119 119 119 / 50%); */
}


    .showNotifReqsModal {
      background-color: white;
      border-radius:10px;
min-height:200px;
min-width: 460px; 
max-width: 460px;
/* margin-top:-100px; */
    }

    .showNotifReqsModalMid {
      margin-top:0;
    }
    .notifReqsHeader {
      display: flex;
      min-width: 100%;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      color: #143051;
      font-size: 18px;
      margin-bottom: 14px;
      padding-left: 5%;
      padding-right: 5%;
      margin-top: 15px;
    }

    .notifReqsContentWrapper {
      border-bottom:1px solid #e0ebf8;
      max-width: 92%;
      margin-left:4%;
      overflow:visible
    }

    .notifReqsShoulder{
      margin-top:-8px;
    /* border-top:1px solid #e0ebf8; */
    min-height:46px;
    /* max-width: 90%; */
    /* margin-left:5%; */
    display: flex;
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    }

    .notifReqsMenu {
      /* background-color: blueviolet; */
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow:visible;
      padding-left:7px;


    }
    
    .notifReqsMenuItem {
      font-size: 14px;
      font-weight: 600;
      color: #8290b6;
      cursor: pointer;
      overflow: visible /* margin-right:10px; */;
      /* background: yellow; */
      min-height: 47px;
      align-items: center;
      display: flex;
      overflow: visible;
    }

 
.notifReqsMenuItemSelected {
  color:#143051;
  cursor: pointer;
}

.notifReqsMenuItemSelected:hover {
  opacity: 1;
}
    .notifReqsMenuItemSplit {
      min-width: 1px;
      min-height: 14px;
      max-height: 14px;
      margin-left:8px;
      margin-right:8px;
      background-color: #e0ebf8
    }

    .notifReqsMenuQty {
      background-color: #ef5353;
      color: white;
      font-weight: 600;
      font-size: 9px;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 3px;
      margin-top: -11px;
      min-height: 13px;
      display: flex;
      align-items: center;
      margin-left: -4px;
      margin-top: 2px !important;
      margin-top: -24px !important;
       }


    .notifReqsContentBody {
      background-color: #e7edf5;
      max-width: 100%;
      /* margin-left: 4%; */
      min-height:calc(100dvh - 200px) !important;
      border: 1px solid #e0ebf8;
      border-bottom: none;
      border-radius: 5px 5px 0 0;
      max-height: calc(100dvh - 200px) !important;
padding-bottom:40px;
      overflow-y: auto;
      /* padding-top:10px */
    }


    @media only screen and (min-width: 820px) {

.approveClock:hover {
  background-color: #77b75f;
}
      .greyColours:hover {
        background-color: #bbcada !important;
      }
.notifReqsBtn:hover {
  border-color: #d2d9e3;
}
      .notifReqsContentBody {
        /* margin-left: 4%; */
        min-height: 500px;
    
        max-height: calc(100dvh - 306px) !important;
        min-height: calc(100dvh - 306px) !important;

      }


    }
    .notifReqsFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 100%;
      padding-left:4%;
      padding-right:4%;
      min-height:50px;
    }

    .notifReqsLoadingImg {
      margin-top:30px;
    }


    .noMgrReqsTxtStrip {
      font-weight: 400;
      font-size: 14px;
      color: #143051;
      padding-left: 30px;
      padding-right: 40px;
      margin-top: 32px;
      line-height: 18px;
      max-width: 400px;
      text-align: left;
    }


    .mobNotifReqsQty {
      background: #a67aff;

border-color:#403a8e !important
    }



    .reqUnitParentNotif {
      padding-top: 10px;
      margin-bottom: 3px;
      margin-top: 20px;
    }
  

    .notifReqExcMinsSpan {
      color:#95a0ee;
      margin-left:4px;
    }


    .x34986498542 {
      margin-bottom:80px
    }

    .notifReqSubBarReq {
      /* max-width: 148px; */
      /* float: right; */
      /* margin-right: 8%;   */
      }

      .mgrNotifDsMade {
        font-weight: 500;
        font-size: 12px;
        color: #516b8b;
        text-align: left;
        margin-left: 6px;
      }


      .disabledApprove {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
      }

      .x2978457  {
        margin-right:10px;
      }


      .reqAllowPositiveGreen {
        color: #82c26a;
        font-weight: 600;      }


      .reqAllowNegativeRed {
        color: #ba0000;
        font-weight: 600;      }



        .mgrReqItemNotifReq {
          min-width: 94%;
          max-width: 94%;
          min-height: 100px;
          max-height: 100px;
        }


.chatToUsHeader {
  font-weight: 600;
}


.unableToApproveOwn {

}


.ccDivTimeline {
  overflow:visible;
  flex-direction: row !important;
}

.ccDiv {
  /* border: 1px solid #cbd7e6; */
  border:1px solid #e3e8ee;

  border-radius: 4px 4px 4px 10px;
  font-weight: 600;
  font-size: 14px;
  overflow: visible;
  max-width: 59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #143051;
  max-height: 32px;
  min-width: 59px;
  min-height: 32px;

}

.ccDiv2 {
  border-radius: 4px 4px 10px 4px

}
.ccDivUpper {
  display: flex;
  justify-content: space-between;
  min-width: 48px;
  min-height: 4px;
  margin-top: -6px;

}

.ccDivUpperNip {
  min-width: 2px;
  min-height: 5px;
  background-color: #d0d7e0;
  border-radius: 10px 10px 2px 2px;
  transform: rotate(-30deg);
  max-height: 3px;
}

.ccDivUpperNip2 {
  transform: rotate(30deg);

}

.ccDivMid {
  min-height: 27px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  overflow: visible;
  min-width: 43px;

  
}


.ccMgrSplitter {
  margin-left:3px;
  margin-right:3px
}


.ccDivTitle {
  color: #00aaff;
  font-size: 11px;
  text-align: left;
  margin-left: 4px;
  margin-top: -14px;
  padding-left: 4px;
  padding-right: 18px;
  max-width: 24px;
  background: white;
}

.ccDivTitle2 {
  margin-left:14px
}

.ccDivTs {
  font-size: 16px;
  overflow:visible
}

.x4985982567876 {
  /* padding:1px; */
}

.ccTmw {
  background-color: #516b8b;
  color: white;
  padding: 3px;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 600;
  margin-top: -30px;
  margin-left: -7px;
  z-index: 99999;
  margin-right:-10px;
}

.ccNotifItem {
min-height:126px;
}

.mgrReqNameTab2 {
  margin-left: 3%;
  margin-top: -123px;
  margin-bottom: 93px;
}

.x435397859383 {
  margin-top: -152px;
  margin-bottom: 122px;
}

.x435397859383Logged {
  margin-top: -126px;
  margin-bottom: 96px;
}

.x29898988444233 {
  margin-top:-27px
}

.x29898988444233cc {
  margin-top:-31px
}

.x29898988444233Logged {
  margin-top:-15px
}

.x29898988444233b {
  margin-top:43px
}
.ccMgrNotifBlob {
  min-height:  86px
}
.ccMgrNotifBlobClockCard {
  min-height: 103px;
  margin-top: 5px;  
}

.notifMgrTsSubBlob {
  min-height: 101px;
  margin-top: 7px;}

.mgrNotifSchedTitle {
  font-size: 10px;
}

.mgrNotifSchedMain {
  color:#143041;
  font-weight: 600;
}

.mgrNotifDataRow {
  margin-top: 5px;
    font-weight: 400;
    margin-bottom: 5px;
}

.notifMgrReqsPlaneImg {
  width: 15px;
  margin-right: 5px;
}

  .lineHeight15 {
    line-height: 15px  !important
  }

  .clockedOffFinalRow {
    /* background-color: orange; */
    /* border-left:none; */
    border-color:white  !important
  }

  .picLoadingImg {
    width: 140px;
    margin-bottom: 40px;
    margin-top: 60px;
   }

   .notifReqsSelBlob {
    display: flex;
    background-color: #fff;
    min-height: 4px;
    min-width: 100%;
    border-radius: 10px;
    margin-top: -10px;
    margin-bottom: 7px;
   }

   .bg00ccff {
    background-color: #00ccff;
   }

   .notifReqsMenuUnit {
    overflow:visible
   }

   .x29849835 {
    margin-top:-2px
   }

   .notifMgrReqsPlaneImg2 {
    min-width: 15px;
    margin-right: 5px;
   }

   .fontWeight600 {
    font-weight: 600 !important;
    font-size: 16px;
   }

   .ccNotifItemLogged {
    /* background-color: orange; */
    
    min-height:100px;
   }


   .decShBorder {
    border-color:#ba0000
   }

   .bgBa00002 {
    background-color: #ba0000 !important;
   }

   .threeWdotsImg {
    width: 3px;
    margin-left: 8px;
    margin-right: 2px;   }




    .kkk1 {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 20px;
      max-height: 20px;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 26px;
      text-align: center;
      padding-left: 1px;
      margin-top: 6px;
      margin-bottom: 0px;
    }


    .kkk2 {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 20px;
      max-height: 20px;
      font-size: 14px;
      color: #0af !important;
      font-weight: 600 !important;
      margin-top: 1px;
      margin-bottom: -1px;      text-align: center;

    }


    .kkk3 {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height:14px;
      max-height:14px;
      color: #bbcada;
    font-size: 16px;
    font-weight: 600;      text-align: center;

    }

.decShUpper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 2px solid #e6eaee; */
  padding-bottom:16px;
  padding-left:20px;
  padding-right:20px;
}
    .decShUserRightWrapper {
      display: flex;
      flex-direction: column;
      align-items: space-around;
      /* min-width: calc(100% - 120px); */
      max-width: calc(100% - 140px);
      overflow:visible;
    }

    .decShUserRightTitle {
      font-weight: 500;
      /* color:#516b8b; */
      min-width: 135px;
      font-size: 16px;
      line-height:26px;
      margin-bottom:14px;
      margin-bottom: 24px;
      margin-top: 10px;
    }

.decShUserBlock {
  display: flex;
  align-items: flex-start;
  overflow: visible;
}

.decShUserPP {
  min-width:28px;
  max-width: 40px;
  min-height:40px;
  max-height:40px;
  border-radius: 5px;
  object-fit: cover;
}

.decShUserRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 8px;
  overflow:visible;
}

.decShUserUpper {
  font-size: 14px;
  line-height:18px;
  font-weight: 500;
  color:#143051;
  max-height:18px;
min-width: fit-content;
overflow:visible;
white-space: nowrap;



  /* padding-right: 10px; */

}

.decShUserJt {
  font-size: 12px;
  margin-top:3px;
  line-height:14px;
  /* padding-right: 20px; */
  /* background-c olor: orange; */
  min-height:50px;
  color:#214978
}

.marginTopMinus32 {
  margin-top:36px
}

.decShIllusBlock {
  /* background-color: yellow; */
  flex-direction: column;
display: flex;
}

.decShTeamBlock {
  display: flex;
  align-items: flex-start;
  overflow:visible;
  margin-left: 28px;
  margin-top: 16px;
  /* margin-bottom: 39px; */
  /* background-color: yellow; */
  /* float: left; */

}

.decShTeamNameImg {
  width:12px;
  margin-top:-1px;
  margin-left:5px;
  opacity: 0.5;
}
.decShTeamNameImgTag {
  margin-top:2px;
}
.decShTeamName {
  font-size: 14px;
  margin-left:6px;
  margin-top: -2px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  line-height:14px;
}

.decShTag {
  margin-right:6px;
  line-height:18px;
  border-left:1px solid #bbcada;
  padding-left:6px;
}

.decShTagFirst {
  border:none;
  padding-left:0px;
}


.decShBtns {
  display:flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  margin-top:-14px;
  padding-left:5px;
  padding-right:5px;
  
}

.decShBtn {
  border:2px solid #143051;
  display: flex;
  align-items: center;
  font-size: 14px;
  width:30%;
  height:40px;
  font-weight: 600;
  border-radius: 5px;
  justify-content: space-around;
  cursor: pointer;
}

.decShBtnDisregard {
  width:14px;
  margin-left:-10px;
}

.decShBtnDisregardOpenUp {
  width:16px
}

.bgBbcada {
  background-color: #859dbb;
  color:#fff
}

.decDisregardSplit {
  min-width: 2px;
  background-color: #e6eaee;
  min-height:35px;
}

.shItemTimeRowDecSh {
  font-size: 16px;
  min-height:32px;
  width:60px
}

.shItemTimesDecSh {
  min-height:68px;
  max-height: 68px;
}


.absCalDivLowerAllTimeDecSh {
  min-height:68px;
  max-height:68px;
  min-width: 48px;
}


.decShBody {
  max-height: fit-content !important;
  height: fit-content !important;

    min-height: fit-content !important;
    padding-bottom:15px;
    /* max-height:calc(100dvh - 140px) !important; */
}

.decShBodyReassign {
  /* max-height: fit-content !important;
  height: fit-content !important;
  padding-bottom:15px;
  max-height:calc(100dvh - 140px) !important; */
    /* min-height: -moz-fit-content !important; */
    min-height: calc(100dvh - 200px) !important;
    height: fit-content !important;

    max-height: calc(100dvh - 200px) !important;

}

.decShActionTxt {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #143051;
  margin-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
}

  .decShReason {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color:#143051;
    /* opacity: 0.5; */
    margin-left:30px;
    padding-right:20px;
    margin-top:5px;
  }

  .decShReasonTitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #00aaff;
    margin-left: 30px;
    padding-right: 20px;
    margin-top: 18px;
  }


  .decShActionSplitter {
    background-color: #e6eaee;
    min-height: 2px;
    min-width: 100%;
    margin-top: 30px;
    margin-bottom: -19px;
  }

  .reassignDiv {
    background-color: #f4f8fe;
border:solid 2px #e6eaee;
max-width: 90%;
border-radius:5px;
margin-left:5%;
margin-top:-10px
  }


  .decShActionTxtReassign {
    margin-top:36px
  }

  .reassignUserRow {
    border-bottom:2px solid #e6eaee;
    display:flex;
    align-items:center;
    min-height:114px;
    padding-left:5px;
    padding-right:5px;
  }

  .reassignUserImg {
    min-width: 26px;
    border-radius:3px;
    max-width: 26px;
    height:38px;
    object-fit: cover;
  }

  .reassignUserNameJt {
    display:flex;
    flex-direction: column;
    margin-left:10px;
  }

  .reassignUserNameUpper {

font-weight:600;
font-size: 14px;
line-height:16px;
max-height:18px;
min-width: 110px;
  }


  .reassignUserNameLower {
max-height:24px;
max-width: 100px;
line-height:12px;
font-size: 10px;
font-weight: 500;
color:#00aaff;
display: flex;
min-height:24px;
/* align-items: center; */
/* background-color: black; */
/* margin-top:2px; */
  }

  .reassignUserRowLeftUpper {
    display: flex;
    align-items: center;
  }


  .viewWeekHoursBtn {
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    /* border-radius: 5px; */
    /* border: #e3e8ee solid 1px; */
    padding-left: 7px;
    padding-right: 6px;
    cursor: pointer;
    min-height: 30px;
    max-height: 30px;
    display: flex;
    align-items: center;
    color: #143051;
    /* background-color: #f9fb ff; */
    margin-top: 6px;
    margin-left: 30px;
    border: none;
    border-top: 1px solid #bbcada;
    border-radius: 0px;
    padding-top: 6px;
    background: none;
    
  }




  .wkHRpopupImg {
    width:12px;
    margin-left:4px;
  }

  .reassignUserRowSplitter {
    min-width: 2px;
    background-color: #e6eaee;
    min-height: 90px;
    margin-left:10px;
  }

  .reassignTitleKey {
    display:flex;
    align-items: center;
    margin-top:-40px;
    /* justify-content: ; */
  }

  .reassignTitleKeyLeft {
    min-width: 190px;
    visibility: hidden;
  }

  .reassignTitleKeyRight {
    font-weight: 500;
    font-size: 12px;
  }

  .reassignUserRowRight {
    /* background-color: blue; */
    min-height: 30px;
    min-width: calc(100% - 163px);
    margin-left: 4px;
    display: flex;
    flex-direction: column;
    min-height:100px;
    justify-content: center;
    align-items: center;
  }

  .assignToUserBtn {
    font-weight: 500;
    font-size: 14px;
    max-width: 90px;
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 35px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
    border: 1px solid #cbd7e6;
    color: #8ea7c5;
    background: #fcfcfc;
  }

  .assignWhiteGo {
    width: 5px;
    margin-left: 23px;
  }


  .x2928498494455 {
    /* background-color: lime; */
    margin-top: -21px;  }



    .reassignUserRowRight {
      padding-bottom:10px;
      padding-top:8px;
    }
    .reassignShUnit {
      min-width: 92px;
      max-width: 92px;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      border-radius: 5px;
      border: #e3e8ee solid 1px;
      padding-left: 7px;
      padding-right: 6px;
      cursor: pointer;
      min-height: 36px;
      max-height: 36px;
      display: flex;
      align-items: center;
      color: #143051;
      background-color:white;
      margin-bottom:5px;
    margin-top:5px;
    }
    
    .reassignShUnitClickable {
      cursor: pointer;
    }
    
 
    .reassignShUnitStrip {
      overflow: visible;
    }
    .reassignShUnitStripBottom {
      max-height:14px;
      line-height:14px;
    }
    .reassignShUnitStripTop {
      max-height:14px;
      line-height:14px;
      font-size: 10px;

    }

    .reassignShUnitLeave {
      background-color: #e9e9ff;
    }
    .reassignShUnitAbsence {
      background-color: #dfe7f0;
    }

    .decShNwDayTxt {
      color: #516b8b;
      font-size: 11px;
      font-weight: 500;
      line-height: 12px;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
      max-width: 110px;
      margin-bottom: 10px;    }


      .notifReqsContentBodyHours {
        min-height: calc(100dvh - 241px) !important;
        max-height: calc(100dvh - 241px) !important;
      }

      .notifReqsContentBodyHoursDesktop {
        min-height: calc(100dvh - 347px) !important;
        max-height: calc(100dvh - 347px) !important;
      }


      .mgrReqNotifHoursKey {
        min-height: 35px;
        border-top: 1px solid #e0ebf8;
        margin-top: -4px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        padding-top: 6px;
      }

      .hoursGroupFiltBtn {
        min-width: fit-content;
        max-width: fit-content;
        cursor: pointer;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        border-radius: 5px;
        border: #e3e8ee solid 1px;
        padding-left: 7px;
        padding-right: 6px;
        cursor: pointer;
        min-height: 30px;
        max-height: 30px;
        display: flex;
        align-items: center;
        color: #8290b6;
        background-color:white;
        cursor:pointer;
        margin-right:4px;
        overflow:visible
      }

      .hoursGroupFiltActive {
        width:11px;
        margin-left: 2px; 
        
      }

      .hoursGroupFiltImg {
        width:11px;
        margin-left: 2px;        opacity: 0.2;
        /* visibility: hidden; */
      }

      .x42984982455 {
        margin-left:8px
      }

      .x42984982455Active {
        margin-left:8px;
      }

      @media only screen and (max-width: 400px) {


        .hoursGroupFiltActive {
          width:12px;
      
  
          
        }
  
        .hoursGroupFiltImg {
          width:12px;
      
        }
      }


      @media only screen and (max-width: 372px) {
.hoursGroupFiltBtn {
  margin-right: 4px;
}

     
      }


      .swapUnitInnerFlexNotifs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 108%;
        padding-right:12px;
  /* padding-right:12px; */
      }

      .swapUnitInnerFlexNotifsMobModal {
        min-width: 100%;
      }

      .upToDate {
        font-weight: 600;
      }


      .x34875478545656 {
        width:14px;
      }

      .formUnitParentNotifReq {
        margin-top:22px;
        margin-bottom:0px;
      }   
      
      .absSLAitem {
        margin-bottom: -26px !important;
        margin-top: 19px;
       }

       .notifReqSubQty {
        background-color: #ef5253;
        color: white;
        min-width: -moz-fit-content;
        min-width: fit-content;
        padding-left: 3px;
        padding-right: 3px;
        border-radius: 3px;
        margin-left: 3px;
        font-size: 10px;
        margin-top: -24px;
        margin-left: 0px;
        margin-right: -13px;
        z-index: 60;
       }

       .x45678657 {
        max-width: 82%;
       }

       .minWidth94 {
        min-width: 94%;
       }

       .marginLeft3pcx {
        margin-left:3%
       }

       .leaveNameBurgerNotifReq {
        min-width: 10px;
        max-width: 10px;
        margin-right: 7px;
        margin-left: 1px;       }


        .rotaPersonNotifReq {
          max-width: 10px;
          margin-right: 7px;
          margin-left: 1px;        }


          .x2389484455 {
            margin-top: 9px !important;
    margin-bottom: 8px !important;
          }

          .x389498483433 {
            margin-bottom:5px;
          }

  .darkRedBg {
    background-color: #ba0000;
  }

  .leaveNameBurgerUnc {
    width: 11px;
    max-width: 11px;
    min-width: 11px;
    margin-right: 9px;
    }

    .mgrReqItemWidth94 {
      min-width: 94%;
      max-width: 94%;
    }

    .teamClockedIconTopLEFT {
      width: 15px;
      min-width: 47px !important;
      padding-top: 7px !important;
      margin-left: -9px !important;
      margin-top: 4px !important;
      padding: 12px !important;
    }



    .userHoursModalBoxArr {
      /* background: white; */
      max-width: 90%;
      margin-left: 5%;
      /* margin-top: 15px; */
      /* border: 1px solid #e0ebf8;
      border-radius: 6px;  */
       }

  .userHoursItem {
    background-color: white;
    margin-top: 10px;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border:1px solid #e0ebf8;
    border-radius:5px;
    cursor: pointer;
  }

  .userHoursItemLeft {
    /* background-color: yellow; */
    min-width: 30px;
    margin-left:8px;
  }

  .userHoursItemMid {
    min-width: calc(100% - 150px);
    max-width: calc(100% - 150px);

    /* background-color: lime; */
  }

  .userHoursItemMidRow {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    min-height: 23px;
    color: #516b8b;
text-align: left;  }

  .userHoursDataRowImg {
    width: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .userHoursItemLeftImg {
    width:17px;
  }

  .x484877857878 {
    color:#0cbce8;
    font-weight: 600;
  }

  .userHoursItemRight {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 98px;
  }

  .negativeTxt {
    color: #ba0000
  }

  .userHoursLeaeveBg {
    background-color: #e9e9ff !important;
  }
  .userHoursAbsenceBg {
    background-color: #dfe7f0 !important;
  }

  .userHoursArrTitle {
    color: #00aaff;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    margin-top: 25px;
    margin-left: 9px;
    margin-bottom: 13px;  }


    .noArrUserHourscontentBox {
      max-height: 386px !important;
      min-height: 386px !important;
    }

    .userHoursModalContentBoxWithArr {
      min-height:73dvh;
    }

    .noMarginBottom {
      margin-bottom: 0px  !important
    }

.userHoursEditBtn {
  min-width: 26px;
  max-width: 26px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  min-height: 26px;
  max-height: 26px;
  display: flex;
  align-items: center;
  color: #143051;
  background-color: white;
  margin-left: 7px;
  cursor:pointer
}


.userHoursDatePeriodInput {
  border-radius: 5px;
  /* margin-top: -30px; */
  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 16px !important;
  padding-left: 8px;
  padding-right: 2px;
  letter-spacing: 1px;
  padding-top: 1px;
  /* text-align: center; */
  font-weight: 500;
  max-width: 132px;
  min-width: 132px;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 2px solid #e3e8ee;
  /* margin-left: 0px; */
  /* margin-top: 4px; */
  cursor: text;

}

.userHoursUntil {
  font-weight: 500;
  font-size:12px;
  color:#143051
}

.periodLoadingImg {
  width: 110px;
  margin-top: 30px;
}

.invalUserHoursDateInput {
  font-weight: 500;
  color: #143051;
  text-align: left;
  border-color:#ba0000;
  /* margin-top: 10px; */
  /* margin-left: 14px; */
  font-size: 14px;}



  .staffReqQtyBlobNotif {
    background-color: #a67aff;
    color: white;
    border-radius: 4px;
    padding: 4px;
    margin-left: 7px;
    border: 1px solid #a67aff;
  }


  .installBenefits {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    padding-right: 20px;
  }

  .installBenRow {
    display: flex;
    color: #143051;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
  }


  .installBenImg {
    min-width:14px;
    margin-right:9px;
  }

  .activeClockNameBrkStatusParent {
    overflow:visible;
  }

  .activeClockBrkStatusTxt {
    font-size: 12px;
    color: #00aaff;
    font-weight: 500;
    margin-bottom: -4px;
    margin-top: -1px;
    margin-left: 1px;
    text-align: left;
  }


  .activePause {
    display:flex;
  }

  .activePauseBlock {
    min-width: 5px;
    background-color: #00aaff;
    margin-left:4px;
    min-height:20px
  }

  .colourba0000 {
    color:#ba0000 !important
  }

  .onlineQty {
    margin-left: 12px;
    color: #74c656;
    background: none;
    margin-top: -23px;
    text-align: right;
    font-size: 11px;
    font-weight: 600;
    }


    .activeClocksMenuBar {
      display:flex;
      align-items: center;
      margin-top:6px;
    }

    .activeClocksMenuBarBtn {
      background: #f5faff;
      border: 1px solid #cbd7e6;
      font-weight: 500;
      color: #214978;
      border-radius: 5px;
      padding-left: 8px;
      padding-right: 8px;
      max-height: 20px;
      display: flex;
      min-height:26px;
      align-items: center;
      font-size: 11px;
       margin-right:8px;
      cursor: pointer;    }


      .x848888484233 {
        margin-top:-10px  !important
      }

      

      .mobOnlineNum {
        position: fixed;
        top: 7px;
        left: 118px;
        font-size: 11px;
        font-weight: 600;
        color: #74c656;

      
      }

      .absCalDivLowerMonthReqs2 {
        margin-bottom: 0px;
        margin-top: -6px;
      }

      .rowReverse {
        flex-direction: row-reverse !important;
      }

      .absCalDivLowerMonthReqs3 {
        margin-top: 1px;
        margin-bottom: -3px;
        font-size: 12px;      }



    .x9348598853 {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0px;
      margin-top: -2px;
      color: #859dbb;
    }

    .showOnBrkManageClocked {
      margin-top:10px
    }
    

    .greyColours {
      border: solid 1px #bbcada;
      background-color: #cbd7e6;
    }
    

    .footerBtnSelBar {
      min-height: 2px;
      background-color: #214978;
      min-width: 54px;
      border-radius:0 0 2px 2px;
      /* margin-top: 5px; */
      margin-bottom: -10px;
      /* margin-right: -12px; */
      position: fixed;
      bottom: 63px;
      margin-left: -15px;
       }

    .purpBg {
      background-color: #874fff;
    }

    .footerBtnSelBarHome {
      margin-left:-2px;
      opacity: 0.4;
      background-color: #00ccff;
    }

    .minWidth100Pad2 {
      min-width: 100%;
      max-width: 100%;
      padding-left:2%;
      margin-left:0px;
    }


    .x2394984855885 {
      min-width: 151px;
      max-width: 151px;
    }


    .adminNavBarBtnHeight {
margin-top:-10px    }

.notifTitleHeaderTxt {
margin-top:-4px;
margin-left:4px;

}


.disableNotif22 {
  pointer-events: none;
  opacity: 0.5;
}